export const detailTabs = [
  'Charts by Fund',
  //'Info2',
  'Holdings',
  'Info',
  'Charts',
  'Technical',
  //'Candle',
  'Factors',
  'Chat',
];

export const detailHeaders = [
  {
    id: 'date',
    name: 'Date',
    title: 'Date',
  },
  {
    id: 'shares',
    name: 'Shares Held',
    title: 'Shares Held',
  },
  {
    id: 'weight',
    name: 'Weight',
    title: 'Weight',
  },
  {
    id: 'sharesmarketvalue',
    name: 'Market Share',
    title: 'Market Share',
  },
  {
    id: 'dailychange',
    name: 'Daily Change',
    title: 'Daily Change',
  },
  {
    id: 'dailycapitalinjection',
    name: 'Daily Buy/Sell',
    title: 'Daily Buy/Sell',
  },
  {
    id: 'weeklycapitalinjection',
    name: 'Weekly Buy/Sell',
    title: 'Weekly Buy/Sell',
  },
  {
    id: 'priceclosed',
    name: 'Close Price',
    title: 'Close Price',
  },
  {
    id: 'previousclose',
    name: 'Previous',
    title: 'Previous Close Price',
  },
  {
    id: 'tradenotedir',
    name: 'M.O. Direction',
    title: 'Market Order Direction. ETF placed a market order of this amount.',
  },
  {
    id: 'tradenoteshares',
    name: 'M.O. Shares',
    title: 'Absolute shares in  Market Order Buy/Sell',
  },
  {
    id: 'tradenotevalue',
    name: 'M.O. Value',
    title: 'Value of Market Order Buy/Sell',
  },
  {
    id: 'float',
    name: 'Float',
    title:
      'The number of shares available to retail investors to buy and sell. Restricted, closely-held (Institutional, Employee) held shares are excluded.',
  },
];
