// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine } from '@nivo/line';
import '../styles/charts.scss';
import { formatNumber } from '../helpers/format';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const colorMap = [
  '#be005c',
  '#00aaff',
  '#aa00ff',
  '#ffaa00',
  '#1ea49d',
  '#b6ade4',
  '#00d9ff',
];
const MyResponsiveLine = ({ chartData, type }) => {
  let dataSet = [];
  let priceSet = [];

  const tempCloseDataSet = chartData
    .filter((c) => c.data.length > 0)
    .map((cc, index) => ({
      id: cc.fund,
      data: Object.entries(cc.data[0].close)
        .map((d) => ({
          x: d[0],
          y: d[1],
        }))
        .slice(1),
      color: colorMap[index],
    }));

  priceSet = tempCloseDataSet[0].data.map((d) => d.y);

  if (type !== 'close') {
    const tempDataSet = chartData
      .filter((c) => c.data.length > 0)
      .map((cc, index) => ({
        id: cc.fund,
        data: Object.entries(cc.data[0][type])
          .map((d) => ({
            x: d[0],
            y: d[1] ? d[1] : 0,
          }))
          .slice(1),
        color: colorMap[index],
      }));

    const tempCombined = tempDataSet.map((t) => t.data);

    if (tempCombined.length > 1) {
      const combinedSummed = tempCombined[0].map((tc, index) => ({
        x: tempCombined[0][index].x,
        y: tempCombined.map((t) => t[index].y).reduce((a, b) => a + b, 0),
      }));

      const combinedDataSet = {
        id: 'Aggregate',
        data: combinedSummed,
        color: '#fff',
      };
      dataSet = [...tempDataSet, combinedDataSet];
    } else {
      dataSet = tempDataSet;
    }
  } else {
    dataSet = [{ ...tempCloseDataSet[0], id: 'Price' }];
  }

  return (
    <ResponsiveLine
      data={dataSet}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=' >-.2f'
      curve='natural'
      axisTop={null}
      axisRight={
        type !== 'close' && {
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          format: (value) => {
            return formatNumber(value, 0);
          },
        }
      }
      axisLeft={
        type === 'close' && {
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          format: (value) => {
            return formatNumber(value, 0);
          },
        }
      }
      axisBottom={null}
      /* axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 1,
        tickRotation: 0,
        legend: 'transportation',
        legendOffset: 36,
        legendPosition: 'middle',
      }} */
      /* axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'count',
        legendOffset: -40,
        legendPosition: 'middle',
      }} */
      enableGridX={false}
      enableGridY={false}
      //colors={{ scheme: 'set1' }}
      colors={type === 'close' ? ['#666'] : colorMap}
      pointSize={5}
      //pointColor={{ theme: 'labels.text.fill' }}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      tooltip={(input) => {
        return (
          <div className='tool-tip small'>
            <strong>
              {input.point.serieId} {input.point.data.x}
            </strong>
            <div>{formatNumber(input.point.data.y, 0)}</div>
            <div>${priceSet[input.point.id.split('.')[1]]}</div>
          </div>
        );
      }}
      theme={{
        tooltip: {
          container: {
            background: '#333333',
          },
        },
      }}
      //animate={false}
      legends={[
        {
          anchor: 'top',
          direction: type === 'close' ? 'column' : 'row',
          justify: false,
          translateX: 23,
          translateY: type === 'close' ? -100 : -45,
          itemWidth: 70,
          itemHeight: type === 'close' ? 80 : 20,
          itemsSpacing: 20,
          symbolSize: 10,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          itemTextColor: '#777',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
export default MyResponsiveLine;
