import React, { useState, useEffect } from 'react';
import { formatNumber, formatCurrency } from '../helpers/format';
import { getWatchlistStats } from '../helpers/api';

function PanelWatchlist({ handleShowModal }) {
  const [items, setItems] = useState([]);
  const getWL = async () => {
    let wlItems = await getWatchlistStats();
    setItems(
      wlItems
        .sort((a, b) => {
          return b.totalwatchers - a.totalwatchers;
        })
        .slice(0, 19)
    );
  };

  useEffect(() => {
    getWL();
  }, []);

  return (
    <div>
      <h4 className='text-center'>
        <span className='faded'>Top Watched on Lucid (All Funds)</span>
      </h4>
      <div className='panel-content'>
        <table>
          {items.map((l, index) => (
            <tbody key={`${l.ticker}${index}`}>
              <tr onClick={() => handleShowModal(l.ticker)} title={l.company}>
                <td>
                  <b>{l.ticker}</b>
                  <div className='small'>
                    ${l.close}
                    <small className='faded'>Close</small>
                  </div>
                  <div className='small'>
                    ${l.previous}
                    <small className='faded'>Previous</small>
                  </div>
                </td>

                <td>
                  <>
                    <span>
                      {formatNumber(l.totalwatchers)}{' '}
                      <small
                        className={
                          l.chgpercent > 0 && l.chgpercent !== 0
                            ? 'success weight-indicator'
                            : 'danger weight-indicator'
                        }
                      >
                        <i
                          className={
                            l.chgpercent > 0
                              ? 'ion-arrow-up-a'
                              : 'ion-arrow-down-a'
                          }
                        ></i>
                        {l.chgpercent}%
                      </small>
                    </span>
                    <div className='small faded'>Watchers</div>
                  </>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        {items.length === 0 && (
          <div className='text-center'>
            <p>Data is unavailble. Please refresh.</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default PanelWatchlist;
