import { getSelectedFundGroup } from './fundvars';

export const getTabId = (code) => {
  // 1 Numerics, 2 A-F, 3 G-K, 4 L-P, 5 Q-U, 6 V-Z
  const AF = ['a', 'b', 'c', 'd', 'e', 'f'];
  const GK = ['g', 'h', 'i', 'j', 'k'];
  const LP = ['l', 'm', 'n', 'o', 'p'];
  const QU = ['q', 'r', 's', 't', 'u'];
  const VZ = ['v', 'w', 'x', 'y', 'z'];
  if (code) {
    let firstChar;
    const codeSliced = code.slice(0, 1);

    //Check to see if firstChar is not a number
    if (typeof codeSliced === 'number') {
      firstChar = +codeSliced;
    } else {
      firstChar = codeSliced.toLowerCase();
    }

    if (AF.includes(firstChar)) {
      return 'A-F';
    } else if (GK.includes(firstChar)) {
      return 'G-K';
    } else if (LP.includes(firstChar)) {
      return 'L-P';
    } else if (QU.includes(firstChar)) {
      return 'Q-U';
    } else if (VZ.includes(firstChar)) {
      return 'V-Z';
    } else {
      return 'Numerics';
    }
  }
};

export const cleanData = (dataToClean) => {
  /*   var id = 1;
  var sheet = 1; */
  var query = '';
  var useIntegers = true;
  var showRows = true;
  var showColumns = true;
  var data = dataToClean.data;
  var responseObj = {};
  var rows = [];
  var columns = {};
  if (data && data.feed && data.feed.entry) {
    for (var i = 0; i < data.feed.entry.length; i++) {
      var entry = data.feed.entry[i];
      var keys = Object.keys(entry);
      var newRow = {};
      var queried = false;
      for (var j = 0; j < keys.length; j++) {
        var gsxCheck = keys[j].indexOf('gsx$');
        if (gsxCheck > -1) {
          var key = keys[j];
          var name = key.substring(4);
          var content = entry[key];
          var value = content.$t;
          if (value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            queried = true;
          }
          if (useIntegers === true && !isNaN(value)) {
            value = Number(value);
          }
          newRow[name] = value;
          if (queried === true) {
            if (!columns.hasOwnProperty(name)) {
              columns[name] = [];
              columns[name].push(value);
            } else {
              columns[name].push(value);
            }
          }
        }
      }
      if (queried === true) {
        rows.push(newRow);
      }
    }
    if (showColumns === true) {
      responseObj['columns'] = columns;
    }
    if (showRows === true) {
      responseObj['rows'] = rows;
    }
    return responseObj;
  } else {
    return [];
  }
};

export const createUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getUserType = () => {
  return localStorage.getItem('userType');
};

export const getCode = () => {
  return localStorage.getItem('userIdentifier');
};

export const getUser = () => {
  return localStorage.getItem('userEmail');
};

export const setUsername = (username) => {
  return localStorage.setItem('username', username);
};

export const getUsername = () => {
  return localStorage.getItem('username');
};

export const saveThemeToLS = (theme) => {
  localStorage.setItem('theme', theme);
};

export const getThemeFromLS = () => {
  return localStorage.getItem('theme');
};

export const removeThemeFromLS = () => {
  return localStorage.removeItem('theme');
};

export const checkIsWatching = (ticker) => {
  const WL = getWatchlist();
  if (WL.find((w) => w.ticker === ticker)) {
    return true;
  } else {
    return false;
  }
};

export const getWatchlist = () => {
  /* [{"ticker":"NNDM","totalwatchers":2853,"close":15.67,"previous":16.72,"chgpercent":-6.28,"weight":0,"previousweight":0,"changeinweight":0,"marketsharevalue":0,"shares":0,"$$hashKey":"object:63"},{"code":"HgrKFXc5r9jwD9mt","status":"Added","ticker":"TSLA"}] */
  const watchlistFromLS = localStorage.getItem('watchlist');
  if (watchlistFromLS) {
    return JSON.parse(localStorage.getItem('watchlist'));
  } else {
    return [];
  }
};

export const removeFromWatchlist = (asset, formWLValues) => {
  const currentWL = JSON.parse(localStorage.getItem('watchlist'));
  const newWL = currentWL.filter((w) => w.ticker !== asset.ticker);

  formWLValues.ticker = asset.ticker;
  formWLValues.action = 'Removed';
};

export const setWatchlistToLS = (ticker) => {
  const currentFund = getSelectedFundGroup();
  let watchlistFromLS = getWatchlist();
  const tickerObj = { fund: currentFund, ticker: ticker };
  if (watchlistFromLS) {
    //Check if ticker already in list
    if (checkIsWatching(ticker)) {
      const removedTickerWL = watchlistFromLS.filter(
        (w) => w.ticker !== ticker
      );
      localStorage.setItem('watchlist', JSON.stringify(removedTickerWL));
      return removedTickerWL;
    } else {
      watchlistFromLS.push(tickerObj);
      localStorage.setItem('watchlist', JSON.stringify(watchlistFromLS));
      return watchlistFromLS;
    }
  } else {
    const newWLArray = [tickerObj];
    localStorage.setItem('watchlist', JSON.stringify(newWLArray));
    return newWLArray;
  }
};

export const removeDuplicateValue = (array) => {
  let uniq;
  let newArray = (uniq = [...new Set(array)]);
  return newArray;
};

export const uniqueBy = (a, cond) => {
  return a.filter((e, i) => a.findIndex((e2) => cond(e, e2)) === i);
};

export const flattenObject = (
  data,
  response = {},
  flatKey = '',
  onlyLastKey = false
) => {
  for (const [key, value] of Object.entries(data)) {
    let newFlatKey;
    if (!isNaN(parseInt(key)) && flatKey.includes('[]')) {
      newFlatKey =
        (flatKey.charAt(flatKey.length - 1) == '.'
          ? flatKey.slice(0, -1)
          : flatKey) + `[${key}]`;
    } else if (!flatKey.includes('.') && flatKey.length > 0) {
      newFlatKey = `${key}`;
    } else {
      newFlatKey = `${key}`;
    }
    if (
      typeof value === 'object' &&
      value !== null &&
      Object.keys(value).length > 0
    ) {
      flattenObject(value, response, ``, onlyLastKey);
    } else {
      if (onlyLastKey) {
        newFlatKey = newFlatKey.split('.').pop();
      }
      if (Array.isArray(response)) {
        response.push({
          [newFlatKey.replace('[]', '')]: value,
        });
      } else {
        response[newFlatKey.replace('[]', '')] = value;
      }
    }
  }
  return response;
};

export const flattenArray = (arr) => {
  return Array.prototype.concat.apply([], arr);
};

export const getDuplicates = (array) => {
  var object = {};
  var result = [];

  array.forEach(function (item) {
    if (!object[item]) object[item] = 0;
    object[item] += 1;
  });

  for (var prop in object) {
    if (object[prop] >= 2) {
      result.push(prop);
    }
  }

  return result;
};

export const getShared = (array, length) => {
  var object = {};
  var result = [];

  array.forEach(function (item) {
    if (!object[item]) object[item] = 0;
    object[item] += 1;
  });

  for (var prop in object) {
    if (object[prop] >= length) {
      result.push(prop);
    }
  }

  return result;
};
