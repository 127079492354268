import React, { useState, useEffect } from 'react';
import {
  optionsHeaders,
  optionsActiveHeaders,
  optionsTabs,
} from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import Preview from '../layouts/preview';
import '../styles/calendars.scss';
import { formatCurrency, formatDate, formatNumber } from '../helpers/format';
import { useLocation } from 'react-router-dom';

let sTab;

function Options({
  myWatchlist,
  userType,
  currentFundGroup,
  handleShowModal,
  handleShowUpgrade,
  optionsData,
  optionsActiveData,
  date,
}) {
  let queryParams = useLocation()?.search.split('=')[1]?.replaceAll('-', ' ');
  if (queryParams === 'Most Active') {
    sTab = optionsTabs[2];
  } else {
    sTab = optionsTabs[0];
  }
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [optionsActive, setOptionsActive] = useState([]);
  const [orderReversed, setOrderReversed] = useState(true);
  const [orderMethod, setOrderMethod] = useState('Vol/OI');
  const [selectedTab, setSelectedTab] = useState(sTab);

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
    if (tab === optionsTabs[0]) {
      setOptions(optionsData);
    } else if (tab === optionsTabs[1]) {
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = optionsData.filter((f) =>
        WLtickers.includes(f.Symbol)
      );
      setOptions(filteredByWL);
    } else if (tab === optionsTabs[2]) {
      setOptionsActive(optionsActiveData);
    } else if (tab === optionsTabs[3]) {
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = optionsActiveData.filter((f) =>
        WLtickers.includes(f.Symbol)
      );
      setOptionsActive(filteredByWL);
    }
  };

  const getOptionsData = async () => {
    setOptions(optionsData);
    setOptionsActive(optionsActiveData);
    setIsLoading(false);
  };

  useEffect(() => {
    getOptionsData();
  }, [date]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`calendars ${currentFundGroup}`}>
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} Options`} />
      )}

      {!isLoading && (
        <div>
          <div>
            <div className='header-content d-flex'>
              <h1>
                <span className='hidden-mobile'>Options Activity </span>
              </h1>
              <div className='tabs'>
                {optionsTabs.map((tab) => (
                  <div
                    key={tab}
                    className={selectedTab === tab ? 'tab active' : 'tab'}
                    onClick={() => handleSetSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>

            <div>
              {userType === 'Member' && (
                <div>
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    <br />
                    <div className='jumbo'>🚀</div>
                    <h2>Upgrade to Premium</h2>
                    <p style={{ marginBottom: '20px' }}>
                      Get access to Market Activity: Options for{' '}
                      {currentFundGroup} ETFs and premium member features.
                    </p>

                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade
                    </a>
                  </div>
                </div>
              )}
              {userType === 'Premium' && (
                <div className='h-scroll'>
                  {selectedTab === optionsTabs[1] && options.length === 0 && (
                    <div
                      className='panel'
                      style={{
                        padding: '20px 10px',
                        maxWidth: '800px',
                        textAlign: 'center',
                        border: 'none',
                      }}
                    >
                      <div className='jumbo'>🧐</div>
                      <h2>
                        No Unusual Option Activity in your {currentFundGroup}{' '}
                        Watchlist.
                      </h2>
                      <p style={{ marginBottom: '20px' }}>
                        Check out the{' '}
                        <a onClick={() => handleSetSelectedTab(optionsTabs[0])}>
                          {optionsTabs[0]} Tab
                        </a>
                        .
                      </p>
                    </div>
                  )}
                  {(selectedTab === optionsTabs[0] ||
                    selectedTab === optionsTabs[1]) &&
                    options.length > 0 && (
                      <table className='striped h-scroll fixed options'>
                        <thead>
                          <tr>
                            {optionsHeaders.map((d) => (
                              <th
                                onClick={() => handleSetOrderMethod(d.id)}
                                key={d.id}
                                className={d.id}
                                title={d.title}
                              >
                                {d.name}
                                {orderMethod.toString() === d.id && (
                                  <i
                                    className={
                                      orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : 'ion-arrow-up-b small'
                                    }
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {options &&
                            options
                              .sort((a, b) => {
                                if (isNaN(a[orderMethod])) {
                                  if (orderReversed) {
                                    return `${a[orderMethod]}`.localeCompare(
                                      `${b[orderMethod]}`
                                    );
                                  } else {
                                    return `${b[orderMethod]}`.localeCompare(
                                      `${a[orderMethod]}`
                                    );
                                  }
                                } else {
                                  if (orderReversed) {
                                    return a[orderMethod] - b[orderMethod];
                                  } else {
                                    return b[orderMethod] - a[orderMethod];
                                  }
                                }
                              })
                              .map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Symbol}${index}`}
                                  onClick={() => handleShowModal(r.Symbol)}
                                >
                                  <Preview
                                    title='Ticker'
                                    CSSclass='strong'
                                    ticker={r.Symbol}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                  {/* <td>{formatCurrency(r.Price, 'USD', 2)}</td> */}
                                  <td>{r.Type}</td>
                                  <td>{formatCurrency(r.Strike, 'USD', 2)}</td>
                                  <td>{formatDate(r['Exp Date'])}</td>
                                  <td>{r.DTE}</td>
                                  <td>{formatNumber(r.Volume, 0)}</td>
                                  <td>{formatNumber(r['Open Int'], 0)}</td>
                                  <td>{formatNumber(r['Vol/OI'], 2)}</td>
                                  <td>{formatNumber(r.IV * 100, 2)}%</td>
                                  <td>{formatCurrency(r.Bid, 'USD', 2)}</td>
                                  <td>
                                    {formatCurrency(r.Midpoint, 'USD', 2)}
                                  </td>
                                  <td>{formatCurrency(r.Ask, 'USD', 2)}</td>
                                  <td>{formatCurrency(r.Last, 'USD', 2)}</td>
                                  <td>{r.Time}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    )}
                  {selectedTab === optionsTabs[3] &&
                    optionsActive.length === 0 && (
                      <div
                        className='panel'
                        style={{
                          padding: '20px 10px',
                          maxWidth: '800px',
                          textAlign: 'center',
                          border: 'none',
                        }}
                      >
                        <div className='jumbo'>🧐</div>
                        <h2>
                          Stocks in your {currentFundGroup} Watchlist are not in
                          the Most Active Options.
                        </h2>
                        <p style={{ marginBottom: '20px' }}>
                          Check out the{' '}
                          <a
                            onClick={() => handleSetSelectedTab(optionsTabs[0])}
                          >
                            {optionsTabs[0]} Tab
                          </a>
                          .
                        </p>
                      </div>
                    )}

                  {(selectedTab === optionsTabs[2] ||
                    selectedTab === optionsTabs[3]) &&
                    optionsActive.length > 0 && (
                      <table className='striped h-scroll fixed options'>
                        <thead>
                          <tr>
                            {optionsActiveHeaders.map((d) => (
                              <th
                                onClick={() => handleSetOrderMethod(d.id)}
                                key={d.id}
                                className={d.name}
                                title={d.title}
                              >
                                {d.name}
                                {`${orderMethod}` === d.id && (
                                  <i
                                    className={
                                      orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : 'ion-arrow-up-b small'
                                    }
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {optionsActive &&
                            optionsActive
                              .sort((a, b) => {
                                if (isNaN(a[orderMethod])) {
                                  if (orderReversed) {
                                    return `${a[orderMethod]}`.localeCompare(
                                      `${b[orderMethod]}`
                                    );
                                  } else {
                                    return `${b[orderMethod]}`.localeCompare(
                                      `${a[orderMethod]}`
                                    );
                                  }
                                } else {
                                  if (orderReversed) {
                                    return a[orderMethod] - b[orderMethod];
                                  } else {
                                    return b[orderMethod] - a[orderMethod];
                                  }
                                }
                              })
                              .map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Symbol}${index}`}
                                  onClick={() => handleShowModal(r.Symbol)}
                                >
                                  <Preview
                                    title='Ticker'
                                    CSSclass='strong'
                                    ticker={r.Symbol}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                  <td>
                                    {formatNumber(r['IV Rank'] * 100, 2)}%
                                  </td>
                                  <td>{formatNumber(r['Options Vol'], 0)}</td>
                                  <td>{formatNumber(r['% Put'] * 100, 2)}%</td>
                                  <td>{formatNumber(r['% Call'] * 100, 2)}%</td>
                                  <td
                                    title={
                                      r['Put/Call Vol'] < 1
                                        ? 'Indicates a buying opportunity'
                                        : 'Indicates a potential selloff'
                                    }
                                    className={
                                      r['Put/Call Vol'] < 1
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatNumber(r['Put/Call Vol'], 2)}
                                  </td>
                                  <td className='Company'>{r.Name}</td>
                                  <td>{r.Time}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Options;
