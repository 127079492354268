import { senateHeaders } from '../helpers/fundvars';
import Preview from '../layouts/preview';

let senate = [];
let headers = [];

function Senate({ senateData, handleShowModal, ticker }) {
  if (ticker) {
    senate = senateData?.filter((f) => f.Ticker === ticker);
    headers = senateHeaders.filter((v) => v.id !== 'Ticker' && v.id !== 'No.');
  } else {
    senate = senateData;
    headers = senateHeaders;
  }

  return (
    <>
      {senate?.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                {headers.map((d) => (
                  <th key={d.id} className={d.id} title={d.title}>
                    {d.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {senate.sort().map((r, index) => (
                <tr
                  className='link'
                  key={`${r.Ticker}${index}`}
                  onClick={() => handleShowModal(r.Ticker)}
                >
                  {!ticker && (
                    <>
                      <Preview
                        ticker={r.Ticker}
                        itemIndex={index}
                        breakPoint='4'
                      />
                    </>
                  )}

                  <td>{r['Reporter']}</td>
                  <td
                    className={
                      r['Type'].includes('Sell') || r['Type'].includes('Sale')
                        ? 'danger'
                        : 'success'
                    }
                  >
                    {r['Type']}
                  </td>
                  <td>{r['Amount']}</td>
                  <td>{r['Transaction Date']}</td>
                  <td>{r['Filed At']}</td>
                  <td>{r['Notes']}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </>
      ) : (
        <div>
          <br />
          No data found.
        </div>
      )}
    </>
  );
}
export default Senate;
