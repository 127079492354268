import { getUser, getCode } from '../helpers/helpers';

const WatchlistForm = ({ ticker, action }) => {
  let user = getUser();
  let userIdentifier = getCode();

  return (
    <div>
      <iframe
        title='secret-frame-four'
        name='secret-frame-four'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <form
        className='form'
        action='https://docs.google.com/forms/d/e/1FAIpQLScrOhrWDqQGbmRZIecQE6AWT4KGJoiY76_9tfQou0OMvp4jZQ/formResponse'
        id='ss-form4'
        method='POST'
        target='secret-frame-four'
        style={{ display: 'none' }}
      >
        <div className='form-group'>
          <label htmlFor='tickerEmail'>Email</label>
          <input name='entry.1516381519' readOnly type='email' value={user} />
        </div>
        <div className='form-group'>
          <label htmlFor='tickerValue'>Ticker</label>
          <input name='entry.1654462701' readOnly type='text' value={ticker} />
        </div>
        <div className='form-group'>
          <label htmlFor='tickerAction'>Action</label>
          <input name='entry.2065171571' readOnly type='text' value={action} />
        </div>
        <div className='form-group'>
          <label htmlFor='tickerAction'>Code</label>
          <input
            name='entry.972482608'
            readOnly
            type='text'
            value={userIdentifier}
          />
        </div>

        <input id='submittickerform' type='submit' value='Submit Ticker' />
      </form>
    </div>
  );
};
export default WatchlistForm;
