import { formatNumber, formatCurrency, formatDate } from '../helpers/format';
import {
  addsAndDropsTypes,
  addsAndDropsPassiveTypes,
} from '../helpers/fundvars';

function PanelAddsDrops({ data, type, handleShowModal }) {
  let items = [];
  if (data) {
    if (
      type === addsAndDropsTypes[0].type ||
      type === addsAndDropsPassiveTypes[0].type
    ) {
      items = data
        .filter((d) => d.status === 'New Add')
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
    }
    if (
      type === addsAndDropsTypes[1].type ||
      type === addsAndDropsPassiveTypes[1].type
    ) {
      items = data
        .filter((d) => d.status === 'Dropped')
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
    }
  }

  return (
    <div>
      <h4 className='text-center'>
        <span className='faded'>{type}</span>
      </h4>
      <div className='panel-content'>
        <table>
          {items.map((l, index) => (
            <tbody key={`${l.ticker}${index}`}>
              <tr onClick={() => handleShowModal(l.ticker)} title={l.company}>
                <td>
                  <b>{l.ticker}</b>
                  <div>{formatDate(l.date)}</div>
                  <div className='small'>{l.fund}</div>
                </td>

                <td>
                  {(type === addsAndDropsTypes[0].type ||
                    type === addsAndDropsPassiveTypes[0].type) && (
                    <>
                      <span className='success'>
                        {formatCurrency(l.buysellvolume, 'USD', 0)}
                      </span>
                      <div className='small'>
                        ETF Weight: {formatNumber(l.etfweight, 2)}%
                      </div>
                    </>
                  )}

                  {(type === addsAndDropsTypes[1].type ||
                    type === addsAndDropsPassiveTypes[1].type) && (
                    <>
                      <span className='danger'>
                        {formatCurrency(l.buysellvolume, 'USD', 0)}
                      </span>
                      <div className='small'>
                        ETF Weight: {formatNumber(l.etfweight, 2)}%
                      </div>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        {items.length === 0 && (
          <div className='text-center'>
            <p>No new stocks added.</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default PanelAddsDrops;
