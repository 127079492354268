//import { useHistory } from 'react-router-dom';
import { formatNumber, formatCurrency } from '../helpers/format';

function Panel({ data, type, handleShowModal }) {
  //const history = useHistory();
  let items = [];

  const pumpFilter = (items) => {
    return items.filter((l) => l.data.sharesdelta > 0);
  };

  const dumpFilter = (items) => {
    return items.filter((l) => l.data.sharesdelta < 0);
  };

  if (type === 'pump') {
    items = pumpFilter(data.list).sort((a, b) => {
      return b.data.dailycapitalinjection - a.data.dailycapitalinjection;
    });
  }

  if (type === 'dump') {
    items = dumpFilter(data.list).sort((a, b) => {
      return a.data.dailycapitalinjection - b.data.dailycapitalinjection;
    });
  }

  /* const goTo = (route) => {
    history.push(`/detail/${route}`);
  }; */

  return (
    <div>
      <h4 className='text-center'>
        <span className='faded' title={data.description}>
          {data.fund} {data.name}{' '}
        </span>
        <span
          title={`${data.type}ly Managed Fund`}
          className={
            data.type === 'Active' ? 'badge small primary' : 'badge small'
          }
        >
          {data.type.substring(0, 1)}
        </span>
      </h4>
      <div className='panel-content'>
        <table>
          {items.map((l, index) => (
            <tbody key={`${l.ticker}${index}`}>
              <tr onClick={() => handleShowModal(l.ticker)} title={l.company}>
                <td>
                  <b>{l.ticker}</b>
                  <div className='small'>
                    ${l.data?.priceclosed?.toFixed(2)}
                    <small className='faded'>Close</small>
                  </div>
                  <div className='small'>
                    ${l.data?.previousclose?.toFixed(2)}
                    <small className='faded'>Previous</small>
                  </div>
                </td>
                <td>
                  <span className={type === 'pump' ? 'success' : 'danger'}>
                    {formatCurrency(l.data.dailycapitalinjection, 'USD', 0)}
                  </span>
                  <div className='small'>
                    Fund Weight: {l.data.weight}%{' '}
                    {l.data.portfolioweightdelta ? (
                      <span
                        className='weight-indicator'
                        className={
                          l.data.portfolioweightdelta > 0 &&
                          l.data.portfolioweightdelta !== 0
                            ? 'success'
                            : 'danger'
                        }
                      >
                        <i
                          className={
                            l.data.portfolioweightdelta > 0
                              ? 'ion-arrow-up-a'
                              : 'ion-arrow-down-a'
                          }
                        ></i>
                        {formatNumber(l.data.portfolioweightdelta, 2)}%
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='small'>
                    Shares {type === 'pump' ? 'Bought' : 'Sold'}:{' '}
                    {formatNumber(l.data.sharesdelta, 0)}
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        {items.length === 0 && (
          <div className='text-center'>
            <p>No change.</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default Panel;
