import React, { useState } from 'react';

function Announcement({ currentFundGroup, fundGroups }) {
  const [toggledAnnouncement, setToggledAnnouncement] = useState(false);
  return (
    <div
      className='announcement link'
      onClick={() => setToggledAnnouncement(!toggledAnnouncement)}
    >
      {currentFundGroup ? (
        <>
          <span className='emoji'>👋</span>
          {/* New: Roundhill Investments ETF data.{' '} */}
          {currentFundGroup} data updated{' '}
          <span
            className={
              currentFundGroup === fundGroups[0].fundGroup ? '' : 'hidden'
            }
          >
            at ~8pm ET (Market Orders) and ~1am ET the following day (Shares
            Data)
          </span>
          <span
            className={
              currentFundGroup !== fundGroups[0].fundGroup ? '' : 'hidden'
            }
          >
            at ~12pm ET following day
          </span>
        </>
      ) : (
        <span>
          <span className='emoji'>👋</span> The data in this section is updated
          ~1pm ET the following day after market close
        </span>
      )}
      .{' '}
      <a className='small'>
        <i
          className={
            toggledAnnouncement ? 'ion-chevron-up' : 'ion-chevron-down'
          }
        ></i>
      </a>
      {toggledAnnouncement && (
        <div className='announcement-body'>
          <div>
            <h6>Data release schedule:</h6>
            <ul style={{ padding: '0 0 0 16px' }}>
              <li>
                ARK Invest Market Orders update at ~8pm ET and Shares Data
                updates ~1am ET the following day. Note that ARK reports
                beginning of trading day data and we convert it to end of day.
                For example, ARK will report 10/06/21 which will translate to
                10/05/21 in our system.
              </li>
              <li> Global X updates ~12pm (noon) EST the following day.</li>
            </ul>
          </div>
          {/* <div>
            <h6>Currently working on:</h6>
            <ul style={{ padding: '0 0 0 16px' }}>
              <li>
                Data mining crypto wallets and transactions to inform premium
                members what coins to pay attention to.
              </li>
              <li>
                Amplify ETFs (CNBS Cannabis ETF YTD currently outperforming all
                ETFs on Lucid on YTD Yield).
              </li>
              <li>Comparison between ETFs on Lucid.</li>
              <li>
                Aggregation between Funds (ARK, BlackRock, Roundhill
                Investments, Global, Amplify).
              </li>
              <li>
                Various external data points to layer over {currentFundGroup}'s
                ETF data that should help shape conviction.
              </li>
              <li>Predictive models via Nueral Net ML.</li>
            </ul>
          </div> */}
          {/* <div>
            <h6>New Features:</h6>
            <ul style={{ padding: '0 0 0 16px' }}>
              <li>
                Hover on Ticker to reveal stock price in candle chart format.
              </li>
              <li>
                Additional data points per fund with regard to insider, options,
                and stock activity.
              </li>
            </ul>
          </div> */}
          <div>
            <h6>Membership Tiers</h6>
            <p>
              Founding members will retain priority and access to highest future
              membership tiers. To you, we are indebted with great gratitude for
              your support. Kudos.
            </p>
            <p>
              New tiers coming including a Pro tier which 🤟 Founding Members
              will automatically convert to while keeping the $4.99/mo
              subscription.
            </p>
          </div>
          <div>
            <h6>
              ARK Invest, Global X, and BlackRock all contain Passively Managed
              ETFs. Designation is provided as per below:
            </h6>
            <p>
              <span className='badge small'>P</span> Passively managed ETFs are
              revisited on a predifined schedule (usually every quarter) and
              buying and selling are tied to an index/formula.{' '}
              <span className='badge small primary'>A</span> Active ETFs have
              fund managers who make decisions on buying and selling.
            </p>
          </div>
          <divThe>
            <h6>Help us identify Funds you'd like to track.</h6>
            <a
              href='https://docs.google.com/forms/d/e/1FAIpQLSdSaRyf35nq7QvfF96Dj5xU_rKaNsJh0QKVlQpFCUHQYdEyVQ/viewform'
              target='_blank'
            >
              Submit Funds or ETFs
            </a>
            .
          </divThe>
          <div>
            <h6>Feature request.</h6>
            Have an idea on what we should be building next?{' '}
            <a
              href='https://docs.google.com/forms/d/e/1FAIpQLSesx-LQlUeOpwCcoP7ntmg1TvaA3I0LiSZlNdNpPpmPscmMcA/viewform'
              target='_blank'
            >
              Submit a Feature
            </a>
            .
          </div>
          <div>
            <h6>Find any bugs?</h6>{' '}
            <a
              href='mailto:hello@lucidtracking.com?subject=Bug'
              target='_blank'
            >
              Report Bugs
            </a>
            .
          </div>
          <br />
          <br />
        </div>
      )}
    </div>
  );
}
export default Announcement;
