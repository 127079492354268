import axios from 'axios';
import { cleanData, getTabId, getCode } from './helpers';
import {
  userSheetId,
  userSheetAltId,
  premiumSheetId,
  premiumSheetAltId,
  stockApiBaseUrl,
  stockApiKey,
  stockApiKeyAlt1,
  stockApiKeyAlt2,
  watchlistSheetId,
  watchlistSheetIdAlt1,
  watchlistSheetIdAlt2,
  chatSheetId,
  chatSheetIdAlt1,
  chatSheetIdAlt2,
  commentsSheetId,
  commentsSheetIdAlt1,
  commentsSheetIdAlt2,
  usernameSheetId,
  cryptoTokenSheetId,
  cryptoSheetId,
  cryptoTabId,
  cryptoTokenTabId,
  cyptoWalletSheetId,
  cryptoWalletTabId,
  cryptoNewCoinsTabId,
  cryptoAllCoinsTabId,
  cyptoTopSheetId,
  cryptoTopTabId,
  cyptoNomicsSheetId,
  cryptoNomicsTabId,
  gsSheetId,
  gsTabDashboardId,
  gsTabHistoricalId,
  memeSheetId,
  memeTabId,
  mainTabId,
  memeShortSheetId,
  memeShortTabId,
} from './vars';

let sApiKey = stockApiKey;
let WLSheetId = watchlistSheetId;
let chatId = chatSheetId;
let commentsId = commentsSheetId;

//AIzaSyDYYMzwUYMz_ICMFggQrku-ng_VcIQrN-I
//https://sheets.googleapis.com/v4/spreadsheets/'+worksheet_id+'/values/'+tab_name+'?alt=json&key='+key-value
const sheetApiCall = async (sheetId, tabId, params) => {
  const res = axios.get(
    /* `https://spreadsheets.google.com/feeds/list/${sheetId}/${tabId}/public/values?alt=json${
      params ? params : ''
    }` */
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${tabId}?alt=json&key=AIzaSyDYYMzwUYMz_ICMFggQrku-ng_VcIQrN-I${
      params ? params : ''
    }`
  );
  const data = await res;
  //console.log('data', data.data);

  const keyArray = data.data.values[0];
  const valueArray = data.data.values.slice(1);
  //console.log('keyArray', keyArray);
  //console.log('valueArray', valueArray);

  const formedArray = valueArray.map((v) =>
    v
      .map((vv, index) => ({
        [keyArray[index]]: vv,
      }))
      .reduce((r, c) => ({ ...r, ...c }), {})
  );
  //console.log('formedArray', formedArray);
  return formedArray;
  /*const cleanedData = await cleanData(await data).rows;
  if ((await cleanedData) && cleanedData.length > 0) {
    console.log('data,', data)
    return cleanedData;
  }  else {
    sheetApiCall(sheetId, tabId);
  } */
};

export const getDataLength = () => {
  const tempd = localStorage.getItem('datalength');
  if (tempd) {
    return tempd;
  } else {
    return '60 Days';
  }
};

export const setDataLength = (length) => {
  localStorage.setItem('datalength', length);
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const getLogin = async (email, password) => {
  let loginUIsheetId = '';
  let loginUItabId = getTabId(password);
  let loginPremiumUIsheetId = '';

  if (Math.random() >= 0.5) {
    loginUIsheetId = userSheetId;
  } else {
    loginUIsheetId = userSheetAltId;
  }

  /* const user = axios.get(
    `https://spreadsheets.google.com/feeds/list/${loginUIsheetId}/${loginUItabId}/public/values?alt=json&sq=c=${password}`
  );
 */
  let user = await sheetApiCall(loginUIsheetId, loginUItabId);

  //console.log('userData', user);

  //const userData = await cleanData(await user).rows;
  const userData = user.find((u) => u.c === password);
  if (userData) {
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userIdentifier', password);

    if (Math.random() >= 0.5) {
      loginPremiumUIsheetId = premiumSheetId;
    } else {
      loginPremiumUIsheetId = premiumSheetAltId;
    }

    /* const premium = axios.get(
      `https://spreadsheets.google.com/feeds/list/${loginPremiumUIsheetId}/${loginUItabId}/public/values?alt=json&sq=c=${password}`
    ); */

    const premium = await sheetApiCall(loginPremiumUIsheetId, loginUItabId);

    const premiumData = premium.find((p) => p.c === password);
    //let premiumData = cleanData(await premium).rows;
    if (premiumData) {
      localStorage.setItem('userType', 'Premium');
      window.location.href = '/';
      window.location.reload();
    } else {
      localStorage.setItem('userType', 'Member');
      window.location.href = '/';
      window.location.reload();
    }
  } else {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userIdentifier');
    localStorage.removeItem('userType');
    return {
      error:
        'Email or password was incorrect. Use your email/password from the welcome email.',
    };
  }
};

export const getGsDashboard = async () => {
  return await sheetApiCall(gsSheetId, gsTabDashboardId);
};

export const getCryptoData = async () => {
  return await sheetApiCall(cryptoSheetId, cryptoTabId);
};

export const getMemeData = async () => {
  return await sheetApiCall(/* memeSheetId, memeTabId */);
};

export const getMemeShortData = async () => {
  return await sheetApiCall(/* memeShortSheetId, memeShortTabId */);
};

export const getMainData = async () => {
  return await sheetApiCall(/* memeSheetId, mainTabId */);
};

export const getGsHistorical = async () => {
  return await sheetApiCall(gsSheetId, gsTabHistoricalId);
};

export const getTokenInfo = async () => {
  return await sheetApiCall(cryptoTokenSheetId, cryptoTokenTabId);
};

export const getWalletInfo = async () => {
  return await sheetApiCall(cyptoWalletSheetId, cryptoWalletTabId);
};

export const getNewCoinsInfo = async () => {
  return await sheetApiCall(cyptoWalletSheetId, cryptoNewCoinsTabId);
};

export const getAllCoinsInfo = async () => {
  return await sheetApiCall(cyptoWalletSheetId, cryptoAllCoinsTabId);
};

export const getTopCryptoInfo = async () => {
  return await sheetApiCall(cyptoTopSheetId, cryptoTopTabId);
};

export const getNomics = async () => {
  return await sheetApiCall(cyptoNomicsSheetId, cryptoNomicsTabId);
};

export const getDetailInfo = async (ticker) => {
  return await sheetApiCall(
    '1qN2PVMbkCXdmXUNoufcWAayuRkQq3GxCpL2tN0FYvnI',
    'Tickys',
    { params: `&sq=ticker=${ticker}` }
  );
};

export const getConfig = async () => {
  const length = getDataLength();
  let selectedFundGroup = localStorage.getItem('fundGroup');
  let fundData;
  let dataForFund;
  if (selectedFundGroup) {
    if (selectedFundGroup.includes('ARK')) {
      if (length !== 'All Data') {
        dataForFund = 'appconfig';
      } else {
        dataForFund = 'appconfigAll';
      }
    } else if (selectedFundGroup === 'BlackRock') {
      /* if (length !== 'All Data') {
        dataForFund = 'appconfig2';
      } else {
        dataForFund = 'appconfig2All';
      } */
      dataForFund = 'appconfig2';
    } else if (selectedFundGroup === 'Global X') {
      /* if (length !== 'All Data') {
        dataForFund = 'appconfig3';
      } else {
        dataForFund = 'appconfig3All';
      } */
      dataForFund = 'appconfig3';
    } else {
      dataForFund = 'appconfig';
    }
  } else {
    dataForFund = 'appconfig';
    localStorage.setItem('fundGroup', 'ARK Invest');
  }

  const config = await axios.get(
    /* `https://lucidtracking.s3.amazonaws.com/assets/appassets/${dataForFund}.json?nocache=${new Date().getTime()}.json` */
    `${
      window.location.origin
    }/config/${dataForFund}.json?nocache=${new Date().getTime()}.json`
    /* `${window.location.origin}/config/${dataForFund}.json` */
  );
  fundData = JSON.parse(atob(config.data.data));
  return fundData;
};

export const getOptions = async () => {
  const res = await axios.get(
    `${
      window.location.origin
    }/config/Options.json?nocache=${new Date().getTime()}.json`
  );
  return res.data;
};

export const getAggregate = async () => {
  const res = await axios.get(
    `${
      window.location.origin
    }/config/Aggregate.json?nocache=${new Date().getTime()}.json`
  );
  const fundData = JSON.parse(atob(res.data.data));
  return fundData;
};

export const getPatterns = async (ticker) => {
  //Cycle Keys
  if (Math.random() >= 0.5) {
    sApiKey = stockApiKey;
    if (Math.random() >= 0.5) {
      sApiKey = stockApiKeyAlt1;
    } else {
      sApiKey = stockApiKeyAlt2;
    }
  }

  const response = await axios.get(
    `${stockApiBaseUrl}scan/pattern?symbol=${ticker}&resolution=5&token=${sApiKey}`
  );
  return response.data.points;
};

export const getEconomicCalendar = async () => {
  //Cycle Keys
  if (Math.random() >= 0.5) {
    sApiKey = stockApiKeyAlt1;
    if (Math.random() >= 0.5) {
      sApiKey = stockApiKeyAlt2;
    } else {
      sApiKey = stockApiKey;
    }
  }
  const response = await axios.get(
    `${stockApiBaseUrl}calendar/economic?&token=${sApiKey}`
  );
  return response.data.economicCalendar;
};

export const getWatchlistStats = async () => {
  //Cycle Sheets
  if (Math.random() >= 0.5) {
    WLSheetId = watchlistSheetIdAlt1;
    if (Math.random() >= 0.5) {
      WLSheetId = watchlistSheetIdAlt2;
    } else {
      WLSheetId = watchlistSheetId;
    }
  }
  return await sheetApiCall(WLSheetId, 'stats');
  /* const response = await axios.get(
    `https://spreadsheets.google.com/feeds/list/${WLSheetId}/'stats'/public/values?alt=json`
  );
  var data = cleanData(response);
  return data.rows; */
};

export const getChats = async () => {
  //Cycle Sheets
  if (Math.random() >= 0.5) {
    chatId = chatSheetIdAlt1;
    if (Math.random() >= 0.5) {
      chatId = chatSheetIdAlt2;
    } else {
      chatId = chatSheetId;
    }
  }
  return await sheetApiCall(chatId, 'responses');
};

export const getComments = async () => {
  //Cycle Sheets
  if (Math.random() >= 0.5) {
    commentsId = commentsSheetIdAlt1;
    if (Math.random() >= 0.5) {
      commentsId = commentsSheetIdAlt2;
    } else {
      commentsId = commentsSheetId;
    }
  }
  return await sheetApiCall(commentsId, 'responses');
};

export const getUsernames = async () => {
  return await sheetApiCall(usernameSheetId, 'Form Responses 1');
};

export const getCommentCounts = async () => {
  //Cycle Sheets
  if (Math.random() >= 0.5) {
    commentsId = commentsSheetIdAlt1;
    if (Math.random() >= 0.5) {
      commentsId = commentsSheetIdAlt2;
    } else {
      commentsId = commentsSheetId;
    }
  }
  return await sheetApiCall(commentsId, 'uniques');
};

export const getUsernameFromSheet = async () => {
  let usernameData = await getUsernames();
  let code = await getCode();
  let username;

  username = usernameData.find((u) => u.c === code)?.username;
  if (username) {
    localStorage.setItem('username', username);
    return username;
  } else {
    let commentData = await getComments();
    username = await commentData.find((d) => d.code === code)?.username;

    //if sheet has UN, set localStorage with username key
    if (username) {
      localStorage.setItem('username', username);
      return username;
    } else {
      let chatData = await getChats();
      let usernameChat = await chatData.find((d) => d.c === code)?.username;
      //if sheet has username, set localStorage with username key
      if (usernameChat) {
        localStorage.setItem('usernameChat', usernameChat);
        return usernameChat;
      } else {
        return null;
      }
    }
  }
};
