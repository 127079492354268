import React, { useState, useEffect } from 'react';
import Loader from '../layouts/loader';
import '../styles/watchlist.scss';
import {
  watchlistTabs,
  watchlistPopularHeaders,
  stocklistHeaders,
  chartTabs,
} from '../helpers/fundvars';
import { getWatchlistStats } from '../helpers/api';
import { formatNumber, formatCurrency } from '../helpers/format';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import BarVix from '../charts/bar-group-vix';
import Preview from '../layouts/preview';

function Watchlist({
  userType,
  currentFundGroup,
  myWatchlist,
  stocklist,
  aggregateList,
  addToWatchlist,
  handleShowModal,
  handleShowUpgrade,
  allTickers,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(watchlistTabs[0]);
  const [myList, setMyList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [filteredAggregateList, setFilteredAggregateList] = useState([]);
  const [watchlistStats, setWatchlistStats] = useState([]);
  const [orderMethodML, setOrderMethodML] = useState('ticker');
  const [orderMethod, setOrderMethod] = useState('totalwatchers');
  const [orderMLReversed, setOrderMLReversed] = useState(true);
  const [orderReversed, setOrderReversed] = useState(false);
  const [selectedChartTab, setSelectedChartTab] = useState(chartTabs[0]);
  const [fundSpecificPopular, setFundSpecificPopular] = useState([]);
  const [toggledTutorial, setToggledTutorial] = useState(false);

  const getFilteredFundArray = (tab) => {
    if (stocklist?.length) {
      const flatMyWatchlist = myWatchlist.map((m) => m.ticker);
      if (tab === watchlistTabs[0]) {
        const filteredList = stocklist.filter((s) =>
          flatMyWatchlist.includes(s.ticker)
        );
        setMyList(
          filteredList.map((r) => ({
            ...r,
            watching: true,
          }))
        );
      } else if (tab === watchlistTabs[1]) {
        setAllList(
          stocklist.map((r) => ({
            ...r,
            watching: myWatchlist.find((m) => m.ticker === r.ticker),
          }))
        );
      } else if (tab === watchlistTabs[2]) {
        const tempFilteredAggregate = aggregateList.filter((s) =>
          flatMyWatchlist.includes(s.ticker)
        );
        setFilteredAggregateList(tempFilteredAggregate);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFilteredFundArray(watchlistTabs[0]);
  }, [stocklist]);

  const handleAddToWatchlist = (ticker, action) => {
    addToWatchlist(ticker, action);
    const newMyWL = myWatchlist;
    if (action === 'Added') {
      newMyWL.push({ fund: currentFundGroup, ticker });

      if (selectedTab === watchlistTabs[1]) {
        const tempList = [...allList];
        tempList.forEach((r) => {
          if (r.ticker === ticker) {
            r.watching = true;
          }
        });
        setAllList(tempList);
      }

      //Watchlist Stats
      const newWLStats = [...watchlistStats];
      newWLStats.forEach((r) => {
        if (ticker === r.ticker) {
          r.watching = true;
        }
      });
      setWatchlistStats(newWLStats);
    } else {
      if (selectedTab === watchlistTabs[0]) {
        const tempList = [...myList];
        tempList.forEach((r) => {
          if (r.ticker === ticker) {
            r.watching = false;
          }
        });
        setMyList(tempList);
      }

      if (selectedTab === watchlistTabs[1]) {
        const tempList = [...allList];
        tempList.forEach((r) => {
          if (r.ticker === ticker) {
            r.watching = false;
          }
        });
        setAllList(tempList);
      }

      //WL Stats
      const newWLStats = [...watchlistStats];
      newWLStats.forEach((r) => {
        if (ticker === r.ticker) {
          r.watching = false;
        }
      });
      setWatchlistStats(newWLStats);
    }
  };

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const handleSetOrderMethodML = (header) => {
    if (header === orderMethodML) {
      setOrderMLReversed(!orderMLReversed);
    } else {
      setOrderMethodML(header);
    }
  };

  const handleGetWatchlistStats = async () => {
    const res = await getWatchlistStats();
    setWatchlistStats(
      res
        .filter((f) => allTickers.map((a) => a.ticker).includes(f.ticker))
        .map((r, index) => ({
          ...r,
          rank: index + 1,
          watching: myWatchlist.find((m) => m.ticker === r.ticker),
        }))
    );
  };

  const handleSetSelectedTab = (tab) => {
    if (tab !== watchlistTabs[3]) {
      getFilteredFundArray(tab);
    }
    if (tab === watchlistTabs[3]) {
      handleGetWatchlistStats(tab);
    }
    setSelectedTab(tab);
  };

  const handleSetSelectedChartTab = (c) => {
    setSelectedChartTab(c);
  };

  return (
    <div className={`watchlist ${currentFundGroup}`}>
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} Watchlist`} />
      )}

      {!isLoading && (
        <div>
          <div>
            <div className='header-content d-flex'>
              <h1 className='hidden-mobile'>
                Watchlist{' '}
                <a onClick={() => setToggledTutorial(!toggledTutorial)}>
                  <i className='ion-help-circled'></i>
                </a>
              </h1>
              <div className='tabs'>
                {watchlistTabs.map((tab) => (
                  <div
                    key={tab}
                    className={
                      selectedTab === tab
                        ? `${currentFundGroup} tab active`
                        : 'tab'
                    }
                    onClick={() => handleSetSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>

            {toggledTutorial && (
              <div className='help-text text-center'>
                <br />
                <p>
                  Your watchlist is shared across funds. You'll only see the
                  tickers that exists in the Fund you're viewing.
                </p>
              </div>
            )}

            <div className={selectedTab === watchlistTabs[0] ? '' : 'hidden'}>
              {myList.length === 0 && (
                <div
                  className='panel'
                  style={{ padding: '10px', textAlign: 'center' }}
                >
                  <div className='jumbo'>💎 🙌</div>
                  <h2>Your {currentFundGroup} Watchlist is Empty</h2>
                  <p style={{ marginBottom: '20px' }}>
                    Check out all the tickers on the All tab.
                  </p>
                </div>
              )}
              {myList.length > 0 && (
                <div className='h-scroll'>
                  <table className='striped h-scroll fixed'>
                    <thead>
                      <tr>
                        <th>Watch</th>
                        {stocklistHeaders.map((h) => (
                          <th
                            title={h.title}
                            className={h.name}
                            onClick={() => handleSetOrderMethodML(h.id)}
                          >
                            {h.name}{' '}
                            {orderMethodML.toString() === h.id && (
                              <i
                                className={
                                  orderMLReversed
                                    ? 'ion-arrow-down-b small'
                                    : 'ion-arrow-up-b small'
                                }
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {myList
                        ?.sort((a, b) => {
                          if (isNaN(a[orderMethodML])) {
                            if (orderMLReversed) {
                              return `${a[orderMethodML]}`.localeCompare(
                                `${b[orderMethodML]}`
                              );
                            } else {
                              return `${b[orderMethodML]}`.localeCompare(
                                `${a[orderMethodML]}`
                              );
                            }
                          } else {
                            if (orderMLReversed) {
                              return a[orderMethodML] - b[orderMethodML];
                            } else {
                              return b[orderMethodML] - a[orderMethodML];
                            }
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={item.ticker}>
                              <td>
                                {item.watching ? (
                                  <button
                                    className='btn btn-success'
                                    onClick={() =>
                                      handleAddToWatchlist(
                                        item.ticker,
                                        'Removed'
                                      )
                                    }
                                  >
                                    Watching
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleAddToWatchlist(item.ticker, 'Added')
                                    }
                                  >
                                    Watch
                                  </button>
                                )}
                              </td>
                              <td
                                className='Company'
                                title='Company'
                                onClick={() => handleShowModal(item.ticker)}
                              >
                                <b>{item.company}</b>
                              </td>
                              <Preview
                                title='Ticker'
                                CSSclass='strong'
                                ticker={item.ticker}
                                itemIndex={index}
                                breakPoint='4'
                                handleShowModal={handleShowModal}
                              />
                              <td>{item.etf?.replaceAll('|', ', ')}</td>
                              <td title='Market Value of Shares Bought/Sold'>
                                <span
                                  className={
                                    item.bought === 0 ? 'faded' : 'hidden'
                                  }
                                >
                                  No Change
                                </span>
                                {item.bought !== 0 && (
                                  <span
                                    className={
                                      item.bought < 0 ? 'danger' : 'success'
                                    }
                                  >
                                    {formatCurrency(item.bought, 'USD', false)}
                                  </span>
                                )}
                              </td>
                              <td title='1 Day Change in Shares Held'>
                                {item.d1shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d1shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d1shares)}
                                  </span>
                                )}
                                {item.d1shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d1shares)}
                                  </span>
                                )}
                                {item.d1shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='1 Day Change %'>
                                {item.d1delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d1delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d1delta)}%
                                  </span>
                                )}
                                {item.d1delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d1delta)}%
                                  </span>
                                )}
                                {item.d1delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='7 Day Change in Shares Held'>
                                {item.d7shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d7shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d7shares)}
                                  </span>
                                )}
                                {item.d7shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d7shares)}
                                  </span>
                                )}
                                {item.d7shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td>
                                {item.d7delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d7delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d7delta)}%
                                  </span>
                                )}
                                {item.d7delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d7delta)}%
                                  </span>
                                )}
                                {item.d7delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='14 Day Change in Shares Held'>
                                {item.d14shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d14shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d14shares)}
                                  </span>
                                )}
                                {item.d14shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d14shares)}
                                  </span>
                                )}
                                {item.d14shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td>
                                {item.d14delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d14delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d14delta)}%
                                  </span>
                                )}
                                {item.d14delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d14delta)}%
                                  </span>
                                )}
                                {item.d14delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='30 Day Change in Shares Held'>
                                {item.d30shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d30shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d30shares)}
                                  </span>
                                )}
                                {item.d30shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d30shares)}
                                  </span>
                                )}
                                {item.d30shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td>
                                {item.d30delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d30delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d30delta)}%
                                  </span>
                                )}
                                {item.d30delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d30delta)}%
                                  </span>
                                )}
                                {item.d30delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='Closing Price'>
                                <span
                                  className={
                                    item.close > item.previous
                                      ? 'success'
                                      : item.close == item.previous
                                      ? 'faded'
                                      : 'danger'
                                  }
                                >
                                  {formatCurrency(item.close, 'USD', true)}
                                </span>
                              </td>
                              <td title='Previous Closing Price'>
                                {formatCurrency(item.previous, 'USD', true)}
                              </td>
                              <td title='Weight in Portfolio'>
                                <span
                                  className={
                                    item.weight > item.previousweight
                                      ? 'success'
                                      : 'danger'
                                  }
                                >
                                  {formatNumber(item.weight)}%
                                </span>
                              </td>
                              <td title='Previous Weight in Portfolio'>
                                {item.previousweight}%
                              </td>
                              <td title='Change In Weight in Portfolio'>
                                {formatNumber(item.changeinweight)}%
                              </td>
                              <td title='Market Share'>
                                {formatCurrency(item.marketshare, 'USD', false)}
                              </td>
                              <td title='Shares'>
                                {formatNumber(item.shares)}
                              </td>
                              <td title='Floating Stock'>
                                {!item.float?.toString().length && (
                                  <span className='faded'>No Data</span>
                                )}

                                <span
                                  className={
                                    item.float?.toString().length
                                      ? ''
                                      : 'hidden'
                                  }
                                >
                                  {item.float}
                                </span>
                              </td>
                              <td title='Float Percentage (Shares Held by ARK / Floating Stock)'>
                                {!item.floatperc?.toString().length && (
                                  <span className='faded'>No Data</span>
                                )}
                                <span
                                  className={
                                    item.floatperc?.toString().length
                                      ? ''
                                      : 'hidden'
                                  }
                                >
                                  {item.floatperc}%
                                </span>
                              </td>
                              <td title='Weighted Avg Buy Price'>
                                {item?.wavgbuyprice?.length === 0 && (
                                  <span className='faded'>No Data</span>
                                )}

                                <span>{item.wavgbuyprice}</span>
                              </td>
                              <td>
                                {item.tradenotedir ? (
                                  <span
                                    className={
                                      item.tradenotedir === 'Buy'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {item.tradenotedir}
                                  </span>
                                ) : (
                                  <span className='faded'>N/A</span>
                                )}
                              </td>
                              <td>
                                {item.tradenoteshares ? (
                                  <span
                                    className={
                                      item.tradenotedir === 'Buy'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatNumber(item.tradenoteshares, 0)}
                                  </span>
                                ) : (
                                  <span className='faded'>N/A</span>
                                )}
                              </td>
                              <td>
                                {item.tradenotevalue ? (
                                  <span
                                    className={
                                      item.tradenotedir === 'Buy'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatCurrency(
                                      item.tradenotevalue,
                                      'USD',
                                      0
                                    )}
                                  </span>
                                ) : (
                                  <span className='faded'>N/A</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={selectedTab === watchlistTabs[1] ? '' : 'hidden'}>
              {allList.length > 0 && (
                <div className='h-scroll'>
                  <table className='striped h-scroll fixed'>
                    <thead>
                      <tr>
                        <th>Watch</th>
                        {stocklistHeaders.map((h) => (
                          <th
                            title={h.title}
                            className={h.name}
                            onClick={() => handleSetOrderMethodML(h.id)}
                          >
                            {h.name}{' '}
                            {orderMethodML.toString() === h.id && (
                              <i
                                className={
                                  orderMLReversed
                                    ? 'ion-arrow-down-b small'
                                    : 'ion-arrow-up-b small'
                                }
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {allList
                        ?.sort((a, b) => {
                          if (isNaN(a[orderMethodML])) {
                            if (orderMLReversed) {
                              return a[orderMethodML]
                                .toString()
                                .localeCompare(b[orderMethodML].toString());
                            } else {
                              return b[orderMethodML]
                                .toString()
                                .localeCompare(a[orderMethodML].toString());
                            }
                          } else {
                            if (orderMLReversed) {
                              return a[orderMethodML] - b[orderMethodML];
                            } else {
                              return b[orderMethodML] - a[orderMethodML];
                            }
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={item.ticker}>
                              <td>
                                {item.watching ? (
                                  <button
                                    className='btn btn-success'
                                    onClick={() =>
                                      handleAddToWatchlist(
                                        item.ticker,
                                        'Removed'
                                      )
                                    }
                                  >
                                    Watching
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleAddToWatchlist(item.ticker, 'Added')
                                    }
                                  >
                                    Watch
                                  </button>
                                )}
                              </td>
                              <td
                                className='Company'
                                title='Company'
                                onClick={() => handleShowModal(item.ticker)}
                              >
                                <b>{item.company}</b>
                              </td>
                              <Preview
                                title='Ticker'
                                CSSclass='strong'
                                ticker={item.ticker}
                                itemIndex={index}
                                breakPoint='4'
                                handleShowModal={handleShowModal}
                              />
                              <td>{item.etf?.replaceAll('|', ', ')}</td>
                              <td title='Market Value of Shares Bought/Sold'>
                                <span
                                  className={
                                    item.bought === 0 ? 'faded' : 'hidden'
                                  }
                                >
                                  No Change
                                </span>
                                {item.bought !== 0 && (
                                  <span
                                    className={
                                      item.bought < 0 ? 'danger' : 'success'
                                    }
                                  >
                                    {formatCurrency(item.bought, 'USD', false)}
                                  </span>
                                )}
                              </td>
                              <td title='1 Day Change in Shares Held'>
                                {item.d1shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d1shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d1shares)}
                                  </span>
                                )}
                                {item.d1shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d1shares)}
                                  </span>
                                )}
                                {item.d1shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='1 Day Change %'>
                                {item.d1delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d1delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d1delta)}%
                                  </span>
                                )}
                                {item.d1delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d1delta)}%
                                  </span>
                                )}
                                {item.d1delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='7 Day Change in Shares Held'>
                                {item.d7shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d7shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d7shares)}
                                  </span>
                                )}
                                {item.d7shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d7shares)}
                                  </span>
                                )}
                                {item.d7shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td>
                                {item.d7delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d7delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d7delta)}%
                                  </span>
                                )}
                                {item.d7delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d7delta)}%
                                  </span>
                                )}
                                {item.d7delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='14 Day Change in Shares Held'>
                                {item.d14shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d14shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d14shares)}
                                  </span>
                                )}
                                {item.d14shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d14shares)}
                                  </span>
                                )}
                                {item.d14shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td>
                                {item.d14delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d14delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d14delta)}%
                                  </span>
                                )}
                                {item.d14delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d14delta)}%
                                  </span>
                                )}
                                {item.d14delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='30 Day Change in Shares Held'>
                                {item.d30shares === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d30shares > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d30shares)}
                                  </span>
                                )}
                                {item.d30shares < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d30shares)}
                                  </span>
                                )}
                                {item.d30shares === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td>
                                {item.d30delta === 0 && (
                                  <span className='faded'>No Change</span>
                                )}
                                {item.d30delta > 0 && (
                                  <span className='success'>
                                    {' '}
                                    +{formatNumber(item.d30delta)}%
                                  </span>
                                )}
                                {item.d30delta < 0 && (
                                  <span className='danger'>
                                    {formatNumber(item.d30delta)}%
                                  </span>
                                )}
                                {item.d30delta === null && (
                                  <span className='faded'>No Data</span>
                                )}
                              </td>
                              <td title='Closing Price'>
                                <span
                                  className={
                                    item.close > item.previous
                                      ? 'success'
                                      : item.close == item.previous
                                      ? 'faded'
                                      : 'danger'
                                  }
                                >
                                  {formatCurrency(item.close, 'USD', true)}
                                </span>
                              </td>
                              <td title='Previous Closing Price'>
                                {formatCurrency(item.previous, 'USD', true)}
                              </td>
                              <td title='Weight in Portfolio'>
                                <span
                                  className={
                                    item.weight > item.previousweight
                                      ? 'success'
                                      : 'danger'
                                  }
                                >
                                  {formatNumber(item.weight)}%
                                </span>
                              </td>
                              <td title='Previous Weight in Portfolio'>
                                {item.previousweight}%
                              </td>
                              <td title='Change In Weight in Portfolio'>
                                {formatNumber(item.changeinweight)}%
                              </td>
                              <td title='Market Share'>
                                {formatCurrency(item.marketshare, 'USD', false)}
                              </td>
                              <td title='Shares'>
                                {formatNumber(item.shares)}
                              </td>
                              <td title='The number of shares available to retail investors to buy and sell. Restricted, closely-held (Institutional, Employee) held shares are excluded.'>
                                {!item.float?.toString().length && (
                                  <span className='faded'>No Data</span>
                                )}

                                <span
                                  className={
                                    item.float?.toString().length
                                      ? ''
                                      : 'hidden'
                                  }
                                >
                                  {item.float}
                                </span>
                              </td>
                              <td title='Float Percentage (Shares Held by ARK / Floating Stock)'>
                                {!item.floatperc?.toString().length && (
                                  <span className='faded'>No Data</span>
                                )}
                                <span
                                  className={
                                    item.floatperc?.toString().length
                                      ? ''
                                      : 'hidden'
                                  }
                                >
                                  {item.floatperc}%
                                </span>
                              </td>
                              <td title='Weighted Avg Buy Price'>
                                {item?.wavgbuyprice?.length === 0 && (
                                  <span className='faded'>No Data</span>
                                )}

                                <span>{item.wavgbuyprice}</span>
                              </td>
                              <td>
                                {item.tradenotedir ? (
                                  <span
                                    className={
                                      item.tradenotedir === 'Buy'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {item.tradenotedir}
                                  </span>
                                ) : (
                                  <span className='faded'>N/A</span>
                                )}
                              </td>
                              <td>
                                {item.tradenoteshares ? (
                                  <span
                                    className={
                                      item.tradenotedir === 'Buy'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatNumber(item.tradenoteshares, 0)}
                                  </span>
                                ) : (
                                  <span className='faded'>N/A</span>
                                )}
                              </td>
                              <td>
                                {item.tradenotevalue ? (
                                  <span
                                    className={
                                      item.tradenotedir === 'Buy'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatCurrency(
                                      item.tradenotevalue,
                                      'USD',
                                      0
                                    )}
                                  </span>
                                ) : (
                                  <span className='faded'>N/A</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {selectedTab === watchlistTabs[1] && (
              <div>
                {myWatchlist.length === 0 && (
                  <div
                    className='panel'
                    style={{ padding: '10px', textAlign: 'center' }}
                  >
                    <div className='jumbo'>💎 🙌</div>
                    <h2>Your {currentFundGroup} Watchlist is Empty</h2>
                    <p style={{ marginBottom: '20px' }}>
                      Check out all the tickers on the All tab.
                    </p>
                  </div>
                )}

                {userType === 'Member' && (
                  <div
                    className='panel'
                    style={{ padding: '10px', textAlign: 'center' }}
                  >
                    <div className='jumbo'>🚀</div>
                    <h2>Upgrade to Premium</h2>
                    <p style={{ marginBottom: '20px' }}>
                      View your watchlist in aggregate instead of by the 5 ETFs.
                      Get access and premium member features.
                    </p>

                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade
                    </a>
                  </div>
                )}
              </div>
            )}

            {selectedTab === watchlistTabs[2] && (
              <div>
                <div className='h-scroll sub-header'>
                  <div className='tabs'>
                    {chartTabs?.map((c) => (
                      <div
                        key={c.label}
                        className={
                          selectedChartTab === c ? 'tab active' : 'tab'
                        }
                        onClick={() => handleSetSelectedChartTab(c)}
                      >
                        <div>{c.label}</div>
                      </div>
                    ))}
                  </div>
                </div>
                {userType === 'Premium' && (
                  <div className='h-scroll'>
                    <div
                      className='fade-in'
                      style={{
                        height: 400,
                        minWidth: `${filteredAggregateList.length * 80}px`,
                      }}
                    >
                      <ParentSize>
                        {({ width, height }) => (
                          <BarVix
                            width={width}
                            height={height}
                            chartData={filteredAggregateList}
                            type={selectedChartTab.type}
                            handleShowModal={handleShowModal}
                            group='Aggregate'
                            currentFundGroup={currentFundGroup}
                            watchList={true}
                          />
                        )}
                      </ParentSize>
                    </div>
                  </div>
                )}

                {userType === 'Member' && (
                  <div>
                    <div style={{ padding: '40px 10px', textAlign: 'center' }}>
                      <br />
                      <div className='jumbo'>🚀</div>
                      <h2>Upgrade to Premium</h2>
                      <p style={{ marginBottom: '20px' }}>
                        Get access to Watchlist Charts by upgrading to Premium.
                      </p>
                      <a
                        onClick={() => handleShowUpgrade(true)}
                        className='btn btn-primary'
                      >
                        Upgrade to Premium
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}

            {selectedTab === watchlistTabs[3] && (
              <>
                {userType === 'Member' && (
                  <div>
                    <div style={{ padding: '40px 10px', textAlign: 'center' }}>
                      <br />
                      <div className='jumbo'>🚀</div>
                      <h2>Upgrade to Premium</h2>
                      <p style={{ marginBottom: '20px' }}>
                        Get access to Popular Watchlist by upgrading to Premium.
                      </p>

                      <a
                        onClick={() => handleShowUpgrade(true)}
                        className='btn btn-primary'
                      >
                        Upgrade to Premium
                      </a>
                    </div>
                  </div>
                )}

                {userType === 'Premium' && (
                  <div className='h-scroll'>
                    <table className='striped h-scroll fixed'>
                      <thead>
                        <tr>
                          {watchlistPopularHeaders.map((h) => (
                            <th
                              title={h.title}
                              className='link'
                              onClick={() => handleSetOrderMethod(h.id)}
                            >
                              {h.title}
                              {orderMethodML.toString() === h.id && (
                                <i
                                  className={
                                    orderReversed
                                      ? 'ion-arrow-down-b small'
                                      : 'ion-arrow-up-b small'
                                  }
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {watchlistStats
                          ?.sort((a, b) => {
                            if (isNaN(a[orderMethod])) {
                              if (orderReversed) {
                                return `${a[orderMethod]}`.localeCompare(
                                  `${b[orderMethod]}`
                                );
                              } else {
                                return `${b[orderMethod]}`.localeCompare(
                                  `${a[orderMethod]}`
                                );
                              }
                            } else {
                              if (orderReversed) {
                                return a[orderMethod] - b[orderMethod];
                              } else {
                                return b[orderMethod] - a[orderMethod];
                              }
                            }
                          })
                          .map((l, index) => (
                            <tr key={l.ticker}>
                              <td>
                                {l.watching ? (
                                  <button
                                    className='btn btn-success'
                                    onClick={() =>
                                      handleAddToWatchlist(l.ticker, 'Removed')
                                    }
                                  >
                                    Watching
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleAddToWatchlist(l.ticker, 'Added')
                                    }
                                  >
                                    Watch
                                  </button>
                                )}
                              </td>
                              <td>#{l.rank}</td>
                              <Preview
                                title='Ticker'
                                CSSclass='strong'
                                ticker={l.ticker}
                                itemIndex={index}
                                breakPoint='4'
                                handleShowModal={handleShowModal}
                              />
                              <td
                                className={
                                  l.close > l.previous
                                    ? 'success'
                                    : l.close == l.previous
                                    ? 'faded'
                                    : 'danger'
                                }
                              >
                                {formatCurrency(l.close, 'USD', true)}
                              </td>
                              <td>{formatCurrency(l.previous, 'USD', true)}</td>
                              <td>{formatNumber(l.totalwatchers, 0)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Watchlist;
