import React, { useState, useEffect } from 'react';
import { memeHeaders, siHeaders, ftdHeaders } from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import '../styles/crypto.scss';
import { formatCurrency, formatNumber } from '../helpers/format';
import { optionsActiveHeaders } from '../helpers/fundvars';
import { flattenArray } from '../helpers/helpers';
import Preview from '../layouts/preview';
import UnusualOptions from '../layouts/unusualoptions';
import Squeeze from '../layouts/squeeze';
import Institutional from '../layouts/institutional';
import Senate from '../layouts/senate';

function MemeIndicators({
  userType,
  handleShowSimpleModal,
  handleShowUpgrade,
  memeInfo,
  optionsData,
  investorsData,
  shortSqueezeData,
  institutionalData,
  senateData,
  optionsActiveData,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [orderReversed, setOrderReversed] = useState(false);
  const [orderMethod, setOrderMethod] = useState('ftdtotalvalue');
  const [orderFTDReversed, setOrderFTDReversed] = useState(false);
  const [orderFTDMethod, setOrderFTDMethod] = useState('value');
  const [siOrderReversed, setSiOrderReversed] = useState(false);
  const [siOrderMethod, setSiOrderMethod] = useState('si');
  const [ftdData, setFtdData] = useState([]);
  const [expandedHeight, setExpandedHeight] = useState('400px');

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const handleSiSetOrderMethod = (header) => {
    if (header === siOrderMethod) {
      setSiOrderReversed(!siOrderReversed);
    } else {
      setSiOrderMethod(header);
    }
  };

  const handleSetFTDOrderMethod = (header) => {
    if (header === orderFTDMethod) {
      setOrderFTDReversed(!orderFTDReversed);
    } else {
      setOrderFTDMethod(header);
    }
  };

  const generateArray = (string, ticker, company) => {
    if (string && string.length > 0) {
      const array = string.split('|').map((s) => s.split(','));
      //console.log('split', array);
      const arrayObject = array.map((a) => {
        if (a.length > 0) {
          return {
            ticker: ticker,
            company: company,
            qty: a[0] || 0,
            price: a[1] || 0,
            settle: a[2] || <span className='faded'>N/A</span>,
            t35: a[3] || <span className='faded'>N/A</span>,
            value: a[4] || 0,
          };
        }
      });
      return arrayObject;
    } else {
      return [];
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (memeInfo.length > 0) {
      setIsLoading(false);
      const ftd = memeInfo.map((m) =>
        generateArray(m.ftdarray, m.ticker, m.company)
      );
      setFtdData(flattenArray(ftd));
    }
  }, [memeInfo, optionsData]);

  return (
    <div className='dashboard'>
      {isLoading && <Loader title={`${userType} Meme Data`} />}

      {!isLoading && (
        <>
          <div className='panel-container text-center'>
            <h2>Unusual Options Activity </h2>
            <h3 className='faded'>US Markets: Meme Stocks</h3>
            <h5 className='faded'>
              Options that are trading at a higher volume relative to the
              contract's open interest. This signals a potential big move in the
              stock.
            </h5>
            <br />
            <div
              className='h-scroll'
              style={{
                height: expandedHeight,
                overflow: 'auto',
                maxWidth: '1200px',
              }}
            >
              <UnusualOptions
                optionsData={optionsData}
                handleShowSimpleModal={handleShowSimpleModal}
                type='meme'
              />
            </div>
          </div>

          <div className='panel-container text-center'>
            <h2>Most Active Options</h2>
            <h3 className='faded'>US Markets: Meme Stocks</h3>
            <h5 className='faded'>
              Put/Call Volume above 1 may indicate a selloff and below 1 is a
              buying opportunity. Higher IV Rank means higher risk/reward.
            </h5>
            <br />
            <div
              className='h-scroll'
              style={{
                height: expandedHeight,
                overflow: 'auto',
                maxWidth: '1200px',
              }}
            >
              <table>
                <thead>
                  <tr>
                    {optionsActiveHeaders.map((d) => (
                      <th key={d.id} className={d.id} title={d.title}>
                        {d.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {optionsActiveData?.map((r, index) => (
                    <tr
                      className='link'
                      key={`${r.Symbol}${index}`}
                      onClick={() => handleShowSimpleModal(r.Symbol)}
                    >
                      <Preview
                        ticker={r.Symbol}
                        itemIndex={index}
                        breakPoint='4'
                      />
                      <td>{formatNumber(r['IV Rank'] * 100, 2)}%</td>
                      <td>{formatNumber(r['Options Vol'], 0)}</td>
                      <td>{formatNumber(r['% Put'] * 100, 2)}%</td>
                      <td>{formatNumber(r['% Call'] * 100, 2)}%</td>
                      <td
                        title={
                          r['Put/Call Vol'] < 1
                            ? 'Indicates a buying opportunity'
                            : 'Indicates a potential selloff'
                        }
                        className={r['Put/Call Vol'] < 1 ? 'success' : 'danger'}
                      >
                        {formatNumber(r['Put/Call Vol'], 2)}
                      </td>
                      <td className='Company'>{r.Name}</td>
                      <td>{r.Time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className='panel-container text-center'>
            <h2>Short Squeeze Potential</h2>
            <h3 className='faded'>US Markets: Meme Stocks</h3>
            <h5>
              <a
                href='https://www.investopedia.com/terms/s/shortsqueeze.asp'
                target='_blank'
              >
                Large short positions pressured to close as price rises causing
                prices to rise further. Premium only.{' '}
                <i className='ion-android-open'></i>
              </a>
            </h5>
            <br />
            {userType === 'Member' && (
              <div>
                <div className='text-center'>
                  <br />
                  <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                  <p className='small faded'>
                    Support Lucid and get access to <br />
                    premium content.
                  </p>
                  <small>
                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade to Premium
                    </a>
                  </small>
                </div>
              </div>
            )}
            {userType === 'Premium' && (
              <>
                {shortSqueezeData?.length === 0 ? (
                  'No Short Squeezes Found.'
                ) : (
                  <div className='h-scroll'>
                    <Squeeze
                      type='meme'
                      shortSqueezeData={shortSqueezeData}
                      handleShowSimpleModal={handleShowSimpleModal}
                    />
                  </div>
                )}
              </>
            )}
          </div> */}

          <div className='panel-container text-center'>
            <h2>Institutional Ownership + Buying</h2>
            <h3 className='faded'>US Markets: Meme Stocks</h3>
            <h5 className='faded'>
              Stocks that institutional buyers such as mutual funds are buying.
              Institutional ownership is the percentage of a firm's shares held
              by these wired-in players, and ranges from 50% to 95% for in favor
              stocks. We monitor Institutional Transactions to confirm that they
              are still buying shares. Premium only.
            </h5>
            <br />
            {userType === 'Member' && (
              <div>
                <div className='text-center'>
                  <br />
                  <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                  <p className='small faded'>
                    Support Lucid and get access to <br />
                    premium content.
                  </p>
                  <small>
                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade to Premium
                    </a>
                  </small>
                </div>
              </div>
            )}
            {userType === 'Premium' && (
              <>
                {institutionalData?.length === 0 ? (
                  'No Institutional Data Found.'
                ) : (
                  <div className='h-scroll'>
                    <Institutional
                      type='meme'
                      institutionalData={institutionalData}
                      handleShowSimpleModal={handleShowSimpleModal}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default MemeIndicators;
