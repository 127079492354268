import React from 'react';
import { Group } from '@visx/group';
import { BarGroup } from '@visx/shape';
import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
//import { timeParse, timeFormat } from 'd3-time-format';
import { fundGroup } from '../helpers/fundvars';
import { getDataLength } from '../helpers/api';
//import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import '../styles/charts.scss';

/* const blue = '#aeeef8';
export const green = '#e5fd3d';
const purple = '#9caff6'; */
export const background = 'transparent';
let barColor = '#333';
/* const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
}; */

const defaultMargin = { top: 40, right: 0, bottom: 40, left: 0 };
//const parseDate = timeParse('%Y-%m-%d');
//const format = timeFormat('%b %d');
const dataLength = getDataLength();
let xValueDivider = 2.5;
//const formatDate = (date) => format(parseDate(date));

//let tooltipTimeout;

// accessors
//const getDate = (d) => d.date;
const getTicker = (d) => d.ticker;

export default function BarVix({
  width,
  height,
  events = true,
  margin = defaultMargin,
  chartData,
  type,
  handleShowModal,
  handleCryptoModalData,
  currentFundGroup,
  watchList = false,
  crypto,
}) {
  //console.log('chartData', chartData);
  let barColorFromFund = fundGroup.find(
    (f) => currentFundGroup === f.name
  )?.color;
  if (barColorFromFund) {
    barColor = fundGroup.find((f) => currentFundGroup === f.name).color;
  }

  let data = [];
  let keys = [];
  let tickerScalePadding;

  data = chartData.map((c) => c[type]);
  keys = data.length ? Object.keys(data[0]).filter((d) => d !== 'ticker') : [];
  if (watchList) {
    //console.log('data.length', data.length / 2);
    tickerScalePadding = data.length * 0.025;
    xValueDivider = data.length / 20;
  } else {
    if (currentFundGroup === 'ARK Invest') {
      if (dataLength === 'All Data') {
        tickerScalePadding = 0;
        xValueDivider = 2.75;
      } else {
        tickerScalePadding = 0.078;
        xValueDivider = 0.8;
      }
      /* tickerScalePadding = 0;
      xValueDivider = 2.5; */
    }
    if (currentFundGroup === 'BlackRock') {
      tickerScalePadding = 0.12;
      xValueDivider = 0.8;
    }
    if (
      currentFundGroup === 'Global X' /* || currentFundGroup === 'Roundhill' */
    ) {
      tickerScalePadding = 0.12;
      xValueDivider = 0.8;
    }
    if (crypto) {
      tickerScalePadding = 0.26;
      xValueDivider = 0.55;
      barColor = 'rgb(228, 44, 128)';
    }
  }

  // scales
  const tickerScale = scaleBand({
    domain: data?.map(getTicker),
    padding: tickerScalePadding,
  });
  const xScale = scaleBand({
    domain: keys,
    padding: 0,
  });
  const yScale = scaleLinear({
    domain: [
      0,
      Math.max(
        ...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
      ),
    ],
  });
  const colorScale = scaleOrdinal({
    domain: keys,
    //range: [blue, green, purple],
  });
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // update scale output dimensions
  tickerScale.rangeRound([0, xMax]);
  xScale.rangeRound([0, tickerScale.bandwidth()]);
  yScale.range([yMax, 0]);

  /* const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip(); */

  //const { containerRef, TooltipInPortal } = useTooltipInPortal();

  return width < 10 ? null : (
    <div className='charts'>
      {data.length === 0 && (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <div className='jumbo'>💎 🙌</div>
          <h2>Your {currentFundGroup} Watchlist is Empty</h2>
          <p style={{ marginBottom: '20px' }}>
            Check out all the tickers on the All tab.
          </p>
        </div>
      )}
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={background} />
        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={data}
            keys={keys}
            height={yMax}
            x0={getTicker}
            x0Scale={tickerScale}
            x1Scale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                  width={data.length}
                >
                  {barGroup.bars.map((bar) => (
                    <rect
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      x={bar.index / xValueDivider}
                      y={bar.y}
                      width={3}
                      height={bar.height}
                      fill={bar.height < 0 ? 'red' : barColor}
                      rx={0}
                      onClick={(e) => {
                        if (e) {
                          if (crypto) {
                            handleCryptoModalData(data[barGroup.index].ticker);
                          } else {
                            handleShowModal(data[barGroup.index].ticker);
                          }
                        }
                      }}
                      /* onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={(event) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const top = event.clientY - margin.top;
                        const left = bar.x + bar.width / 2;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: top,
                          tooltipLeft: left,
                        });
                        
                      }} */
                    />
                  ))}
                </Group>
              ))
            }
          </BarGroup>
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          //tickFormat={formatDate}
          scale={tickerScale}
          stroke={'#000'}
          tickStroke={'transparent'}
          hideAxisLine
          numTicks={data.length}
          tickLabelProps={() => ({
            fill: '#eee',
            fontWeight: 600,
            fontSize:
              currentFundGroup === 'Global X' /* ||
              currentFundGroup === 'Roundhill' */
                ? 10
                : 13,
            textAnchor: 'middle',
          })}
        />
      </svg>
      <div className='chart-line-1'></div>
      <div className='chart-line-2'></div>
      <div className='chart-line-3'></div>
      <div className='chart-line-4'></div>
      <div className='chart-line-5'></div>
      <div className='chart-line-6'></div>
      <div className='chart-line-7'></div>
      <div className='chart-line-8'></div>
    </div>
  );
}
