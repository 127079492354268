import { formatNumber, formatCurrency } from '../helpers/format';

function PanelRankings({ data, handleShowModal }) {
  let items = data.data.filter((d) => d.rankchg > 3 || d.rankchg < -3);

  const setPositive = (number) => {
    if (!number) {
      return 0;
    }
    return Math.abs(number);
  };

  return (
    <div>
      <h4 className='text-center'>
        <span className='faded' title={data.description}>
          {data.fund} {data.name}{' '}
        </span>
        <span
          title={`${data.type}ly Managed Fund`}
          className={
            data.type === 'Active' ? 'badge small primary' : 'badge small'
          }
        >
          {data.type?.substring(0, 1)}
        </span>
      </h4>
      <div className='panel-content'>
        <table>
          {items.map((l, index) => (
            <tbody key={`${l.ticker}${index}`}>
              <tr onClick={() => handleShowModal(l.ticker)} title={l.company}>
                <td>
                  <b>
                    #{l.rank} {l.ticker}
                  </b>
                  <div className='small' style={{ lineHeight: 1 }}>
                    <span
                      className={
                        l.rankchg > 0
                          ? 'success weight-indicator'
                          : 'danger weight-indicator'
                      }
                    >
                      <i
                        className={
                          parseInt(l.rankchg) > 0
                            ? 'ion-arrow-up-a'
                            : 'ion-arrow-down-a'
                        }
                      ></i>
                      {setPositive(l.rankchg)}
                    </span>
                    <span className='faded' style={{ marginLeft: '10px' }}>
                      {l.ranks1d} Previous
                    </span>
                  </div>
                  <div>
                    <small>
                      Weight: {formatNumber(l.weight, 2)}%
                      {l.weightchg !== 0 && (
                        <span
                          className={
                            l.weightchg > 0
                              ? 'success weight-indicator'
                              : 'danger weight-indicator'
                          }
                        >
                          <i
                            className={
                              l.weightchg > 0
                                ? 'ion-arrow-up-a'
                                : 'ion-arrow-down-a'
                            }
                          ></i>
                          {formatNumber(setPositive(l.weightchg), 2)}%
                        </span>
                      )}
                    </small>
                  </div>
                </td>
                <td>
                  <div className='small'>
                    ${l.close}
                    <small className='faded'>Close</small>
                  </div>
                  <div className='small'>
                    ${l.yestclose}
                    <small className='faded'>Previous</small>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        {items.length === 0 && (
          <div className='text-center'>
            <p>No change.</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default PanelRankings;
