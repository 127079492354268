import React, { useState } from 'react';
import { investorHeaders } from '../helpers/fundvars';
import Preview from '../layouts/preview';
import { formatNumber, formatCurrency, formatDate } from '../helpers/format';

let investors = [];
let headers = [];

function AlsoHeldBy({
  investorsData,
  handleShowModal,
  ticker,
  filterBy,
  type,
}) {
  const [orderMethod, setOrderMethod] = useState('Symbol');
  const [orderReversed, setOrderReversed] = useState(true);
  //const [investors, setInvestors] = useState([]);

  if (ticker) {
    investors = investorsData.filter((f) => f.Symbol === ticker);
    headers = investorHeaders.filter(
      (v) => v.id !== 'Symbol' && v.id !== 'Company'
    );
  } else {
    if (filterBy && filterBy.length > 0) {
      //console.log('filterBy', filterBy);
      investors = investorsData.filter((f) => filterBy.includes(f.Symbol));
    } else {
      investors = investorsData;
    }
    headers = investorHeaders;
  }

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  return (
    <>
      {investors.length > 0 ? (
        <table className={type}>
          <thead>
            <tr>
              {headers.map((d) => (
                <th
                  key={d.id}
                  className={`${d.id} link`}
                  title={d.title}
                  onClick={() => handleSetOrderMethod(d.id)}
                >
                  {d.name}
                  {`${orderMethod}` === d.id && (
                    <i
                      className={
                        orderReversed
                          ? 'ion-arrow-down-b small'
                          : 'ion-arrow-up-b small'
                      }
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {investors
              .sort((a, b) => {
                if (isNaN(a[orderMethod])) {
                  if (orderReversed) {
                    return `${a[orderMethod]}`.localeCompare(
                      `${b[orderMethod]}`
                    );
                  } else {
                    return `${b[orderMethod]}`.localeCompare(
                      `${a[orderMethod]}`
                    );
                  }
                } else {
                  if (orderReversed) {
                    return a[orderMethod] - b[orderMethod];
                  } else {
                    return b[orderMethod] - a[orderMethod];
                  }
                }
              })
              .map((r, index) => (
                <tr
                  className='link'
                  key={`${r.Symbol}${index}`}
                  onClick={() => handleShowModal(r.Symbol)}
                >
                  <td>{r.Investor}</td>

                  {!ticker && (
                    <>
                      <td className='Company'>{r.Company}</td>
                      <Preview
                        ticker={r.Symbol}
                        itemIndex={index}
                        breakPoint='4'
                      />
                    </>
                  )}

                  <td
                    className={
                      r.Type === 'Stock'
                        ? ''
                        : r.Type === 'Call'
                        ? 'success'
                        : 'danger'
                    }
                  >
                    {r.Type}
                  </td>
                  <td>{formatNumber(r.Holdings, 0)}</td>
                  <td>${formatNumber(r.Value, 0)}</td>
                  <td>{formatCurrency(r['Avg Paid'], 'USD', 2)}</td>
                  <td
                    className={
                      r.Weight == r.Prev
                        ? 'faded'
                        : r.Weight > r.Prev
                        ? 'success'
                        : 'danger'
                    }
                  >
                    {formatNumber(r.Weight, 2)}
                  </td>
                  <td>{formatNumber(r.Prev, 2)}</td>
                  <td>{formatDate(r['Source Date'])}</td>
                  <td>{formatDate(r['Date Reported'])}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        'No data found.'
      )}
    </>
  );
}
export default AlsoHeldBy;
