import React, { useState, useEffect } from 'react';
import Loader from '../layouts/loader';
import '../styles/rankings.scss';
import { formatNumber, formatCurrency, formatDate } from '../helpers/format';
import Preview from '../layouts/preview';

function Rankings({
  userType,
  currentFundGroup,
  topRankings,
  topRankingsTabs,
  handleShowModal,
  handleShowUpgrade,
  fundArray,
}) {
  const [toggledTutorial, setToggledTutorial] = useState(false);
  const [selectedTab, setSelectedTab] = useState({});
  const [selectedRankingTab, setSelectedRankingTab] = useState(
    topRankingsTabs[0].value
  );
  const [rankingOrderMethod, setRankingOrderMethod] = useState('rank');
  const [rankings, setRankings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderReversed, setOrderReversed] = useState(true);

  //console.log('topRankings', topRankings);

  const orderBy = (orderMethod) => {
    setRankingOrderMethod(orderMethod);
    setOrderReversed(!orderReversed);
  };

  useEffect(() => {
    if (topRankings?.length > 0) {
      setIsLoading(false);
      setSelectedTab(topRankings[0].fund);
      setRankings(topRankings);
      //orderBy(topRankings, 'rank');
      console.log('rankings', topRankings);
    }
  }, [topRankings]);

  return (
    <div className={`dashboard rankings ${currentFundGroup}`}>
      {isLoading && <Loader title={`${currentFundGroup} Rankings`} />}
      {!isLoading && (
        <div>
          <div className='h-scroll header-content d-flex'>
            <h1 onClick={() => setToggledTutorial(!toggledTutorial)}>
              {fundArray.length && formatDate(fundArray[0].list[0].data.date)}{' '}
              <span className='hidden-mobile'>Rankings</span>{' '}
              <a onClick={() => setToggledTutorial(!toggledTutorial)}>
                <i className='ion-help-circled'></i>
              </a>
            </h1>
            <div className='tabs'>
              {topRankingsTabs.map((tab) => (
                <div
                  key={tab.value}
                  onClick={() => setSelectedRankingTab(tab.value)}
                  className={
                    selectedRankingTab === tab.value ? 'active tab' : 'tab'
                  }
                >
                  {tab.name}
                </div>
              ))}
            </div>
          </div>

          {toggledTutorial && (
            <div className='help-text'>
              <p style={{ marginBottom: '15px' }}>
                The weight of holdings in each company, provided in a ranked
                listing. Look for drastic increases and decreases.
              </p>

              <p style={{ marginBottom: '15px' }}>
                Weight = (total shares held of a company * daily close) / Market
                Value of ETF.
              </p>

              <p style={{ marginBottom: '15px' }}>
                Aggregates = (total shares held of a company across all 5 ETF’s
                * daily close) / Combined Market Value of all 5 ETF’s.
              </p>
            </div>
          )}

          {userType === 'Member' && (
            <div>
              <div className='panel text-center'>
                <br />
                <div className='jumbo'>🚀</div>
                <h2 style={{ marginTop: 0 }}>Upgrade to Premium</h2>
                <p>Get access to Rankings and premium member features.</p>

                <a
                  onClick={() => handleShowUpgrade(true)}
                  className='btn btn-primary'
                >
                  Upgrade to Premium
                </a>
                <br />
                <img
                  className='sample'
                  src='https://lucidtracking.s3.amazonaws.com/assets/appassets/rankings-premium.png'
                  alt='Aggregate Sample'
                />
              </div>
            </div>
          )}

          {userType === 'Premium' && (
            <>
              <div className='h-scroll sub-header double'>
                <div className='tabs'>
                  {topRankings.map((tab) => (
                    <div
                      key={tab.fund}
                      onClick={() => setSelectedTab(tab.fund)}
                      className={
                        selectedTab === tab.fund ? 'active tab' : 'tab'
                      }
                    >
                      <div>{tab.fund}</div>
                      <small className='faded white'>{tab.name}</small>
                    </div>
                  ))}
                </div>
              </div>
              <div className='h-scroll'>
                <div style={{ maxWidth: '1600px', margin: 'auto' }}>
                  {rankings.map((group) => (
                    <div key={group.fund}>
                      {group.fund === selectedTab && (
                        <div className='h-scroll'>
                          {/* <h2>
                          {group.fund} {group.name}
                        </h2> */}

                          <table className='striped fixed'>
                            <thead>
                              <tr className='link'>
                                <th
                                  title='Rank'
                                  className='text-center rank'
                                  onClick={() => orderBy('rank')}
                                >
                                  Rank
                                  <i
                                    className={
                                      rankingOrderMethod === 'rank' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'rank' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>
                                <th
                                  className='Company'
                                  title='Company'
                                  onClick={() => orderBy('company')}
                                >
                                  Company
                                  <i
                                    className={
                                      rankingOrderMethod === 'company' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'company' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>
                                <th
                                  title='Ticker'
                                  onClick={() => orderBy('ticker')}
                                >
                                  Ticker
                                  <i
                                    className={
                                      rankingOrderMethod === 'ticker' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'ticker' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>
                                <th
                                  title='Change In Rank'
                                  onClick={() => orderBy('rankchg')}
                                >
                                  Change In Rank
                                  <i
                                    className={
                                      rankingOrderMethod === 'rankchg' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'rankchg' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>

                                <th
                                  title='Weight'
                                  onClick={() => orderBy('weight')}
                                >
                                  Weight
                                  <i
                                    className={
                                      rankingOrderMethod === 'weight' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'weight' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>

                                <th
                                  title='Percent Change'
                                  onClick={() => orderBy('weightchg')}
                                >
                                  Weight Change
                                  <i
                                    className={
                                      rankingOrderMethod === 'weightchg' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'weightchg' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>

                                {selectedRankingTab === 'data' && (
                                  <th
                                    title='Market Share'
                                    onClick={() => orderBy('msv')}
                                  >
                                    Market Share
                                    <i
                                      className={
                                        rankingOrderMethod === 'msv' &&
                                        orderReversed
                                          ? 'ion-arrow-up-b small'
                                          : ''
                                      }
                                    ></i>
                                    <i
                                      className={
                                        rankingOrderMethod === 'msv' &&
                                        !orderReversed
                                          ? 'ion-arrow-down-b small'
                                          : ''
                                      }
                                    ></i>
                                  </th>
                                )}

                                <th
                                  onClick={() => orderBy('close')}
                                  title='Close Price'
                                >
                                  Close
                                  <i
                                    className={
                                      rankingOrderMethod === 'close' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'close' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>

                                <th
                                  onClick={() => orderBy('yestclose')}
                                  title='Previous Close Price'
                                >
                                  Previous
                                  <i
                                    className={
                                      rankingOrderMethod === 'yestclose' &&
                                      orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      rankingOrderMethod === 'yestclose' &&
                                      !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {group[selectedRankingTab]
                                .sort((a, b) => {
                                  if (isNaN(a[rankingOrderMethod])) {
                                    if (orderReversed) {
                                      return `${a[rankingOrderMethod]}`.localeCompare(
                                        `${b[rankingOrderMethod]}`
                                      );
                                    } else {
                                      return `${b[rankingOrderMethod]}`.localeCompare(
                                        `${a[rankingOrderMethod]}`
                                      );
                                    }
                                  } else {
                                    if (orderReversed) {
                                      return (
                                        a[rankingOrderMethod] -
                                        b[rankingOrderMethod]
                                      );
                                    } else {
                                      return (
                                        b[rankingOrderMethod] -
                                        a[rankingOrderMethod]
                                      );
                                    }
                                  }
                                })
                                .map((item, index) => (
                                  <tr
                                    key={item.ticker}
                                    className='link'
                                    onClick={() => handleShowModal(item.ticker)}
                                  >
                                    <td
                                      title='Rank'
                                      className='text-center rank'
                                    >
                                      <b>{item.rank}</b>
                                    </td>
                                    <td className='Company'>
                                      <b>{item.company}</b>
                                    </td>
                                    <Preview
                                      title='Ticker'
                                      CSSclass='strong'
                                      ticker={item.ticker}
                                      itemIndex={index}
                                      breakPoint='4'
                                    />
                                    <td
                                      title='Change in Rank'
                                      className={
                                        item.rankchg < 0 ? 'danger' : 'success'
                                      }
                                    >
                                      {item.rankchg === 0 ? (
                                        <span className='secondary faded'>
                                          No Change
                                        </span>
                                      ) : (
                                        <span>
                                          <i
                                            className={
                                              item.rankchg > 0
                                                ? 'ion-arrow-up-a'
                                                : 'ion-arrow-down-a'
                                            }
                                          ></i>
                                          {item.rankchg}
                                        </span>
                                      )}
                                    </td>

                                    <td title='Weight'>
                                      {formatNumber(item.weight, true)}%
                                    </td>

                                    <td
                                      title='Change in Weight in Fund'
                                      className={
                                        item.weightchg < 0
                                          ? 'danger'
                                          : 'success'
                                      }
                                    >
                                      {item.weightchg === 0 ? (
                                        <span className='secondary faded'>
                                          No Change
                                        </span>
                                      ) : (
                                        <span>
                                          {formatNumber(item.weightchg, true)}%
                                        </span>
                                      )}
                                    </td>

                                    {selectedRankingTab === 'data' && (
                                      <td title='Market Share'>
                                        {formatCurrency(item.msv, 'USD', false)}
                                      </td>
                                    )}

                                    <td
                                      title='Close Price'
                                      className={
                                        item.close > item.yestclose
                                          ? 'success'
                                          : item.close == item.yestclose
                                          ? 'faded'
                                          : 'danger'
                                      }
                                    >
                                      ${item.close && item.close.toFixed(2)}
                                    </td>
                                    <td title='Previous Close Price'>
                                      $
                                      {item.yestclose &&
                                        item.yestclose.toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <br />
          <footer-directive></footer-directive>
        </div>
      )}
    </div>
  );
}

export default Rankings;
