import Detail from '../pages/Detail';
import Coin from './coin';

function Modal({
  ticker,
  userType,
  currentFundGroup,
  fundArray,
  searchListTickers,
  addToWatchlist,
  handleShowModal,
  handleShowUpgrade,
  tickerTranslations,
  myWatchlist,
  allTickers,
  insiderData,
  date,
  type,
  chartDataFromAgg,
  isLoaded,
  topRankings,
  investorsData,
  optionsData,
  shortSqueezeData,
  institutionalData,
  theme,
  coinData,
  handleShowCryptoModal,
  senateData,
}) {
  return (
    <>
      {coinData ? (
        <div className='modal'>
          <Coin
            userType={userType}
            coinData={coinData}
            symbol={coinData.symbol}
            handleShowCryptoModal={handleShowCryptoModal}
            handleShowUpgrade={handleShowUpgrade}
            theme={theme}
          />
        </div>
      ) : (
        <div className={type ? 'modal-small' : ''}>
          <div className='modal'>
            <Detail
              ticker={ticker}
              userType={userType}
              currentFundGroup={currentFundGroup}
              fundArray={fundArray}
              searchListTickers={searchListTickers}
              addToWatchlist={addToWatchlist}
              handleShowModal={handleShowModal}
              handleShowUpgrade={handleShowUpgrade}
              tickerTranslations={tickerTranslations}
              myWatchlist={myWatchlist}
              allTickers={allTickers}
              insiderData={insiderData}
              date={date}
              type={type}
              chartDataFromAgg={chartDataFromAgg}
              isLoaded={isLoaded}
              topRankings={topRankings}
              investorsData={investorsData}
              optionsData={optionsData}
              theme={theme}
              shortSqueezeData={shortSqueezeData}
              institutionalData={institutionalData}
              senateData={senateData}
            ></Detail>
          </div>
        </div>
      )}
    </>
  );
}
export default Modal;
