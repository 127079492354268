const userIdentifier = localStorage.getItem('userIdentifier');
const user = localStorage.getItem('userEmail');
const referrer = localStorage.getItem('referrer');
let userType = localStorage.getItem('userType');

function Membership({ handleShowUpgrade }) {
  return (
    <div>
      {/* <script
        src='https://www.paypal.com/sdk/js?client-id=AVUfDp5SRjvR8s9N5phhti-vsIyPmzOzeUv42KOKARDyVmmlIEH19LrLiL4zgy__SfpJORyD-f51MvVu&vault=true'
        data-sdk-integration-source='button-factory'
      ></script> */}
      <div className='membership'>
        <p>
          <img
            src='https://lucidtracking.s3.amazonaws.com/assets/logos/LUCID.svg'
            alt='Logo'
            className='logo'
          />
          <sup>beta</sup>
          <iframe
            name='secret-frame-five'
            width='0'
            height='0'
            border='0'
            className='hidden'
          ></iframe>
        </p>
        <div>
          <div className='landing membership'>
            <div className='background-blur'></div>
            <div className='foreground'>
              <a onClick={() => handleShowUpgrade(false)}>
                <i className='ion-ios-close-empty'></i>
              </a>
              <a href=''>
                <img
                  src='https://lucidtracking.s3.amazonaws.com/assets/logos/LUCID.svg'
                  alt='Logo'
                  className='logo'
                />
                <sup>beta</sup>
              </a>
              <div className='panel' style={{ textAlign: 'left' }}>
                <h1 style={{ color: '#007bff' }}>Premium Membership</h1>
                {userType === 'Premium' && (
                  <div className='success'>
                    <i className='ion-ios-checkmark'></i> Your premium
                    membership is active. Thank you for your support, Founding
                    Member.
                  </div>
                )}
                {userType === 'Member' && (
                  <div>
                    <h3>
                      Monthly Subscription $9.99/mo{' '}
                      <del className='faded small'>$19.99/mo</del>
                    </h3>
                    <p className='small'>
                      Premium Membership at a discount is being offered for a
                      limited time and will receive all future top tier features
                      at the $9.99/mo price.
                    </p>
                  </div>
                )}

                <ol>
                  <li>
                    Access to Aggregate, Rankings, All, and Crypto Page (coming
                    soon).{' '}
                  </li>
                  <li>
                    Access to Full ARK, BlackRock, Global X Data and future ETF
                    data.
                  </li>
                  <li>Access to Fund Manager Aggregated data.</li>
                  <li>Access to members chat.</li>
                  <li>Access to top watch lists.</li>
                  <li>
                    To have full control over your subscription, we suggest
                    paying with Paypal and not using a credit/debit card. Cancel
                    anytime via Paypal. Subscription charges every month on the
                    day of the start of your subscription.
                  </li>
                  <li>
                    🙏 Support the Lucid Team as we bring you more features!
                  </li>
                </ol>
                {userType === 'Member' && (
                  <div>
                    <div id='paypal-button-container'></div>

                    <h4
                      className='text-center small'
                      style={{ margin: '20px auto' }}
                    >
                      ----- OR -----
                    </h4>
                    <h3 className='center'>
                      One Year, One Time Payment $59.88 (75% off)
                    </h3>
                    <div id='paypal-button-container-one'></div>
                    <p className='small' style={{ marginBottom: '10px' }}>
                      {' '}
                      By paying for the One Year, One Time Membership, you agree
                      to no refunds even in the event that Lucid Tracking ceases
                      to exist and services are halted. It may take up to 2
                      hours to enable premium features. Please{' '}
                      <a
                        href='mailto:hello@lucidtracking.com?subject=Premium%20Not%20Working&body=Please%20check%20my%20access.'
                        target='_blank'
                      >
                        email
                      </a>{' '}
                      us if it takes longer.
                    </p>
                    <br />
                  </div>
                )}
                {userType === 'Premium' && (
                  <p className='small faded' style={{ marginBottom: '10px' }}>
                    Charges are done via paypal subscriptions for Lucid, LLC.
                  </p>
                )}
              </div>
            </div>
            <form
              className='form'
              action='https://docs.google.com/forms/d/e/1FAIpQLSeJugcGwbg3tpaNOqJL1vtmVWEuTHsBPOo2NhC7b3WuDpkSNQ/formResponse'
              id='ss-form'
              method='POST'
              target='secret-frame-five'
              style={{ display: 'none' }}
            >
              <div className='form-group'>
                <label>Email</label>
                <input
                  readOnly
                  name='entry.1573622460'
                  type='email'
                  value={user}
                />
              </div>
              <div className='form-group'>
                <label>Referrer</label>
                <input
                  readOnly
                  name='entry.1136073171'
                  type='text'
                  value={`${referrer}`}
                />
              </div>
              <div className='form-group'>
                <label>Code</label>
                <input
                  readOnly
                  name='entry.1567684533'
                  type='text'
                  value={userIdentifier}
                />
              </div>
              <div className='form-group'>
                <label>Subscription ID</label>
                <input
                  readOnly
                  id='subscriptionid'
                  name='entry.2083610371'
                  type='text'
                />
              </div>
              <input
                id='submitmembershipform'
                type='submit'
                value='Submit Membership'
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membership;
