export const userSheetId = '1YsvXnK80_evyIrM5ytUKok3-5ghod5NOPh7rSSNGHoA';
export const userSheetAltId = '1g6BKyjNGT7MVWbhK9bK_ErfRp9b9r4q8b0qNuOfb208';
export const premiumSheetId = '1BOLyfBX0cjT1J7KJtJ92zyqdA4MlofyhSQgRjkhf5H4';
export const premiumSheetAltId = '1EOX21Mzs89BbnOZxohlFef1S3FYofGweQPvq50o_GVo';
export const watchlistSheetId = '1CnLMIkibohMR-NzU8RCgaTDim5CJkCHWcLYuN8-z_mM';
export const watchlistSheetIdAlt1 =
  '1CnLMIkibohMR-NzU8RCgaTDim5CJkCHWcLYuN8-z_mM';
export const watchlistSheetIdAlt2 =
  '1CnLMIkibohMR-NzU8RCgaTDim5CJkCHWcLYuN8-z_mM';
export const watchlistTabId = 'stats';

export const chatSheetId = '1uS90k2gFiJJV5n-PGgsW8xOK2Ru0vWL7dh1jq2coiA0';
export const chatSheetIdAlt1 = '13LU1iteUubmo7KRi0ExAaRsNws3XZBChoEQKw7TVF20';
export const chatSheetIdAlt2 = '1f5c36sGLXKk2zBXWOifZWJecoSeDoQnH_aoQg34_6O0';

export const stockApiBaseUrl = 'https://finnhub.io/api/v1/';
export const stockApiKey = 'bstggan48v6ug49hkpog';
export const stockApiKeyAlt1 = 'c0apl5748v6sc0grosi0';
export const stockApiKeyAlt2 = 'c0apmev48v6sc0grotqg';

export const alphaBaseUrl = 'https://www.alphavantage.co/query?';
export const alphaKey = '0M2E9OY4SXPM1UVG';
export const alphaKeyAlt1 = '0M2E9OY4SXPM1UVG';
export const alphaKeyAlt2 = '0M2E9OY4SXPM1UVG';

export const commentsSheetId = '1jjBykbrGyuV39BeJdhp446fiW97-9rlSi7KBJo_KUSU';
export const commentsSheetIdAlt1 =
  '1EtF1RCpD8kLZ9xzJsOzmxYvNAH1s2V-dr0o0y1xi6gs';
export const commentsSheetIdAlt2 =
  '1-1JUi4W-YnIQaQUTND4oYVRSsPrBU6bA9NnwvM_ZcIQ';

export const usernameSheetId = '1hk_GFt2eTCZ2RMBFatMqT7aXj6FxPJPUZb-QKoTSEUc';

export const cryptoTokenSheetId =
  '1B9tDTvuaFqVwxqfXWnS5U2YxhbfIlyhZciIGXwRCnRo';
export const cryptoTokenTabId = 'NotableCoins';
export const cyptoWalletSheetId =
  '1B9tDTvuaFqVwxqfXWnS5U2YxhbfIlyhZciIGXwRCnRo';
export const cryptoWalletTabId = 'ERC20_TOKENS_STATIC';
export const cryptoNewCoinsTabId = 'NewCoins_N';
export const cryptoAllCoinsTabId = 'AllCoins_N';
export const cyptoTopSheetId = '1B9tDTvuaFqVwxqfXWnS5U2YxhbfIlyhZciIGXwRCnRo';
export const cryptoTopTabId = 'Top_Cap_STATIC';
export const cyptoNomicsSheetId =
  '1B9tDTvuaFqVwxqfXWnS5U2YxhbfIlyhZciIGXwRCnRo';
export const cryptoNomicsTabId = 'Nomics_AllTickers_Information';
export const gsSheetId = '1tJURPiJUzu8l51VM65affYzBZ93nAXldlBp0cUxQogg';
export const gsTabDashboardId = 'dashboard';
export const gsTabHistoricalId = 'static_historical';
export const cryptoSheetId = '1B9tDTvuaFqVwxqfXWnS5U2YxhbfIlyhZciIGXwRCnRo';
export const cryptoTabId = 'AllCoins_N';
/* export const memeSheetId = '1bpmk9KlVESRTm3gboUz9NfC6pMqHPP-6XY6XqMM9rvk';
export const memeTabId = 'Meme';
export const mainTabId = 'mqv4';
export const memeShortSheetId = '1EA1RQNWQtqdBNZRTce0TLbOkhbqem2t7__bddHLUohI';
export const memeShortTabId = 'aggregate'; */
