function Widget({ ticker }) {
  return (
    <div>
      <iframe
        width='100%'
        frameBorder='0'
        height='200'
        src={`https://widget.finnhub.io/widgets/stocks/chart?symbol=${ticker}&amp;watermarkColor=%23222222&amp;backgroundColor=%23222222&amp;textColor=white`}
      ></iframe>
    </div>
  );
}
export default Widget;
