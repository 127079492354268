import React, { useState, useEffect } from 'react';
import { formatNumber, formatCurrency, formatDate } from '../helpers/format';
import Preview from '../layouts/preview';

function UpcomingEarnings({
  userType,
  earningsData,
  handleShowUpgrade,
  handleShowModalWithAggChart,
  fundGroup,
  limit,
}) {
  const [eData, setEData] = useState([]);

  earningsData.data.then((result) => {
    setEData(result.earningsData);
  });

  return (
    <div className='h-scroll'>
      {userType === 'Premium' && (
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th
                className='Company hidden-mobile'
                style={{ width: '300px', maxWidth: '300px' }}
              >
                Company
              </th>
              <th>Ticker</th>
              <th>
                Shares Held
                <div>{eData[0]?.date && formatDate(eData[0]?.date)}</div>
              </th>
              <th>-1 Day</th>
              <th>-7 Days</th>
              <th>-14 Days</th>
              <th>-30 Days</th>
              <th>Estimate</th>
            </tr>
          </thead>
          <tbody>
            {eData?.slice(0, limit).map((r, index) => (
              <tr
                className='link'
                key={`${r.ticker}${index}`}
                onClick={() => handleShowModalWithAggChart(r.ticker, fundGroup)}
              >
                <td>{formatDate(r.reportDate)}</td>
                <td
                  className='Company hidden-mobile'
                  style={{ width: '300px', maxWidth: '300px' }}
                >
                  {r.name}
                </td>
                <Preview ticker={r.ticker} itemIndex={index} breakPoint='4' />
                <td
                  className={
                    r.shares === r.shares1d
                      ? 'faded'
                      : r.shares > r.shares1d
                      ? 'success'
                      : 'danger'
                  }
                >
                  {formatNumber(r.shares, 2)}
                </td>
                <td
                  className={
                    r.shares1d === r.shares7d
                      ? 'faded'
                      : r.shares1d > r.shares7d
                      ? 'success'
                      : 'danger'
                  }
                >
                  {formatNumber(r.shares1d, 2)}
                </td>
                <td
                  className={
                    r.shares7d === r.shares14d
                      ? 'faded'
                      : r.shares7d > r.shares14d
                      ? 'success'
                      : 'danger'
                  }
                >
                  {formatNumber(r.shares7d, 2)}
                </td>
                <td
                  className={
                    r.shares14d === r.shares30
                      ? 'faded'
                      : r.shares14d > r.shares30
                      ? 'success'
                      : 'danger'
                  }
                >
                  {formatNumber(r.shares14d, 2)}
                </td>
                <td>{formatNumber(r.shares30d, 2)}</td>
                <td
                  className={
                    r.estimate < 0
                      ? 'danger'
                      : r.estimate > 0
                      ? 'success'
                      : 'faded'
                  }
                >
                  {formatCurrency(r.estimate, 'USD', 2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {userType === 'Member' && (
        <div className='text-center'>
          <br />
          <br />
          <div className='jumbo'>🚀</div>
          <h2 style={{ marginTop: 0 }}>Upgrade to Premium</h2>
          <p style={{ marginBottom: '20px' }}>
            Support Lucid and get access to <br />
            premium content.
          </p>

          <a
            onClick={() => handleShowUpgrade(true)}
            className='btn btn-primary'
          >
            Upgrade to Premium
          </a>
        </div>
      )}
    </div>
  );
}
export default UpcomingEarnings;
