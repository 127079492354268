import React, { useState } from 'react';
import { coinHeaders } from '../helpers/fundvars';
import { formatNumber, formatCurrency } from '../helpers/format';
import TradingViewWidget, { Themes, BarStyles } from 'react-tradingview-widget';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

function Coin({ coinData, handleShowCryptoModal, symbol, theme }) {
  //console.log('coindata', coinData);
  const [orderMethod, setOrderMethod] = useState('Symbol');
  const [orderReversed, setOrderReversed] = useState(true);
  //const [investors, setInvestors] = useState([]);

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const tradingViewTheme = theme ? Themes.LIGHT : Themes.DARK;
  const themeModule = theme ? 'light' : 'dark';
  const themeForTradingViewEmbed = theme ? 'light' : 'dark';

  /* setTimeout(() => {
    if (document.getElementById('tvtimeline')) {
      const script = document.createElement('script');
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        //symbol: `BITSTAMP:${symbol}USD`,
        symbol: 'BINANCE:YFIUSDT',
        //isTransparent: true,
        largeChartUrl: '',
        displayMode: 'adaptive',
        width: '100%',
        height: '100%',
        locale: 'en',
        colorTheme: themeModule,
      });

      document.getElementById('tvtimeline').appendChild(script);
    }
  }, 0); */
  /* <!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/history-timeline/" rel="noopener" target="_blank"><span class="blue-text">AAPL</span></a> History by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-timeline.js" async>
  {
  "symbol": "NASDAQ:AAPL",
  "colorTheme": "light",
  "isTransparent": false,
  "largeChartUrl": "",
  "displayMode": "regular",
  "width": 480,
  "height": 830,
  "locale": "en"
}
  </script>
</div>
<!-- TradingView Widget END --> */
  /* setTimeout(() => {
    if (document.getElementById('companychart')) {
      const script = document.createElement('script');
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbol: `BITSTAMP:${symbol}USD`,
        //isTransparent: true,
        largeChartUrl: '',
        displayMode: 'adaptive',
        width: '100%',
        height: '100%',
        locale: 'en',
        colorTheme: themeModule,
      });

      document.getElementById('companychart').appendChild(script);
    }
  }, 500); */

  return (
    <div className='detail coin'>
      <div className='header-content d-flex modal-header'>
        <h1>
          <a
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
            onClick={() =>
              handleShowCryptoModal({
                symbol: '',
                coinData: {},
              })
            }
          >
            <i className='ion-chevron-left'></i> {symbol}
            <span className='faded'>{coinData.name}</span>
          </a>
        </h1>
        <div className='right'>
          <a
            href={`https://coinmarketcap.com/currencies/${coinData.symbol}`}
            target='_blank'
          >
            <button className='yahoo'>
              CoinMarketCap <i className='ion-android-open'></i>
            </button>
          </a>
        </div>
      </div>

      <div className='charts d-flex'>
        {/* <div className='chart-half' id='companychart'></div> */}
        <div className='chart-left'>
          <div className='chart-header'>
            <h2>
              {coinData.img && (
                <img
                  src={coinData.img}
                  onError={(e) => {
                    e.currentTarget.src = '/logos/Lucid-Round@2x.png';
                    e.currentTarget.className += ' faded';
                  }}
                  alt={coinData.symbol}
                  className='coin-img'
                />
              )}

              <b>{coinData.name}</b>
            </h2>
            <div className='pills'>
              {coinData?.tg?.length > 0 && (
                <a className='pill' href={`${coinData.tg}`} target='_blank'>
                  telegram <i className='ion-android-open small'></i>
                </a>
              )}

              {coinData?.web?.length > 0 && (
                <a className='pill' href={`${coinData.web}`} target='_blank'>
                  website <i className='ion-android-open small'></i>
                </a>
              )}

              {coinData?.reddit?.length > 0 && (
                <a className='pill' href={`${coinData.reddit}`} target='_blank'>
                  reddit <i className='ion-android-open small'></i>
                </a>
              )}

              {coinData?.twtr?.lenght > 0 && (
                <a className='pill' href={`${coinData.twtr}`} target='_blank'>
                  twitter <i className='ion-android-open small'></i>
                </a>
              )}

              {coinData?.twtr?.length > 0 && (
                <a
                  className='pill'
                  href={`https://www.coingecko.com/en/coins/${coinData.symbol}`}
                  target='_blank'
                >
                  coingecko <i className='ion-android-open small'></i>
                </a>
              )}
            </div>
            <div className='pills'>
              {coinData.ttags?.length > 0 &&
                coinData.ttags
                  .split(',')
                  .map((t) => <a className='tag'>{t}</a>)}
            </div>
          </div>
          <div className='chart-body'>
            {coinData.maxsupply ? (
              <>
                <h4 className='faded'>Summary</h4>
                <table>
                  <tbody>
                    <tr>
                      <td>Max Supply</td>
                      <td>
                        {coinData.maxsupply &&
                          formatNumber(coinData.maxsupply, 2)}
                      </td>
                    </tr>
                    <tr>
                      <td>1 Day Price Diff</td>
                      <td
                        className={
                          coinData.tpricediff > 0 ? 'success' : 'danger'
                        }
                      >
                        {coinData.tpricediff &&
                          formatCurrency(coinData.tpricediff, 'USD', 2)}{' '}
                        <small>
                          (
                          <i
                            className={
                              coinData.tpricechg > 0
                                ? 'ion-arrow-up-a'
                                : 'ion-arrow-down-a'
                            }
                          />
                          {coinData.tpricechg &&
                            formatNumber(+coinData.tpricechg, 2)}
                          %)
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>7 Day Price Diff</td>
                      <td
                        className={
                          coinData.tpricediff7 > 0 ? 'success' : 'danger'
                        }
                      >
                        {coinData.tpricediff7 &&
                          formatCurrency(coinData.tpricediff7, 'USD', 2)}{' '}
                        <small>
                          (
                          <i
                            className={
                              coinData.tpricechg7 > 0
                                ? 'ion-arrow-up-a'
                                : 'ion-arrow-down-a'
                            }
                          />
                          {coinData.tpricechg7 &&
                            formatNumber(coinData.tpricechg7, 2)}
                          %)
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Market Cap</td>
                      <td>
                        {coinData.tmc && formatCurrency(coinData.tmc, 'USD', 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>Circulating Supply</td>
                      <td>
                        {coinData.tcircsupply &&
                          formatNumber(coinData.tcircsupply, 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>24h Volume</td>
                      <td>
                        {coinData.tvol24 && formatNumber(coinData.tvol24, 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>1 Day Volume Diff</td>
                      <td
                        className={coinData.tvoldiff > 0 ? 'success' : 'danger'}
                      >
                        {coinData.tvoldiff &&
                          formatNumber(coinData.tvoldiff, 0)}{' '}
                        <small>
                          (
                          <i
                            className={
                              coinData.tvolchg > 0
                                ? 'ion-arrow-up-a'
                                : 'ion-arrow-down-a'
                            }
                          />
                          {coinData.tvolchg &&
                            formatNumber(+coinData.tvolchg, 2)}
                          %)
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>7 Day Volume Diff</td>
                      <td
                        className={
                          coinData.tvoldiff7 > 0 ? 'success' : 'danger'
                        }
                      >
                        {coinData.tvoldiff7 &&
                          formatNumber(coinData.tvoldiff7, 0)}{' '}
                        <small>
                          (
                          <i
                            className={
                              coinData.tvolchg7 > 0
                                ? 'ion-arrow-up-a'
                                : 'ion-arrow-down-a'
                            }
                          />
                          {coinData.tvolchg7 &&
                            formatNumber(coinData.tvolchg7, 2)}
                          %)
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>30 Day Volume Diff</td>
                      <td
                        className={
                          coinData.tvoldiff30 > 0 ? 'success' : 'danger'
                        }
                      >
                        {formatNumber(coinData.tvoldiff30, 0)}{' '}
                        <small>
                          (
                          <i
                            className={
                              coinData.tvolchg30 > 0
                                ? 'ion-arrow-up-a'
                                : 'ion-arrow-down-a'
                            }
                          />
                          {coinData.tvolchg30 &&
                            formatNumber(coinData.tvolchg30, 2)}
                          %)
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              ''
            )}
            <div className='twitter-embed'>
              <TwitterTimelineEmbed
                sourceType='profile'
                screenName={coinData.twtr}
                options={{
                  height: 400,
                }}
                theme={themeModule}
                noHeader='true'
                noFooter='true'
                transparent='true'
              />
            </div>
          </div>
        </div>
        <div className='chart-right'>
          <div className='tv-widget'>
            {coinData.ticky ? (
              <TradingViewWidget
                symbol={coinData.ticky}
                theme={tradingViewTheme}
                locale='us'
                autosize
                interval='D'
                timezone='Etc/UTC'
                style='1'
                toolbar_bg='#f1f3f6'
                enable_publishing='false'
                hide_side_toolbar='false'
                details={true}
                studies={[
                  'MASimple@tv-basicstudies',
                  'RSI@tv-basicstudies',
                  'Volume@tv-basicstudies',
                ]}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '10%',
                }}
              >
                <h3>No Chart Available.</h3>

                <a
                  href={`https://coinmarketcap.com/currencies/${coinData.symbol}`}
                  target='_blank'
                  style={{ margin: '0 5px' }}
                >
                  CoinMarketCap <i className='ion-android-open'></i>
                </a>
              </div>
            )}
          </div>

          {/* <div className='tv-widget'>
            <div className='' id='tvtimeline'></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default Coin;
