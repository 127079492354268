function Loader({ title }) {
  return (
    <div className='loading-indicator'>
      <h1 className='text-center'>{title}</h1>
      <div className='loader'></div>
      Loading
    </div>
  );
}
export default Loader;
