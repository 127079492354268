import React, { useState } from 'react';
import { optionsHeaders } from '../helpers/fundvars';
import Preview from '../layouts/preview';
import { formatNumber, formatDate, formatCurrency } from '../helpers/format';

let options = [];
let headers = [];

function UnusualOptions({
  optionsData,
  handleShowModal,
  ticker,
  handleShowSimpleModal,
  type,
}) {
  const [orderMethod, setOrderMethod] = useState('Symbol');
  const [orderReversed, setOrderReversed] = useState(true);

  if (ticker) {
    options = optionsData?.filter((f) => f.Symbol === ticker);
    headers = optionsHeaders.filter(
      (v) => v.id !== 'Symbol' && v.id !== 'Company'
    );
  } else if (type === 'meme') {
    options = optionsData;
    headers = optionsHeaders;
  } else {
    options = optionsData.slice(0, 10);
    headers = optionsHeaders;
  }

  const handleShowModalhandle = (ticker) => {
    if (type === 'meme') {
      handleShowSimpleModal(ticker);
    } else {
      handleShowModal(ticker);
    }
  };

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  return (
    <>
      {options?.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                {headers.map((d) => (
                  <th
                    key={d.id}
                    className={`${d.id} link`}
                    title={d.title}
                    onClick={() => handleSetOrderMethod(d.id)}
                  >
                    {d.name}
                    {`${orderMethod}` === d.id && (
                      <i
                        className={
                          orderReversed
                            ? 'ion-arrow-down-b small'
                            : 'ion-arrow-up-b small'
                        }
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {options
                ?.sort((a, b) => {
                  if (isNaN(a[orderMethod])) {
                    if (orderReversed) {
                      return `${a[orderMethod]}`.localeCompare(
                        `${b[orderMethod]}`
                      );
                    } else {
                      return `${b[orderMethod]}`.localeCompare(
                        `${a[orderMethod]}`
                      );
                    }
                  } else {
                    if (orderReversed) {
                      return a[orderMethod] - b[orderMethod];
                    } else {
                      return b[orderMethod] - a[orderMethod];
                    }
                  }
                })
                .map((r, index) => (
                  <tr
                    className='link'
                    key={`${r.Symbol}${index}`}
                    onClick={() => handleShowModalhandle(r.Symbol)}
                  >
                    {!ticker && (
                      <Preview
                        ticker={r.Symbol}
                        itemIndex={index}
                        breakPoint='4'
                      />
                    )}

                    <td>{r.Type}</td>
                    <td>{formatCurrency(r.Strike, 'USD', 2)}</td>
                    <td>{formatDate(r['Exp Date'])}</td>
                    <td>{r.DTE}</td>
                    <td>{formatNumber(r.Volume, 0)}</td>
                    <td>{formatNumber(r['Open Int'], 0)}</td>
                    <td>{formatNumber(r['Vol/OI'], 2)}</td>
                    <td>{formatNumber(r.IV * 100, 2)}%</td>
                    <td>{formatCurrency(r.Bid, 'USD', 2)}</td>
                    <td>{formatCurrency(r.Midpoint, 'USD', 2)}</td>
                    <td>{formatCurrency(r.Ask, 'USD', 2)}</td>
                    <td>{formatCurrency(r.Last, 'USD', 2)}</td>
                    <td>{r.Time}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </>
      ) : (
        'No data found.'
      )}
    </>
  );
}
export default UnusualOptions;
