import React, { useState, useEffect } from 'react';
import '../styles/navigation.scss';
import {
  fundGroup,
  getSelectedFund,
  dataLengths,
  setSelectedFundGroup,
} from '../helpers/fundvars';
import {
  getUser,
  saveThemeToLS,
  getThemeFromLS,
  removeThemeFromLS,
} from '../helpers/helpers';
import { setDataLength } from '../helpers/api';
import { useHistory } from 'react-router-dom';
import fire from '../helpers/fire.js';

function Navigation({
  userType,
  currentFundGroup,
  allTickers,
  myWatchlist,
  handleShowModal,
  handleShowUpgrade,
  handleChangeCurrentFundGroup,
  dataLength,
}) {
  const history = useHistory();
  const [toggledMenu, setToggledMenu] = useState(false);
  const [selectedFundGroup, setSelectedFundGroupToState] = useState(
    fundGroup[0]
  );
  const [user, setUser] = useState(getUser());
  const [searchText, setSearchText] = useState('');
  const [searchListTickers, setSearchListTickers] = useState([]);
  const [watchList, setWatchList] = useState(myWatchlist);
  let currentTheme = getThemeFromLS();

  /* let isCryptoPage =
    history.location.pathname.includes('/crypto') ||
    history.location.pathname.includes('/wallets') ||
    history.location.pathname.includes('/grayscale') ||
    currentFundGroup === 'Crypto'; */
  let isAllPage =
    history.location.pathname.includes('/all') || currentFundGroup === 'All';

  /* let isMemePage =
    history.location.pathname.includes('/meme') ||
    history.location.pathname.includes('/meme-si') ||
    history.location.pathname.includes('/meme-indicators') ||
    currentFundGroup === 'Meme'; */

  const handleCurrentPathOfAll = () => {
    if (isAllPage) {
      setSelectedFundGroupToState({
        name: 'All',
        link: 'all',
        color: '#4e4eff',
      });
    }
    /*  if (isCryptoPage) {
      setSelectedFundGroupToState({
        name: 'Crypto',
        link: 'crypto',
        color: '#e42c80',
      });
    }
    if (isMemePage) {
      setSelectedFundGroupToState({
        name: 'Meme',
        link: 'meme',
        color: 'rgb(229, 25, 55)',
      });
    } */
  };

  const getSelectedFundFromLS = async () => {
    const selectedFundFromLS = await getSelectedFund();
    //console.log('selectedFundFromLS', selectedFundFromLS);
    if (selectedFundFromLS) {
      setSelectedFundGroupToState(selectedFundFromLS);
    } else {
      setSelectedFundGroupToState(
        fundGroup.find((f) => f.name === currentFundGroup)
      );
    }
  };

  const switchTheme = () => {
    if (currentTheme) {
      removeThemeFromLS();
    } else {
      saveThemeToLS('Light');
    }

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    setWatchList(myWatchlist);
  }, [myWatchlist]);

  useEffect(() => {
    if (currentFundGroup.length > 0) {
      getSelectedFundFromLS();
    }
    setTimeout(() => {
      handleCurrentPathOfAll();
    }, 10);
  }, [currentFundGroup]);

  useEffect(() => {
    if (allTickers?.length > 0) {
      setSearchListTickers(allTickers);
    }
  }, [allTickers]);

  const goTo = (route) => {
    setToggledMenu(false);
    setSearchText('');
    //console.log('route', route);
    history.push(`/${route}`);
    handleCurrentPathOfAll();
  };

  const resetApp = () => {
    localStorage.clear();
    window.location.href = '/';
    window.location.reload();
  };

  const resetUsername = () => {
    localStorage.removeItem('username');
    window.location.reload();
  };

  const resetWL = () => {
    localStorage.removeItem('watchlist');
    setWatchList([]);
    window.location.reload();
  };

  const signOut = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userIdentifier');
    localStorage.removeItem('userType');
    localStorage.removeItem('username');
    window.location.href = '/';
    window.location.reload();
    fire.auth().signOut();
  };

  const setFundGroup = (fund) => {
    if (history.location.pathname.includes('/all')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/crypto')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/grayscale')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/wallets')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/all-coins')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/new-coins')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/top-picks')) {
      history.push('/');
    }
    if (history.location.pathname.includes('/meme')) {
      history.push('/');
    }
    setSelectedFundGroupToState(fund);
    handleChangeCurrentFundGroup(fund.name);
    //localStorage.setItem('fundGroup', fund.name);
    setSelectedFundGroup(fund.name);
    //console.log('fund', fund);
  };

  const handleDropdownClick = (ticker) => {
    handleShowModal(ticker);
    setSearchText('');
    setToggledMenu(false);
  };

  const handleGoTo = (route) => {
    goTo(route);
    window.location.reload();
  };

  return (
    <div className='navigation'>
      <div
        className='top-nav'
        style={{ backgroundColor: selectedFundGroup?.color }}
      >
        <div className='fund-container'>
          {fundGroup.map((f, index) => (
            <a
              key={`${f?.name}${index}`}
              className={f?.name === selectedFundGroup?.name ? 'active' : ''}
              onClick={() => setFundGroup(f)}
            >
              {f.name}
            </a>
          ))}
          <a
            className={'All' === selectedFundGroup?.name ? 'active' : ''}
            onClick={() => handleGoTo('all-dashboard')}
          >
            All
          </a>
          {/* <a
            className={'Crypto' === selectedFundGroup?.name ? 'active' : ''}
            onClick={() => handleGoTo('crypto')}
          >
            Crypto
          </a>
          <a
            className={'Meme' === selectedFundGroup?.name ? 'active' : ''}
            onClick={() => handleGoTo('meme')}
          >
            Meme
          </a> */}
        </div>
        {/* {!isCryptoPage && !isAllPage && !isMemePage && (
          <a className='setter-container'>
            {dataLengths.map((d) => (
              <span
                key={d}
                className={`setter ${dataLength === d ? 'active' : ''}`}
                onClick={() => setDataLength(d)}
              >
                {d}
              </span>
            ))}
          </a>
        )} */}
        {!isAllPage && (
          <a className='setter-container'>
            {dataLengths.map((d) => (
              <span
                key={d}
                className={`setter ${dataLength === d ? 'active' : ''}`}
                onClick={() => setDataLength(d)}
              >
                {d}
              </span>
            ))}
          </a>
        )}
      </div>
      <nav>
        <div className='logo-container'>
          <img
            className='logo'
            src='https://lucidtracking.s3.amazonaws.com/assets/logos/LUCID.svg'
            alt='Logo'
          />
          <sup style={{ color: selectedFundGroup?.color }}>beta</sup>
        </div>
        <div className='nav-items'>
          <div
            className={
              'All' === selectedFundGroup /* ||
              ('Crypto' === selectedFundGroup?.name ? 'hidden' : '') ||
              ('Meme' === selectedFundGroup?.name ? 'hidden' : '') */
            }
          >
            {allTickers?.length > 1 && (
              <div styles={{ position: 'relative' }}>
                <div className='search-container'>
                  <input
                    type='text'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={`Search ${currentFundGroup}...`}
                  />
                  <i
                    className={
                      searchText.length > 1 ? 'ion-ios-close' : 'hidden'
                    }
                    onClick={() => setSearchText('')}
                  ></i>
                </div>

                {searchText.length > 0 && (
                  <div className='nav dropdown search-drawer'>
                    {searchListTickers
                      ?.filter((s) =>
                        Object.values(s)
                          .join(' ')
                          .toLowerCase()
                          .match(searchText.toLowerCase())
                      )
                      .map((t) => (
                        <div
                          key={t.ticker}
                          className='dropdown-item'
                          onClick={() => handleDropdownClick(t.ticker)}
                        >
                          {t.ticker}{' '}
                          <small className='faded'>{t.company}</small>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            style={{ marginTop: '-2px' }}
            className={'All' === selectedFundGroup?.name ? 'hidden' : ''}
          >
            {/* {'Crypto' === selectedFundGroup?.name && (
              <>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('crypto')}
                >
                  All Coins
                </a>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('new-coins')}
                >
                  New Coins
                </a>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('top-picks')}
                >
                  Top Picks
                </a>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('wallets')}
                >
                  Token Tracker
                </a>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('grayscale')}
                >
                  Grayscale
                </a>
              </>
            )}
            {'Meme' == selectedFundGroup?.name && (
              <>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('meme')}
                >
                  Summary
                </a>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('meme-si')}
                >
                  SI+FTDs
                </a>
                <a
                  className='small item-dashboard'
                  onClick={() => goTo('meme-indicators')}
                >
                  Indicators
                </a>
              </>
            )} */}

            <>
              <a className='small item-dashboard' onClick={() => goTo('')}>
                Dashboard
              </a>
              <a
                className='small item-watchlist'
                onClick={() => goTo('aggregate')}
              >
                Aggregate
              </a>
              <a
                className='small item-watchlist'
                onClick={() => goTo('rankings')}
              >
                Rankings
              </a>
              <a
                className='small item-watchlist'
                onClick={() => goTo('calendars')}
              >
                Calendars
              </a>
              <a
                className='small item-watchlist'
                onClick={() => goTo('watchlist')}
              >
                Watchlist
                <span
                  className={watchList.length > 0 ? 'badge small' : 'hidden'}
                >
                  {watchList.length}
                </span>
              </a>
              <a
                className='small item-watchlist'
                title='Chat'
                onClick={() => goTo('chat')}
              >
                <i className='ion-chatbubble'></i>
              </a>
            </>

            {/* {'Crypto' !== selectedFundGroup?.name &&
              'Meme' !== selectedFundGroup?.name && (
                <>
                  <a className='small item-dashboard' onClick={() => goTo('')}>
                    Dashboard
                  </a>
                  <a
                    className='small item-watchlist'
                    onClick={() => goTo('aggregate')}
                  >
                    Aggregate
                  </a>
                  <a
                    className='small item-watchlist'
                    onClick={() => goTo('rankings')}
                  >
                    Rankings
                  </a>
                  <a
                    className='small item-watchlist'
                    onClick={() => goTo('calendars')}
                  >
                    Calendars
                  </a>
                  <a
                    className='small item-watchlist'
                    onClick={() => goTo('watchlist')}
                  >
                    Watchlist
                    <span
                      className={
                        watchList.length > 0 ? 'badge small' : 'hidden'
                      }
                    >
                      {watchList.length}
                    </span>
                  </a>
                  <a
                    className='small item-watchlist'
                    title='Chat'
                    onClick={() => goTo('chat')}
                  >
                    <i className='ion-chatbubble'></i>
                  </a>
                </>
              )} */}
          </div>

          <a
            className='small item-account'
            onClick={() => setToggledMenu(!toggledMenu)}
          >
            <i className='ion-person' />
            <i className='ion-arrow-down-b' />
          </a>
        </div>
      </nav>
      {toggledMenu && (
        <div className='nav dropdown'>
          {/* {'All' === selectedFundGroup?.name && (
            <>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('all-shared')}
              >
                Dashboard
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('all-shared')}
              >
                Aggregates
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('all-overlaps')}
              >
                Overlaps
              </div>
              <div className='dropdown-item divider'></div>
            </>
          )} */}
          {/* {'Meme' == selectedFundGroup?.name && (
            <>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('meme')}
              >
                Summary
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('meme-si')}
              >
                SI+FTDs
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('meme-indicators')}
              >
                Indicators
              </div>
            </>
          )}
          {'Crypto' === selectedFundGroup?.name && (
            <>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('crypto')}
              >
                All Coins
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('new-coins')}
              >
                New Coins
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('top-picks')}
              >
                Top Picks
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('wallets')}
              >
                Token Tracker
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('grayscale')}
              >
                Grayscale
              </div>
            </>
          )} */}
          {'All' !== selectedFundGroup?.name && (
            /* 'All' !== selectedFundGroup?.name &&
            'Crypto' !== selectedFundGroup?.name &&
            'Meme' !== selectedFundGroup?.name && */ <>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('')}
              >
                Dashboard
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('aggregate')}
              >
                Aggregate{' '}
                <span className={userType !== 'Premium' ? 'badge' : 'hidden'}>
                  Premium
                </span>
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('rankings')}
              >
                Rankings{' '}
                <span className={userType !== 'Premium' ? 'badge' : 'hidden'}>
                  Premium
                </span>
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('watchlist')}
              >
                Watchlist
              </div>
              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('calendars')}
              >
                Calendars{' '}
                <span className={userType !== 'Premium' ? 'badge' : 'hidden'}>
                  Premium
                </span>
              </div>
              {!isAllPage /* && !isCryptoPage && !isMemePage */ && (
                <div>
                  <div
                    className='dropdown-item'
                    onClick={() => goTo('investors')}
                  >
                    Also Held By
                  </div>
                  <div
                    className='dropdown-item'
                    onClick={() => goTo('insider')}
                  >
                    Insider Activity
                  </div>
                  <div
                    className='dropdown-item'
                    onClick={() => goTo('options')}
                  >
                    Options Activity
                  </div>
                  <div className='dropdown-item' onClick={() => goTo('stock')}>
                    Stock Activity
                  </div>
                </div>
              )}

              <div
                className='dropdown-item hidden-desktop'
                onClick={() => goTo('chat')}
              >
                Chat{' '}
                <span className={userType !== 'Premium' ? 'badge' : 'hidden'}>
                  Premium
                </span>
              </div>
              <div className='dropdown-item hidden-desktop divider'></div>
            </>
          )}
          <div
            className='dropdown-item'
            onClick={() => handleShowUpgrade(true)}
          >
            Premium Membership
          </div>
          <div className='dropdown-item' onClick={() => goTo('faqs')}>
            FAQs
          </div>
          <div className='dropdown-item' onClick={() => goTo('terms')}>
            Terms and Policies
          </div>
          <div className='dropdown-item divider'></div>
          <div className='dropdown-item' onClick={() => switchTheme()}>
            {`Switch to ${currentTheme !== 'Light' ? 'Light' : 'Dark'} Mode`}
          </div>
          <div className='dropdown-item' onClick={() => signOut()}>
            Logout <small className='faded'>{user}</small>
          </div>
          <div className='dropdown-item divider'></div>
          <div className='dropdown-item danger' onClick={() => resetWL()}>
            Clear Watchlist
          </div>
          <div className='dropdown-item danger' onClick={() => resetUsername()}>
            Reset Username
          </div>
          <div className='dropdown-item danger' onClick={() => resetApp()}>
            Reset App
          </div>
        </div>
      )}
    </div>
  );
}

export default Navigation;
