import '../styles/page.scss';

function Terms() {
  return (
    <div className='page terms'>
      <div>
        <div>
          <h2>Terms of Use</h2>
          <p>
            The Standard Terms and Conditions ("Terms and Conditions") governing
            the use of the information and services owned and provided by
            Lucidtracking.com shall be as follows:
          </p>

          <h3>Use of Information.</h3>
          <p>
            Lucid, LLC hereby provides to the User a non-exclusive,
            nonassignable, non-transferable license to receive and use the
            Information provided through the subscription service only for
            internal business use or personal, noncommercial use. In no event
            shall the User publish, sell, lease, disseminate, retransmit,
            redistribute, broadcast, circulate or otherwise reproduce, provide
            or permit access to any Lucid, LLC Information in any format to
            anyone without permission; provided, however, that User may furnish
            Information to customers, on a non-continuous basis, using only the
            following limited methods: (a) In written advertisements,
            correspondence, or other literature, or (b) during voice telephonic
            conversations not entailing computerized voice, automated
            information inquiry systems, or similar technologies.
          </p>
          <p>
            Use of automated scripting tools to crawl the site or attempting to
            bypass the API is strictly prohibited and may result in termination
            of your subscription. Excessive requests may result in throttling of
            your accounts access to data
          </p>
          <p>
            The use of non-API-based forms of automation, such as scripting the
            website are prohibited. The use of these techniques may result in
            the permanent suspension of your account.
          </p>
          <h3>Cancellations and Refunds:</h3>
          <p>
            Premium subscriptions will be billed immediately upon completion of
            the payment form. Payments are non-refundable. We reserve the right,
            however, to issue refunds or credits at our sole discretion.
          </p>
          <p>
            Your subscription will initially be set to auto-renew. This can be
            changed by going into your Paypal (www.paypal.com) subscriptions
            page or requested with this{' '}
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSeU_to3cQq_14-TlSZLfm9VyvpqjIVQ5PZS_1TXQ5RH5cdC9A/viewform'>
              form
            </a>
          </p>

          <h3>Accuracy of Information.</h3>
          <p>
            Lucid, LLC does not attempt to verify and does not warrant the
            accuracy, completeness or suitability of any data or information
            provided. Lucid, LLC shall not be liable for any loss, direct or
            consequential damages or expenses relating to the accuracy and
            completeness of the data provided. This data has been compiled for
            informational purposes only and not as investment advice. Users
            should not rely upon this data or information when making an
            investment decision or selection of an investment manager, and you
            should independently verify all relevant information prior to making
            an investment decision. Users should note that past performance is
            not an indication of future results. The Services are provided
            without warranty of any kind, express or implied, including but not
            limited to warranties of performance, merchantability, fitness for a
            particular purpose, accuracy, omissions, completeness, correctness
            and delays. In particular the Subscriber understands:
          </p>
          <p>
            That it is not possible for all data contained in the Services to be
            comprehensive, accurate and up-to-date, and that at any time the
            Services are likely to contain inaccuracies or omissions.
          </p>
          <p>
            That the Services may contain forward-looking statements about
            companies, including assessments about theft business prospects and
            future results of operations. These statements involve risks and
            uncertainties. Among the important additional factors that could
            cause actual results to differ materially from those forward-looking
            statements are risks associated with the overall economic
            environment, realization of customer contracts, strength of the
            company’s patents, validation of the technology, changes in
            anticipated earnings of the company and other factors detailed in
            the company’s filings with various stock markets or contained in the
            company’s proprietary information. In addition, the factors
            underlying all forecasts are dynamic and subject to change and
            therefore any forecast published or reproduced by Lucidtracking.com
            is relevant only to the date at which it is made.
          </p>
          <p>
            That Lucidtracking.com does not undertake to update its coverage
            even though from time to time it may do so.
          </p>
          <p>
            Lucidtracking.com provides the Subscriber with opportunities to
            point out any inaccuracies that may be included in the Services.
            This should not be taken to mean that Lucidtracking.com is committed
            to correcting any inaccuracies brought to its attention within any
            specified time or at all.
          </p>
          <p>
            Lucidtracking.com does not represent or warrant that access to or
            use of the Services will be uninterrupted or error-free or that the
            Services supplied are accurate or correct or that they meet any
            particular criteria of performance or quality.
          </p>
          <h3>Limited Warranties Disclaimer.</h3>
          <p>
            User acknowledges and agrees that no warranty is given that the
            information is error-free or accurate. The information and any other
            product that Lucid, LLC markets directly or indirectly provides are
            provides “as is”. Except as specifically set forth herein, Lucid,
            LLC does not make any express or implied warranties or conditions,
            including quality, merchantability, fitness for a particular
            purpose, title and non-infringement. User expressly assumes all
            risks from using the information, and/or related products that
            Lucid, LLC provides.
          </p>
          <p>
            IN NO EVENT SHALL Lucid, LLC BE LIABLE FOR ANY INVESTMENT GAIN/LOSS
            YOU MAY INCUR. INVESTING IN STOCKS IS RISKY AND THE PAST PERFORMANCE
            OF ANY INDEX OR PRODUCT DOES NOT GUARANTEE THEIR FUTURE PERFORMANCE.
            IF YOU INVEST BASED ON THE INFORMATION PROVIDED AT THIS WEBSITE, IT
            MEANS THAT YOU ACCEPT THE TERMS AND CONDITIONS OF USING THIS
            WEBSITE. YOU ARE RESPONSIBLE FOR ANY OF YOUR OWN ACTIONS.
            FURTHERMORE, IN NO EVENT SHALL Lucid, LLC BE LIABLE FOR (A) ANY
            DAMAGES TO OR VIRUSES THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR
            OTHER PROPERTY AS THE RESULT OF YOUR ACCESS TO, USE OF OR BROWSING
            IN OUR SITE OR YOUR DOWNLOADING OF ANY CONTENT, INFORMATION,
            MATERIALS, DATA, TEXT OR IMAGES FROM OUR SITE OR (B) ANY INJURY,
            LOSS, CLAIM, DAMAGE, OR ANY SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT,
            INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING WITHOUT
            LIMITATION LOST PROFITS OR LOST SAVINGS), WHETHER BASED IN CONTRACT,
            TORT, STRICT LIABILITY OR OTHERWISE, THAT ARISES OUT OF OR IS IN ANY
            WAY CONNECTED WITH (I) ANY USE OF OUR SITE OR CONTENT, DATA,
            MATERIALS OR INFORMATION FOUND THEREIN, (II) ANY FAILURE OR DELAY IN
            USE OF OR INABILITY TO USE ANY COMPONENT OF THIS SITE, OR (III) THE
            PERFORMANCE OR NON-PERFORMANCE BY US OR ANY THIRD-PARTY PROVIDER TO
            OUR SITE, EVEN IF WE HAVE BEEN, OR A THIRD-PARTY PROVIDER HAS BEEN,
            ADVISED OF THE POSSIBILITY OF DAMAGES TO SUCH PARTIES OR ANY OTHER
            PARTY.
          </p>
          <p>
            This disclaimer of liability applies to any damages or injury caused
            by any failure of performance, error, omission, interruption,
            deletion, defect, delay in operation or transmission, computer
            virus, communication line failure, theft or destruction or
            unauthorized access to, alteration of, or use of record, whether for
            breach of contract, tortuous behavior, negligence, or under any
            other cause of action. An investment in stocks could lose money over
            short or even long periods. You should expect stock prices and
            returns may have large range fluctuations. NO GUARANTEE CAN BE MADE
            IF YOU INVEST BASED ON THE INFORMATION PROVIDED ON THIS WEBSITE.
          </p>
          <h3>Indemnification</h3>
          <p>
            User (and User's fund, if User is a representative of a fund)
            jointly and severally agree to indemnify and hold harmless Lucid,
            LLC, and its agents, information providers and their agents,
            representatives, directors, officers, shareholders, partners,
            predecessors, successors and assigns, from and against any claim or
            demand, proceeding, damages, liabilities, losses or expenses, from
            claims made by a third party, arising from User's use of the Lucid,
            LLC information which constitutes a violation of the Terms of Use or
            an improper act or omission.
          </p>
          <p>
            If Subscriber makes use of the Services or any Content other than as
            expressly permitted under these Terms and Conditions, he or she may
            violate copyright and other laws and may be subject to penalties.
            Without limiting the preceding sentences, Subscriber will not:
          </p>
          <p>
            Engage in or permit any reselling, display, reproduction, copying,
            translation, modification, adaptation, creation of derivative works
            from, distribution, transmission, transfer, republication,
            compilation, decompilation or reverse engineering of the Services,
            any Content, any portion thereof, or any data, other content or
            information provided to Subscriber in connection with the use of the
            Services by Lucidtracking.com or any of its third-party sources, in
            any form, media or technology now existing or hereafter developed or
            over any network, including a local area network;
          </p>
          <p>
            Remove, obscure or alter any notice, disclaimer or other disclosure
            affixed to or contained within the Services, including any copyright
            notice, trademark or other proprietary rights notice or any other
            legal notice affixed to, or displayed in association with, the
            Content;
          </p>
          <p>
            Create a frame or use framing techniques to enclose any portion of
            the Services or the Content, without express prior written consent
            from Lucidtracking.com;
          </p>
          <p>
            Impersonate any person, or falsely state or otherwise misrepresent
            his or her affiliation with any person, in connection with any use
            of the Services;
          </p>
          <p>
            Breach or attempt to breach the security of the Services or any
            network, servers, data, or computers or other hardware relating to
            or used in connection with the Services or any of the Content,
            whether or not operated by Lucidtracking.com or any third party; - 2
            - nor use with, or distribute through, the Services any software or
            other tools or devices designed to interfere with or compromise the
            privacy, security or use of the Services by others or the operations
            or assets of any person;
          </p>
          <p>
            Violate any applicable law, including, but not limited to securities
            laws and laws governing investment activity in any country;
          </p>
          <p>
            Restrict or inhibit any other authorized user from using and
            enjoying the Services;
          </p>
          <p>
            Post, publish, transmit, reproduce, distribute or in any way exploit
            any of the Content or other information or other materials obtained
            through the Services for commercial purposes other than as expressly
            permitted by these Terms and Conditions or by express prior
            permission of Lucidtracking.com.
          </p>
          <h3>Service changes</h3>
          <p>
            Lucidtracking.com may change, suspend or discontinue any aspect of
            the Services at any time, including the availability of any Service
            feature, database, or content. Lucidtracking.com may also impose
            limits on certain features and services or restrict Subscriber’s
            access to all or any parts of the Services without notice.
          </p>
          <p>
            Lucidtracking.com reserves the right at its sole discretion and
            conditional only upon repayment of any unused prepaid subscription
            fees as calculated on a pro-rata basis to terminate Subscriber’s
            access to the Services without notice.
          </p>
          <h3>Notifications</h3>
          <p>
            In subscribing to Lucidtracking.com, Subscriber agrees to receive
            electronic mail from Lucidtracking.com notifying of new features,
            services or events or consisting of selected Content.
            Lucidtracking.com's electronic mail, or any mail sent by Subscriber
            from within the Services, may include features allowing
            Lucidtracking.com to collect certain information regarding
            Subscriber’s use of the Services. These features are a required
            element of the Services.
          </p>
          <h3>Fees</h3>
          <p>
            Customer shall pay Subscription fees in accordance with
            Lucidtracking.com Terms of Purchase. Subscription fees shall be paid
            in full, and any levies, duties and/or taxes imposed by Customer's
            jurisdiction (including, but not limited to, value added tax, sales
            tax and withholding tax), shall be borne solely by Customer.
            Customer may not deduct any amounts from fees payable to
            Lucidtracking.com unless otherwise specified in the applicable terms
            of purchase.
          </p>
          <h3>Subscription Terminations</h3>
          <p>
            Upon cancellation or termination of a subscription: (1) all Fees
            owed by Customer to Lucidtracking.com are immediately due upon
            receipt of the final electronic invoice; (2) Customer will delete
            any data provided by Lucidtracking.com
          </p>

          <h1>Privacy Policy</h1>
          <h3>Information Collection and Use</h3>
          <p>
            Lucidtracking.com is the sole owner of information collected on this
            site. We will not sell, share, or rent this information to any
            outside parties, except as outlined in this policy. To access
            certain features of the site we require you to register and create
            an account. Information collected from registering includes your
            name and email address. We also require you to submit a username and
            password of your choice for your future access to your account
            information. To safeguard that your user name and password remain
            confidential, DO NOT share this information with anyone. If you
            elect to use our email alerts feature then your contact information
            will be used for the delivery of these items.
          </p>
          <p>
            If you contact us by email through the Site, we may keep a record of
            your contact information and correspondence, and may use your email
            address, and any information that you provide to us in your message,
            to respond to you.
          </p>
          <p>
            We may use Traffic Data or Contact Data to send you information
            about our company or our products or services, or promotional
            material from some of our partners, or to facilitate the renewal of
            your subscription, or to improve our Site, or to contact you when
            necessary.
          </p>
          <h3>Email Alert Opt-Out Policy</h3>
          <p>
            Your contact information may be used to provide you with certain
            features of Lucidtracking.com such as automated email alerts for new
            SEC filings. If you decide at any time that you no longer wish to
            receive such information or communications from us, please follow
            the unsubscribe instructions provided in any of the communications.
          </p>
          <h3>Log Files</h3>
          <p>
            We use IP addresses to analyze trends, administer the site, track
            user movement, and gather broad demographic information for
            aggregate use. We do not link IP addresses to personally
            identifiable information, and we do not distribute or share IP
            information with any third parties.
          </p>
          <h3>Cookies</h3>
          <p>
            Like many websites, we also use "cookie" technology to collect
            additional website usage data and to improve the Service. A cookie
            is a small data file that we transfer to your computer’s hard disk.
            We do not use cookies to collect personally identifiable
            information. Lucidtracking.com may use both session cookies and
            persistent cookies to better understand how you interact with the
            Service, to monitor aggregate usage by our users and web traffic
            routing on the Site, and to improve the Service. A session cookie
            enables certain features of the Site and our service and is deleted
            from your computer when you disconnect from or leave the Site. A
            persistent cookie remains after you close your browser and may be
            used by your browser on subsequent visits to the Site. Persistent
            cookies can be removed by following your web browser help file
            directions. Most Internet browsers automatically accept cookies. You
            can instruct your browser, by editing its options, to stop accepting
            cookies or to prompt you before accepting a cookie from the websites
            you visit.
          </p>
          <h3>How we may contact you</h3>
          <p>
            Lucidtracking.com may send you information related to your account
            or services. Because we believe such information is important, you
            will not be given the opportunity to opt-out of receiving them.
          </p>
          <h3>Updating your information</h3>
          <p>
            You can terminate your account at any time, by sending an email to
            hello@lucidtracking.com requesting your account be deleted. We
            typically deactivate accounts within two business days of such
            requests. You should be aware, however, that residual copies of
            information may remain stored on our systems even after the deletion
            of information or the termination of your account
          </p>
          <h3>Security</h3>
          <p>
            Lucidtracking.com is very concerned about safeguarding the
            confidentiality of your personally identifiable information. We
            employ administrative, physical and electronic measures designed to
            protect your information from unauthorized access.
          </p>
          <p>
            We will make any legally-required disclosures of any breach of the
            security, confidentiality, or integrity of your unencrypted
            electronically stored personal data to you via email or conspicuous
            posting on this Site in the most expedient time possible and without
            unreasonable delay, consistent with (i) the legitimate needs of law
            enforcement or (ii) any measures necessary to determine the scope of
            the breach and restore the reasonable integrity of the data system.
          </p>
          <p>
            Lucidtracking.com services and data utilize Google servers. These
            services have a robust security policy.
          </p>
          <h3>Third-Party Online Advertising</h3>
          <p>
            We may use third parties who collect Information about Site Visitors
            through their own cookies, web beacons, and other technologies
            regarding your online activities, either on our Sites and/or other
            websites across the Internet, in an effort to understand your
            interests and deliver you advertisements that are tailored to your
            interests. These third parties include advertisers and ad networks
            that may collect information about when you view or interact with
            one of their advertisements and may collect information regarding
            your activities on our Sites and your online activities over time
            and across different Websites. Like most advertisers, we place
            advertisements where we think they will be most relevant to the
            recipient. One way we do so is by allowing the advertising networks
            with whom we work to place their own cookies when an individual
            visits our Sites. This enables the network advertising company to
            recognize individuals who have previously visited our Sites. When
            the individual visits a third-party website on which that network
            advertising company has purchased ad space, the advertising company
            can then recognize the individual's interest in Lucid, LLC and
            deliver one of our advertisements.
          </p>
          <p>
            You may generally opt out of receiving personalized advertisements
            from certain third-party advertisers and ad networks. To learn more
            about these advertisements or to opt out, please visit the websites
            of the Digital Advertising Alliance and the Network Advertising
            Initiative.
          </p>
          <h3>How do we manage data on children</h3>
          <p>
            In compliance with Children’s Online Privacy Protection Act
            (“COPPA”), this Site is not directed at children under the age of 13
            and we do not intend to collect any personal identifiable
            information, such as name, home address, e-mail address, telephone
            number, or social security number, from children under the age of
            13. If you are under the age of 13, please do not provide us with
            your personal identifiable information.
          </p>
          <p>
            If you are under the age of 16 (EU & elsewhere), please ask your
            parent/guardian for their consent before submitting your personal
            information to this site.
          </p>
          <p>
            If you have any reason to believe that a child under the age of 13
            or 16 has provided us with his or her personal identifiable
            information, please contact us immediately for removal of that
            information from our databases.
          </p>
          <h3>Privacy Notice for EEA Individuals</h3>
          <p>
            This section covers disclosures on the General Data Protection
            Regulation (GDPR) for users residing within EEA only. GDPR replaces
            the existing Directive 95/46/ec, and aims at harmonizing data
            protection laws in the EU that are fit for purpose in the digital
            age. The primary objective of the GDPR is to give citizens back
            control of their personal data.
          </p>
          <h3>Privacy Notice for California Residents</h3>
          <p>
            This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the
            information contained in the Privacy Statement of Lucid, LLC LLC.
            (“Lucid, LLC”,“we,” “us,” or “our”) and applies solely to visitors,
            users, and others who reside in the State of California (“consumers”
            or “you”). We adopt this notice to comply with the California
            Consumer Privacy Act of 2018 (“CCPA”) and other California privacy
            laws. Please follow the link below to access Lucid, LLC's California
            Privacy Notice. California-Privacy-Notice
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
