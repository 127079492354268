import React, { useState, useEffect } from 'react';
import All from './pages/All';
import Aggregate from './pages/Aggregate';
import Calendars from './pages/Calendars';
import Chat from './pages/Chat';
import Crypto from './pages/Crypto';
import Dashboard from './pages/Dashboard';
import Grayscale from './pages/Grayscale';
import Tokens from './pages/Tokens';
import Wallets from './pages/Wallets';
import Insider from './pages/Insider';
import Investors from './pages/Investors';
import Join from './pages/Join';
import Landing from './pages/Landing';
import Membership from './pages/Membership';
import Watchlist from './pages/Watchlist';
import Navigation from './layouts/navigation';
import Main from './pages/Main';
import Meme from './pages/Meme';
import MemeShorts from './pages/MemeShorts';
import MemeIndicators from './pages/MemeIndicators';
import Modal from './layouts/modal';
import SimpleModal from './layouts/simpleModal';
import Options from './pages/Options';
import PaymentSuccess from './pages/Payment-Success';
import Rankings from './pages/Rankings';
import Stock from './pages/Stock';
import Faq from './pages/Faq';
import Terms from './pages/Terms';
import './App.scss';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import {
  getConfig,
  getOptions,
  getDataLength,
  getWalletInfo,
  getNewCoinsInfo,
  getAllCoinsInfo,
  getGsDashboard,
  getGsHistorical,
  getCryptoData,
  getMainData,
  getMemeData,
  getMemeShortData,
} from './helpers/api';
import {
  uniqueBy,
  flattenObject,
  getUserType,
  getCode,
  getWatchlist,
  setWatchlistToLS,
  getThemeFromLS,
} from './helpers/helpers';
import {
  fundGroups,
  topRankingsTabs,
  getSelectedFundGroup,
  setSelectedFundGroup,
} from './helpers/fundvars';
import WatchlistForm from './forms/WatchlistForm';
import { signUserInFB, isFBUser } from './helpers/firebaseHelpers.js';
import fire from './helpers/fire.js';

let fireuser;
let dataLength = getDataLength();
let CFG;
const path = window.location.pathname;
const paramsArray = path.split('/');
if (path.includes('/all')) {
  CFG = 'All';
} else if (paramsArray.includes('detail')) {
  CFG = decodeURI(paramsArray[3]);
  setSelectedFundGroup(CFG);
} else {
  CFG = getSelectedFundGroup();
}

let userType = getUserType();
let authed = getCode();
let fundData = [];
let stocklist;
let aggregateList;
let searchListTickers;
let topRankings;
let allTickers;
let addDrops;
let addDropsPassive;
let netPortfolio;
let cleanedDataArray;
let tickerTranslations;
let theme = getThemeFromLS() || '';
let earningsData = [];
let optionsData = [];
let stockVolumeData = [];
let stockVolumeAdvancesData = [];
let insiderData = [];
let optionsActiveData = [];
let shortSqueezeData = [];
let institutionalData = [];
let uptrendData = [];
let canslimData = [];
let marketorderData = [];
let investorsData = [];
let allInvestorsData = [];
let senateData = [];
let summarySenateBuys = [];
let summarySenateSells = [];

function App() {
  const [fundArray, setFundArray] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [myWatchlist, setMyWatchlist] = useState(getWatchlist());
  const [watchlistTicker, setWatchlistTicker] = useState('');
  const [watchlistAction, setWatchlistAction] = useState('');
  const [showModal, setShowModal] = useState({
    ticker: '',
    type: '',
    chartDataFromAgg: [],
  });
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [currentFundGroup, setCurrentFundGroup] = useState(
    CFG ? CFG : 'ARK Invest'
  );
  const [iData, setIData] = useState({});
  const [cryptoInfo, setCryptoInfo] = useState([]);
  const [walletInfo, setWalletInfo] = useState([]);
  const [newCoinsInfo, setNewCoinsInfo] = useState([]);
  const [mainInfo, setMainInfo] = useState([]);
  const [memeInfo, setMemeInfo] = useState([]);
  const [allCoinsInfo, setAllCoinsInfo] = useState([]);
  const [gsDashboardInfo, setGsDashboardInfo] = useState([]);
  const [gsHistoricalInfo, setGsHistoricalInfo] = useState([]);
  const [showCryptoModal, setShowCryptoModal] = useState({
    symbol: '',
    coinData: {},
  });
  const [showSimpleModal, setShowSimpleModal] = useState({
    ticker: '',
  });

  const handleShowUpgrade = (boolean) => {
    setShowUpgrade(boolean);
  };

  const handleChangeCurrentFundGroup = (fundName) => {
    setCurrentFundGroup(fundName);
    window.location.reload();
  };

  const handleAddToWatchlist = async (ticker, action) => {
    setWatchlistTicker(ticker);
    setWatchlistAction(action);
    const newWL = await setWatchlistToLS(ticker);
    setMyWatchlist(newWL);

    setTimeout(() => {
      window.document.querySelector('#submittickerform').click();
      setWatchlistTicker('');
      setWatchlistAction('');
    }, 500);
  };

  const getData = async () => {
    let data = await getConfig();

    const currentFund = fundGroups.find(
      (f) => f.fundGroup === currentFundGroup
    );
    let funds = currentFund.data;

    funds.forEach((f) => {
      const dataForFund = Object.values(data[f.fund]);
      f.data = dataForFund;
    });

    fundData = funds;

    //Aggregates
    stocklist = Object.values(data.Aggregates);
    searchListTickers = stocklist.map((r) => {
      return {
        ticker: `${r.ticker}`,
        company: r.company,
      };
    });

    //All Tickers
    allTickers = Object.values(data['Aggregates'])
      .map((a) => ({
        ticker: a.ticker,
        company: a.company,
      }))
      .sort((a, b) => `${a.ticker}`.localeCompare(`${b.ticker}`));

    ////API Get Industry Data
    const industryData = await getOptions();
    //console.log('industr', industryData);

    //Earnings Data
    const schedule = Object.values(industryData['Earnings Calendar']);

    //Filter Calendar to Fund Tickers
    const fundEarnings = schedule.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.symbol)
    );
    earningsData = fundEarnings
      .sort((a, b) => {
        return new Date(a.reportDate) - new Date(b.reportDate);
      })
      .map((f) => {
        const eData = stocklist.find((s) => s.ticker === f.symbol);
        return { ...f, ...eData };
      });

    //Options
    const options = Object.values(industryData['Unusual Options Activity']);
    optionsData = options.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );

    //Short Squeeze
    const shortSqueeze = Object.values(industryData['SS']);
    shortSqueezeData = shortSqueeze.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Ticker)
    );

    //Institutional
    const institutional = Object.values(industryData['InstiTrading']);
    institutionalData = institutional.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Ticker)
    );

    //Senate
    const senate = industryData['Senate']
      ? Object.values(industryData['Senate'])
      : [];
    senateData = senate.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Ticker)
    );
    summarySenateBuys = uniqueBy(
      senateData.filter(
        (ss) => !ss.Type.includes('Sell') || !ss.Type.includes('Sale')
      ),
      (o1, o2) => o1.Ticker === o2.Ticker
    );
    summarySenateSells = uniqueBy(
      senateData.filter(
        (ss) => ss.Type.includes('Sell') || ss.Type.includes('Sale')
      ),
      (o1, o2) => o1.Ticker === o2.Ticker
    );

    //Investors
    const investors = Object.values(industryData['Investors']);
    allInvestorsData = investors;
    investorsData = investors.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );

    //Uptrend
    const uptrend = Object.values(industryData['UTWL']);
    uptrendData = uptrend.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Ticker)
    );

    //Marketorder
    if (currentFund.fundGroup === 'ARK Invest') {
      const MO = industryData['MarketOrder'];
      if (MO) {
        marketorderData = Object.values(MO);
      }
    }

    //Canslim
    const canslim = Object.values(industryData['CS']);
    canslimData = canslim.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Ticker)
    );

    //Options Most Active
    const optionsActive = Object.values(industryData['Options Most Active']);
    optionsActiveData = optionsActive.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );

    //Insider Trading
    const insider = Object.values(industryData['Insider Trading']);
    insiderData = insider
      .filter((s) => allTickers.map((t) => t.ticker).includes(s.Ticky))
      .map((m) => ({
        ...m,
        Qty: parseInt(m.Qty),
        Owned: parseInt(m.Owned),
        ΔOwn: parseInt(m['ΔOwn']) === 0 ? 0 : parseInt(m['ΔOwn']),
        Value: parseInt(m.Value),
      }));

    //Most Actively Traded Stocks
    const mostActive = Object.values(industryData['Stock Volume']);
    stockVolumeData = mostActive.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );

    //Stock Volume Change
    const stockVolumeAdvances = Object.values(
      industryData['Stock Volume Advances']
    );
    stockVolumeAdvancesData = stockVolumeAdvances.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );

    //Adds and Drops
    addDrops = Object.values(data.Newdrop);
    addDropsPassive = data.NewdropPassive
      ? Object.values(data.NewdropPassive)
      : [];
    //NetPortfolio
    netPortfolio = Object.values(data.NetPortfolio);
    aggregateList = allTickers.map((a, index) => {
      if (!a.ticker) {
        console.log('missing ticker', a, index);
        console.log('one before', allTickers[index - 1]);
        console.log('allTickers', allTickers);
      }
      return {
        ticker: a.ticker.toString(),
        company: a.company.toString(),
        shares: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.shares }))
          ),
        },
        marketvalue: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.sharesmarketvalue }))
          ),
        },
        weight: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.weight }))
          ),
        },
        dailycapitalinjection: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.dailycapitalinjection }))
          ),
        },
        weeklycapitalinjection: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({
                [d.date]: d.weeklycapitalinjection,
              }))
          ),
        },
        float: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.float }))
          ),
        },
        portfolioweightdelta: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.portfolioweightdelta }))
          ),
        },
        close: {
          ticker: a.ticker.toString(),
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.priceclosed ? d.priceclosed : 0 }))
          ),
        },
      };
    });

    //Ticker Translations
    tickerTranslations = Object.values(data.Tickers);

    //Rank Data
    let rankData = [];

    if (currentFundGroup === 'ARK Invest') {
      const Agg1 = Object.values(data['1agg']);
      const ARKK1 = Object.values(data['1ARKK']);
      const ARKQ1 = Object.values(data['1ARKQ']);
      const ARKW1 = Object.values(data['1ARKW']);
      const ARKG1 = Object.values(data['1ARKG']);
      const ARKF1 = Object.values(data['1ARKF']);
      const ARKX1 = data['1ARKX'] ? Object.values(data['1ARKX']) : [];
      const PRNT1 = Object.values(data['1PRNT']);
      const IZRL1 = Object.values(data['1IZRL']);

      const Agg5 = Object.values(data['5agg']);
      const ARKK5 = Object.values(data['5ARKK']);
      const ARKQ5 = Object.values(data['5ARKQ']);
      const ARKW5 = Object.values(data['5ARKW']);
      const ARKG5 = Object.values(data['5ARKG']);
      const ARKF5 = Object.values(data['5ARKF']);
      const ARKX5 = data['5ARKX'] ? Object.values(data['5ARKX']) : [];
      const PRNT5 = Object.values(data['5PRNT']);
      const IZRL5 = Object.values(data['5IZRL']);

      const Agg14 = Object.values(data['14agg']);
      const ARKK14 = Object.values(data['14ARKK']);
      const ARKQ14 = Object.values(data['14ARKQ']);
      const ARKW14 = Object.values(data['14ARKW']);
      const ARKG14 = Object.values(data['14ARKG']);
      const ARKF14 = Object.values(data['14ARKF']);
      const ARKX14 = data['14ARKX'] ? Object.values(data['14ARKX']) : [];
      const PRNT14 = data['14PRNT'] ? Object.values(data['14PRNT']) : [];
      const IZRL14 = data['14IZRL'] ? Object.values(data['14IZRL']) : [];

      const Agg30 = Object.values(data['30agg']);
      const ARKK30 = Object.values(data['30ARKK']);
      const ARKQ30 = Object.values(data['30ARKQ']);
      const ARKW30 = Object.values(data['30ARKW']);
      const ARKG30 = Object.values(data['30ARKG']);
      const ARKF30 = Object.values(data['30ARKF']);
      const ARKX30 = data['30ARKX'] ? Object.values(data['30ARKX']) : [];
      const PRNT30 = data['30PRNT'] ? Object.values(data['30PRNT']) : [];
      const IZRL30 = data['30IZRL'] ? Object.values(data['30IZRL']) : [];

      const Agg60 = Object.values(data['60agg']);
      const ARKK60 = Object.values(data['60ARKK']);
      const ARKQ60 = Object.values(data['60ARKQ']);
      const ARKW60 = Object.values(data['60ARKW']);
      const ARKG60 = Object.values(data['60ARKG']);
      const ARKF60 = Object.values(data['60ARKF']);
      const ARKX60 = data['60ARKX'] ? Object.values(data['60ARKX']) : [];
      const PRNT60 = data['60PRNT'] ? Object.values(data['60PRNT']) : [];
      const IZRL60 = data['60IZRL'] ? Object.values(data['60IZRL']) : [];
      rankData = [
        {
          fund: 'Aggregate',
          name: 'All ETFs',
          type: 'Active',
          data: Agg1,
          five: Agg5,
          fourteen: Agg14,
          thirty: Agg30,
          sixty: Agg60,
        },
        {
          fund: 'ARKK',
          name: 'Innovation ETF',
          type: 'Active',
          data: ARKK1,
          five: ARKK5,
          fourteen: ARKK14,
          thirty: ARKK30,
          sixty: ARKK60,
        },
        {
          fund: 'ARKQ',
          name: 'Autonomous + Robotics',
          type: 'Active',
          data: ARKQ1.filter((a) => a.company !== null),
          five: ARKQ5.filter((a) => a.company !== null),
          fourteen: ARKQ14.filter((a) => a.company !== null),
          thirty: ARKQ30.filter((a) => a.company !== null),
          sixty: ARKQ60.filter((a) => a.company !== null),
        },
        {
          fund: 'ARKW',
          name: 'Internet',
          type: 'Active',
          data: ARKW1,
          five: ARKW5,
          fourteen: ARKW14,
          thirty: ARKW30,
          sixty: ARKW60,
        },
        {
          fund: 'ARKG',
          name: 'Genomic',
          type: 'Active',
          data: ARKG1,
          five: ARKG5,
          fourteen: ARKG14,
          thirty: ARKG30,
          sixty: ARKG60,
        },
        {
          fund: 'ARKF',
          name: 'Fintech',
          type: 'Active',
          data: ARKF1,
          five: ARKF5,
          fourteen: ARKF14,
          thirty: ARKF30,
          sixty: ARKF60,
        },
        {
          fund: 'ARKX',
          name: 'Space',
          type: 'Active',
          data: ARKX1,
          five: ARKX5,
          fourteen: ARKX14,
          thirty: ARKX30,
          sixty: ARKX60,
        },
        {
          fund: 'PRNT',
          name: '3D Printing',
          type: 'Passive',
          data: PRNT1,
          five: PRNT5,
          fourteen: PRNT14,
          thirty: PRNT30,
          sixty: PRNT60,
        },
        {
          fund: 'IZRL',
          name: 'Israel Innovation Tech',
          type: 'Passive',
          data: IZRL1,
          five: IZRL5,
          fourteen: IZRL14,
          thirty: IZRL30,
          sixty: IZRL60,
        },
      ];
    }

    /* if (currentFundGroup === 'Roundhill') {
      const Agg1 = Object.values(data['1agg']);
      const METV1 = Object.values(data['1METV']);
      const BETZ1 = Object.values(data['1BETZ']);
      const CHAT1 = Object.values(data['1CHAT']);
      const DEEP1 = Object.values(data['1DEEP']);
      const NERD1 = Object.values(data['1NERD']);
      const BIGB1 = data['1BIGB'] ? Object.values(data['1BIGB']) : [];
      const BIGT1 = Object.values(data['1BIGT']);
      const MEME1 = Object.values(data['1MEME']);
      const BYTE1 = Object.values(data['1BYTE']);
      const WEED1 = Object.values(data['1WEED']);

      const Agg5 = Object.values(data['5agg']);
      const METV5 = Object.values(data['5METV']);
      const BETZ5 = Object.values(data['5BETZ']);
      const CHAT5 = Object.values(data['5CHAT']);
      const DEEP5 = Object.values(data['5DEEP']);
      const NERD5 = Object.values(data['5NERD']);
      const BIGB5 = data['5BIGB'] ? Object.values(data['5BIGB']) : [];
      const BIGT5 = Object.values(data['5BIGT']);
      const MEME5 = Object.values(data['5MEME']);
      const BYTE5 = Object.values(data['5BYTE']);
      const WEED5 = Object.values(data['5WEED']);

      const Agg14 = Object.values(data['14agg']);
      const METV14 = Object.values(data['14METV']);
      const BETZ14 = Object.values(data['14BETZ']);
      const CHAT14 = Object.values(data['14CHAT']);
      const DEEP14 = Object.values(data['14DEEP']);
      const NERD14 = Object.values(data['14NERD']);
      const BIGB14 = data['14BIGB'] ? Object.values(data['14BIGB']) : [];
      const BIGT14 = data['14BIGT'] ? Object.values(data['14BIGT']) : [];
      const MEME14 = data['14MEME'] ? Object.values(data['14MEME']) : [];
      const BYTE14 = Object.values(data['14BYTE']);
      const WEED14 = Object.values(data['14WEED']);

      const Agg30 = Object.values(data['30agg']);
      const METV30 = Object.values(data['30METV']);
      const BETZ30 = Object.values(data['30BETZ']);
      const CHAT30 = Object.values(data['30CHAT']);
      const DEEP30 = Object.values(data['30DEEP']);
      const NERD30 = Object.values(data['30NERD']);
      const BIGB30 = data['30BIGB'] ? Object.values(data['30BIGB']) : [];
      const BIGT30 = data['30BIGT'] ? Object.values(data['30BIGT']) : [];
      const MEME30 = data['30MEME'] ? Object.values(data['30MEME']) : [];
      const BYTE30 = Object.values(data['30BYTE']);
      const WEED30 = Object.values(data['30WEED']);

      const Agg60 = Object.values(data['60agg']);
      const METV60 = Object.values(data['60METV']);
      const BETZ60 = Object.values(data['60BETZ']);
      const CHAT60 = Object.values(data['60CHAT']);
      const DEEP60 = Object.values(data['60DEEP']);
      const NERD60 = Object.values(data['60NERD']);
      const BIGB60 = data['60BIGB'] ? Object.values(data['60BIGB']) : [];
      const BIGT60 = data['60BIGT'] ? Object.values(data['60BIGT']) : [];
      const MEME60 = data['60MEME'] ? Object.values(data['60MEME']) : [];
      const BYTE60 = Object.values(data['60BYTE']);
      const WEED60 = Object.values(data['60WEED']);
      rankData = [
        {
          fund: 'Aggregate',
          name: 'All ETFs',
          type: 'Active',
          data: Agg1,
          five: Agg5,
          fourteen: Agg14,
          thirty: Agg30,
          sixty: Agg60,
        },
        {
          fund: 'METV',
          name: 'Metaverse',
          type: 'Active',
          data: METV1,
          five: METV5,
          fourteen: METV14,
          thirty: METV30,
          sixty: METV60,
        },
        {
          fund: 'BETZ',
          name: 'Sports Betting & iGaming',
          type: 'Active',
          data: BETZ1.filter((a) => a.company !== null),
          five: BETZ5.filter((a) => a.company !== null),
          fourteen: BETZ14.filter((a) => a.company !== null),
          thirty: BETZ30.filter((a) => a.company !== null),
          sixty: BETZ60.filter((a) => a.company !== null),
        },
        {
          fund: 'CHAT',
          name: 'AI & Technologies',
          type: 'Active',
          data: CHAT1,
          five: CHAT5,
          fourteen: CHAT14,
          thirty: CHAT30,
          sixty: CHAT60,
        },
        {
          fund: 'DEEP',
          name: 'Deep Value',
          type: 'Active',
          data: DEEP1,
          five: DEEP5,
          fourteen: DEEP14,
          thirty: DEEP30,
          sixty: DEEP60,
        },
        {
          fund: 'NERD',
          name: 'Video Games',
          type: 'Active',
          data: NERD1,
          five: NERD5,
          fourteen: NERD14,
          thirty: NERD30,
          sixty: NERD60,
        },
        {
          fund: 'BIGB',
          name: 'Banking',
          type: 'Active',
          data: BIGB1,
          five: BIGB5,
          fourteen: BIGB14,
          thirty: BIGB30,
          sixty: BIGB60,
        },
        {
          fund: 'BIGT',
          name: 'Technology',
          type: 'Passive',
          data: BIGT1,
          five: BIGT5,
          fourteen: BIGT14,
          thirty: BIGT30,
          sixty: BIGT60,
        },
        {
          fund: 'MEME',
          name: 'Meme Stocks',
          type: 'Passive',
          data: MEME1,
          five: MEME5,
          fourteen: MEME14,
          thirty: MEME30,
          sixty: MEME60,
        },
        {
          fund: 'BYTE',
          name: 'Digital Infrastructure',
          type: 'Passive',
          data: BYTE1,
          five: BYTE5,
          fourteen: BYTE14,
          thirty: BYTE30,
          sixty: BYTE60,
        },
        {
          fund: 'WEED',
          name: 'Cannabis',
          type: 'Passive',
          data: WEED1,
          five: WEED5,
          fourteen: WEED14,
          thirty: WEED30,
          sixty: WEED60,
        },
      ];
    } */

    if (currentFundGroup === 'BlackRock') {
      const Agg1 = Object.values(data['1agg']);
      const BTEK1 = Object.values(data['1BTEK']);
      //const BFTR1 = Object.values(data['1BFTR']);
      const BMED1 = Object.values(data['1BMED']);
      const ICLN1 = Object.values(data['1ICLN']);
      const IDRV1 = Object.values(data['1IDRV']);
      const IGV1 = Object.values(data['1IGV']);
      const IDNA1 = Object.values(data['1IDNA']);
      const IRBO1 = Object.values(data['1IRBO']);
      const SOXX1 = Object.values(data['1SOXX']);

      const Agg5 = Object.values(data['5agg']);
      const BTEK5 = Object.values(data['5BTEK']);
      //const BFTR5 = Object.values(data['5BFTR']);
      const BMED5 = Object.values(data['5BMED']);
      const ICLN5 = Object.values(data['5ICLN']);
      const IDRV5 = Object.values(data['5IDRV']);
      const IGV5 = Object.values(data['5IGV']);
      const IDNA5 = Object.values(data['5IDNA']);
      const IRBO5 = Object.values(data['5IRBO']);
      const SOXX5 = Object.values(data['5SOXX']);

      const Agg14 = Object.values(data['14agg']);
      const BTEK14 = Object.values(data['14BTEK']);
      //const BFTR14 = Object.values(data['14BFTR']);
      const BMED14 = Object.values(data['14BMED']);
      const ICLN14 = Object.values(data['14ICLN']);
      const IDRV14 = Object.values(data['14IDRV']);
      const IGV14 = Object.values(data['14IGV']);
      const IDNA14 = Object.values(data['14IDNA']);
      const IRBO14 = Object.values(data['14IRBO']);
      const SOXX14 = Object.values(data['14SOXX']);

      const Agg30 = data['30agg'] ? Object.values(data['30agg']) : [];
      const BTEK30 = data['30BTEK'] ? Object.values(data['30BTEK']) : [];
      //const BFTR30 = data['30BFTR'] ? Object.values(data['30BFTR']) : [];
      const BMED30 = data['30BMED'] ? Object.values(data['30BMED']) : [];
      const ICLN30 = data['30ICLN'] ? Object.values(data['30ICLN']) : [];
      const IDRV30 = data['30IDRV'] ? Object.values(data['30IDRV']) : [];
      const IGV30 = data['30IGV'] ? Object.values(data['30IGV']) : [];
      const IDNA30 = data['30IDNA'] ? Object.values(data['30IDNA']) : [];
      const IRBO30 = data['30IRBO'] ? Object.values(data['30IRBO']) : [];
      const SOXX30 = data['30SOXX'] ? Object.values(data['30SOXX']) : [];

      const Agg60 = data['60agg'] ? Object.values(data['60agg']) : [];
      const BTEK60 = data['60BTEK'] ? Object.values(data['60BTEK']) : [];
      //const BFTR60 = data['60BFTR'] ? Object.values(data['60BFTR']) : [];
      const BMED60 = data['60BMED'] ? Object.values(data['60BMED']) : [];
      const ICLN60 = data['60ICLN'] ? Object.values(data['60ICLN']) : [];
      const IDRV60 = data['60IDRV'] ? Object.values(data['60IDRV']) : [];
      const IGV60 = data['60IGV'] ? Object.values(data['60IGV']) : [];
      const IDNA60 = data['60IDNA'] ? Object.values(data['60IDNA']) : [];
      const IRBO60 = data['60IRBO'] ? Object.values(data['60IRBO']) : [];
      const SOXX60 = data['60SOXX'] ? Object.values(data['60SOXX']) : [];

      rankData = [
        {
          fund: 'Aggregate',
          name: 'All ETFs',
          type: 'Active',
          data: Agg1,
          five: Agg5,
          fourteen: Agg14,
          thirty: Agg30,
          sixty: Agg60,
        },
        {
          fund: 'BTEK',
          name: 'Future Tech',
          type: 'Active',
          data: BTEK1,
          five: BTEK5,
          fourteen: BTEK14,
          thirty: BTEK30,
          sixty: BTEK60,
        },
        {
          fund: 'BMED',
          name: 'Future Health',
          type: 'Active',
          data: BMED1,
          five: BMED5,
          fourteen: BMED14,
          thirty: BMED30,
          sixty: BMED60,
        },
        /* {
          fund: 'BFTR',
          name: 'Future Innovators',
          type: 'Active',
          data: BFTR1,
          five: BFTR5,
          fourteen: BFTR14,
          thirty: BFTR30,
          sixty: BFTR60,
        }, */
        {
          fund: 'ICLN',
          name: 'Global Clean Energy',
          type: 'Active',
          data: ICLN1,
          five: ICLN5,
          fourteen: ICLN14,
          thirty: ICLN30,
          sixty: ICLN60,
        },
        {
          fund: 'IDRV',
          name: 'Self-Driving EV',
          type: 'Active',
          data: IDRV1,
          five: IDRV5,
          fourteen: IDRV14,
          thirty: IDRV30,
          sixty: IDRV60,
        },
        {
          fund: 'IGV',
          name: 'Expanded Tech-Software',
          type: 'Active',
          data: IGV1,
          five: IGV5,
          fourteen: IGV14,
          thirty: IGV30,
          sixty: IGV60,
        },
        {
          fund: 'IDNA',
          name: 'Genomics Immunology, Health',
          type: 'Active',
          data: IDNA1,
          five: IDNA5,
          fourteen: IDNA14,
          thirty: IDNA30,
          sixty: IDNA60,
        },
        {
          fund: 'IRBO',
          name: 'Robotics and AI',
          type: 'Active',
          data: IRBO1,
          five: IRBO5,
          fourteen: IRBO14,
          thirty: IRBO30,
          sixty: IRBO60,
        },
        {
          fund: 'SOXX',
          name: 'PHLX Semiconductor',
          type: 'Active',
          data: SOXX1,
          five: SOXX5,
          fourteen: SOXX14,
          thirty: SOXX30,
          sixty: SOXX60,
        },
      ];
    }

    if (currentFundGroup === 'Global X') {
      const Agg1 = Object.values(data['1agg']);
      const BOTZ1 = Object.values(data['1BOTZ']);
      const POTX1 = Object.values(data['1POTX']);
      const CLOU1 = Object.values(data['1CLOU']);
      const FINX1 = Object.values(data['1FINX']);
      const EDOC1 = Object.values(data['1EDOC']);
      const BKCH1 = Object.values(data['1BKCH']);
      const HERO1 = Object.values(data['1HERO']);
      const DRIV1 = Object.values(data['1DRIV']);
      const CTEC1 = Object.values(data['1CTEC']);
      const GNOM1 = Object.values(data['1GNOM']);
      const LIT1 = Object.values(data['1LIT']);

      const Agg5 = Object.values(data['5agg']);
      const BOTZ5 = Object.values(data['5BOTZ']);
      const POTX5 = Object.values(data['5POTX']);
      const CLOU5 = Object.values(data['5CLOU']);
      const FINX5 = Object.values(data['5FINX']);
      const EDOC5 = Object.values(data['5EDOC']);
      const BKCH5 = Object.values(data['5BKCH']);
      const HERO5 = Object.values(data['5HERO']);
      const DRIV5 = Object.values(data['5DRIV']);
      const CTEC5 = Object.values(data['5CTEC']);
      const GNOM5 = Object.values(data['5GNOM']);
      const LIT5 = Object.values(data['5LIT']);

      const Agg14 = data['14agg'] ? Object.values(data['14agg']) : [];
      const BOTZ14 = data['14BOTZ'] ? Object.values(data['14BOTZ']) : [];
      const POTX14 = data['14POTX'] ? Object.values(data['14POTX']) : [];
      const CLOU14 = data['14CLOU'] ? Object.values(data['14CLOU']) : [];
      const FINX14 = data['14FINX'] ? Object.values(data['14FINX']) : [];
      const EDOC14 = data['14EDOC'] ? Object.values(data['14EDOC']) : [];
      const BKCH14 = data['14BKCH'] ? Object.values(data['14BKCH']) : [];
      const HERO14 = data['14HERO'] ? Object.values(data['14HERO']) : [];
      const DRIV14 = data['14DRIV'] ? Object.values(data['14DRIV']) : [];
      const CTEC14 = data['14CTEC'] ? Object.values(data['14CTEC']) : [];
      const GNOM14 = data['14GNOM'] ? Object.values(data['14GNOM']) : [];
      const LIT14 = data['14LIT'] ? Object.values(data['14LIT']) : [];

      const Agg30 = data['30agg'] ? Object.values(data['30agg']) : [];
      const BOTZ30 = data['30BOTZ'] ? Object.values(data['30BOTZ']) : [];
      const POTX30 = data['30POTX'] ? Object.values(data['30POTX']) : [];
      const CLOU30 = data['30CLOU'] ? Object.values(data['30CLOU']) : [];
      const FINX30 = data['30FINX'] ? Object.values(data['30FINX']) : [];
      const EDOC30 = data['30EDOC'] ? Object.values(data['30EDOC']) : [];
      const BKCH30 = data['30BKCH'] ? Object.values(data['30BKCH']) : [];
      const HERO30 = data['30HERO'] ? Object.values(data['30HERO']) : [];
      const DRIV30 = data['30DRIV'] ? Object.values(data['30DRIV']) : [];
      const CTEC30 = data['30CTEC'] ? Object.values(data['30CTEC']) : [];
      const GNOM30 = data['30GNOM'] ? Object.values(data['30GNOM']) : [];
      const LIT30 = data['30LIT'] ? Object.values(data['30LIT']) : [];

      const Agg60 = data['60agg'] ? Object.values(data['60agg']) : [];
      const BOTZ60 = data['60BOTZ'] ? Object.values(data['60BOTZ']) : [];
      const POTX60 = data['60POTX'] ? Object.values(data['60POTX']) : [];
      const CLOU60 = data['60CLOU'] ? Object.values(data['60CLOU']) : [];
      const FINX60 = data['60FINX'] ? Object.values(data['60FINX']) : [];
      const EDOC60 = data['60EDOC'] ? Object.values(data['60EDOC']) : [];
      const BKCH60 = data['60BKCH'] ? Object.values(data['60BKCH']) : [];
      const HERO60 = data['60HERO'] ? Object.values(data['60HERO']) : [];
      const DRIV60 = data['60DRIV'] ? Object.values(data['60DRIV']) : [];
      const CTEC60 = data['60CTEC'] ? Object.values(data['60CTEC']) : [];
      const GNOM60 = data['60GNOM'] ? Object.values(data['60GNOM']) : [];
      const LIT60 = data['60LIT'] ? Object.values(data['60LIT']) : [];

      rankData = [
        {
          fund: 'Aggregate',
          name: 'All ETFs',
          type: 'Active',
          data: Agg1,
          five: Agg5,
          fourteen: Agg14,
          thirty: Agg30,
          sixty: Agg60,
        },
        {
          fund: 'BOTZ',
          name: 'Robotics & AI',
          type: 'Active',
          data: BOTZ1,
          five: BOTZ5,
          fourteen: BOTZ14,
          thirty: BOTZ30,
          sixty: BOTZ60,
        },
        {
          fund: 'POTX',
          name: 'Cannabis',
          type: 'Active',
          data: POTX1,
          five: POTX5,
          fourteen: POTX14,
          thirty: POTX30,
          sixty: POTX60,
        },
        {
          fund: 'CLOU',
          name: 'Cloud Computing',
          type: 'Active',
          data: CLOU1,
          five: CLOU5,
          fourteen: CLOU14,
          thirty: CLOU30,
          sixty: CLOU60,
        },
        {
          fund: 'FINX',
          name: 'Fintech',
          type: 'Active',
          data: FINX1,
          five: FINX5,
          fourteen: FINX14,
          thirty: FINX30,
          sixty: FINX60,
        },
        {
          fund: 'EDOC',
          name: 'Telemedicine & Digital Health',
          type: 'Active',
          data: EDOC1,
          five: EDOC5,
          fourteen: EDOC14,
          thirty: EDOC30,
          sixty: EDOC60,
        },
        {
          fund: 'BKCH',
          name: 'Blockchain ETF',
          type: 'Passive',
          data: BKCH1,
          five: BKCH5,
          fourteen: BKCH14,
          thirty: BKCH30,
          sixty: BKCH60,
        },
        {
          fund: 'HERO',
          name: 'Video Games & Esports',
          type: 'Passive',
          data: HERO1,
          five: HERO5,
          fourteen: HERO14,
          thirty: HERO30,
          sixty: HERO60,
        },
        {
          fund: 'DRIV',
          name: 'Autonomous & EV',
          type: 'Active',
          data: DRIV1,
          five: DRIV5,
          fourteen: DRIV14,
          thirty: DRIV30,
          sixty: DRIV60,
        },
        {
          fund: 'CTEC',
          name: 'Cleantech',
          type: 'Active',
          data: CTEC1,
          five: CTEC5,
          fourteen: CTEC14,
          thirty: CTEC30,
          sixty: CTEC60,
        },
        {
          fund: 'GNOM',
          name: 'Genomics & Biotech',
          type: 'Active',
          data: GNOM1,
          five: GNOM5,
          fourteen: GNOM14,
          thirty: GNOM30,
          sixty: GNOM60,
        },
        {
          fund: 'LIT',
          name: 'Lithium & Battery Tech',
          type: 'Active',
          data: LIT1,
          five: LIT5,
          fourteen: LIT14,
          thirty: LIT30,
          sixty: LIT60,
        },
      ];
    }

    //topRankings and topRankingsTabs
    topRankings = rankData;
    funds.forEach((f) => {
      const cleanedData = f.data.filter((ff) => ff.ticker !== 0).reverse();
      cleanedDataArray = [...cleanedData];
      let tickers = uniqueBy(
        cleanedDataArray.map((r) => {
          return {
            ticker: r.ticker,
            company: r.company,
            fund: r.fund,
            data: [],
            list: [],
          };
        }),
        (o1, o2) => o1.ticker === o2.ticker
      ).reverse();

      //finds error in which etf
      //console.log('f', f);
      tickers.forEach((t) => {
        cleanedDataArray.forEach((r) => {
          if (r.ticker.toString() === t.ticker.toString()) {
            t.data.push(r);
          }
        });
      });
      f.list = tickers.map((item) => ({
        company: item.company,
        data: item.data[0],
        //fund: item.fund,
        ticker: item.ticker,
      }));

      f.chartData = tickers.map((t) => ({
        ticker: t.ticker.toString(),
        company: t.company,
        shares: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.reverse().map((d) => ({
              [d.date]: d.shares,
            }))
          ),
        },
        marketvalue: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: d.sharesmarketvalue,
            }))
          ),
        },

        weight: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: d.weight,
            }))
          ),
        },

        dailycapitalinjection: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: d.dailycapitalinjection,
            }))
          ),
        },

        weeklycapitalinjection: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: isNaN(d.weeklycapitalinjection)
                ? 0
                : d.weeklycapitalinjection,
            }))
          ),
        },
        float: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: isNaN(d.float) ? 0 : d.float,
            }))
          ),
        },
        portfolioweightdelta: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: isNaN(d.portfolioweightdelta)
                ? 0
                : d.portfolioweightdelta,
            }))
          ),
        },
        close: {
          ticker: t.ticker.toString(),
          ...flattenObject(
            t.data.map((d) => ({
              [d.date]: d.priceclosed,
            }))
          ),
        },
        dates: t.data.map((d) => d.date),
      }));
    });

    setIsLoaded(true);
  };

  const handleShowModal = (ticker, type, chartDataFromAgg) => {
    setShowModal({ ticker: `${ticker}`, type, chartDataFromAgg });
  };

  const handleShowSimpleModal = (ticker, name) => {
    setShowSimpleModal({ ticker, name });
  };

  const handleShowCryptoModal = (coinData) => {
    setShowCryptoModal({ symbol: coinData.symbol, coinData: coinData });
  };

  const getIndustryData = async () => {
    const iDataFromAPI = await getOptions();
    setIData(iDataFromAPI);
    return iDataFromAPI;
  };

  const getOptionsAfterFB = async (meme) => {
    const industryData = await getOptions();
    const allTickers = meme.map((m) => m.ticker);

    //Options
    const options = Object.values(industryData['Unusual Options Activity']);
    optionsData = options.filter((s) => allTickers.includes(s.Symbol));

    //Short Squeeze
    const shortSqueeze = Object.values(industryData['SS']);
    shortSqueezeData = shortSqueeze.filter((s) =>
      allTickers.includes(s.Ticker)
    );

    //Institutional
    const institutional = Object.values(industryData['InstiTrading']);
    institutionalData = institutional.filter((s) =>
      allTickers.includes(s.Ticker)
    );

    //Options Most Active
    const optionsActive = Object.values(industryData['Options Most Active']);
    optionsActiveData = optionsActive.filter((s) =>
      allTickers.includes(s.Symbol)
    );

    //Senate
    const senate = Object.values(industryData['Senate']);
    senateData = senate.filter((s) => allTickers.includes(s.Ticker));
    summarySenateBuys = uniqueBy(
      senateData.filter(
        (ss) => !ss.Type.includes('Sell') || !ss.Type.includes('Sale')
      ),
      (o1, o2) => o1.Ticker === o2.Ticker
    );
    summarySenateSells = uniqueBy(
      senateData.filter(
        (ss) => ss.Type.includes('Sell') || ss.Type.includes('Sale')
      ),
      (o1, o2) => o1.Ticker === o2.Ticker
    );

    //Investors
    const investors = Object.values(industryData['Investors']);
    allInvestorsData = investors;
    investorsData = investors.filter((s) => allTickers.includes(s.Symbol));
    setIsLoaded(true);
  };

  const getMemeInfo = async () => {
    if (!isFBUser()) {
      fireuser = await signUserInFB();
    }

    let meme;
    let memeWithoutSPY;
    const dbRef = await fire.database().ref();
    dbRef
      .child('1bpmk9KlVESRTm3gboUz9NfC6pMqHPP-6XY6XqMM9rvk')
      .child('mqv4')
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          meme = snapshot.val();
          memeWithoutSPY = meme.filter((m) => m.ticker !== 'SPY');
          setMemeInfo(memeWithoutSPY);
          getOptionsAfterFB(meme);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });

    /* 
    const meme = await getMemeData();

    console.log('meme', meme);
    const memeShortData = await getMemeShortData();
    const memeWithoutSPY = meme.filter((m) => m.ticker !== 'SPY');
    setMemeInfo(memeWithoutSPY);
    
    const industryData = await getOptions();
    const allTickers = meme.map((m) => m.ticker);
    //Options
    const options = Object.values(industryData['Unusual Options Activity']);
    optionsData = options.filter((s) => allTickers.includes(s.Symbol));

    //Short Squeeze
    const shortSqueeze = Object.values(industryData['SS']);
    shortSqueezeData = shortSqueeze.filter((s) =>
      allTickers.includes(s.Ticker)
    );

    //Institutional
    const institutional = Object.values(industryData['InstiTrading']);
    institutionalData = institutional.filter((s) =>
      allTickers.includes(s.Ticker)
    );

    //Options Most Active
    const optionsActive = Object.values(industryData['Options Most Active']);
    optionsActiveData = optionsActive.filter((s) =>
      allTickers.includes(s.Symbol)
    );

    //Senate
    const senate = Object.values(industryData['Senate']);
    senateData = senate.filter((s) => allTickers.includes(s.Ticker));
    summarySenateBuys = uniqueBy(
      senateData.filter(
        (ss) => !ss.Type.includes('Sell') || !ss.Type.includes('Sale')
      ),
      (o1, o2) => o1.Ticker === o2.Ticker
    );
    summarySenateSells = uniqueBy(
      senateData.filter(
        (ss) => ss.Type.includes('Sell') || ss.Type.includes('Sale')
      ),
      (o1, o2) => o1.Ticker === o2.Ticker
    );

    //Investors
    const investors = Object.values(industryData['Investors']);
    allInvestorsData = investors;
    investorsData = investors.filter((s) => allTickers.includes(s.Symbol));
    setIsLoaded(true); */
  };

  const getGSData = async () => {
    const dbRef = await fire.database().ref();
    return dbRef;
  };

  const processCrypto = async (cryptoFB) => {
    let cryptoGSFB;
    const crypto = await cryptoFB.AllCoins_N;
    const wallet = await cryptoFB.ERC20_TOKENS_STATIC;
    const newCoins = await Object.values(cryptoFB.NewCoins_N);
    const allCoins = await cryptoFB.AllCoins_N;
    const gsDataDBRef = await getGSData();

    gsDataDBRef
      .child('1tJURPiJUzu8l51VM65affYzBZ93nAXldlBp0cUxQogg')
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          cryptoGSFB = snapshot.val();
          const gsDashboard = Object.values(cryptoGSFB.dashboard);
          const gsHistorical = Object.values(cryptoGSFB.static_historical);
          setCryptoInfo(crypto);
          setNewCoinsInfo(newCoins);
          setAllCoinsInfo(allCoins);

          //Start Wallet
          const walletWithCryptoInfo = wallet.map((w) => {
            const coinData = crypto.find((c) => c?.symbol === w?.symbol);
            return {
              ...w,
              ...coinData,
            };
          });
          setWalletInfo(walletWithCryptoInfo);
          //End Wallet

          //combine gs and coin info
          const uniqueGsPlusCryptoInfo = gsDashboard.map((u) => {
            const gsData = crypto.find((c) => c?.symbol === u?.symbol);
            return {
              ...u,
              ...gsData,
            };
          });

          //combine gsHistorical and coin info
          const uniqueGsHistoricalPlusCoinInfo = gsHistorical.map((u) => {
            const gsData = crypto.find((c) => c?.symbol === u?.symbol);
            return {
              ...u,
              ...gsData,
            };
          });
          setGsDashboardInfo(uniqueGsPlusCryptoInfo);
          setGsHistoricalInfo(uniqueGsHistoricalPlusCoinInfo);
          setIsLoaded(true);
        } else {
          console.log('No data available');
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTokenData = async () => {
    if (!isFBUser()) {
      fireuser = await signUserInFB();
    }

    const dbRef = await fire.database().ref();
    dbRef
      .child('1B9tDTvuaFqVwxqfXWnS5U2YxhbfIlyhZciIGXwRCnRo')
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          processCrypto(snapshot.val());
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });

    /* const crypto = await getCryptoData();
    const wallet = await getWalletInfo();
    const newCoins = await getNewCoinsInfo();
    const allCoins = await getAllCoinsInfo();
    const gsDashboard = await getGsDashboard();
    const gsHistorical = await getGsHistorical();
    setCryptoInfo(crypto);
    setNewCoinsInfo(newCoins);
    setAllCoinsInfo(allCoins);

    //Start Wallet
    const walletWithCryptoInfo = wallet.map((w) => {
      const coinData = crypto.find((c) => c.symbol === w.symbol);
      return {
        ...w,
        ...coinData,
      };
    });
    setWalletInfo(walletWithCryptoInfo);
    //End Wallet

    //combine gs and coin info
    const uniqueGsPlusCryptoInfo = gsDashboard.map((u) => {
      const gsData = crypto.find((c) => c.symbol === u.symbol);
      return {
        ...u,
        ...gsData,
      };
    });

    //combine gsHistorical and coin info
    const uniqueGsHistoricalPlusCoinInfo = gsHistorical.map((u) => {
      const gsData = crypto.find((c) => c.symbol === u.symbol);
      return {
        ...u,
        ...gsData,
      };
    });
    setGsDashboardInfo(uniqueGsPlusCryptoInfo);
    setGsHistoricalInfo(uniqueGsHistoricalPlusCoinInfo);
    setIsLoaded(true); */
  };

  useEffect(() => {
    if (authed) {
      if (path.includes('/all')) {
        getIndustryData();
      } else if (
        path.includes('/crypto') ||
        path.includes('/grayscale') ||
        path.includes('/wallet') ||
        path.includes('/new-coins') ||
        path.includes('/all-coins') ||
        path.includes('/top-picks')
      ) {
        getTokenData();
      } else if (
        path.includes('/meme') ||
        path.includes('/meme-indicators') ||
        path.includes('/meme-si')
      ) {
        getMemeInfo();
      } else if (path.includes('/main')) {
        getMainData();
      } else {
        if (fundData.length === 0) {
          getData();
        }
      }
    }
    setFundArray(fundData);
  }, [fundData]);

  return (
    <div className={`darkmode ${theme}`}>
      {showCryptoModal.symbol?.length > 0 && (
        <Modal
          coinData={showCryptoModal.coinData}
          handleShowCryptoModal={handleShowCryptoModal}
          theme={theme}
        />
      )}
      {showSimpleModal.ticker?.length > 0 && (
        <SimpleModal
          ticker={showSimpleModal.ticker}
          theme={theme}
          handleShowSimpleModal={handleShowSimpleModal}
        />
      )}
      {showModal.ticker.length > 0 && (
        <Modal
          ticker={showModal.ticker}
          handleShowModal={handleShowModal}
          userType={userType}
          currentFundGroup={currentFundGroup}
          fundArray={fundArray}
          searchListTickers={searchListTickers}
          addToWatchlist={handleAddToWatchlist}
          handleShowUpgrade={handleShowUpgrade}
          tickerTranslations={tickerTranslations}
          date={fundArray[0]?.list[0].data.date}
          myWatchlist={myWatchlist}
          allTickers={allTickers}
          insiderData={insiderData}
          type={showModal.type}
          chartDataFromAgg={showModal.chartDataFromAgg}
          topRankings={topRankings}
          optionsData={optionsData}
          investorsData={investorsData}
          shortSqueezeData={shortSqueezeData}
          institutionalData={institutionalData}
          senateData={senateData}
          theme={theme}
        ></Modal>
      )}

      <Router>
        <div className='App'>
          <div className={showUpgrade ? 'modal' : 'hidden'}>
            <Membership
              userType={userType}
              handleShowUpgrade={handleShowUpgrade}
            />
          </div>
          {authed ? (
            <>
              <Navigation
                userType={userType}
                currentFundGroup={currentFundGroup}
                allTickers={allTickers}
                myWatchlist={myWatchlist}
                handleShowModal={handleShowModal}
                handleShowUpgrade={handleShowUpgrade}
                handleChangeCurrentFundGroup={handleChangeCurrentFundGroup}
                dataLength={dataLength}
              />
              <Switch>
                <Route
                  exact
                  path='/all-overlaps'
                  render={() => (
                    <All
                      userType={userType}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      type='Overlaps'
                    />
                  )}
                />
                <Route
                  exact
                  path='/all-shared'
                  render={() => (
                    <All
                      userType={userType}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      type='Shared Across Funds'
                    />
                  )}
                />
                <Route
                  exact
                  path='/all-dashboard'
                  render={() => (
                    <All
                      userType={userType}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      //earningsData={earningsData}
                      type='Dashboard'
                      industryData={iData}
                    />
                  )}
                />
                <Route
                  exact
                  path='/aggregate'
                  render={() => (
                    <Aggregate
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      stocklist={stocklist}
                      fundGroups={fundGroups}
                      searchListTickers={searchListTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                    />
                  )}
                />
                <Route
                  exact
                  path='/calendars'
                  render={() => (
                    <Calendars
                      myWatchlist={myWatchlist}
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      allTickers={allTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      earningsData={earningsData}
                      date={fundArray[0]?.list[0].data.date}
                    />
                  )}
                />
                <Route
                  exact
                  path='/top-picks'
                  render={() => (
                    <Crypto
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowCryptoModal={handleShowCryptoModal}
                      handleShowUpgrade={handleShowUpgrade}
                      cryptoInfo={cryptoInfo}
                    />
                  )}
                />
                {/* <Route
                  exact
                  path='/grayscale'
                  render={() => (
                    <Grayscale
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowCryptoModal={handleShowCryptoModal}
                      handleShowUpgrade={handleShowUpgrade}
                      gsDashboardInfo={gsDashboardInfo}
                      cryptoInfo={cryptoInfo}
                      gsHistoricalInfo={gsHistoricalInfo}
                    />
                  )}
                />
                <Route
                  exact
                  path='/crypto'
                  render={() => (
                    <Tokens
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowCryptoModal={handleShowCryptoModal}
                      handleShowUpgrade={handleShowUpgrade}
                      tokenInfo={allCoinsInfo}
                      type='All Coins'
                      description='New Cryptocurrencies by Market Cap. Updated every 2 hours.'
                    />
                  )}
                />
                <Route
                  exact
                  path='/wallets'
                  render={() => (
                    <Wallets
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowCryptoModal={handleShowCryptoModal}
                      handleShowUpgrade={handleShowUpgrade}
                      walletInfo={walletInfo}
                    />
                  )}
                />
                <Route
                  exact
                  path='/new-coins'
                  render={() => (
                    <Tokens
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowCryptoModal={handleShowCryptoModal}
                      handleShowUpgrade={handleShowUpgrade}
                      tokenInfo={newCoinsInfo}
                      type='New Coins'
                      description='Top Cryptocurrencies by Market Cap'
                    />
                  )}
                />
                <Route
                  exact
                  path='/main'
                  render={() => (
                    <Main
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowModal={handleShowCryptoModal}
                      handleShowUpgrade={handleShowUpgrade}
                      mainInfo={mainInfo}
                    />
                  )}
                />
                <Route
                  exact
                  path='/meme'
                  render={() => (
                    <Meme
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowUpgrade={handleShowUpgrade}
                      memeInfo={memeInfo}
                      handleShowSimpleModal={handleShowSimpleModal}
                      optionsData={optionsData}
                      investorsData={investorsData}
                      shortSqueezeData={shortSqueezeData}
                      institutionalData={institutionalData}
                      senateData={senateData}
                      optionsActiveData={optionsActiveData}
                    />
                  )}
                />
                <Route
                  exact
                  path='/meme-indicators'
                  render={() => (
                    <MemeIndicators
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowUpgrade={handleShowUpgrade}
                      memeInfo={memeInfo}
                      handleShowSimpleModal={handleShowSimpleModal}
                      optionsData={optionsData}
                      investorsData={investorsData}
                      shortSqueezeData={shortSqueezeData}
                      institutionalData={institutionalData}
                      senateData={senateData}
                      optionsActiveData={optionsActiveData}
                    />
                  )}
                />
                <Route
                  exact
                  path='/meme-si'
                  render={() => (
                    <MemeShorts
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowUpgrade={handleShowUpgrade}
                      memeInfo={memeInfo}
                      handleShowSimpleModal={handleShowSimpleModal}
                      optionsData={optionsData}
                      investorsData={investorsData}
                      shortSqueezeData={shortSqueezeData}
                      institutionalData={institutionalData}
                      senateData={senateData}
                      optionsActiveData={optionsActiveData}
                    />
                  )}
                /> */}
                {/* <Route
                  exact
                  path='/meme-shorts'
                  render={() => (
                    <MemeShorts
                      userType={userType}
                      isLoaded={isLoaded}
                      handleShowUpgrade={handleShowUpgrade}
                      memeShortData={memeShortData}
                      handleShowSimpleModal={handleShowSimpleModal}
                    />
                  )}
                /> */}
                <Route
                  exact
                  path='/detail/:id/:fund'
                  render={() => (
                    <div>
                      <Modal
                        ticker={showModal.ticker}
                        handleShowModal={handleShowModal}
                        userType={userType}
                        currentFundGroup={currentFundGroup}
                        fundArray={fundArray}
                        searchListTickers={searchListTickers}
                        addToWatchlist={handleAddToWatchlist}
                        handleShowUpgrade={handleShowUpgrade}
                        tickerTranslations={tickerTranslations}
                        date={fundArray[0]?.list[0].data.date}
                        myWatchlist={myWatchlist}
                        allTickers={allTickers}
                        insiderData={insiderData}
                        type={showModal.type}
                        isLoaded={isLoaded}
                        investorsData={investorsData}
                        optionsData={optionsData}
                        investorsData={investorsData}
                        shortSqueezeData={shortSqueezeData}
                        institutionalData={institutionalData}
                        senateData={senateData}
                        theme={theme}
                      ></Modal>
                    </div>
                  )}
                />
                <Route
                  exact
                  path='/'
                  render={() => (
                    <Dashboard
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      addDrops={addDrops}
                      addDropsPassive={addDropsPassive}
                      topRankings={topRankings}
                      fundArray={fundArray}
                      stocklist={stocklist}
                      aggregateList={aggregateList}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      addToWatchlist={handleAddToWatchlist}
                      earningsData={earningsData}
                      optionsData={optionsData}
                      optionsActiveData={optionsActiveData}
                      stockVolumeData={stockVolumeData}
                      stockVolumeAdvancesData={stockVolumeAdvancesData}
                      insiderData={insiderData}
                      dataLength={dataLength}
                      shortSqueezeData={shortSqueezeData}
                      institutionalData={institutionalData}
                      senateData={senateData}
                      uptrendData={uptrendData}
                      canslimData={canslimData}
                      marketorderData={marketorderData}
                      investorsData={investorsData}
                      myWatchlist={myWatchlist}
                      summarySenateBuys={summarySenateBuys}
                      summarySenateSells={summarySenateSells}
                    />
                  )}
                />
                <Route
                  exact
                  path='/insider'
                  render={() => (
                    <Insider
                      myWatchlist={myWatchlist}
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      allTickers={allTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      insiderData={insiderData}
                      date={fundArray[0]?.list[0].data.date}
                    />
                  )}
                />
                <Route
                  exact
                  path='/investors'
                  render={() => (
                    <Investors
                      myWatchlist={myWatchlist}
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      allTickers={allTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      investorsData={investorsData}
                      allInvestorsData={allInvestorsData}
                      date={fundArray[0]?.list[0].data.date}
                    />
                  )}
                />
                <Route
                  exact
                  path='/options'
                  render={() => (
                    <Options
                      myWatchlist={myWatchlist}
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      allTickers={allTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      optionsData={optionsData}
                      optionsActiveData={optionsActiveData}
                      date={fundArray[0]?.list[0].data.date}
                    />
                  )}
                />
                <Route
                  exact
                  path='/stock'
                  render={() => (
                    <Stock
                      myWatchlist={myWatchlist}
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      allTickers={allTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      optionsData={optionsData}
                      date={fundArray[0]?.list[0].data.date}
                      stockVolumeData={stockVolumeData}
                      stockVolumeAdvancesData={stockVolumeAdvancesData}
                    />
                  )}
                />
                <Route
                  exact
                  path='/rankings'
                  render={() => (
                    <Rankings
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      topRankings={topRankings}
                      topRankingsTabs={topRankingsTabs}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      fundArray={fundArray}
                    />
                  )}
                />
                <Route
                  exact
                  path='/watchlist'
                  render={() => (
                    <Watchlist
                      myWatchlist={myWatchlist}
                      userType={userType}
                      currentFundGroup={currentFundGroup}
                      stocklist={stocklist}
                      addToWatchlist={handleAddToWatchlist}
                      handleShowModal={handleShowModal}
                      aggregateList={aggregateList}
                      handleShowUpgrade={handleShowUpgrade}
                      allTickers={allTickers}
                    />
                  )}
                />
                <Route
                  exact
                  path='/chat'
                  render={() => (
                    <Chat
                      userType={userType}
                      allTickers={allTickers}
                      handleShowModal={handleShowModal}
                      handleShowUpgrade={handleShowUpgrade}
                      currentFundGroup={currentFundGroup}
                    />
                  )}
                />
                <Route exact path='/faqs' component={Faq} />
                <Route
                  exact
                  path='/payment-success'
                  component={PaymentSuccess}
                />
                <Route exact path='/terms' component={Terms} />
                <Redirect to='/' />
              </Switch>
            </>
          ) : (
            <Switch>
              <Route exact path='/join/:id' component={Join} />
              <Route exact path='/' component={Landing} />
              <Route exact path='/terms' component={Terms} />
              <Route exact path='/payment-success' component={PaymentSuccess} />
              <Redirect to='/' />
            </Switch>
          )}
        </div>
      </Router>
      <WatchlistForm
        ticker={watchlistTicker}
        action={watchlistAction}
      ></WatchlistForm>
    </div>
  );
}

export default App;
