import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAwvw_MTI_NVhnOSXASDo9VmDg8kug1n9o',
  authDomain: 'lucid-2f588.firebaseapp.com',
  databaseURL: 'https://lucid-2f588-default-rtdb.firebaseio.com',
  projectId: 'lucid-2f588',
  storageBucket: 'lucid-2f588.appspot.com',
  messagingSenderId: '684391445615',
  appId: '1:684391445615:web:df5989eb73df9ce646c99a',
  measurementId: 'G-LMQPVME7Y8',
};

try {
  firebase.initializeApp(firebaseConfig);
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}

const fire = firebase;
export default fire;
