import React, { useState } from 'react';

function WatchButton({ ticker, isWatching, handleAddToWatchlist }) {
  let watching = isWatching;
  const [isLoading, setIsLoading] = useState(false);

  const handleWatching = (action) => {
    setIsLoading(true);
    setTimeout(() => {}, 10);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    setTimeout(() => {
      handleAddToWatchlist(ticker, action);
    }, 50);
  };

  return (
    <>
      {watching ? (
        <button
          onClick={() => handleWatching('Removed')}
          className='btn-success'
        >
          {isLoading ? <span className='faded'>Updating</span> : 'Watching'}
        </button>
      ) : (
        <button onClick={() => handleWatching('Added')}>
          {isLoading ? <span className='faded'>Updating</span> : 'Watch'}
        </button>
      )}
    </>
  );
}
export default WatchButton;
