import { formatNumber, formatCurrency } from '../helpers/format';
import { aggregateTypes } from '../helpers/fundvars';

function PanelAggregates({ data, type, handleShowModal }) {
  let items = [];

  if (data) {
    if (type === aggregateTypes[0].type) {
      items = data
        .filter((d) => d.d1shares > 0)
        .sort((a, b) => {
          return b.d1shares - a.d1shares;
        })
        .slice(0, 19);
    }

    if (type === aggregateTypes[1].type) {
      items = data
        .filter((d) => d.bought > 0)
        .sort((a, b) => {
          return b.bought - a.bought;
        })
        .slice(0, 19);
    }

    if (type === aggregateTypes[2].type) {
      items = data
        .filter((d) => d.d1shares < 0)
        .sort((a, b) => {
          return a.d1shares - b.d1shares;
        })
        .slice(0, 19);
    }

    if (type === aggregateTypes[3].type) {
      items = data
        .filter((d) => d.bought < 0)
        .sort((a, b) => {
          return a.bought - b.bought;
        })
        .slice(0, 19);
    }

    if (type === aggregateTypes[4].type) {
      items = data
        .filter((d) => d.d7shares > 0)
        .sort((a, b) => {
          return b.d7shares - a.d7shares;
        })
        .slice(0, 19);
    }
  }

  return (
    <div>
      <h4 className='text-center'>
        <span className='faded'>{type}</span>
      </h4>
      <div className='panel-content'>
        <table>
          {items.map((l, index) => (
            <tbody key={`${l.ticker}${index}`}>
              <tr onClick={() => handleShowModal(l.ticker)} title={l.company}>
                <td>
                  <b>{l.ticker}</b>
                  <div className='small'>
                    ${l.close?.toFixed(2)}
                    <small className='faded'>Close</small>
                  </div>
                  <div className='small'>
                    ${l.previous?.toFixed(2)}
                    <small className='faded'>Previous</small>
                  </div>
                </td>

                <td>
                  {type === aggregateTypes[0].type && (
                    <>
                      <span className='success'>
                        +{formatNumber(l.d1shares, 0)}
                      </span>
                      <div className='small'>
                        Agg Weight: {l.weight}%{' '}
                        {l.changeinweight ? (
                          <span
                            className='weight-indicator'
                            className={
                              l.changeinweight > 0 && l.changeinweight !== 0
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <i
                              className={
                                l.changeinweight > 0
                                  ? 'ion-arrow-up-a'
                                  : 'ion-arrow-down-a'
                              }
                            ></i>
                            {l.changeinweight > 0
                              ? l.changeinweight
                              : l.changeinweight.toString().substring(1)}
                            %
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='small'>
                        {formatCurrency(l.bought, 'USD', 0)}
                      </div>
                    </>
                  )}

                  {type === aggregateTypes[1].type && (
                    <>
                      <span className='success'>
                        {formatCurrency(l.bought, 'USD', 0)}
                      </span>
                      <div className='small'>
                        Agg Weight: {l.weight}%{' '}
                        {l.changeinweight ? (
                          <span
                            className='weight-indicator'
                            className={
                              l.changeinweight > 0 && l.changeinweight !== 0
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <i
                              className={
                                l.changeinweight > 0
                                  ? 'ion-arrow-up-a'
                                  : 'ion-arrow-down-a'
                              }
                            ></i>
                            {l.changeinweight > 0
                              ? l.changeinweight
                              : l.changeinweight.toString().substring(1)}
                            %
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='small'>
                        Shares Bought: {formatNumber(l.shares, 0)}
                      </div>
                    </>
                  )}

                  {type === aggregateTypes[2].type && (
                    <>
                      <span className='danger'>
                        {formatNumber(l.d1shares, 0)}
                      </span>
                      <div className='small'>
                        Agg Weight: {l.weight}%{' '}
                        {l.changeinweight ? (
                          <span
                            className='weight-indicator'
                            className={
                              l.changeinweight > 0 && l.changeinweight !== 0
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <i
                              className={
                                l.changeinweight > 0
                                  ? 'ion-arrow-up-a'
                                  : 'ion-arrow-down-a'
                              }
                            ></i>
                            {l.changeinweight > 0
                              ? l.changeinweight
                              : l.changeinweight.toString().substring(1)}
                            %
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='small'>
                        {formatCurrency(l.bought, 'USD', 0)}
                      </div>
                    </>
                  )}

                  {type === aggregateTypes[3].type && (
                    <>
                      <span className='danger'>
                        {formatCurrency(l.bought, 'USD', 0)}
                      </span>
                      <div className='small'>
                        Agg Weight: {l.weight}%{' '}
                        {l.changeinweight ? (
                          <span
                            className='weight-indicator'
                            className={
                              l.changeinweight > 0 && l.changeinweight !== 0
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <i
                              className={
                                l.changeinweight > 0
                                  ? 'ion-arrow-up-a'
                                  : 'ion-arrow-down-a'
                              }
                            ></i>
                            {l.changeinweight > 0
                              ? l.changeinweight
                              : l.changeinweight.toString().substring(1)}
                            %
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='small'>
                        Shares Bought: {formatNumber(l.shares, 0)}
                      </div>
                    </>
                  )}

                  {type === aggregateTypes[4].type && (
                    <>
                      <span className='success'>
                        +{formatCurrency(l.d7shares, 'USD', 0)}
                      </span>
                      <div className='small'>
                        Shares Added: {l.weight}%{' '}
                        {l.changeinweight ? (
                          <span
                            className='weight-indicator'
                            className={
                              l.changeinweight > 0 && l.changeinweight !== 0
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <i
                              className={
                                l.changeinweight > 0
                                  ? 'ion-arrow-up-a'
                                  : 'ion-arrow-down-a'
                              }
                            ></i>
                            {l.changeinweight > 0
                              ? l.changeinweight
                              : l.changeinweight.toString().substring(1)}
                            %
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='small'>
                        Shares Bought: {formatNumber(l.shares, 0)}
                      </div>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        {items.length === 0 && (
          <div className='text-center'>
            <p>No change.</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default PanelAggregates;
