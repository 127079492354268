export const fundGroup = [
  { name: 'ARK Invest', link: 'ark-invest', color: '#a64dff' },
  { name: 'BlackRock', link: 'black-rock', color: '#007fda' },
  /* { name: 'Roundhill', link: 'round-hill', color: '#007fda' }, */
  { name: 'Global X', link: 'global-x', color: '#ff5400' },
];

export const fundGroups = [
  {
    id: 1,
    fundGroup: 'ARK Invest',
    color: '#a64dff',
    data: [
      {
        fund: 'ARKK',
        name: 'Innovation',
        description:
          'Built on the cornerstone investments that present the best risk-reward opportunities from ARK’s innovation-based themes.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'ARKQ',
        name: 'Autonomous + Robotics',
        description:
          'Exposure to autonomous vehicles, energy storage, robotics and automation, 3D printing, and space exploration.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'ARKW',
        name: 'Internet',
        description:
          'Exposure to the Next Generation Internet including artificial intelligence, big data, cloud computing, cybersecurity, and blockchain technology.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'ARKG',
        name: 'Genomic',
        description:
          'Exposure to CRISPR, gene editing, therapeutics, agricultural biology and health care innovation.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'ARKF',
        name: 'Fintech',
        description:
          'Exposure to fintech innovations including mobile payments, digital wallets, peer-to-peer lending, blockchain technology, and risk transformation.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'ARKX',
        name: 'Space',
        description:
          'Companies that lead, enable, or benefit from technologically enabled products and/or services that occur beyond the surface of the Earth.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'PRNT',
        name: '3D Printing',
        description:
          '3D printing is one of the highest growth potential industries in the economy and is set to transform the manufacturing landscape.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'IZRL',
        name: 'Israel Innovation Tech',
        description:
          'Gain exposure to Israeli companies across industries such as health care, life sciences, manufacturing, and telecommunications.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
    ],
  },
  {
    id: 2,
    fundGroup: 'BlackRock',
    color: '#007fda',
    data: [
      {
        fund: 'BTEK',
        name: 'Future Tech',
        description:
          'Seeks to maximize total return by investing in companies developing innovative and emerging technologies in the technology sector.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'BMED',
        name: 'Future Health',
        description:
          'Seeks to maximize total return by investing in innovative and emerging companies in the healthcare sector.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      /* {
        fund: 'BFTR',
        name: 'Future Innovators',
        description:
          'Seeks long term capital appreciation by investing in innovative companies across sectors in the small/mid cap growth category.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      }, */
      {
        fund: 'ICLN',
        name: 'Global Clean Energy',
        description:
          'Companies that produce energy from solar, wind, and other renewable sources.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'IDRV',
        name: 'Self-Driving EV',
        description:
          'Innovation in and around electric vehicles, battery technologies and autonomous driving technologies.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'IGV',
        name: 'Expanded Tech-Software',
        description:
          'Software companies in the technology, interactive home entertainment, interactive media and services industries',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'IDNA',
        name: 'Genomics, Immunology, Health',
        description:
          'Innovation in genomics, immunology, and bioengineering focused on long-term growth.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'IRBO',
        name: 'Robotics and AI',
        description:
          'Companies at the forefront of robotics and artificial intelligence innovation.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'SOXX',
        name: 'PHLX Semiconductor',
        description:
          'U.S. companies that design, manufacture, and distribute semiconductors.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
    ],
  },
  /* {
    id: 2,
    fundGroup: 'Roundhill',
    color: '#007fda',
    data: [
      {
        fund: 'METV',
        name: 'Metaverse',
        description:
          'The Roundhill Ball Metaverse ETF ("METV ETF") is designed to offer investors exposure to the Metaverse by providing investment results that closely correspond to the performance of the Ball Metaverse Index ("BALLMETA Index").',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'BETZ',
        name: 'Sports Betting & iGaming',
        description:
          'The Roundhill Sports Betting & iGaming ETF (“BETZ ETF”) is designed to offer investors exposure to sports betting and iGaming industries.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'CHAT',
        name: 'AI & Technologies',
        description:
          'The Roundhill Generative AI & Technology ETF (“CHAT ETF”) is an actively-managed fund designed to provide exposure to companies involved in the investment theme of artificial intelligence (“AI”), generative artificial intelligence, and related technologies.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'DEEP',
        name: 'Deep Value',
        description:
          'The Roundhill Acquirers Deep Value ETF (“DEEP ETF”) is designed to offer retail and institutional investors exposure to deeply undervalued stocks.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'NERD',
        name: 'Video Games',
        description:
          'The Roundhill Video Games ETF (“NERD ETF”) is designed to offer investors exposure to the video game sector by providing investment results that closely correspond to the performance of the Nasdaq CTA Global Video Games Software Index.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'BIGB',
        name: 'Banking',
        description:
          'The Roundhill BIG Bank ETF (“BIGB ETF”) pursues its investment objective of capital appreciation by seeking exposure to between five and ten companies (“Underlying Issuers”) in the Banks Industry and/or Capital Markets Industry (“Bank and Capital Markets Industries”).',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'BIGT',
        name: 'Technology',
        description:
          'The Roundhill BIG Tech ETF (“BIGT ETF”) pursues its investment objective of capital appreciation by seeking exposure to between five and ten companies (“Underlying Issuers”) in the Technology Hardware Industry; E-Commerce Discretionary Industry; Internet Media & Services Industry; and Software Industry (collectively, the “Technology industry”).',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'MEME',
        name: 'Meme Stocks',
        description:
          'The Roundhill MEME ETF (“MEME ETF”) is designed to offer investors exposure to “meme stocks” and is the first ETF globally explicitly designed to track the performance of meme stocks.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'BYTE',
        name: 'Digital Infrastructure',
        description:
          'The Roundhill IO Digital Infrastructure ETF (“BYTE ETF”) is designed to offer investors exposure to digital infrastructure by seeking to provide investment results that track, before fees and expenses, the performance of the IO Digital Infrastructure Index (“BYTE Index”).',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'WEED',
        name: 'Cannabis',
        description:
          'The Roundhill Cannabis ETF ("WEED ETF") is designed to offer investors exposure to the cannabis sector.',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
    ],
  },*/
  {
    id: 3,
    fundGroup: 'Global X',
    color: '#ff5400',
    data: [
      {
        fund: 'BOTZ',
        name: 'Robotics & AI',
        description:
          'Companies that potentially stand to benefit from increased adoption and utilization of robotics and artificial intelligence (AI), including those involved with industrial robotics and automation, non-industrial robots, and autonomous vehicles.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'CLOU',
        name: 'Cloud Computing',
        description:
          'Companies positioned to benefit from the increased adoption of cloud computing technology, including companies whose principal business is in offering computing Software-as-a-Service (SaaS), Platform-as-a-Service (PaaS), Infrastructure-as-a-Service (IaaS), managed server storage space and data center real estate investment trusts, and/or cloud and edge computing infrastructure and hardware.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'CTEC',
        name: 'Cleantech',
        description:
          'Companies that stand to benefit from the increased adoption of technologies that inhibit or reduce negative environmental impacts..',
        type: 'Active',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'BKCH',
        name: 'Blockchain',
        description:
          'Companies positioned to benefit from the increased adoption of blockchain technology, including companies in digital asset mining, blockchain & digital asset transactions, blockchain applications, blockchain & digital asset hardware, and blockchain & digital asset integration.Companies that potentially stand to benefit from increased adoption and utilization of robotics and artificial intelligence (AI), including those involved with industrial robotics and automation, non-industrial robots, and autonomous vehicles.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'DRIV',
        name: 'Autonomous & EV',
        description:
          'Companies involved in the development of autonomous vehicle technology, electric vehicles (“EVs”), and EV components and materials.  This includes companies involved in the development of autonomous vehicle software and hardware, as well as companies that produce EVs, EV components such as lithium batteries, and critical EV materials such as lithium and cobalt.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'EDOC',
        name: 'Telemedicine & Digital Health',
        description:
          'Companies involved in Telemedicine, Health Care Analytics, Connected Health Care Devices, and Administrative Digitization  positioned to benefit from further advances in the field of telemedicine and digital health.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'FINX',
        name: 'Fintech',
        description:
          'Companies on the leading edge of the emerging financial technology sector, which encompasses a range of innovations helping to transform established industries like insurance, investing, fundraising, and third-party lending through unique mobile and digital solutions.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'GNOM',
        name: 'Genomics & Biotech',
        description:
          'Companies that potentially stand to benefit from further advances in the field of genomic science, such as companies involved in gene editing, genomic sequencing, genetic medicine/therapy, computational genomics, and biotechnology.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'HERO',
        name: 'Video Games & Esports',
        description:
          'Companies that develop or publish video games, facilitate the streaming and distribution of video gaming or esports content, own and operate within competitive esports leagues, or produce hardware used in video games and esports, including augmented and virtual reality.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'LIT',
        name: 'Lithium & Battery Tech',
        description:
          'Full lithium cycle, from mining and refining the metal, through battery production.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
      {
        fund: 'POTX',
        name: 'Cannabis',
        description:
          'Companies involved in the legal production, growth and distribution of cannabis and industrial hemp, as well as those involved in providing financial services to the cannabis industry, pharmaceutical applications of cannabis, cannabidiol (i.e., CBD), or other related uses including but not limited to extracts, derivatives or synthetic versions.',
        type: 'Passive',
        list: [],
        chartLabels: [],
        chartData: [],
      },
    ],
  },
];

export const topRankingsTabs = [
  { name: 'Current', value: 'data' },
  { name: '-5 Day', value: 'five' },
  { name: '-14 Day', value: 'fourteen' },
  { name: '-30 Day', value: 'thirty' },
  { name: '-60 Day', value: 'sixty' },
];

export const stocklistHeaders = [
  {
    id: 'company',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'etf',
    name: 'ETF',
    title: 'ETF',
  },
  {
    id: 'bought',
    name: 'Bought or Sold',
    title: 'Market Value of Shares Bought/Sold',
  },
  {
    id: 'd1shares',
    name: '1D Shares Chg',
    title: '1 Day Change in Shares Held',
  },
  {
    id: 'd1delta',
    name: '1D Shares Chg %',
    title: '1 Day Change in Shares Held %',
  },
  {
    id: 'd7shares',
    name: '7D Shares Chg',
    title: '7 Day Change in Shares Held',
  },
  {
    id: 'd7delta',
    name: '7D Shares Chg %',
    title: '7 Day Change in Shares Held',
  },
  {
    id: 'd14shares',
    name: '14D Shares Chg',
    title: '14 Day Change in Shares Held',
  },
  {
    id: 'd14delta',
    name: '14D Shares Chg %',
    title: '14 Day Change in Shares Held',
  },
  {
    id: 'd30shares',
    name: '30D Shares Chg',
    title: '30 Day Change in Shares Held',
  },
  {
    id: 'd30delta',
    name: '30D Shares Chg %',
    title: '30 Day Change in Shares Held',
  },
  {
    id: 'close',
    name: 'Close',
    title: 'Closing Price',
  },
  {
    id: 'previous',
    name: 'Prev Close',
    title: 'Previous Closing Price',
  },
  {
    id: 'weight',
    name: 'Weight',
    title: 'Aggregate Weight Across ETFs',
  },
  {
    id: 'previousweight',
    name: 'Prev Weight',
    title: 'Aggregate Change in Weight',
  },
  {
    id: 'changeinweight',
    name: 'Weight Chg',
    title: 'Change in Weight',
  },
  {
    id: 'marketshare',
    name: 'Market Share',
    title: 'Stock Price * Number of Shares',
  },
  {
    id: 'shares',
    name: 'Total Shares',
    title: 'Total Shares Held Across ETFS',
  },
  {
    id: 'float',
    name: 'Float',
    title: 'Float (shares available to trade in the market)',
  },
  {
    id: 'floatperc',
    name: 'Float %',
    title: 'Float %',
  },
  {
    id: 'wavgbuyprice',
    name: 'Avg Buy Price',
    title: 'Weighted Avg Buy Price',
  },
  {
    id: 'trdir',
    name: 'M.O. Direction',
    title: 'Market Order Direction',
  },
  {
    id: 'trshares',
    name: 'M.O. Shares',
    title: 'Absolute shares in  Market Order Buy/Sell',
  },
  {
    id: 'trmarkval',
    name: 'M.O. Value',
    title: 'Value of Market Order Buy/Sell',
  },
];

export const aggregateTypes = [
  {
    type: 'Top 20 Absolute Shares Added',
  },
  {
    type: 'Top 20 Buys',
  },
  {
    type: 'Top 20 Absolute Shares Removed',
  },
  {
    type: 'Top 20 Sells',
  },
  {
    type: 'Top 20 Shares Added (7 Day)',
  },
];

export const addsAndDropsTypes = [
  {
    type: 'New Stocks Added To ETF',
  },
  {
    type: 'Stocks Dropped From ETF',
  },
];

export const addsAndDropsPassiveTypes = [
  {
    type: 'New Passive Stocks Added To ETF',
  },
  {
    type: 'Passive Stocks Dropped From ETF',
  },
];

export const dataLengths = ['60 Days', 'All Data'];

export const getSelectedFund = () => {
  const fundGroupFromLS = localStorage.getItem('fundGroup');
  if (fundGroupFromLS) {
    return fundGroup.find((f) => f.name === fundGroupFromLS);
  } else {
    return null;
  }
};

export const getSelectedFundGroup = () => {
  return localStorage.getItem('fundGroup');
};

export const setSelectedFundGroup = (fund) => {
  localStorage.setItem('fundGroup', fund);
};

export const createEmptyMyTickers = (currentFundGroup, stocklist) => {
  const emptyFundGroup = {
    fund: currentFundGroup,
    tickers: stocklist.map((s) => s.ticker),
  };
  //localStorage.setItem('myTickerGroups', JSON.stringify([emptyFundGroup]));
  return emptyFundGroup;
};

export const getMyTickersFromLS = (currentFundGroup, stocklist) => {
  let myTicks = JSON.parse(localStorage.getItem('myTickerGroups'));
  if (myTicks && myTicks.length > 0) {
    return myTicks.find((m) => m.fund === currentFundGroup);
  } else {
    return createEmptyMyTickers(currentFundGroup, stocklist);
  }
};

export const saveMyTickersToLS = (myTickers, currentFund) => {
  /* "[{"fund":"ARK","tickers":{"fund":"ARK","tickers":["AAPL","ACCD"]}}]" */
  const myTickersFromLS = JSON.parse(localStorage.getItem('myTickerGroups'));
  let targetedFund = {};
  if (myTickersFromLS?.length) {
    targetedFund = myTickersFromLS.find((m) => m.fund === currentFund);
    if (targetedFund) {
      targetedFund.tickers = myTickers;
      myTickersFromLS.forEach((m) => {
        if (m.fund === targetedFund.fund) {
          m = targetedFund;
        }
        return m;
      });
      localStorage.setItem('myTickerGroups', JSON.stringify(myTickersFromLS));
    } else {
      myTickersFromLS.push(myTickers);
      localStorage.setItem('myTickerGroups', JSON.stringify(myTickersFromLS));
    }
  } else {
    localStorage.setItem(
      'myTickerGroups',
      JSON.stringify([{ fund: currentFund, tickers: myTickers }])
    );
  }
};

export const getMyHeaders = () => {
  let myHeads = JSON.parse(localStorage.getItem('myHeaders'));
  if (myHeads && myHeads.length > 0) {
    return myHeads;
  } else {
    return stocklistHeaders.map((s) => s.id);
  }
};

export const dashboardTabs = ['Top', 'Charts', 'List'];

export const dashboardHeaders = [
  {
    id: 'company',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'shares',
    name: 'Shares Held',
    title: 'Shares Held',
  },
  {
    id: 'weight',
    name: 'Weight',
    title: 'Weight',
  },
  {
    id: 'sharesmarketvalue',
    name: 'Market Share',
    title: 'Market Share',
  },
  {
    id: 'dailychange',
    name: 'Daily Change',
    title: 'Daily Change',
  },
  {
    id: 'dailycapitalinjection',
    name: 'Daily Buy/Sell',
    title: 'Daily Buy/Sell',
  },
  {
    id: 'weeklycapitalinjection',
    name: 'Weekly Buy/Sell',
    title: 'Weekly Buy/Sell',
  },
  {
    id: 'priceclosed',
    name: 'Close Price',
    title: 'Close Price',
  },
  {
    id: 'previousclose',
    name: 'Previous',
    title: 'Previous Close Price',
  },
  {
    id: 'tradenotedir',
    name: 'Direction',
    title: 'Market Order Direction',
  },
  {
    id: 'tradenoteshares',
    name: 'Order Shares',
    title: 'Absolute shares in  Market Order Buy/Sell',
  },
  {
    id: 'tradenotevalue',
    name: 'Order Value',
    title: 'Value of Market Order Buy/Sell',
  },
  {
    id: 'float',
    name: 'Float',
    title: 'Float (shares available to trade in the market)',
  },
];

export const optionsTabs = [
  'Unusual Activity',
  'U.A. In Watchlist',
  'Most Active',
  'M.A. In Watchlist',
];

export const optionsHeaders = [
  {
    id: 'Symbol',
    name: 'Ticker',
    title: 'Ticker',
  },
  /*   {
    id: 'Price',
    name: 'Price',
    title: 'The last price of the underlying stock',
  }, */
  {
    id: 'Type',
    name: 'Type',
    title: 'A Call is right to buy a stock and a Put is right to sell a stock.',
  },
  {
    id: 'Strike',
    name: 'Strike',
    title:
      'The price you can buy (Call) or sell (Put) the stock if option is exercised.',
  },
  {
    id: 'Exp Date',
    name: 'Exp Date',
    title: 'The option expiration date.',
  },
  {
    id: 'DTE',
    name: 'DTE',
    title: 'The number of days until the option expires.',
  },
  {
    id: 'Volume',
    name: 'Volume',
    title: 'Total # of contracts traded for the day.',
  },
  {
    id: 'Open Int',
    name: 'Open Int',
    title:
      'Total # of open option contracts that have been traded but not yet liquidated.',
  },
  {
    id: 'Vol/OI',
    name: 'Vol/OI',
    title:
      "Today's volume / open interest. A higher ratio indicates unusual activity for the option.",
  },
  {
    id: 'IV',
    name: 'IV',
    title:
      'Implied Volatility is the estimated volatility of the underlying stock over the period of the option.',
  },
  {
    id: 'Bid',
    name: 'Bid',
    title: 'The bid price.',
  },
  {
    id: 'Midpoint',
    name: 'Midpoint',
    title: 'The midpoint between the bid and ask price.',
  },
  {
    id: 'Ask',
    name: 'Ask',
    title: 'The ask price.',
  },
  {
    id: 'Last',
    name: 'Last',
    title: 'The last trade price.',
  },
  {
    id: 'Time',
    name: 'Time',
    title: 'Time purchased.',
  },
];

export const optionsActiveHeaders = [
  {
    id: 'Symbol',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'IV Rank',
    name: 'IV Rank',
    title:
      'The average implied volatility relative to the highest and lowest values over the past 1-year. If IV Rank is 100% this means the IV is at its highest level over the past 1-year.',
  },
  {
    id: 'Options Vol',
    name: 'Volume',
    title: 'Total Options Volume',
  },
  {
    id: '% Put',
    name: '% Put',
    title: 'Percentage of Total Options Volume that are Puts',
  },
  {
    id: '% Call',
    name: '% Call',
    title: 'Percentage of Total Options Volume that are Calls',
  },
  {
    id: 'Put/Call Vol',
    name: 'Put/Call Vol',
    title:
      'The total Put/Call volume ratio for all option contracts (across all expiration dates) traded during the current session.',
  },
  {
    id: 'Name',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Time',
    name: 'Time',
    title: 'Time',
  },
];

export const topETFsHeaders = [
  {
    id: 'Symbol',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Name',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Wtd Alpha',
    name: 'Wtd Alpha',
    title:
      'How much the ETF price has risen or fallen over a one-year period with a higher weighting for recent price activity.',
  },
  {
    id: 'YTD %Chg',
    name: 'YTD %Chg',
    title:
      'Year-to-Date Percent Change: The difference between the current price and the settlement price from January 1 of the current year expressed as a percentage.',
  },
];

export const stockTabs = [
  'Most Traded',
  'M.T. in Watchlist',
  'Volume Advances',
  'V.A. in Watchlist',
];

export const stockVolumeHeaders = [
  {
    id: 'Symbol',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Name',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Volume',
    name: 'Volume',
    title: 'Total # of Shares/Contracts traded for the day.',
  },
  {
    id: 'Time',
    name: 'Time',
    title: 'Time reported',
  },
];

export const stockVolumeAdvancesHeaders = [
  {
    id: 'Symbol',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Name',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Volume',
    name: 'Volume',
    title: 'Total # of Shares/Contracts traded for the day.',
  },
  {
    id: '% Volume',
    name: 'Volume Change',
    title:
      'Percentage difference between the current volume and the 20-day average volume.',
  },
  {
    id: 'Time',
    name: 'Time',
    title: 'Time Reported',
  },
];

export const chartTabs = [
  {
    type: 'shares',
    label: 'Shares Held',
  },
  {
    type: 'marketvalue',
    label: 'Market Value',
  },
  {
    type: 'weight',
    label: 'Weight',
  },
  {
    type: 'dailycapitalinjection',
    label: 'Daily Buys $',
  },
  {
    type: 'weeklycapitalinjection',
    label: 'Weekly Buys $',
  },
  /* {
    type: 'float',
    label: 'Float',
  },
  {
    type: 'portfolioweightdelta',
    label: 'Weight Change',
  }, */
];

export const watchlistTabs = ['My List', 'All', 'Charts', 'Popular'];
export const watchlistPopularHeaders = [
  { id: 'watch', title: 'Watch' },
  { id: 'totalwatchers', title: 'Rank' },
  { id: 'ticker', title: 'Ticker' },
  { id: 'close', title: 'Close' },
  { id: 'previous', title: 'Prev Close' },
  { id: 'totalwatchers', title: 'Total Watchers' },
];

export const insiderTabs = ['Buying/Selling', 'In Watchlist'];
export const insiderHeaders = [
  /* {
    id: 'Transaction Filing',
    name: 'Filing Type',
    title: '',
  }, */

  {
    id: 'Trade Date',
    name: 'Trade Date',
    title: '',
  },
  {
    id: 'Ticker',
    name: 'Ticker',
    title: '',
  },

  {
    id: 'Trade Type',
    name: 'Trade Type',
    title: '',
  },
  {
    id: 'Price',
    name: 'Price',
    title: '',
  },
  {
    id: 'Qty',
    name: 'Qty',
    title: '',
  },
  {
    id: 'Owned',
    name: 'Owned',
    title: '',
  },
  /* {
    id: 'ΔOwn',
    name: 'Change',
    title: '',
  }, */
  {
    id: 'Value',
    name: 'Value',
    title: '',
  },
  {
    id: 'Company Name',
    name: 'Company',
    title: '',
  },
  {
    id: 'Insider Name',
    name: 'Insider',
    title: '',
  },
  {
    id: 'Title',
    name: 'Title',
    title: '',
  },
  {
    id: 'Filing Date',
    name: 'Filing Date',
    title: '',
  },
];

export const investorTabs = ['All Data', 'In Fund', 'In Watchlist'];

export const uptrendHeaders = [
  {
    id: 'No.',
    name: 'Rank',
    title: 'Rank',
  },
  {
    id: 'Ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Perf Week',
    name: 'Perf Wk',
    title: 'Performance Week',
  },
  {
    id: 'Perf Month',
    name: 'Perf Mo',
    title: 'Performance Month',
  },
  {
    id: 'Perf Quart',
    name: 'Perf Qtr',
    title: 'Performance Quarter',
  },
  {
    id: 'Perf Half',
    name: 'Perf Half',
    title: 'Performance Half Year',
  },
  {
    id: 'Perf Year',
    name: 'Perf Yr',
    title: 'Performance Year',
  },
  {
    id: 'Perf YTD',
    name: 'YTD',
    title: 'Year to Date Performance',
  },
  {
    id: 'Volatility W',
    name: 'Volatility Wk',
    title: 'Volatility Week',
  },
  {
    id: 'Volatility M',
    name: 'Volatility Mo',
    title: 'Volatility Month',
  },
  {
    id: 'Recom',
    name: 'Recom',
    title: 'Analyst Recommendation',
  },
  {
    id: 'Avg Volume',
    name: 'Avg Vol',
    title: 'Avg Volume',
  },
  {
    id: 'Rel Volume',
    name: 'Rel Vol',
    title: 'Rel Volume',
  },
  /* {
    id: 'Volume',
    name: 'Volume',
    title: 'Volume',
  }, */
];
export const squeezeHeaders = [
  {
    id: 'No.',
    name: 'Rank',
    title: 'Rank',
  },
  {
    id: 'Ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Market Cap',
    name: 'Market Cap',
    title: 'Market Cap',
  },
  {
    id: 'Outstanding',
    name: 'Outstanding',
    title: 'Outstanding',
  },
  {
    id: 'Float',
    name: 'Float',
    title: 'Float (shares available to trade in the market)',
  },
  {
    id: 'Insider Own',
    name: 'Insider Own',
    title: 'Insider Own',
  },
  {
    id: 'Insider Trans',
    name: 'Insider Trans',
    title: 'Insider Trans',
  },
  {
    id: 'Inst Own',
    name: 'Inst Own',
    title: 'Institution Own',
  },
  {
    id: 'Inst Trans',
    name: 'Inst Trans',
    title: 'Institution Trans',
  },
  {
    id: 'Float Short',
    name: 'Float Short',
    title: 'Float Short (the percentage of the float that is borrowed)',
  },
  {
    id: 'Short Ratio',
    name: 'Short Ratio',
    title:
      'Short Ratio (number of shares short in a stock by average daily trading volume)',
  },
  {
    id: 'Avg Volume',
    name: 'Avg Volume',
    title: 'Avg Volume',
  },
  {
    id: 'Volume',
    name: 'Volume',
    title: 'Volume',
  },
];

export const senateHeaders = [
  /*  {
    id: 'Id',
    name: 'Id',
    title: 'Id',
  }, */
  {
    id: 'Ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Reporter',
    name: 'Reporter',
    title: 'Reporter',
  },
  {
    id: 'Type',
    name: 'Type',
    title: 'Type',
  },
  {
    id: 'Amount',
    name: 'Amount',
    title: 'Amount',
  },
  {
    id: 'Transaction Date',
    name: 'Transaction Date',
    title: 'Transaction Date',
  },
  {
    id: 'Filed At',
    name: 'Filing Date',
    title: 'Filing Date',
  },
  {
    id: 'Notes',
    name: 'Notes',
    title: 'Notes',
  },
];

export const gsHeaders = [
  {
    id: 'symbol',
    name: 'Symbol',
    title: 'Symbol',
  },
  {
    id: 'ticker',
    name: 'Ticker',
    title: 'Stock Ticker',
  },
  {
    id: 'aum',
    name: 'AUM',
    title: 'Assets Under Management',
  },
  {
    id: 'outstd',
    name: 'Outstanding',
    title: 'Outstanding',
  },
  /* {
    id: 'pershare',
    name: 'Outstanding/Share',
    title: 'Outstanding Per Share',
  }, */
  {
    id: 'holdings',
    name: 'Crypto Held',
    title: 'Crypto Held',
  },
  {
    id: 'cryptoclose',
    name: 'Crypto Close',
    title: 'Crypto Close Price',
  },
  /* {
    id: 'shareprice',
    name: 'Price/Share',
    title: 'Price Per Share',
  }, */
  {
    id: 'close',
    name: 'Stock Close',
    title: 'Stock Close Price',
  },
  {
    id: 'premium',
    name: 'Premium',
    title: 'Premium',
  },
  {
    id: 'diff1d',
    name: '1D Diff',
    title: '1 Day Diff',
  },
  {
    id: 'chg1d',
    name: '1D Chg',
    title: '1 Day Change',
  },
  {
    id: 'diff7d',
    name: '7D Diff',
    title: '7 Day Diff',
  },
  {
    id: 'chg1d',
    name: '7D Chg',
    title: '7 Day Change',
  },
  {
    id: 'diff30d',
    name: '30D Diff',
    title: '30 Day Diff',
  },
  {
    id: 'chg30d',
    name: '30D Chg',
    title: '30 Day Change',
  },
  {
    id: 'diff60d',
    name: '60D Diff',
    title: '60 Day Diff',
  },
  {
    id: 'chg60d',
    name: '60D Chg',
    title: '60 Day Change',
  },
  /* {
    id: 'ts',
    name: 'Timestamp',
    title: 'Timestamp',
  }, */
];

/* export const gsHeaders = [
  {
    id: 'ticker',
    name: 'Symbol',
    title: 'Symbol',
  },
  {
    id: 'aum',
    name: 'AUM',
    title: 'Assets Under Management',
  },
  {
    id: 'outstd',
    name: 'Outstanding',
    title: 'Outstanding',
  },
  {
    id: 'pershare',
    name: 'Outstanding/Share',
    title: 'Outstanding Per Share',
  },
  {
    id: 'holdings',
    name: 'Crypto Held',
    title: 'Crypto Held',
  },
  {
    id: 'price',
    name: 'Crypto Price at Close',
    title: 'Crypto Price at Close',
  },
  {
    id: 'shareprice',
    name: 'Price/Share',
    title: 'Price Per Share',
  },
  {
    id: 'close',
    name: 'Close',
    title: 'Stock Closing Price',
  },
  {
    id: 'premium',
    name: 'Premium',
    title: 'Premium',
  },
  {
    id: 'diff1d',
    name: '1D Change',
    title: '1D Change',
  },
]; */

export const patternHeaders = [
  {
    id: 'patternname',
    name: 'Name',
    title: 'Name',
  },
  {
    id: 'patternname',
    name: 'Type',
    title: 'Type',
  },
  {
    id: 'entry',
    name: 'Entry',
    title: 'Entry',
  },
  {
    id: 'start_price',
    name: 'Start Price',
    title: 'Start Price',
  },
  {
    id: 'end_price',
    name: 'End Price',
    title: 'End Price',
  },
  {
    id: 'stoploss',
    name: 'Stoploss',
    title: 'Stoploss',
  },
  {
    id: 'start_time',
    name: 'Start Time',
    title: 'Start Time',
  },
  {
    id: 'end_time',
    name: 'End Time',
    title: 'End Time',
  },
  {
    id: 'status',
    name: 'Status',
    title: 'Status',
  },
];

export const canslimHeaders = [
  {
    id: 'No.',
    name: 'Rank',
    title: 'Rank',
  },
  {
    id: 'Company',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Sector',
    name: 'Sector',
    title: 'Market Sector',
  },
  {
    id: 'Market Cap',
    name: 'Market Cap',
    title: 'Market Cap',
  },
  {
    id: 'P/E',
    name: 'P/E',
    title: 'Price/Earnings',
  },
  {
    id: 'Volume',
    name: 'Volume',
    title: 'Volume',
  },
];

export const investorHeaders = [
  {
    id: 'Investor',
    name: 'Investor',
    title: 'Investor',
  },
  {
    id: 'Company',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Symbol',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Type',
    name: 'Type',
    title: 'Type',
  },
  {
    id: 'Holdings',
    name: 'Shares',
    title: 'Shares',
  },
  {
    id: 'Value',
    name: 'Market Value',
    title: 'Market Value',
  },
  {
    id: 'Avg Paid',
    name: 'Avg Price Paid',
    title: 'Estimated Avg Price Paid',
  },
  {
    id: 'Weight',
    name: 'Weight',
    title: 'Portfolio Weight',
  },
  {
    id: 'Prev',
    name: 'Prev Weight',
    title: 'Previous Portfolio Weight',
  },
  {
    id: 'Source Date',
    name: 'Source Date',
    title: 'Source Date',
  },
  {
    id: 'Date Reported',
    name: 'Date Reported',
    title: 'Date Reported',
  },
];

export const marketOrderHeaders = [
  /* {
    id: 'Date',
    name: 'Date',
    title: 'Date',
  }, */
  {
    id: 'Direction',
    name: 'Direction',
    title: 'Direction',
  },
  {
    id: 'Ticker',
    name: 'Ticker',
    title: 'Ticker',
  },
  {
    id: 'Company',
    name: 'Company',
    title: 'Company',
  },
  {
    id: 'Shares',
    name: 'Shares',
    title: 'Shares',
  },
  {
    id: '% of ETF',
    name: 'Weight in ETF',
    title: 'Weight in ETF',
  },
  {
    id: 'Close',
    name: 'Close',
    title: 'Close',
  },
  {
    id: 'Market Value',
    name: 'Close * Shares',
    title: 'Estimated Market Value',
  },
  {
    id: 'Fund',
    name: 'Fund',
    title: 'Fund',
  },
];

export const allOverlapHeaders = [
  {
    id: 'ticker',
    name: 'Ticker',
    title: '',
  },
  {
    id: 'overlap',
    name: 'Overlap',
    title: 'ETFs include ticker',
    type: 'array',
  },
  {
    id: 'abuyp',
    name: '% ARK Buy',
    title: '',
    type: 'percent',
  },
  {
    id: 'bbuyp',
    name: '% BlackRock Buy',
    title: '',
    type: 'percent',
  },
  {
    id: 'gbuyp',
    name: '% Global X Buy',
    title: '',
    type: 'percent',
  },
  {
    id: 'asellp',
    name: '% Global X Sold',
    title: '',
    type: 'percent',
  },
  {
    id: 'bsellp',
    name: '% BlackRock Sold',
    title: '',
    type: 'percent',
  },
  {
    id: 'gsellp',
    name: '% Global X Sold',
    title: '',
    type: 'percent',
  },
  /* {
    id: 'arkp',
    name: '% ARK',
    title: '',
    type: 'percent',
  },
  {
    id: 'brp',
    name: '% BlackRock',
    title: '',
    type: 'percent',
  },
  {
    id: 'gxp',
    name: '% Global X',
    title: '',
    type: 'percent',
  },
  {
    id: 'arks',
    name: 'ARK Shares',
    title: '',
    type: 'number',
  },
  {
    id: 'brs',
    name: 'BlackRock Shares',
    title: '',
    type: 'number',
  },
  {
    id: 'gxs',
    name: 'Global X Shares',
    title: '',
    type: 'number',
  },
  {
    id: 'arkmv',
    name: 'ARK M.V.',
    title: 'Ark Market Value',
    type: 'number',
  },
  {
    id: 'brmv',
    name: 'BlackRock M.V.',
    title: 'BlackRock Market Value',
    type: 'number',
  },
  {
    id: 'gxmv',
    name: 'Global X M.V.',
    title: 'Global X Market Value',
    type: 'number',
  },
  {
    id: 'ts',
    name: 'Total Shares',
    title: '',
    type: 'number',
  },
  {
    id: 'arkbs',
    name: 'ARK Buys',
    title: '',
    type: 'currency',
  },
  {
    id: 'brbs',
    name: 'BlackRock Buys',
    title: '',
    type: 'currency',
  },
  {
    id: 'gxbs',
    name: 'Global X Buys',
    title: '',
    type: 'currency',
  }, */
];

export const tokenHeaders = [
  {
    id: 'symbol',
    name: 'Symbol',
    title: 'Symbol',
  },
  {
    id: 'name',
    name: 'Name',
    title: 'Name',
  },
  {
    id: 'tprice',
    name: 'Price',
    title: 'Price',
  },
  {
    id: 'tpricediff',
    name: '24h%',
    title: '24 Hour Price Change %',
  },
  {
    id: 'tpricediff7',
    name: '7d%',
    title: '7 Day Price Change %',
  },
  {
    id: 'endprice',
    name: '7D Prediction',
    title:
      '7 Day Prediction from machine learning results. Should not be taken as trading/investment advice.',
  },
  {
    id: 'tmc',
    name: 'Market Cap',
    title: 'Total Market Cap',
  },
  {
    id: 'tvol24',
    name: '24h Volume',
    title: '24 Hour Total Volume',
  },
  {
    id: 'tcircsupply',
    name: 'Circulating',
    title: 'Total Circulating Supply',
  },
  {
    id: 'maxsupply',
    name: 'Max Supply',
    title: 'Max Supply',
  },
];

export const walletHeaders = [
  {
    id: 'ticker',
    name: 'Symbol',
    title: 'Symbol',
  },
  {
    id: 'holders',
    name: '# of Holders',
    title: 'Number of Holders from top 1000 wallets by Market Value',
  },
  {
    id: 'hchg',
    name: 'Holder Chg',
    title: 'Change in Holders',
  },
  {
    id: 'price',
    name: 'Price',
    title: 'Price',
  },
  {
    id: 'chg',
    name: 'Price Chg',
    title: 'Price Change',
  },
  {
    id: 'vol',
    name: 'Volume',
    title: 'Volume',
  },
  {
    id: 'mcap',
    name: 'Market Cap',
    title: 'Total Market Cap',
  },
];

export const topCryptoCapHeaders = [
  {
    id: 'symbol',
    name: 'Symbol',
    title: 'Symbol',
  },
  {
    id: 'tmc',
    name: 'Mkt Cap',
    title: 'Market Cap',
  },
  {
    id: 'tprice',
    name: 'Price',
    title: 'Price',
  },
  {
    id: 'tpricediff',
    name: '24H',
    title: 'Price Change 1 Day',
  },
  {
    id: 'tpricediff7',
    name: '7D',
    title: 'Price Change 7 Days',
  },
  {
    id: 'cap30dcurr',
    name: '30D',
    title: 'Price Change 30 Days',
  },
];

export const topCryptoVolumeHeaders = [
  {
    id: 'symbol',
    name: 'Symbol',
    title: 'Symbol',
  },
  {
    id: 'tvol24',
    name: 'Volume (24H)',
    title: 'Volume 24 Hours',
  },
  {
    id: 'tprice',
    name: 'Price',
    title: 'Price',
  },
  {
    id: 'tpricediff',
    name: '24H',
    title: 'Price Change 1 Day',
  },
  {
    id: 'tpricediff7',
    name: '7D',
    title: 'Price Change 7 Days',
  },
  {
    id: 'cap30dcurr',
    name: '30D',
    title: 'Price Change 30 Days',
  },
];

export const memeSummaryHeaders = [
  {
    id: 'ticker',
    name: 'ticker',
    title: 'Ticker',
  },
  {
    id: 'rank',
    name: 'Lucid Rank',
    title: 'Lucid Rank',
  },
  {
    id: 'total',
    name: 'Lucid Score',
    title: 'Lucid Score',
  },
  {
    id: 'social_display',
    name: 'Social Score',
    title: 'Social Score',
  },
  {
    id: 'si',
    name: 'Short Interest',
    title: 'Short Interest',
  },
  {
    id: 'sishares',
    name: 'SI Shares',
    title: 'Short Interest Shares',
  },
  {
    id: 'siratio',
    name: 'SI Ratio',
    title:
      'Short Interest Ratio: number of shares short / average daily trading volume.',
  },
  {
    id: 'float',
    name: 'Float',
    title: 'Float (shares available to trade in the market)',
  },
];

export const memeHeaders = [
  {
    id: 'ticker',
    name: 'ticker',
    title: 'Ticker',
  },
  {
    id: 'ftdtotalshares',
    name: 'FTD Total Shares',
    title: 'Failure to Deliver Total Shares',
  },
  {
    id: 'ftdtotalvalue',
    name: 'FTD Total Value',
    title: 'Failure to Deliver Total Value',
  },
  {
    id: 'float',
    name: 'Float',
    title: 'Float (shares available to trade in the market)',
  },
  {
    id: 'si',
    name: 'SI',
    title: 'Short Interest: Shorted Shares / Shares Outstanding',
  },
  {
    id: 'sichg',
    name: 'SI Chg',
    title: 'Short Interest Change',
  },
  {
    id: 'redditmentionsnow',
    name: 'Reddit Mentions',
    title: 'Current Reddit Mentions',
  },
  {
    id: 'mentionschg1d',
    name: 'Reddit -1D',
    title: 'Reddit Mentions -1 Day',
  },
];

export const siHeaders = [
  {
    id: 'ticker',
    name: 'ticker',
    title: 'Ticker',
  },
  {
    id: 'price',
    name: 'Price',
    title: 'Price',
  },
  {
    id: 'pricechg',
    name: 'Price Change',
    title: 'Price Change',
  },
  {
    id: 'si',
    name: 'SI',
    title: 'Short Interest: Shorted Shares / Shares Outstanding',
  },
  {
    id: 'siratio',
    name: 'SIR',
    title: 'Short Interest Ratio: SI / Avg Trading Volume',
  },
  {
    id: 'sichg',
    name: 'SI Chg',
    title: 'Change in Short Interest',
  },
  {
    id: 'sishares',
    name: 'SI Shares',
    title: 'Short Interest Shares Amount',
  },
  {
    id: 'float',
    name: 'Float',
    title: 'Float (shares available to trade in the market)',
  },
  {
    id: 'priorsishares',
    name: 'Prior SI',
    title: 'Prior Short Interest Shares',
  },
];

export const ftdHeaders = [
  /* {
    id: 'company',
    name: 'Company',
    title: 'Company',
  }, */
  {
    id: 'ticker',
    name: 'ticker',
    title: 'Ticker',
  },
  {
    id: 'price',
    name: 'Price',
    title: 'Price',
  },
  {
    id: 'qty',
    name: 'Quantity',
    title: 'Quantity',
  },
  {
    id: 'value',
    name: 'FTD Value',
    title: 'FTD Value',
  },
  {
    id: 'settle',
    name: 'Settle Date',
    title: 'Settle Date',
  },
  {
    id: 't35',
    name: 'Settlement Due T+35',
    title: 'T+35 Date when FTD Must be Covered',
  },
];
