import React, { useState, useEffect } from 'react';
import {
  tokenHeaders,
  walletHeaders,
  topCryptoCapHeaders,
  topCryptoVolumeHeaders,
  gsHeaders,
} from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import '../styles/crypto.scss';
import { formatCurrency, formatNumber, formatDate } from '../helpers/format';

function Crypto({
  userType,
  handleShowCryptoModal,
  handleShowUpgrade,
  isLoaded,
  cryptoInfo,
}) {
  //console.log('cryptoInfo', cryptoInfo);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenData, setTokenData] = useState([]);
  const [topData, setTopData] = useState([]);
  const [orderReversed, setOrderReversed] = useState(false);
  const [walletOrderReversed, setWalletOrderReversed] = useState(false);
  const [topCapOrderReversed, setTopCapOrderReversed] = useState(false);
  const [topVolOrderReversed, setTopVolOrderReversed] = useState(false);
  const [orderMethod, setOrderMethod] = useState('tmc');
  const [topCapOrderMethod, setTopCapOrderMethod] = useState('tmc');
  const [topVolOrderMethod, setTopVolOrderMethod] = useState('tvol24');
  const [topCapHeight, setTopCapHeight] = useState('355px');
  const [topVolHeight, setTopVolHeight] = useState('355px');

  const handleSetOrderMethod = (header, type) => {
    if (type === 'token') {
      if (header === orderMethod) {
        setOrderReversed(!orderReversed);
      } else {
        setOrderMethod(header);
      }
    }
    if (type === 'topCap') {
      if (header === topCapOrderMethod) {
        setTopCapOrderReversed(!topCapOrderReversed);
      } else {
        setTopCapOrderMethod(header);
      }
    }
    if (type === 'topVol') {
      if (header === topVolOrderMethod) {
        setTopVolOrderReversed(!topVolOrderReversed);
      } else {
        setTopVolOrderMethod(header);
      }
    }
  };

  useEffect(() => {
    const tokenInfo = cryptoInfo.filter((c) => c.pickslist === 'Y');
    setTokenData(tokenInfo);
    const topCrypto = cryptoInfo.filter((c) => c.topcaplist === 'Y');
    setTopData(topCrypto);
    if (cryptoInfo?.length > 0) {
      setIsLoading(false);
    }
  }, [cryptoInfo, isLoaded]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='dashboard crypto'>
      {isLoading && <Loader title={`${userType} Crypto Data`} />}

      {!isLoading && (
        <>
          <div className='panel-container text-center'>
            <h2>Disruptive Picks</h2>
            <h3 className='faded'>
              Projects Pushing Mainstream Blockchain Adoption
            </h3>
            <h5 className='faded' style={{ marginBottom: '20px' }}>
              We’ve identified a series of projects that we believe are
              disruptive, innovative, and will help shape the future of Finance
              (DeFi), Internet Technologies, Data, and Commerce. Always remember
              to do your own research before investing. We do not recommend
              these picks as financial advice, but rather as noteworthy projects
              that are worth further investigation.
              {userType === 'Premium' && ' Premium only.'}
            </h5>
            {userType === 'Member' && (
              <div>
                <div className='text-center'>
                  <br />
                  <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                  <p className='small faded'>
                    Support Lucid and get access to <br />
                    premium content.
                  </p>
                  <small>
                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade to Premium
                    </a>
                  </small>
                </div>
              </div>
            )}
            {userType === 'Premium' && (
              <div>
                <div
                  style={{
                    clear: 'both',
                  }}
                ></div>

                <div className='h-scroll' style={{ maxWidth: '2600px' }}>
                  {tokenData?.length > 0 && (
                    <table>
                      <thead>
                        <tr>
                          {tokenHeaders.map((d) => (
                            <th
                              key={d.id}
                              className={`${d.id} link`}
                              title={d.title}
                              onClick={() =>
                                handleSetOrderMethod(d.id, 'tokens')
                              }
                            >
                              {d.name}
                              {`${orderMethod}` === d.id && (
                                <i
                                  className={
                                    orderReversed
                                      ? 'ion-arrow-down-b small'
                                      : 'ion-arrow-up-b small'
                                  }
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tokenData &&
                          tokenData
                            .sort((a, b) => {
                              if (isNaN(a[orderMethod])) {
                                if (orderReversed) {
                                  return `${a[orderMethod]}`.localeCompare(
                                    `${b[orderMethod]}`
                                  );
                                } else {
                                  return `${b[orderMethod]}`.localeCompare(
                                    `${a[orderMethod]}`
                                  );
                                }
                              } else {
                                if (orderReversed) {
                                  return a[orderMethod] - b[orderMethod];
                                } else {
                                  return b[orderMethod] - a[orderMethod];
                                }
                              }
                            })
                            .map((r, index) => (
                              <tr
                                key={`${r.symbol}${index}`}
                                className='link'
                                onClick={() => handleShowCryptoModal(r)}
                              >
                                <td title='Symbol' className='link'>
                                  <img
                                    src={r.img}
                                    onError={(e) => {
                                      e.currentTarget.src =
                                        '/logos/Lucid-Round@2x.png';
                                      e.currentTarget.className += ' faded';
                                    }}
                                    alt={r.symbol}
                                    className='coin-img'
                                  />
                                  <b>{r.symbol}</b>
                                </td>
                                <td title='Name' className='link'>
                                  <b>{r.name}</b>
                                </td>
                                <td title='Price'>
                                  {formatCurrency(r.tprice, 'USD', 3)}
                                </td>
                                <td
                                  title='1D Price Diff'
                                  className={
                                    r.tpricechg > 0 ? 'success' : 'danger'
                                  }
                                >
                                  {formatNumber(r.tpricechg, 2)}%
                                </td>
                                <td
                                  title='7D Price Diff'
                                  className={
                                    r.tpricechg7 > 0 ? 'success' : 'danger'
                                  }
                                >
                                  {r.tpricechg7 !== '#N/A' &&
                                    `${formatNumber(r.tpricechg7, 2)}%`}
                                </td>
                                <td title='7 Day Price Prediction'>
                                  {r.endprice
                                    ? formatCurrency(r.endprice, 'USD', 3)
                                    : 'N/A'}
                                </td>
                                <td title='Total Market Cap'>
                                  {formatCurrency(r.tmc, 'USD', 0)}
                                </td>
                                <td title='24H Volume'>
                                  {formatNumber(r.tvol24, 0)}
                                </td>
                                <td title='Circulating'>
                                  {formatNumber(r.tcircsupply, 0)}
                                </td>
                                <td title='Max Supply'>
                                  {r.maxsupply
                                    ? formatNumber(r.maxsupply, 2)
                                    : 'N/A'}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className='panel-container text-center'>
            <h2>Tokens By Market Cap</h2>
            <h3 className='faded' style={{ marginTop: '-1px' }}>
              Ethereum based Alt Coins (ERC-20)
            </h3>
            <h5 className='faded' style={{ marginBottom: '20px' }}>
              Larger market cap, less risk.
            </h5>
            <div>
              <a
                className='btn btn-secondary'
                onClick={() =>
                  topCapHeight !== '100%'
                    ? setTopCapHeight('100%')
                    : setTopCapHeight('355px')
                }
              >
                {topCapHeight !== '100%' ? 'Expand' : 'Collapse'}
              </a>
              <div
                style={{
                  clear: 'both',
                }}
              ></div>
              <br />
              <div
                className='h-scroll'
                style={{
                  height: topCapHeight,
                  maxWidth: '2600px',
                }}
              >
                {topData?.length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        {topCryptoCapHeaders.map((d) => (
                          <th
                            key={d.id}
                            className={`${d.id} link`}
                            title={d.title}
                            onClick={() => handleSetOrderMethod(d.id, 'topCap')}
                          >
                            {d.name}
                            {`${topCapOrderMethod}` === d.id && (
                              <i
                                className={
                                  topCapOrderReversed
                                    ? 'ion-arrow-down-b small'
                                    : 'ion-arrow-up-b small'
                                }
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {topData &&
                        topData
                          .sort((a, b) => {
                            if (isNaN(a[topCapOrderMethod])) {
                              if (topCapOrderReversed) {
                                return `${a[topCapOrderMethod]}`.localeCompare(
                                  `${b[topCapOrderMethod]}`
                                );
                              } else {
                                return `${b[topCapOrderMethod]}`.localeCompare(
                                  `${a[topCapOrderMethod]}`
                                );
                              }
                            } else {
                              if (topCapOrderReversed) {
                                return (
                                  a[topCapOrderMethod] - b[topCapOrderMethod]
                                );
                              } else {
                                return (
                                  b[topCapOrderMethod] - a[topCapOrderMethod]
                                );
                              }
                            }
                          })
                          .map((r, index) => (
                            <tr
                              key={`${r.symbol}${index}`}
                              className='link'
                              onClick={() => handleShowCryptoModal(r)}
                            >
                              <td title='Symbol' className='link'>
                                <img
                                  src={r.img}
                                  onError={(e) => {
                                    e.currentTarget.src =
                                      '/logos/Lucid-Round@2x.png';
                                    e.currentTarget.className += ' faded';
                                  }}
                                  alt={r.symbol}
                                  className='coin-img'
                                />
                                <b>{r.symbol}</b>
                              </td>
                              <td title='Market Cap'>
                                {formatCurrency(r.tmc, 'USD', 0)}
                              </td>
                              <td title='Price'>
                                {formatCurrency(r.tprice, 'USD', 3)}
                              </td>
                              <td
                                title='Price Change 1D'
                                className={
                                  r.tpricechg > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r.tpricechg, 2)}%
                              </td>
                              <td
                                title='Price Change 7D'
                                className={
                                  r.tpricechg7 > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r.tpricechg7, 2)}%
                              </td>
                              <td
                                title='Price Change 30D'
                                className={
                                  r.tpricechg30 > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r.tpricechg30, 2)}%
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <div className='panel-container text-center'>
            <h2>Tokens By Volume Traded</h2>
            <h3 className='faded' style={{ marginTop: '-1px' }}>
              Ethereum based Alt Coins (ERC-20)
            </h3>
            <h5 className='faded' style={{ marginBottom: '20px' }}>
              Larger volume, more liquidity.
            </h5>
            <div>
              <a
                className='btn btn-secondary'
                onClick={() =>
                  topVolHeight !== '100%'
                    ? setTopVolHeight('100%')
                    : setTopVolHeight('355px')
                }
              >
                {topVolHeight !== '100%' ? 'Expand' : 'Collapse'}
              </a>
              <div
                style={{
                  clear: 'both',
                }}
              ></div>
              <br />
              <div
                className='h-scroll'
                style={{
                  height: topVolHeight,
                  maxWidth: '2600px',
                }}
              >
                {topData?.length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        {topCryptoVolumeHeaders.map((d) => (
                          <th
                            key={d.id}
                            className={`${d.id} link`}
                            title={d.title}
                            onClick={() => handleSetOrderMethod(d.id, 'topVol')}
                          >
                            {d.name}
                            {`${topVolOrderMethod}` === d.id && (
                              <i
                                className={
                                  topVolOrderReversed
                                    ? 'ion-arrow-down-b small'
                                    : 'ion-arrow-up-b small'
                                }
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {topData &&
                        topData
                          .sort((a, b) => {
                            if (isNaN(a[topVolOrderMethod])) {
                              if (topVolOrderReversed) {
                                return `${a[topVolOrderMethod]}`.localeCompare(
                                  `${b[topVolOrderMethod]}`
                                );
                              } else {
                                return `${b[topVolOrderMethod]}`.localeCompare(
                                  `${a[topVolOrderMethod]}`
                                );
                              }
                            } else {
                              if (topVolOrderReversed) {
                                return (
                                  a[topVolOrderMethod] - b[topVolOrderMethod]
                                );
                              } else {
                                return (
                                  b[topVolOrderMethod] - a[topVolOrderMethod]
                                );
                              }
                            }
                          })
                          .map((r, index) => (
                            <tr
                              key={`${r.symbol}${index}`}
                              className='link'
                              onClick={() => handleShowCryptoModal(r)}
                            >
                              <td title='Symbol' className='link'>
                                <img
                                  src={r.img}
                                  onError={(e) => {
                                    e.currentTarget.src =
                                      '/logos/Lucid-Round@2x.png';
                                    e.currentTarget.className += ' faded';
                                  }}
                                  alt={r.symbol}
                                  className='coin-img'
                                />
                                <b>{r.symbol}</b>
                              </td>
                              <td title='24H Volume'>
                                {formatNumber(r.tvol24, 0)}
                              </td>
                              <td title='Price'>
                                {formatCurrency(r.tprice, 'USD', 3)}
                              </td>
                              <td
                                title='1D Price Diff'
                                className={
                                  r.tpricechg > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r.tpricechg, 2)}%
                              </td>
                              <td
                                title='7D Price Diff'
                                className={
                                  r.tpricechg7 > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r.tpricechg7, 2)}%
                              </td>
                              <td
                                title='30D Price Diff'
                                className={
                                  r.tpricechg30 > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r.tpricechg30, 2)}%
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
              </div>
              <br></br>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Crypto;
