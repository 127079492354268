function Footer({ title }) {
  return (
    <div
      className='text-center faded'
      style={{ maxWidth: '800px', fontSize: '8px', margin: '30px auto' }}
    >
      Lucid does not have any affiliation with ARK Invest, BlackRock, or Global
      X. The data and information provided is not to be used as investment
      advice. The information displayed on this page is solely meant to for
      educative purposes by using public data provided by ARK Invest. The NAV,
      Market Share Values, Daily/Weekly Capital Injections (buys/sells) provided
      are calculated using daily close prices; ARK Invests trade price
      executions may differ from what is provided by Lucid.
    </div>
  );
}
export default Footer;
