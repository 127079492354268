export const earningsCalendarHeaders = [
  'Date',
  'Ticker',
  'Quarter',
  'EPS Actual',
  'EPS Estimate',
  'Revenue Actual',
  'Revenue Estimate',
  'Year',
];

export const economicCalendarHeaders = [
  'Date',
  'Country',
  'Event',
  'Impact',
  'Actual',
  'Estimate',
];

export const calendarTabs = ['All Earnings', 'Watched Earnings', 'Economic'];

export const allPageTabs = ['Top', 'Charts'];
