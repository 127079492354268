import React, { useState, useEffect } from 'react';
import { insiderHeaders, insiderTabs } from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import Preview from '../layouts/preview';
import '../styles/calendars.scss';
import { formatCurrency, formatDate, formatNumber } from '../helpers/format';

let headers = [];

function Insider({
  myWatchlist,
  userType,
  currentFundGroup,
  handleShowModal,
  handleShowUpgrade,
  insiderData,
  date,
  ticker,
}) {
  if (ticker) {
    headers = insiderHeaders.filter(
      (h) => h.id !== 'Ticker' && h.id !== 'Company Name'
    );
  } else {
    headers = insiderHeaders;
  }

  const [isLoading, setIsLoading] = useState(true);
  const [insider, setInsider] = useState([]);
  const [orderReversed, setOrderReversed] = useState(false);
  const [orderMethod, setOrderMethod] = useState('Trade Date');
  const [selectedTab, setSelectedTab] = useState(insiderTabs[0]);
  const [filterByTicker, setFilterByTicker] = useState(ticker);

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
    if (tab === insiderTabs[0]) {
      setInsider(insiderData);
    } else {
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = insiderData.filter((f) =>
        WLtickers.includes(f.Ticky)
      );
      setInsider(filteredByWL);
    }
  };

  const getInsiderData = async () => {
    if (filterByTicker) {
      setInsider(insiderData.filter((f) => f.Ticky === ticker));
    } else {
      setInsider(insiderData);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getInsiderData();
  }, [date]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={
        ticker ? `${currentFundGroup}` : `calendars ${currentFundGroup}`
      }
    >
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} Insider Activity`} />
      )}

      {!isLoading && (
        <div>
          <div>
            {!ticker && (
              <div className='header-content d-flex'>
                <h1>
                  <span className='hidden-mobile'>Insider Activity </span>
                  <span className='faded'>{date && formatDate(date)}</span>
                </h1>

                <div className='tabs'>
                  {insiderTabs.map((tab) => (
                    <div
                      key={tab}
                      className={selectedTab === tab ? 'tab active' : 'tab'}
                      onClick={() => handleSetSelectedTab(tab)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div>
              {userType === 'Member' && (
                <div>
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    <br />
                    <div className='jumbo'>🚀</div>
                    <h2>Upgrade to Premium</h2>
                    <p style={{ marginBottom: '20px' }}>
                      Get access to Insider Activity for {currentFundGroup} ETFs
                      and premium member features.
                    </p>

                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade
                    </a>
                  </div>
                </div>
              )}
              {userType === 'Premium' && (
                <div className='h-scroll'>
                  {ticker && insider.length === 0 && 'No data found.'}
                  {selectedTab === insiderTabs[1] && insider.length === 0 && (
                    <div
                      className='panel'
                      style={{
                        padding: '20px 10px',
                        maxWidth: '800px',
                        textAlign: 'center',
                        border: 'none',
                      }}
                    >
                      <div className='jumbo'>🧐</div>
                      <h2>
                        No Insider Activity in your {currentFundGroup}{' '}
                        Watchlist.
                      </h2>
                      <p style={{ marginBottom: '20px' }}>
                        Check out the{' '}
                        <a onClick={() => handleSetSelectedTab(insiderTabs[0])}>
                          {insiderTabs[0]} Tab
                        </a>
                        .
                      </p>
                    </div>
                  )}
                  {insider.length > 0 && (
                    <table className='striped h-scroll fixed'>
                      <thead>
                        <tr>
                          {headers.map((d) => (
                            <th
                              onClick={() => handleSetOrderMethod(d.id)}
                              key={d.id}
                              className={d.id}
                              title={d.title}
                            >
                              {d.name}
                              {orderMethod.toString() === d.id && (
                                <i
                                  className={
                                    orderReversed
                                      ? 'ion-arrow-down-b small'
                                      : 'ion-arrow-up-b small'
                                  }
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {insider &&
                          insider
                            .sort((a, b) => {
                              if (isNaN(a[orderMethod])) {
                                if (orderReversed) {
                                  return `${a[orderMethod]}`.localeCompare(
                                    `${b[orderMethod]}`
                                  );
                                } else {
                                  return `${b[orderMethod]}`.localeCompare(
                                    `${a[orderMethod]}`
                                  );
                                }
                              } else {
                                if (orderReversed) {
                                  return a[orderMethod] - b[orderMethod];
                                } else {
                                  return b[orderMethod] - a[orderMethod];
                                }
                              }
                            })
                            .map((r, index) => (
                              <tr
                                className='link'
                                key={`${r.Ticky}${index}`}
                                onClick={() => handleShowModal(r.Ticky)}
                              >
                                <td>{formatDate(r['Trade Date'])}</td>
                                {!ticker && (
                                  <Preview
                                    title='Ticker'
                                    CSSclass='strong'
                                    ticker={r.Ticky}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                )}

                                <td>{r['Trade Type']}</td>
                                <td>{formatCurrency(r.Price, 'USD', 2)}</td>
                                <td
                                  className={
                                    r['Qty'] > 0 ? 'success' : 'danger'
                                  }
                                >
                                  {formatNumber(r['Qty'], 2)}
                                </td>
                                <td>{formatNumber(r.Owned, 2)}</td>
                                <td
                                  className={r.Value > 0 ? 'success' : 'danger'}
                                >
                                  {formatCurrency(r.Value, 'USD', 0)}
                                </td>
                                {!ticker && (
                                  <td className='Company'>
                                    {r['Company Name']}
                                  </td>
                                )}

                                <td className='Insider'>{r['Insider Name']}</td>
                                <td className='Title'>{r.Title}</td>
                                <td>{formatDate(r['Filing Date'])}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Insider;
