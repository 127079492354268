import { formatDate } from '../helpers/format';
import '../styles/page.scss';

function PaymentSuccess() {
  return (
    <div className='page terms'>
      <div>
        <div>
          <div
            className='panel'
            style={{ textAlign: 'left', paddingBottom: '20px' }}
          >
            <h1>🚀 To the moon!</h1>
            <p>
              Thanks for supporting Lucid and becoming a Founding Member. You
              now have access to the premium features on Lucid.
            </p>
            {/* <table style={{ margin: '20px auto' }}>
              <tr>
                <td>Date</td>
                <td>{formatDate(new Date())}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>Founding Member Monthly Subscription</td>
              </tr>
              <tr>
                <td>Monthly Cost</td>
                <td>
                  $4.99/mo <del className='faded small'>$9.99/mo</del>
                </td>
              </tr>
            </table> */}
            <b>Questions?</b>
            <p>Contact us at hello@lucidtracking.com.</p>
            <br />
            <a href='/' className='btn btn-primary'>
              Go To Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
