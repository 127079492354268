import React, { useState, useEffect } from 'react';
import { patternHeaders } from '../helpers/fundvars';
import {
  formatNumber,
  formatDateTime,
  formatCurrency,
} from '../helpers/format';
import { getPatterns } from '../helpers/api';

function Patterns({ handleShowModal, ticker }) {
  const [pattern, setPattern] = useState([]);

  const getPatternData = async () => {
    const patternData = await getPatterns(ticker);
    setPattern(patternData);
  };

  const getDate = (time) => {
    const d2 = new Date();
    d2.setTime(time * 1000);
    return formatDateTime(d2);
  };
  useEffect(() => {
    getPatternData();
  }, []);
  return (
    <>
      {pattern?.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                {patternHeaders.map((d) => (
                  <th key={d.id} className={d.id} title={d.title}>
                    {d.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pattern?.map((r, index) => (
                <tr
                  className='link'
                  key={`${ticker}${index}`}
                  onClick={() => handleShowModal(ticker)}
                >
                  <td>{r['patternname']}</td>
                  <td
                    className={
                      r.patterntype === 'Bullish' ? 'success' : 'danger'
                    }
                  >
                    {r['patterntype']}
                  </td>
                  <td>
                    {r.entry ? (
                      formatCurrency(r.entry, 'USD', '2')
                    ) : (
                      <span className='faded'>No Data</span>
                    )}
                  </td>
                  <td>
                    {r.start_price ? (
                      formatCurrency(r.start_price, 'USD', '2')
                    ) : (
                      <span className='faded'>No Data</span>
                    )}
                  </td>
                  <td>
                    {r.end_price ? (
                      formatCurrency(r.end_price, 'USD', '2')
                    ) : (
                      <span className='faded'>No Data</span>
                    )}
                  </td>
                  <td>
                    {r.stoploss ? (
                      formatCurrency(r.stoploss, 'USD', '2')
                    ) : (
                      <span className='faded'>No Data</span>
                    )}
                  </td>
                  <td>
                    {r.start_time ? (
                      getDate(r.start_time)
                    ) : (
                      <span className='faded'>No Data</span>
                    )}
                  </td>
                  <td>
                    {r.end_time ? (
                      getDate(r.end_time)
                    ) : (
                      <span className='faded'>No Data</span>
                    )}
                  </td>
                  <td>{r['status']}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </>
      ) : (
        'No data found.'
      )}
    </>
  );
}
export default Patterns;
