import React, { useState, useEffect } from 'react';
import {
  getUsername,
  setUsername,
  getCode,
  createUUID,
  getUserType,
} from '../helpers/helpers';
import { getComments, getUsernameFromSheet } from '../helpers/api';

const code = getCode();
const timer = 10000;
const userType = getUserType();
let username = getUsername();

/* if (!username) {
  const sheetUN = getUsernameFromSheet();
  if (!sheetUN) {
    username = '';
  }
} */

function Comments({ ticker, handleShowUpgrade }) {
  const [desiredUsername, setDesiredUsername] = useState('');
  const [usernameMB, setUsernameMB] = useState(username);
  const [usernameError, setUsernameError] = useState('');
  const [messageBoardComments, setMessageBoardComments] = useState([]);
  const [messageBoardComment, setMessageBoardComment] = useState('');
  const [messageBoardCommentUUID, setMessageBoardCommentUUID] = useState(
    createUUID()
  );
  const [deleteCommentUUID, setDeleteCommentUUID] = useState('');

  const getSheetUN = async () => {
    if (!username) {
      const usernameFromS = await getUsernameFromSheet();
      setUsernameMB(usernameFromS ? usernameFromS : '');
    } else {
      setUsernameMB(username);
    }
  };

  const saveDesiredUsername = () => {
    if (desiredUsername.length > 0) {
      //is user in list?
      let usernameExists = messageBoardComments.find(
        (u) => u.username === desiredUsername
      );

      if (usernameExists) {
        setUsernameError('Username already exists. Please try another name.');
        setDesiredUsername('');
        setTimeout(() => {
          setUsernameError('');
        }, 3000);
      } else {
        //submit username to form
        document.querySelector('#submitusernameMBform').click();
        setTimeout(() => {
          setUsernameMB(desiredUsername);
          setUsername(desiredUsername);
          setTimeout(() => {
            getComments();
          }, 1000);
        }, 0);
      }
    }
  };

  const postMessageBoardComment = () => {
    window.document.querySelector('#submitmessageboardform').click();

    setTimeout(() => {
      setMessageBoardComment('');
      getCommentsFromSheet();
      setMessageBoardCommentUUID(createUUID());
    }, 1000);
  };

  const getCommentsFromSheet = async () => {
    const tickersFromSheet = await getComments(ticker);
    const filteredByTicker = tickersFromSheet
      .filter((t) => t.ticker === ticker)
      .reverse();
    setMessageBoardComments(filteredByTicker);
  };

  const startTimer = () => {
    setTimeout(() => {
      getCommentsFromSheet(ticker);
    }, timer);
  };

  const deleteComment = (comment) => {
    setDeleteCommentUUID(comment.uuid);
    const newCommentsFromSheet = messageBoardComments.filter(
      (c) => c.uuid !== comment.uuid
    );
    setMessageBoardComments(newCommentsFromSheet);
    setTimeout(() => {
      window.document.querySelector('#submitdeletecommentform').click();
    }, 500);
  };

  useEffect(() => {
    getSheetUN();
  }, []);

  useEffect(() => {
    getCommentsFromSheet(ticker);
    setMessageBoardCommentUUID(createUUID());
    startTimer();
  }, [ticker]);

  return (
    <div className='board'>
      <iframe
        name='secret-frame-six'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <iframe
        name='secret-frame-nine'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <iframe
        name='secret-frame-seven'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>

      {userType === 'Premium' && (
        <div className='comment-container'>
          {!usernameMB && (
            <div className='form-group'>
              <label htmlFor='usernamelabel'>
                Create a Username to comment.
              </label>
              <input
                type='text'
                placeholder='Enter username...'
                value={desiredUsername}
                onChange={(e) => setDesiredUsername(e.target.value)}
              />
              {usernameError && <p className='danger'>{usernameError}</p>}

              <button
                style={{ width: '100%' }}
                className='btn-primary'
                onClick={() => saveDesiredUsername()}
              >
                Save Username
              </button>

              <form
                className='form'
                action='https://docs.google.com/forms/d/e/1FAIpQLSc54G_4XfEb_cTdorCYlD-7iM7nV73YZxo5ND3HRRayu4lo3g/formResponse'
                id='ss-form9'
                method='POST'
                target='secret-frame-nine'
                onsubmit=''
                style={{ display: 'none' }}
              >
                <div className='form-group'>
                  <label htmlFor='code'>Code</label>
                  <input
                    name='entry.1431414506'
                    readOnly
                    type='text'
                    value={code}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='registrationEmail'>Username</label>
                  <input
                    name='entry.1475482522'
                    type='text'
                    value={desiredUsername}
                    onChange={(e) => setDesiredUsername(e.target.value)}
                  />
                </div>
                <input
                  id='submitusernameMBform'
                  type='submit'
                  value='Submit Username'
                />
              </form>
            </div>
          )}
          {usernameMB && (
            <div>
              <div>
                <div className='form-group'>
                  <input
                    name='comment'
                    placeholder={`Comment on ${ticker}...`}
                    type='text'
                    value={messageBoardComment}
                    onChange={(e) => setMessageBoardComment(e.target.value)}
                  />
                  <form
                    className='form'
                    action='https://docs.google.com/forms/d/e/1FAIpQLSeCsTLaPEzmmMwJDAOkRNqFZxO-gcaVXI9yNvBuZxkFEzWdRA/formResponse'
                    id='ss-form6'
                    method='POST'
                    target='secret-frame-six'
                    style={{ display: 'none' }}
                  >
                    <div className='form-group'>
                      <label>Code</label>
                      <input
                        name='entry.771828'
                        readOnly
                        type='text'
                        value={code}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Username</label>
                      <input
                        name='entry.1452126980'
                        type='text'
                        readOnly
                        value={usernameMB}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Comment</label>
                      <input
                        name='entry.1762678516'
                        type='text'
                        readOnly
                        value={messageBoardComment}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Ticker</label>
                      <input
                        name='entry.513162060'
                        type='text'
                        readOnly
                        value={ticker}
                      />
                    </div>
                    <div className='form-group'>
                      <label>UUID</label>
                      <input
                        name='entry.1120477299'
                        type='text'
                        readOnly
                        value={messageBoardCommentUUID}
                      />
                    </div>

                    <input
                      id='submitmessageboardform'
                      type='submit'
                      value='Submit Comment'
                      disabled={messageBoardComment.length === 0}
                    />
                  </form>

                  <form
                    className='form'
                    action='https://docs.google.com/forms/d/e/1FAIpQLSfjndEhr2tpfTbWtsUgAMCE0_9oamyMZAnNx1Js4yfeGJNvoQ/formResponse'
                    id='ss-form7'
                    method='POST'
                    target='secret-frame-seven'
                    style={{ display: 'none' }}
                  >
                    <div className='form-group'>
                      <label>UUID</label>
                      <input
                        name='entry.2145533324'
                        type='text'
                        value={deleteCommentUUID}
                        readOnly
                      />
                    </div>

                    <input
                      id='submitdeletecommentform'
                      type='submit'
                      value='Submit Delete Comment'
                    />
                  </form>
                </div>
                <div className='form-group'>
                  <button
                    className='btn-primary btn-block'
                    onClick={() => postMessageBoardComment()}
                  >
                    <i className='ion-chatbubble'></i> Comment
                  </button>
                </div>
              </div>
              {messageBoardComments.map(
                (comment) =>
                  comment.comment.length && (
                    <div className='item' key={comment.uuid}>
                      <h6 className='faded'>{comment.timestamp}</h6>
                      <p>{comment.comment}</p>

                      <small className='faded'>{comment.username}</small>
                      {comment.code === code && (
                        <button onClick={() => deleteComment(comment)}>
                          Delete
                        </button>
                      )}
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      )}

      {userType === 'Member' && (
        <div>
          <div
            className='panel'
            style={{ padding: '40px 10px', textAlign: 'center' }}
          >
            <div className='jumbo'>🚀</div>
            <h2 style={{ marginTop: '0px' }}>Upgrade to Premium</h2>
            <p style={{ marginBottom: '20px' }}>
              Get access to chat and Premium Member features.
            </p>
            <a
              onClick={() => handleShowUpgrade(true)}
              className='btn btn-primary'
            >
              Upgrade to Premium
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
export default Comments;
