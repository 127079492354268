import '../styles/page.scss';

function Faq() {
  return (
    <div className='page terms'>
      <div>
        <br />
        <h2 style={{ marginBottom: 0 }}>FAQs</h2>
        <small>
          We are not affiliated with Ark Invest, BlackRock, or Global X.
        </small>
        <div className='faq-container'>
          <h4>Learning Videos</h4>
          <p>
            Watch videos where Lucid is used to spot potential buying
            opportunities and get some insight on how to use Lucid.
          </p>
          <div className='video-container'>
            <div className='hidden-mobile'>
              <iframe
                title='Palantir'
                width={560 / 2}
                height={315 / 2}
                src='https://www.youtube.com/embed/QT4NRS1A7og'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
              <iframe
                title='Lucid'
                width={560 / 2}
                height={315 / 2}
                src='https://www.youtube.com/embed/pmpwt9p7JcA?start=187'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
            <div>
              <iframe
                title='Lucid Video'
                width={560 / 2}
                height={315 / 2}
                src='https://www.youtube.com/embed/4xsA8MReNgo'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
              <iframe
                title='Video of Lucid'
                width={560 / 2}
                height={315 / 2}
                src='https://www.youtube.com/embed/3bERKe4MMh4'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
            <div>
              <iframe
                title='Lucid in the wild'
                width={560 / 2}
                height={315 / 2}
                src='https://www.youtube.com/embed/df9CBTH19ro?start=400'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
              <iframe
                video='Stock video using Lucid'
                width={560 / 2}
                height={315 / 2}
                src='https://www.youtube.com/embed/hrbh00Byw6E?start=400'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className='faq-container'>
          <h4>Request New Asset Tracking</h4>
          <p>
            Send us an email at{' '}
            <a
              href='mailto:hello@lucidtracking.com?subject=Add %20Tracking&body=Please%20track%20the%20following:'
              target='_blank'
            >
              hello@lucidtracking.com
            </a>{' '}
            with funds you'd like to see analysis and charts of and we will take
            it into consideration. If you're a premium member, send it in the
            chat for faster response.
          </p>
        </div>
        <div className='faq-container'>
          <h4>Custom Passwords</h4>
          <p>
            We currently only support our system generated passwords. If you
            need your password reset, please email us at{' '}
            <a
              href='mailto:hello@lucidtracking.com?subject=Add %20Tracking&body=Please%20track%20the%20following:'
              target='_blank'
            >
              hello@lucidtracking.com
            </a>
            .
          </p>
        </div>
        <div className='faq-container'>
          <h4>Support Lucid</h4>
          <p>
            Did we help you spot some trends that led to high returns? Show us
            some love! You can support Lucid by tipping us at:
          </p>
          <p>
            Ethereum Donation Address:
            0x1De6766A3d82b1619bFaf065b6Bd91334A732B99
          </p>
          <p>Paypal Donation Address: hello@lucidtracking.com</p>
        </div>
        <div className='faq-container'>
          <h4>Cancel Premium Subscription</h4>
          <p>
            Cancel your Lucid Premium Membership on{' '}
            <a
              href='https://www.paypal.com/va/smarthelp/article/how-do-i-cancel-a-recurring-payment,-subscription,-or-automatic-billing-agreement-i-have-with-a-merchant-faq1067'
              target='_blank'
            >
              PayPal's
            </a>{' '}
            website. Thank you for your support! Keep in mind that v2 is coming
            and with it an increase to the membership fee along with more
            subscription tiers. Founding members get grandfathered into their
            membership price and will receive all the new premium features. It's
            the gift that keeps on giving.
          </p>
        </div>
        <div className='faq-container'>
          <h4>Delete Account</h4>
          <p>
            <a
              href='mailto:hello@lucidtracking.com?subject=Delete%20Account&body=Please%20delete%20my%20account.%20Thank%20you!'
              target='_blank'
            >
              Email
            </a>{' '}
            us to delete your account.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Faq;
