import React from 'react';
import { BrowserRouter as Router, useParams } from 'react-router-dom';
import '../styles/join.scss';

const lucidreferrers = [
  {
    id: 'sam',
    name: 'Sam',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwngvZ-ZjGqbLlq_ka4vOlGEYZygfHFWUaH6-PqvD4g=s900-c-k-c0x00ffffff-no-rj',
    title: 'My Financial Friend',
    greeting: "Hi it's Sam!",
  },
  {
    id: 'meet-kevin',
    name: 'Kevin',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwnjHlsNj8xsGqe0L8SjQYh2cW6Ga-naP5mXgY42SXgI=s176-c-k-c0x00ffffff-no-rj',
    title: 'Meet Kevin',
    greeting: "What's up everybody!",
  },
  {
    id: 'tactical-investing',
    name: 'Tactical Investing',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwnhQMky3LlzYoMqYXcS6HpJL3-7vSUTu9QYHz9afmA=s88-c-k-c0x00ffffff-no-rj',
    title: 'Tactical Investing',
    url: 'https://www.youtube.com/channel/UCPRC2wIfZtAlzCa_6iKE46w',
    greeting: 'What is up!',
  },
  {
    id: 'mike',
    name: 'Mike Jones Investing',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwngIb37RTTWJqqtERSjcR4aCA6PBebgppYmAquR2=s176-c-k-c0x00ffffff-no-rj',
    title: 'Mike Jones Investing',
    url: 'https://www.youtube.com/channel/UCPRC2wIfZtAlzCa_6iKE46w',
    greeting: 'Hey Guys!',
  },
  {
    id: 'the-investing-opportunity',
    name: 'The Investing Opportunity',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwni36CqhWLgu6WUtpkhkzDvEj3kAIowoXJa3CU8Imw=s88-c-k-c0x00ffffff-no-rj',
    title: 'The Investing Opportunity',
    url: 'https://www.youtube.com/channel/UCPRC2wIfZtAlzCa_6iKE46w',
    greeting: 'Hey Guys!',
  },
  {
    id: 'jason',
    name: 'Jason Polun Investing',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwni3tEUpOGvXWbeVlSmFGCO1PqxQs6m6Y3HMLYO4=s88-c-k-c0x00ffffff-no-rj',
    title: 'Jason Polun Investing',
    url: 'https://www.youtube.com/channel/UCWK8OSNaimZhy-q5Ky2_42w',
    greeting: 'Hey Guys!',
  },
  {
    id: 'we-coach',
    name: 'We Coach',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwnjUCSBE5kOvrE0MBRuauwy0JoqiliGqfn4NwVaP3A=s176-c-k-c0x00ffffff-no-rj',
    title: 'We Coach',
    url: 'https://www.youtube.com/channel/UCDvEeeKsXSFwmBR0mhTZSag',
    greeting: 'Hey Guys!',
  },
  {
    id: 'henry',
    name: 'Invest with Henry',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwniqfSfFgBgPLhi65gOaSf30LKLrCh_g2ovkpGTh=s176-c-k-c0x00ffffff-no-rj',
    title: 'Invest with Henry',
    url: 'https://www.youtube.com/channel/UC2Zg12phLyX1KTnH6D47d9g',
    greeting: 'Hey Guys!',
  },
  {
    id: 'tech-conversations',
    name: 'Tech Conversations',
    icon: 'https://yt3.ggpht.com/ytc/AAUvwnirWB_AaMsag3TUCYiCWzWpCwA6V-xh4S6x2M1iaQ=s88-c-k-c0x00ffffff-no-rj',
    title: 'Tech Conversations',
    url: 'https://www.youtube.com/c/TechConversations',
    greeting: 'Hey Guys!',
  },
  {
    id: 'diariodeunainversion',
    name: 'Diario De Una Inversion',
    icon: 'https://yt3.ggpht.com/ytc/AKedOLRo_AtafzdknWVQywEPhM6b-dzmF6UUeYdJzl8k7g=s900-c-k-c0x00ffffff-no-rj',
    title: 'Diario De Una Inversion',
    url: 'https://www.youtube.com/channel/UC0i4456b4Ypr_YqiMkPzc-Q',
    greeting: 'Hola! Buena Fortuna!',
  },
  {
    id: 'stockcurry',
    name: 'Stock Curry',
    icon: 'https://yt3.ggpht.com/ytc/AKedOLS4qWfa7P4O1TRUqlez-dQq4f02514DD3m7b6vWvg=s176-c-k-c0x00ffffff-no-rj',
    title: 'Stock Curry',
    url: 'https://www.youtube.com/channel/UCxFRGG-_23Kqxe0YexDc1eg',
    greeting: 'Hi Guys, It‘s Stock Curry!',
  },
];

function Join() {
  const { id } = useParams();
  const referrer = lucidreferrers.find(
    (l) => l.id.toLowerCase() === id.toLowerCase()
  );
  localStorage.setItem('referrer', referrer.id);
  return (
    <div className='join'>
      <div className='join-container'>
        <h4>
          <img
            src='https://lucidtracking.s3.amazonaws.com/assets/logos/LUCID.svg'
            alt='Logo'
            className='logo'
          />
          <i className='ion-ios-close-empty'></i> {referrer.title}
        </h4>

        <div className='panel'>
          <img src={referrer.icon} alt={referrer.name} />
          <h2>{referrer.greeting}</h2>

          <div ng-show="referrer.type != 'immediate'">
            <h3>You're invited to join Lucid.</h3>
            <p>
              With this invitation, you'll able to upgrade to a Founding Member
              via the upgrade option on the website.
            </p>
          </div>

          {referrer.type === 'immediate' && (
            <>
              <h3>You're invited to join Lucid.</h3>
              <p>
                Join Lucid as a Founding Member and get access to all of Lucid's
                premium features.
              </p>
              <div id='paypal-button-container'></div>
            </>
          )}

          <a href={`/?join=${referrer.id}`} className='btn btn-primary'>
            Register for Free
          </a>
        </div>
      </div>
    </div>
  );
}

export default Join;
