import React, { useState, useEffect } from 'react';
import {
  tokenHeaders,
  walletHeaders,
  topCryptoCapHeaders,
  topCryptoVolumeHeaders,
  gsHeaders,
} from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import '../styles/crypto.scss';
import { formatCurrency, formatNumber, formatDate } from '../helpers/format';

function Wallets({
  userType,
  isLoaded,
  handleShowCryptoModal,
  handleShowUpgrade,
  walletInfo,
}) {
  const [toggledTutorial, setToggledTutorial] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [walletData, setWalletData] = useState([]);
  const [walletOrderReversed, setWalletOrderReversed] = useState(false);
  const [walletOrderMethod, setWalletOrderMethod] = useState('holders');

  const handleSetOrderMethod = (header, type) => {
    if (type === 'wallet') {
      if (header === walletOrderMethod) {
        setWalletOrderReversed(!walletOrderReversed);
      } else {
        setWalletOrderMethod(header);
      }
    }
  };

  useEffect(() => {
    setWalletData(walletInfo);
    if (walletInfo?.length > 0) {
      setIsLoading(false);
    }
  }, [walletInfo, isLoaded]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='aggregates-page dashboard crypto'>
      {isLoading && <Loader title={`${userType} Crypto Data`} />}

      {!isLoading && (
        <>
          <div>
            <div className='header-content d-flex'>
              <h1>
                Token Tracker
                <a>
                  <i
                    style={{ marginLeft: '3px' }}
                    className='ion-help-circled'
                    onClick={() => setToggledTutorial(!toggledTutorial)}
                  ></i>
                </a>
              </h1>
            </div>
            {toggledTutorial && (
              <>
                <p className='aggregates-tutorial'>
                  Compiled and updated every 2 hours. Use this data to back
                  conviction on ERC-20 projects built on the Ethereum platform.
                  Look for projects that may have sharp increases in # of
                  holders, which may signal for new coin listings; or look for
                  projects which a large # of holders, which may signal for
                  stability and/or conviction in a project.
                </p>
              </>
            )}

            {userType === 'Member' && (
              <div>
                <div className='text-center'>
                  <br />
                  <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                  <p className='small faded'>
                    Support Lucid and get access to <br />
                    premium content.
                  </p>
                  <small>
                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade to Premium
                    </a>
                  </small>
                </div>
              </div>
            )}
            {userType === 'Premium' && (
              <div>
                {walletData?.length > 0 && (
                  <table className='fixed'>
                    <thead>
                      <tr>
                        {walletHeaders.map((d) => (
                          <th
                            key={d.id}
                            className={`${d.id} link`}
                            title={d.title}
                            onClick={() => handleSetOrderMethod(d.id, 'wallet')}
                          >
                            {d.name}
                            {`${walletOrderMethod}` === d.id && (
                              <i
                                className={
                                  walletOrderReversed
                                    ? 'ion-arrow-down-b small'
                                    : 'ion-arrow-up-b small'
                                }
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {walletData &&
                        walletData
                          .sort((a, b) => {
                            if (isNaN(a[walletOrderMethod])) {
                              if (walletOrderReversed) {
                                return `${a[walletOrderMethod]}`.localeCompare(
                                  `${b[walletOrderMethod]}`
                                );
                              } else {
                                return `${b[walletOrderMethod]}`.localeCompare(
                                  `${a[walletOrderMethod]}`
                                );
                              }
                            } else {
                              if (walletOrderReversed) {
                                return (
                                  a[walletOrderMethod] - b[walletOrderMethod]
                                );
                              } else {
                                return (
                                  b[walletOrderMethod] - a[walletOrderMethod]
                                );
                              }
                            }
                          })
                          .map((r, index) => (
                            <tr
                              key={`${r.symbol}${index}`}
                              className='link'
                              onClick={() => handleShowCryptoModal(r)}
                            >
                              <td title='Symbol' className='link'>
                                <img
                                  src={r.img}
                                  onError={(e) => {
                                    e.currentTarget.src =
                                      '/logos/Lucid-Round@2x.png';
                                    e.currentTarget.className += ' faded';
                                  }}
                                  className='coin-img'
                                />
                                <b>{r.symbol}</b>
                              </td>
                              <td title='# of Holders'>
                                {formatNumber(r.holders, 2)}
                              </td>
                              <td
                                title='Holder Chg'
                                className={r.hchg > 0 ? 'success' : 'danger'}
                              >
                                {formatNumber(r.hchg, 2)}%
                              </td>
                              <td title='Price'>
                                {formatCurrency(r.price, 'USD', 3)}
                              </td>
                              <td title='Price Chg'>
                                {formatNumber(r.chg, 2)}%
                              </td>
                              <td title='Volume'>{formatNumber(r.vol, 0)}</td>
                              <td title='Market Cap'>
                                {formatCurrency(r.mcap, 'USD', 0)}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Wallets;
