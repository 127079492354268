import React, { useState, useEffect } from 'react';
import { investorTabs } from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import Preview from '../layouts/preview';
import '../styles/calendars.scss';
import { formatCurrency, formatDate, formatNumber } from '../helpers/format';
import AlsoHeldBy from '../layouts/alsoheldby';

let headers = [];

function Investors({
  myWatchlist,
  userType,
  currentFundGroup,
  handleShowModal,
  handleShowUpgrade,
  investorsData,
  allInvestorsData,
  date,
  ticker,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [investors, setInvestors] = useState([]);
  const [orderReversed, setOrderReversed] = useState(false);
  const [orderMethod, setOrderMethod] = useState('Trade Date');
  const [selectedTab, setSelectedTab] = useState(investorTabs[0]);
  const [filterByTicker, setFilterByTicker] = useState(ticker);
  const [filterBy, setFilterBy] = useState([]);

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
    if (tab === investorTabs[0]) {
      setInvestors(allInvestorsData);
    }
    if (tab === investorTabs[1]) {
      setInvestors(investorsData);
    }
    if (tab === investorTabs[2]) {
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = investorsData.filter((f) =>
        WLtickers.includes(f.Symbol)
      );
      setInvestors(filteredByWL);
    }
  };

  const getInvestorsData = async () => {
    if (filterByTicker) {
      setInvestors(investorsData.filter((f) => f.Ticky === ticker));
    } else {
      setInvestors(investorsData);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    getInvestorsData();
  }, [date, investorsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={
        ticker ? `${currentFundGroup}` : `calendars ${currentFundGroup}`
      }
    >
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} Investors Activity`} />
      )}

      {!isLoading && (
        <div>
          <div>
            {!ticker && (
              <div className='header-content d-flex'>
                <h1>
                  <span className='hidden-mobile'>Also Held By </span>
                  <span className='faded'></span>
                </h1>

                <div className='tabs'>
                  {investorTabs.map((tab) => (
                    <div
                      key={tab}
                      className={selectedTab === tab ? 'tab active' : 'tab'}
                      onClick={() => handleSetSelectedTab(tab)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div>
              {userType === 'Member' && (
                <div>
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    <br />
                    <div className='jumbo'>🚀</div>
                    <h2>Upgrade to Premium</h2>
                    <p style={{ marginBottom: '20px' }}>
                      Get access to Also Held By for {currentFundGroup} ETFs and
                      premium member features.
                    </p>

                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade
                    </a>
                  </div>
                </div>
              )}
              {userType === 'Premium' && (
                <div className='h-scroll'>
                  {ticker && investors.length === 0 && 'No data found.'}
                  {investors.length === 0 && (
                    <div
                      className='panel'
                      style={{
                        padding: '20px 10px',
                        maxWidth: '800px',
                        textAlign: 'center',
                        border: 'none',
                      }}
                    >
                      <div className='jumbo'>🧐</div>
                      <h2>
                        No Also Held By in your {currentFundGroup} Watchlist.
                      </h2>
                      <p style={{ marginBottom: '20px' }}>
                        Check out the{' '}
                        <a
                          onClick={() => handleSetSelectedTab(investorTabs[0])}
                        >
                          {investorTabs[0]} Tab
                        </a>
                        .
                      </p>
                    </div>
                  )}

                  {investors.length > 0 && (
                    <AlsoHeldBy
                      investorsData={investors}
                      handleShowModal={handleShowModal}
                      filterBy={filterBy}
                      type='fixed'
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Investors;
