import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Announcement from '../layouts/announcement';
import Panel from '../layouts/panel';
import Preview from '../layouts/preview';
import Footer from '../layouts/footer';
import WatchButton from '../layouts/watch-button';
import PanelAggregates from '../layouts/panel-aggregates';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { formatNumber, formatCurrency } from '../helpers/format';
import BarVix from '../charts/bar-group-vix';
import '../styles/dashboard.scss';
import {
  aggregateTypes,
  addsAndDropsTypes,
  addsAndDropsPassiveTypes,
  dashboardTabs,
  chartTabs,
  dashboardHeaders,
  optionsActiveHeaders,
  stockVolumeHeaders,
  stockVolumeAdvancesHeaders,
  fundGroups,
  insiderHeaders,
  uptrendHeaders,
  canslimHeaders,
  marketOrderHeaders,
} from '../helpers/fundvars';
import { formatDate } from '../helpers/format';
import PanelWatchlist from '../layouts/panel-watchlist';
import PanelAddsDrops from '../layouts/panel-addsdrops';
import PanelRankings from '../layouts/panel-rankings';
import { checkIsWatching } from '../helpers/helpers';
//import { leftTickLabelProps } from '@visx/axis/lib/axis/AxisLeft';
import AlsoHeldBy from '../layouts/alsoheldby';
import UnusualOptions from '../layouts/unusualoptions';
import Squeeze from '../layouts/squeeze';
import Institutional from '../layouts/institutional';
import Senate from '../layouts/senate';

function Dashboard({
  userType,
  addDrops,
  addDropsPassive,
  topRankings,
  fundArray,
  currentFundGroup,
  stocklist,
  aggregateList,
  handleShowModal,
  handleShowUpgrade,
  addToWatchlist,
  earningsData,
  optionsData,
  optionsActiveData,
  stockVolumeData,
  stockVolumeAdvancesData,
  insiderData,
  dataLength,
  shortSqueezeData,
  institutionalData,
  uptrendData,
  canslimData,
  marketorderData,
  investorsData,
  myWatchlist,
  senateData,
  summarySenateBuys,
  summarySenateSells,
}) {
  const history = useHistory();
  //let queryParams = useLocation().search.split('=')[1];
  //console.log('myWatchlist', myWatchlist);
  let myWatchlistTickers = myWatchlist?.map((w) => w.ticker);
  const [isLoading, setIsLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [expandedRankings, setExpandedRankings] = useState(false);
  const [toggledAnnouncement, setToggledAnnouncement] = useState(false);
  const [expandAddsDrops, setExpandAddsDrops] = useState(false);
  const [expandTop, setExpandTop] = useState(false);
  const [expandPump, setExpandPump] = useState(false);
  const [expandDump, setExpandDump] = useState(false);
  const [selectedTab, setSelectedTab] = useState(dashboardTabs[0]);
  const [selectedFundTab, setSelectedFundTab] = useState('');
  const [orderMethod, setOrderMethod] = useState('weight');
  const [orderReversed, setOrderReversed] = useState(false);
  const [selectedChartTab, setSelectedChartTab] = useState(chartTabs[0]);
  const [earnings, setEarnings] = useState([]);
  const [squeeze, setSqueeze] = useState([]);
  const [uptrend, setUptrend] = useState([]);
  const [optionsActive, setOptionsActive] = useState([]);
  const [stockVolume, setStockVolume] = useState([]);
  const [stockVolumeChange, setStockVolumeChange] = useState([]);
  const [insiderBuying, setInsiderBuying] = useState([]);
  const [isHovered, setIsHovered] = useState('');
  const [isHoveredIndex, setIsHoveredIndex] = useState(null);
  const [canslim, setCanslim] = useState([]);
  const [marketorder, setMarketorder] = useState([]);
  const [marketOrderHeight, setMarketOrderHeight] = useState('345px');
  const [alsoHeldByHeight, setAlsoHeldByHeight] = useState('345px');
  const [filterBy, setFilterBy] = useState([]);
  const buysRef = useRef();
  const sellsRef = useRef();
  const earningsRef = useRef();
  const addsRef = useRef();
  const ranksRef = useRef();
  const squeezeRef = useRef();
  const uptrendRef = useRef();
  const canslimRef = useRef();
  const institutionalRef = useRef();
  const senateRef = useRef();

  /* const senateAmounts = senateData.map((item) => {
    const amt = item.Amount?.split('-')[1] || item.Amount;
    return {
      ...item,
      Amount: amt,
    };
  }); */

  useEffect(() => {
    if (fundArray.length > 0) {
      setIsLoading(false);
      setSelectedFundTab(fundArray[0].fund);
      setEarnings(earningsData);
      setOptionsActive(optionsActiveData);
      setStockVolume(stockVolumeData);
      setStockVolumeChange(stockVolumeAdvancesData);
      setInsiderBuying(insiderData);
      setSqueeze(shortSqueezeData);
      setUptrend(uptrendData);
      setCanslim(canslimData);
      setMarketorder(marketorderData);
    }
  }, [fundArray]);

  const goTo = (route) => {
    history.push(`/${route}`);
  };

  const orderBy = (orderMethod) => {
    setOrderMethod(orderMethod);
    setOrderReversed(!orderReversed);
  };

  const handleSetIsHovered = (ticker, index) => {
    setIsHovered(ticker);
    setIsHoveredIndex(index);
  };

  const handleSetSelectedTab = (tab) => {
    if (tab === 'Charts') {
      setIsChartLoading(true);
      setTimeout(() => {
        setIsChartLoading(false);
      }, 500);
    }
    setSelectedTab(tab);
  };

  const scrollToSection = (id) => {
    if (id === 'addsRef') {
      addsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'buysRef') {
      buysRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'sellsRef') {
      sellsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'ranksRef') {
      ranksRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'earningsRef') {
      earningsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'squeezeRef') {
      squeezeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'uptrendRef') {
      uptrendRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'canslimRef') {
      canslimRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'institutionalRef') {
      institutionalRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'senateRef') {
      senateRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSetSelectedChartTab = (c) => {
    setIsChartLoading(true);
    setSelectedChartTab(c);
    setTimeout(() => {
      setIsChartLoading(false);
    }, 100);
  };

  const handleAddToWatchlist = (ticker, action) => {
    addToWatchlist(ticker, action);
  };

  return (
    <div
      className={
        selectedTab === dashboardTabs[2]
          ? `dashboard affix ${currentFundGroup}`
          : `dashboard ${currentFundGroup}`
      }
    >
      <div>
        {isLoading && (
          <div className='loading-indicator'>
            <h1 className='text-center'>{currentFundGroup} Dashboard</h1>
            <div className='loader'></div>
            Loading{' '}
            {dataLength === '60 Days'
              ? '60 Days of Data (Faster Loading Time)'
              : 'Full Data (Longer Loading Time)'}
          </div>
        )}
        <Announcement
          currentFundGroup={currentFundGroup}
          fundGroups={fundGroups}
        />

        {!isLoading && (
          <div>
            <div className='header-content d-flex'>
              <h1>
                <span className='hidden-mobile'>Dashboard </span>
                <span className='faded'>
                  {stocklist &&
                    selectedTab === dashboardTabs[2] &&
                    formatDate(stocklist[0].date)}
                </span>
              </h1>
              <div className='tabs'>
                {dashboardTabs.map((tab) => (
                  <div
                    key={tab}
                    className={selectedTab === tab ? 'tab active' : 'tab'}
                    onClick={() => handleSetSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>

            {selectedTab === dashboardTabs[2] && (
              <div>
                <div className='h-scroll sub-header double'>
                  <div className='tabs'>
                    {fundArray?.map((ff) => (
                      <div
                        key={ff.fund}
                        className={
                          selectedFundTab === ff.fund ? 'tab active' : 'tab'
                        }
                        onClick={() => setSelectedFundTab(ff.fund)}
                      >
                        <div>
                          {ff.fund}
                          <span
                            className={
                              selectedFundTab === ff.fund ? 'small' : 'hidden'
                            }
                          >
                            {/* {' '}
                            {ff.list[0].data.date} */}
                          </span>
                        </div>
                        <small className='faded white'>{ff.name}</small>
                      </div>
                    ))}
                  </div>
                </div>
                {userType === 'Member' && (
                  <div className='text-center'>
                    <br />
                    <br />
                    <div className='jumbo'>🚀</div>
                    <h2 style={{ marginTop: 0 }}>Upgrade to Premium</h2>
                    <p style={{ marginBottom: '20px' }}>
                      Support Lucid and get access to <br />
                      premium content.
                    </p>

                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade to Premium
                    </a>

                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {userType === 'Premium' && (
                  <div>
                    {fundArray?.map((f) => (
                      <div
                        key={f.name}
                        className={selectedFundTab === f.fund ? '' : 'hidden'}
                      >
                        <div>
                          <div className='h-scroll'>
                            <table className='striped fixed'>
                              <thead>
                                <tr className='link'>
                                  <th>Action</th>
                                  {dashboardHeaders.map((d) => (
                                    <th
                                      key={d.id}
                                      className={d.id}
                                      title={d.title}
                                      onClick={() => orderBy(d.id)}
                                    >
                                      {d.name}
                                      <i
                                        className={
                                          orderMethod === d.id && orderReversed
                                            ? 'ion-arrow-up-b small'
                                            : ''
                                        }
                                      ></i>
                                      <i
                                        className={
                                          orderMethod === d.id && !orderReversed
                                            ? 'ion-arrow-down-b small'
                                            : ''
                                        }
                                      ></i>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {f.list
                                  .sort((a, b) => {
                                    if (isNaN(a.data[orderMethod])) {
                                      if (orderReversed) {
                                        return `${a.data[orderMethod]}`.localeCompare(
                                          `${b.data[orderMethod]}`
                                        );
                                      } else {
                                        return `${b.data[orderMethod]}`.localeCompare(
                                          `${a.data[orderMethod]}`
                                        );
                                      }
                                    } else {
                                      if (orderReversed) {
                                        return (
                                          a.data[orderMethod] -
                                          b.data[orderMethod]
                                        );
                                      } else {
                                        return (
                                          b.data[orderMethod] -
                                          a.data[orderMethod]
                                        );
                                      }
                                    }
                                  })
                                  .map((ff, index) => (
                                    <tr key={ff.fund} className='link'>
                                      <td>
                                        <WatchButton
                                          ticker={ff.data.ticker}
                                          isWatching={checkIsWatching(
                                            ff.data.ticker
                                          )}
                                          handleAddToWatchlist={
                                            handleAddToWatchlist
                                          }
                                        />
                                      </td>
                                      <td
                                        onClick={() =>
                                          handleShowModal(ff.data.ticker)
                                        }
                                        className='Company'
                                      >
                                        {ff.data.company}
                                      </td>
                                      <Preview
                                        ticker={ff.data.ticker}
                                        itemIndex={index}
                                        handleShowModal={handleShowModal}
                                        breakPoint='4'
                                      />
                                      <td>{formatNumber(ff.data.shares)}</td>
                                      <td>{ff.data.weight}</td>
                                      <td>
                                        {formatCurrency(
                                          ff.data.sharesmarketvalue,
                                          'USD',
                                          0
                                        )}
                                      </td>
                                      <td
                                        className={
                                          ff.data.dailychange > 0
                                            ? 'success'
                                            : ff.data.dailychange == 0
                                            ? 'faded'
                                            : 'danger'
                                        }
                                      >
                                        {ff.data.dailychange}%
                                      </td>
                                      <td
                                        className={
                                          ff.data.dailycapitalinjection > 0
                                            ? 'success'
                                            : ff.data.dailycapitalinjection == 0
                                            ? 'faded'
                                            : 'danger'
                                        }
                                      >
                                        {formatCurrency(
                                          ff.data.dailycapitalinjection,
                                          'USD',
                                          0
                                        )}
                                      </td>
                                      <td
                                        className={
                                          ff.data.weeklycapitalinjection > 0
                                            ? 'success'
                                            : ff.data.weeklycapitalinjection ==
                                              0
                                            ? 'faded'
                                            : 'danger'
                                        }
                                      >
                                        {formatCurrency(
                                          ff.data.weeklycapitalinjection,
                                          'USD',
                                          0
                                        )}
                                      </td>
                                      <td
                                        className={
                                          ff.data.priceclosed >
                                          ff.data.previousclose
                                            ? 'success'
                                            : ff.data.priceclosed ==
                                              ff.data.previousclose
                                            ? 'faded'
                                            : 'danger'
                                        }
                                      >
                                        {formatCurrency(
                                          ff.data.priceclosed,
                                          'USD',
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {formatCurrency(
                                          ff.data.previousclose,
                                          'USD',
                                          2
                                        )}
                                      </td>
                                      <td className='tradenotedir'>
                                        {ff.data.tradenotedir ? (
                                          <span
                                            className={
                                              ff.data.tradenotedir === 'Buy'
                                                ? 'success'
                                                : 'danger'
                                            }
                                          >
                                            {ff.data.tradenotedir}
                                          </span>
                                        ) : (
                                          <span className='faded'>N/A</span>
                                        )}
                                      </td>
                                      <td className='tradenoteshares'>
                                        {ff.data.tradenoteshares ? (
                                          <span
                                            className={
                                              ff.data.tradenotedir === 'Buy'
                                                ? 'success'
                                                : 'danger'
                                            }
                                          >
                                            {formatNumber(
                                              ff.data.tradenoteshares,
                                              0
                                            )}
                                          </span>
                                        ) : (
                                          <span className='faded'>N/A</span>
                                        )}
                                      </td>
                                      <td className='tradenotedir'>
                                        {ff.data.tradenotevalue ? (
                                          <span
                                            className={
                                              ff.data.tradenotedir === 'Buy'
                                                ? 'success'
                                                : 'danger'
                                            }
                                          >
                                            {formatCurrency(
                                              ff.data.tradenotevalue,
                                              'USD',
                                              0
                                            )}
                                          </span>
                                        ) : (
                                          <span className='faded'>N/A</span>
                                        )}
                                      </td>
                                      <td>
                                        {ff.data.float &&
                                          formatNumber(ff.data.float, 2)}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {selectedTab === dashboardTabs[1] && (
              <div>
                {isChartLoading && (
                  <div className='section-loader'>
                    <div className='loader'></div>
                    Loading {dashboardTabs[1]}
                  </div>
                )}

                <div>
                  <div className='h-scroll sub-header'>
                    <div className='tabs'>
                      {chartTabs?.map((c) => (
                        <div
                          key={c.label}
                          className={
                            selectedChartTab === c ? 'tab active' : 'tab'
                          }
                          onClick={() => handleSetSelectedChartTab(c)}
                        >
                          <div>{c.label}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {fundArray?.map((f) => (
                    <div key={f.fund}>
                      <h2 className='text-center'>
                        {f.fund} {f.name}
                        <span
                          style={{ marginLeft: '3px' }}
                          title={`${f.type}ly Managed Fund`}
                          className={
                            f.type === 'Active'
                              ? 'badge small primary'
                              : 'badge small'
                          }
                        >
                          {f.type.substring(0, 1)}
                        </span>
                        <div className='small faded'>
                          {formatDate(f.data[0].date)} to{' '}
                          {formatDate(f.list[0].data.date)}
                        </div>
                      </h2>
                      <p
                        className='text-center'
                        style={{
                          padding: '0 15px',
                          maxWidth: '800px',
                          margin: 'auto',
                          fontSize: '13px',
                        }}
                      >
                        {f.description}
                      </p>
                      <div className='h-scroll'>
                        <div
                          className='fade-in'
                          key={f.fund}
                          style={{
                            height: 400,
                            minWidth: `${f.chartData?.length * 60}px`,
                          }}
                        >
                          <ParentSize>
                            {({ width, height }) => (
                              <BarVix
                                width={width}
                                height={height}
                                chartData={f.chartData}
                                type={selectedChartTab.type}
                                handleShowModal={handleShowModal}
                                currentFundGroup={currentFundGroup}
                              />
                            )}
                          </ParentSize>
                        </div>
                      </div>
                      <div className='horizontal-scroll'>
                        {f?.list.map((l) => (
                          <div key={l.ticker} className='card'>
                            <div
                              className='link'
                              onClick={() => handleShowModal(l.ticker)}
                            >
                              <h5>{l.ticker}</h5>
                              <p>{l.company}</p>
                            </div>
                            <WatchButton
                              ticker={l.ticker}
                              isWatching={checkIsWatching(l.ticker)}
                              handleAddToWatchlist={handleAddToWatchlist}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  <h2 className='text-center'>
                    Aggregate{' '}
                    <div className='small faded'>
                      {formatDate(fundArray[0].data[0].date)} to{' '}
                      {formatDate(fundArray[0].list[0].data.date)}
                    </div>
                  </h2>
                  <p
                    className='text-center'
                    style={{
                      padding: '0 15px',
                      maxWidth: '800px',
                      margin: 'auto',
                      fontSize: '13px',
                    }}
                  >
                    Data summed across all ETFs giving you the net total across
                    their entire portfolio for each individual stock.
                  </p>
                  <div className='h-scroll'>
                    {aggregateList && (
                      <div
                        className='fade-in chart-lines'
                        style={{
                          height: 400,
                          minWidth: `${aggregateList.length * 60}px`,
                        }}
                      >
                        <ParentSize>
                          {({ width, height }) => (
                            <BarVix
                              width={width}
                              height={height}
                              chartData={aggregateList}
                              type={selectedChartTab.type}
                              group='Aggregate'
                              currentFundGroup={currentFundGroup}
                              handleShowModal={handleShowModal}
                            />
                          )}
                        </ParentSize>
                      </div>
                    )}
                  </div>
                  <div className='horizontal-scroll'>
                    {aggregateList.map((l) => (
                      <div key={l.ticker} className='card'>
                        <div
                          className='link'
                          onClick={() => handleShowModal(l.ticker)}
                        >
                          <h5>{l.ticker}</h5>
                          <p>{l.company}</p>
                        </div>
                        <WatchButton
                          ticker={l.ticker}
                          isWatching={checkIsWatching(l.ticker)}
                          handleAddToWatchlist={handleAddToWatchlist}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {selectedTab === dashboardTabs[0] && (
              <>
                <div className='panel-container text-center summary'>
                  <h2>
                    Summary{' '}
                    <small className='faded'>
                      {formatDate(fundArray[0]?.list[0]?.data?.date)}
                    </small>
                  </h2>
                  <h5 className='faded' style={{ marginBottom: '10px' }}>
                    Notable happenings with {currentFundGroup} ETFs.
                    {userType === 'Premium' && ' Premium only.'}
                  </h5>

                  <div className='h-scroll'>
                    {userType === 'Premium' ? (
                      <div>
                        <section title='Top bought by Market Value.'>
                          <b onClick={() => scrollToSection('buysRef')}>
                            Top Bought
                          </b>
                          {stocklist
                            ?.sort((a, b) => {
                              return b.bought - a.bought;
                            })
                            .slice(0, 5)
                            .map((s, index) => (
                              <div
                                key={`top${s.ticker}${index}`}
                                className='item success'
                                onClick={() => handleShowModal(s.ticker)}
                              >
                                {s.ticker}
                              </div>
                            ))}
                        </section>
                        <section title='Top sold by Market Value.'>
                          <b onClick={() => scrollToSection('sellsRef')}>
                            Top Sold
                          </b>
                          {stocklist
                            ?.sort((b, a) => {
                              return b.bought - a.bought;
                            })
                            .slice(0, 5)
                            .map((s, index) => (
                              <div
                                key={`sold${s.ticker}${index}`}
                                className='item danger'
                                onClick={() => handleShowModal(s.ticker)}
                              >
                                {s.ticker}
                              </div>
                            ))}
                        </section>
                        <section title='Green color is buying, Red is selling as of the day before.'>
                          <b onClick={() => scrollToSection('earningsRef')}>
                            Upcoming Earnings
                          </b>
                          {earnings?.slice(0, 5).map((s, index) => (
                            <div
                              key={`earnings${s.ticker}${index}`}
                              className={
                                s.shares === s.shares1d
                                  ? 'faded item'
                                  : s.shares > s.shares1d
                                  ? 'success item'
                                  : 'danger item'
                              }
                              onClick={() => handleShowModal(s.symbol)}
                            >
                              {s.symbol}
                            </div>
                          ))}
                        </section>
                        <section title='Newly added Stocks to an ETF.'>
                          <b onClick={() => scrollToSection('addsRef')}>
                            Latest Adds
                          </b>
                          {addDrops
                            ?.filter((a) => a.buysellvolume > 0)
                            .slice(0, 5)
                            .map((s, index) => (
                              <div
                                key={`adds${s.ticker}${index}`}
                                className='item success'
                                onClick={() => handleShowModal(s.ticker)}
                              >
                                {s.ticker}
                              </div>
                            ))}
                        </section>
                        <section title='Stocks dropped entirely off an ETF.'>
                          <b onClick={() => scrollToSection('addsRef')}>
                            Latest Drops
                          </b>
                          {addDrops
                            ?.filter((a) => a.buysellvolume < 0)
                            .slice(0, 5)
                            .map((s, index) => (
                              <div
                                key={`drops${s.ticker}${index}`}
                                className='item danger'
                                onClick={() => handleShowModal(s.ticker)}
                              >
                                {s.ticker}
                              </div>
                            ))}
                        </section>
                        <section title='Stocks that increased in rank.'>
                          <b onClick={() => scrollToSection('ranksRef')}>
                            Rank Change
                          </b>
                          {topRankings &&
                            topRankings[0].data
                              .sort((b, a) => {
                                return a.rankchg - b.rankchg;
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`rankstop${s.ticker}${index}`}
                                  className='item'
                                  onClick={() => handleShowModal(s.ticker)}
                                >
                                  {s.ticker}{' '}
                                  <small className='success'>
                                    <i className='ion-arrow-up-a'></i>
                                    {s.rankchg}%
                                  </small>
                                </div>
                              ))}
                        </section>

                        <section title='Stocks rising in price with large short positons that may need to be closed pushing the price higher.'>
                          <b onClick={() => scrollToSection('squeezeRef')}>
                            Short Squeeze{' '}
                          </b>
                          {squeeze &&
                            squeeze
                              .sort((b, a) => {
                                return b['No.'] - a['No.'];
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`ss${s.ticker}${index}`}
                                  className='item'
                                  onClick={() => handleShowModal(s.Ticker)}
                                >
                                  {s.Ticker}
                                </div>
                              ))}
                          {squeeze.length === 0 && (
                            <span className='faded'>No Short Squeezes</span>
                          )}
                        </section>
                        <section title='Stocks that are down this week with a potential to rebound. Green indicates weekly performance is greater than monthly performance.'>
                          <b onClick={() => scrollToSection('uptrendRef')}>
                            Uptrend Potential
                          </b>
                          {uptrend &&
                            uptrend
                              .sort((b, a) => {
                                return b['No.'] - a['No.'];
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`ut${s.Ticker}${index}`}
                                  className={
                                    s['Perf Week'] === s['Perf Month']
                                      ? 'faded item'
                                      : s['Perf Week'] > s['Perf Month']
                                      ? 'success item'
                                      : 'danger item'
                                  }
                                  onClick={() => handleShowModal(s.Ticker)}
                                >
                                  {s.Ticker}
                                </div>
                              ))}
                          {uptrend.length === 0 && (
                            <span className='faded'>No uptrend found.</span>
                          )}
                        </section>
                        <section title='CANSLIM: get into these high-growth stocks before institutional funds are fully invested.'>
                          <b onClick={() => scrollToSection('canslimRef')}>
                            CANSLIM
                          </b>
                          {canslim &&
                            canslim
                              .sort((b, a) => {
                                return b['No.'] - a['No.'];
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`can${s.Ticker}${index}`}
                                  className='item'
                                  onClick={() => handleShowModal(s.Ticker)}
                                >
                                  {s.Ticker}
                                </div>
                              ))}
                          {canslim.length === 0 && (
                            <span className='faded'>No CANSLIM found.</span>
                          )}
                        </section>
                        <section title='Institutional Ownership %'>
                          <b
                            onClick={() => scrollToSection('institutionalRef')}
                          >
                            Institutional Ownership{' '}
                          </b>
                          {institutionalData &&
                            institutionalData
                              .sort((b, a) => {
                                return a['Inst Own'] - b['Inst Own'];
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`insti${s.Ticker}${index}`}
                                  className='item'
                                  onClick={() => handleShowModal(s.Ticker)}
                                >
                                  {s.Ticker}
                                </div>
                              ))}
                          {squeeze.length === 0 && (
                            <span className='faded'>
                              No Institutional Buying.
                            </span>
                          )}
                        </section>
                        <section title='Disclosed US Senate Buying'>
                          <b onClick={() => scrollToSection('senateRef')}>
                            US Senate Buys{' '}
                          </b>
                          {summarySenateBuys &&
                            summarySenateBuys
                              .sort((b, a) => {
                                return a['Amount'] - b['Amount'];
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`senatebuys${s.Ticker}${index}`}
                                  className='item'
                                  onClick={() => handleShowModal(s.Ticker)}
                                >
                                  {s.Ticker}
                                </div>
                              ))}

                          {summarySenateBuys.length === 0 && (
                            <span className='faded'>
                              No Senate/Congress Buying.
                            </span>
                          )}
                        </section>
                        <section title='Disclosed US Senate Buying'>
                          <b onClick={() => scrollToSection('senateRef')}>
                            US Senate Sells{' '}
                          </b>
                          {summarySenateSells &&
                            summarySenateSells
                              .sort((b, a) => {
                                return a['Amount'] - b['Amount'];
                              })
                              .slice(0, 5)
                              .map((s, index) => (
                                <div
                                  key={`senatesells${s.Ticker}${index}`}
                                  className='item'
                                  onClick={() => handleShowModal(s.Ticker)}
                                >
                                  {s.Ticker}
                                </div>
                              ))}

                          {summarySenateSells.length === 0 && (
                            <span className='faded'>
                              No Senate/Congress Selling.
                            </span>
                          )}
                        </section>
                      </div>
                    ) : (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {marketorder?.length > 0 && (
                  <div className='panel-container text-center'>
                    <h2>
                      Market Orders{' '}
                      <small className='faded'>
                        {formatDate(marketorder[0]?.Date)}
                      </small>
                    </h2>
                    <h5 className='faded'>
                      Can be up to <u>2 Days delayed</u> as {currentFundGroup}{' '}
                      waits for Market Orders to fully execute before reporting
                      them. For the most up-to-date data, use the Shares data in
                      the Daily Buys and Daily Sells section below.
                    </h5>
                    <div className='pumpdump'>
                      <a
                        className='btn btn-secondary'
                        style={{ margin: '10px auto 0' }}
                        onClick={() =>
                          marketOrderHeight !== '100%'
                            ? setMarketOrderHeight('100%')
                            : setMarketOrderHeight('345px')
                        }
                      >
                        {marketOrderHeight !== '100%' ? 'Expand' : 'Collapse'}
                      </a>
                    </div>
                    <br />

                    <div
                      className='h-scroll'
                      style={{
                        height: marketOrderHeight,
                        overflow: 'auto',
                        maxWidth: '1200px',
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            {marketOrderHeaders.map((d) => (
                              <th key={d.id} className={d.id} title={d.title}>
                                {d.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {marketorder
                            .sort((a, b) => {
                              return b['Market Value'] - a['Market Value'];
                            })
                            .map((r, index) => (
                              <tr
                                className={
                                  r.Direction === 'Buy'
                                    ? 'link success'
                                    : 'link danger'
                                }
                                key={`${r.Ticker}${r.Fund}`}
                                onClick={() => handleShowModal(r.Ticker)}
                              >
                                {/* <td>{formatDate(r.Date)}</td> */}
                                <td>{r.Direction}</td>
                                <Preview
                                  ticker={r.Ticker}
                                  itemIndex={index}
                                  breakPoint='4'
                                />
                                <td>{r.Company}</td>

                                <td>{formatNumber(r.Shares, 0)}</td>
                                <td>{r['% of ETF']}</td>
                                <td>${r.Close?.toFixed(2)}</td>
                                <td>
                                  {formatCurrency(r['Market Value'], 'USD', 0)}
                                </td>
                                <td>{r.Fund}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className='panel-container text-center'>
                  <h2 ref={buysRef}>
                    Daily Buys*
                    <small className='faded'>
                      {formatDate(fundArray[0].list[0].data.date)}
                    </small>
                  </h2>
                  <h5 className='faded'>
                    {currentFundGroup} increased their position in these stocks
                    from previous date. <br />
                    *Buys represent shares added via market orders and ETF unit
                    creations.
                    {userType === 'Premium' &&
                      ' Premium members see all panels.'}
                  </h5>

                  <div className='pumpdump'>
                    <a
                      className='btn btn-secondary'
                      onClick={() => setExpandPump(!expandPump)}
                    >
                      {expandPump ? 'Collapse' : 'Expand'}
                    </a>

                    <Link
                      className='btn btn-secondary'
                      to={{
                        pathname: '/aggregate',
                        //search: '?sort=name',
                        //hash: '#the-hash',
                        //state: { fromDashboard: true },
                      }}
                    >
                      View Aggregate
                    </Link>
                  </div>

                  <div className='h-scroll'>
                    {userType === 'Premium' ? (
                      <>
                        {fundArray?.map((f, index) => (
                          <div
                            key={`${f.fund}${index}`}
                            className={expandPump ? 'panel expanded' : 'panel'}
                          >
                            <Panel
                              data={f}
                              handleShowModal={handleShowModal}
                              type='pump'
                            ></Panel>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {fundArray.map((f, index) => (
                          <div
                            key={f.fund}
                            className={expandPump ? 'expanded panel' : 'panel'}
                          >
                            {index < 3 ? (
                              <Panel
                                data={f}
                                handleShowModal={handleShowModal}
                                type='pump'
                              ></Panel>
                            ) : (
                              <div className='text-center'>
                                <h4>
                                  {f.fund} {f.name}
                                </h4>
                                <br />
                                <br />
                                <p style={{ margin: '0 auto 2px' }}>
                                  Premium Content
                                </p>
                                <p className='small faded'>
                                  Support Lucid and get access to <br />
                                  premium content.
                                </p>
                                <small>
                                  <a
                                    onClick={() => handleShowUpgrade(true)}
                                    className='btn btn-primary'
                                  >
                                    Upgrade to Premium
                                  </a>
                                </small>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className='panel-container text-center'>
                  <h2 ref={sellsRef}>
                    Daily Sells*
                    <small className='faded'>
                      {formatDate(fundArray[0].list[0].data.date)}
                    </small>
                  </h2>
                  <h5 className='faded'>
                    {currentFundGroup} decreased their position in these stocks
                    from previous date. <br />
                    *Sells represent shares sold via market orders and ETF unit
                    redemptions.
                    {userType === 'Premium' &&
                      ' Premium members see all panels.'}
                  </h5>
                  <div className='pumpdump'>
                    <a
                      className='btn btn-secondary'
                      onClick={() => setExpandDump(!expandDump)}
                    >
                      {expandDump ? 'Collapse' : 'Expand'}
                    </a>
                    <Link
                      className='btn btn-secondary'
                      to={{
                        pathname: '/aggregate',
                        search: '?sort=sells',
                        //hash: '#the-hash',
                        //state: { fromDashboard: true },
                      }}
                    >
                      View Aggregate
                    </Link>
                  </div>
                  <div className='h-scroll'>
                    {userType === 'Premium' ? (
                      <div>
                        {fundArray.map((f) => (
                          <div
                            key={f.fund}
                            className={expandDump ? 'expanded panel' : 'panel'}
                          >
                            <Panel
                              data={f}
                              handleShowModal={handleShowModal}
                              type='dump'
                            ></Panel>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {fundArray.map((f, index) => (
                          <div
                            key={f.fund}
                            className={expandDump ? 'expanded panel' : 'panel'}
                          >
                            {index < 3 ? (
                              <Panel
                                data={f}
                                handleShowModal={handleShowModal}
                                type='dump'
                              ></Panel>
                            ) : (
                              <div className='text-center'>
                                <h4>
                                  {f.fund} {f.name}
                                </h4>
                                <br />
                                <br />
                                <p style={{ margin: '0 auto 2px' }}>
                                  Premium Content
                                </p>
                                <p className='small faded'>
                                  Support Lucid and get access to <br />
                                  premium content.
                                </p>
                                <small>
                                  <a
                                    onClick={() => handleShowUpgrade(true)}
                                    className='btn btn-primary'
                                  >
                                    Upgrade to Premium
                                  </a>
                                </small>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>
                    Aggregates{' '}
                    <small className='faded'>
                      {formatDate(fundArray[0].list[0].data.date)}
                    </small>
                  </h2>
                  <h5 className='faded'>
                    Top metrics when looking at the aggregate of the ETFs.
                    {userType === 'Premium' &&
                      ' Premium members see all panels.'}
                  </h5>

                  <div className='pumpdump'>
                    <a
                      className='btn btn-secondary'
                      onClick={() => setExpandTop(!expandTop)}
                    >
                      {expandTop ? 'Collapse' : 'Expand'}
                    </a>
                    <a
                      onClick={() => goTo('aggregate')}
                      className='btn btn-secondary'
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <div className='h-scroll'>
                    {userType === 'Premium' ? (
                      <div>
                        {aggregateTypes.map((a) => (
                          <div
                            key={a.type}
                            className={expandTop ? 'expanded panel' : 'panel'}
                          >
                            <PanelAggregates
                              data={stocklist}
                              handleShowModal={handleShowModal}
                              type={a.type}
                            ></PanelAggregates>
                          </div>
                        ))}
                        <div className={expandTop ? 'expanded panel' : 'panel'}>
                          <PanelWatchlist
                            handleShowModal={handleShowModal}
                          ></PanelWatchlist>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={addsRef}>Adds and Drops</h2>
                  <h5 className='faded'>
                    A log of when a new stock is added or dropped from an ETF.
                    {userType === 'Premium' && ' Premium members see content.'}
                  </h5>
                  <div className='pumpdump'>
                    <a
                      className='btn btn-secondary'
                      onClick={() => setExpandAddsDrops(!expandAddsDrops)}
                    >
                      {expandAddsDrops ? 'Collapse' : 'Expand'}
                    </a>
                  </div>
                  <div className='h-scroll'>
                    {userType === 'Premium' ? (
                      <div>
                        {addsAndDropsTypes.map((a) => (
                          <div
                            key={`${a.type}adds`}
                            className={
                              expandAddsDrops ? 'expanded panel' : 'panel'
                            }
                          >
                            <PanelAddsDrops
                              data={addDrops}
                              handleShowModal={handleShowModal}
                              type={a.type}
                            ></PanelAddsDrops>
                          </div>
                        ))}
                        {addDropsPassive?.length
                          ? addsAndDropsPassiveTypes.map((a) => (
                              <div
                                key={a.type}
                                className={
                                  expandAddsDrops ? 'expanded panel' : 'panel'
                                }
                              >
                                <PanelAddsDrops
                                  data={addDropsPassive}
                                  handleShowModal={handleShowModal}
                                  type={a.type}
                                ></PanelAddsDrops>
                              </div>
                            ))
                          : ''}
                      </div>
                    ) : (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='panel-container text-center'>
                  <h2 ref={ranksRef}>
                    Notable Change in Rank for
                    <small className='faded'>
                      {formatDate(fundArray[0].list[0].data.date)}
                    </small>
                  </h2>
                  <h5 className='faded'>
                    Stocks where its fund weight increased/decreased the most.
                    {userType === 'Premium' && ' Premium members see content.'}
                  </h5>
                  <div className='pumpdump'>
                    <a
                      className='btn btn-secondary'
                      onClick={() => setExpandedRankings(!expandedRankings)}
                    >
                      {expandedRankings ? 'Collapse' : 'Expand'}
                    </a>
                    <a
                      onClick={() => goTo('rankings')}
                      className='btn btn-secondary'
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <div className='h-scroll'>
                    <div>
                      {topRankings?.map((fund) => (
                        <div
                          key={fund.fund}
                          className={
                            expandedRankings ? 'expanded panel' : 'panel'
                          }
                        >
                          <PanelRankings
                            data={fund}
                            handleShowModal={handleShowModal}
                          ></PanelRankings>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Also Held By</h2>
                  <h5 className='faded'>
                    Taken from SEC (i.e. 13F/C) filings of the investor's
                    portfolio. Use this to further your conviction on a stock.
                  </h5>

                  <div className='pumpdump'>
                    <a
                      className='btn btn-secondary'
                      style={{ margin: '10px 2.5px 0 0' }}
                      onClick={() =>
                        alsoHeldByHeight !== '100%'
                          ? setAlsoHeldByHeight('100%')
                          : setAlsoHeldByHeight('345px')
                      }
                    >
                      {alsoHeldByHeight !== '100%' ? 'Expand' : 'Collapse'}
                    </a>
                    <a
                      className='btn btn-secondary'
                      style={{ margin: '10px 2.5px 0 2.5px' }}
                      onClick={() =>
                        filterBy.length > 0
                          ? setFilterBy([])
                          : setFilterBy(myWatchlistTickers)
                      }
                    >
                      {filterBy.length > 0
                        ? `All ${currentFundGroup}`
                        : 'My Watchlist'}
                    </a>
                    <a
                      onClick={() => goTo('investors')}
                      className='btn btn-secondary'
                      style={{ margin: '10px 0 0 2.5px' }}
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <br />
                  <div
                    className='h-scroll'
                    style={{
                      height: alsoHeldByHeight,
                      overflow: 'auto',
                      maxWidth: '1800px',
                    }}
                  >
                    {userType === 'Premium' && (
                      <AlsoHeldBy
                        investorsData={investorsData}
                        handleShowModal={handleShowModal}
                        filterBy={filterBy}
                      />
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            See which stocks Warren Buffett and other icons hold
                            from the {currentFundGroup} ETFs.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={earningsRef}>Upcoming Earnings</h2>
                  <h5 className='faded'>
                    Track what {currentFundGroup} is doing prior to Earnings.
                  </h5>
                  <div className='pumpdump'>
                    <a
                      onClick={() => goTo('calendars')}
                      className='btn btn-secondary'
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <br />
                  <div className='h-scroll' style={{ maxWidth: '1800px' }}>
                    {userType === 'Premium' && (
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th className='Company hidden-mobile'>Company</th>
                            <th>Ticker</th>
                            <th>
                              Shares Held
                              <div>
                                {formatDate(fundArray[0].list[0].data.date)}
                              </div>
                            </th>
                            <th>-1 Day</th>
                            <th>-7 Days</th>
                            <th>-14 Days</th>
                            <th>-30 Days</th>
                            <th>Estimate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {earnings?.slice(0, 10).map((r, index) => (
                            <tr
                              className='link'
                              key={r.symbol}
                              onClick={() => handleShowModal(r.symbol)}
                            >
                              <td>{formatDate(r.reportDate)}</td>
                              <td className='Company hidden-mobile'>
                                {r.name}
                              </td>
                              <Preview
                                ticker={r.symbol}
                                itemIndex={index}
                                breakPoint='4'
                              />
                              <td
                                className={
                                  r.shares === r.shares1d
                                    ? 'faded'
                                    : r.shares > r.shares1d
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares, 2)}
                              </td>
                              <td
                                className={
                                  r.shares1d === r.shares7d
                                    ? 'faded'
                                    : r.shares1d > r.shares7d
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares1d, 2)}
                              </td>
                              <td
                                className={
                                  r.shares7d === r.shares14d
                                    ? 'faded'
                                    : r.shares7d > r.shares14d
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares7d, 2)}
                              </td>
                              <td
                                className={
                                  r.shares14d === r.shares30
                                    ? 'faded'
                                    : r.shares14d > r.shares30
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares14d, 2)}
                              </td>
                              <td>{formatNumber(r.shares30d, 2)}</td>
                              <td
                                className={
                                  r.estimate < 0
                                    ? 'danger'
                                    : r.estimate > 0
                                    ? 'success'
                                    : 'faded'
                                }
                              >
                                ${r.estimate?.toFixed(2)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Most Traded Stocks</h2>
                  <h3 className='faded'>Daily Summary US Markets</h3>
                  <h5 className='faded'>
                    Ranks {currentFundGroup}'s stocks by the highest daily trade
                    volume.
                  </h5>
                  <div className='pumpdump'>
                    <a
                      onClick={() => goTo('stock')}
                      className='btn btn-secondary'
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <br />
                  <div className='h-scroll' style={{ maxWidth: '1000px' }}>
                    {userType === 'Premium' && (
                      <table>
                        <thead>
                          <tr>
                            {stockVolumeHeaders.map((d) => (
                              <th key={d.id} className={d.id} title={d.title}>
                                {d.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {stockVolume?.slice(0, 10).map((r, index) => (
                            <tr
                              className='link'
                              key={r.Symbol}
                              onClick={() => handleShowModal(r.Symbol)}
                            >
                              <Preview
                                ticker={r.Symbol}
                                itemIndex={index}
                                breakPoint='4'
                              />
                              <td>{r.Name}</td>
                              <td>{formatNumber(r.Volume, 0)}</td>
                              <td>{r.Time}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='panel-container text-center'>
                  <h2>Stock Volume Percent Advances </h2>
                  <h3 className='faded'>Daily Summary US Markets</h3>
                  <h5 className='faded'>
                    Ranks {currentFundGroup}'s stocks by their Volume Advance (%
                    change in current volume from 20-day average volume). Use
                    this to pinpoint spikes in volume relative to their average
                    volume.
                  </h5>
                  <div className='pumpdump'>
                    <a
                      onClick={() => goTo('stock')}
                      className='btn btn-secondary'
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <br />
                  <div className='h-scroll' style={{ maxWidth: '1000px' }}>
                    {userType === 'Premium' && (
                      <table>
                        <thead>
                          <tr>
                            {stockVolumeAdvancesHeaders.map((d) => (
                              <th key={d.id} className={d.id} title={d.title}>
                                {d.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {stockVolumeChange?.slice(0, 10).map((r, index) => (
                            <tr
                              className='link'
                              key={r.Symbol}
                              onClick={() => handleShowModal(r.Symbol)}
                            >
                              <Preview
                                ticker={r.Symbol}
                                itemIndex={index}
                                breakPoint='4'
                              />
                              <td>{r.Name}</td>
                              <td>{formatNumber(r.Volume, 0)}</td>
                              <td
                                className={
                                  r['% Volume'] > 0 ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(r['% Volume'] * 100, 2)}%
                              </td>
                              <td>{r.Time}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={squeezeRef}>Short Squeeze</h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5>
                    <a
                      href='https://www.investopedia.com/terms/s/shortsqueeze.asp'
                      target='_blank'
                    >
                      Large short positions pressured to close as price rises
                      causing prices to rise further. Premium only.{' '}
                      <i className='ion-android-open'></i>
                    </a>
                  </h5>
                  <br />
                  {userType === 'Member' && (
                    <div>
                      <div className='text-center'>
                        <br />
                        <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                        <p className='small faded'>
                          Support Lucid and get access to <br />
                          premium content.
                        </p>
                        <small>
                          <a
                            onClick={() => handleShowUpgrade(true)}
                            className='btn btn-primary'
                          >
                            Upgrade to Premium
                          </a>
                        </small>
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <>
                      {shortSqueezeData?.length === 0 ? (
                        'No Short Squeezes Found.'
                      ) : (
                        <div className='h-scroll'>
                          <Squeeze
                            shortSqueezeData={shortSqueezeData}
                            handleShowModal={handleShowModal}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={uptrendRef}>Uptrend Potential</h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5 className='faded'>
                    Down this week with potential to run. Premium only.
                  </h5>
                  <br />
                  {userType === 'Member' && (
                    <div>
                      <div className='text-center'>
                        <br />
                        <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                        <p className='small faded'>
                          Support Lucid and get access to <br />
                          premium content.
                        </p>
                        <small>
                          <a
                            onClick={() => handleShowUpgrade(true)}
                            className='btn btn-primary'
                          >
                            Upgrade to Premium
                          </a>
                        </small>
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <>
                      {uptrend?.length === 0 ? (
                        'No Uptrends Found.'
                      ) : (
                        <div className='h-scroll'>
                          <table>
                            <thead>
                              <tr>
                                {uptrendHeaders.map((d) => (
                                  <th
                                    key={d.id}
                                    className={d.id}
                                    title={d.title}
                                  >
                                    {d.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {uptrend.map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Ticker}${index}`}
                                  onClick={() => handleShowModal(r.Ticker)}
                                >
                                  <td>{index + 1}</td>

                                  <Preview
                                    ticker={r.Ticker}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                  <td>
                                    {formatNumber(r['Perf Week'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Perf Month'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Perf Quart'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Perf Half'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Perf Year'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Perf YTD'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Volatility W'] * 100, 2)}%
                                  </td>
                                  <td>
                                    {formatNumber(r['Volatility M'] * 100, 2)}%
                                  </td>
                                  <td>{r['Recom']}</td>
                                  <td>{r['Avg Volume']}</td>
                                  <td>{formatNumber(r['Rel Volume'], 2)}</td>
                                  {/* <td>{r['Volume']}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={institutionalRef}>
                    Institutional Ownership + Buying
                  </h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5 className='faded'>
                    Stocks that institutional buyers such as mutual funds are
                    buying. Institutional ownership is the percentage of a
                    firm's shares held by these wired-in players, and ranges
                    from 50% to 95% for in favor stocks. We monitor
                    Institutional Transactions to confirm that they are still
                    buying shares. Premium only.
                  </h5>
                  <br />
                  {userType === 'Member' && (
                    <div>
                      <div className='text-center'>
                        <br />
                        <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                        <p className='small faded'>
                          Support Lucid and get access to <br />
                          premium content.
                        </p>
                        <small>
                          <a
                            onClick={() => handleShowUpgrade(true)}
                            className='btn btn-primary'
                          >
                            Upgrade to Premium
                          </a>
                        </small>
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <>
                      {institutionalData?.length === 0 ? (
                        'No Institutional Data Found.'
                      ) : (
                        <div className='h-scroll'>
                          <Institutional
                            institutionalData={institutionalData}
                            handleShowModal={handleShowModal}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={senateRef}>US Senate/Congress Buying</h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5 className='faded'>
                    Disclosed US Government Senate/Congress Buys and Sells.
                    Premium only.
                  </h5>
                  <br />
                  {userType === 'Member' && (
                    <div>
                      <div className='text-center'>
                        <br />
                        <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                        <p className='small faded'>
                          Support Lucid and get access to <br />
                          premium content.
                        </p>
                        <small>
                          <a
                            onClick={() => handleShowUpgrade(true)}
                            className='btn btn-primary'
                          >
                            Upgrade to Premium
                          </a>
                        </small>
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <>
                      {senateData?.length === 0 ? (
                        'No Senate Data Found.'
                      ) : (
                        <div className='h-scroll'>
                          <Senate
                            senateData={senateData}
                            handleShowModal={handleShowModal}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className='panel-container text-center'>
                  <h2>Insider Buying/Selling</h2>
                  <h5 className='faded'>
                    See what the insiders (executives, owners, institutions) are
                    doing with their options and stocks for tickers held in{' '}
                    {currentFundGroup}'s ETFs.
                  </h5>
                  <div className='pumpdump'>
                    <a
                      onClick={() => goTo('insider')}
                      className='btn btn-secondary'
                    >
                      {' '}
                      View All{' '}
                    </a>
                  </div>
                  <br />
                  <div className='h-scroll'>
                    {userType === 'Premium' && (
                      <table>
                        <thead>
                          <tr>
                            {insiderHeaders.map((d) => (
                              <th key={d.id} className={d.id} title={d.title}>
                                {d.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {insiderBuying?.slice(0, 10).map((r, index) => (
                            <tr
                              className='link'
                              key={`${r.Ticky}${index}`}
                              onClick={() => handleShowModal(r.Ticky)}
                            >
                              <td>{formatDate(r['Trade Date'])}</td>
                              <Preview
                                ticker={r.Ticky}
                                itemIndex={index}
                                breakPoint='4'
                              />
                              <td>{r['Trade Type']}</td>
                              <td>{formatCurrency(r.Price, 'USD', 2)}</td>
                              <td
                                className={r['Qty'] > 0 ? 'success' : 'danger'}
                              >
                                {formatNumber(r['Qty'], 2)}
                              </td>
                              <td>{formatNumber(r.Owned, 2)}</td>
                              <td
                                className={r.Value > 0 ? 'success' : 'danger'}
                              >
                                {formatCurrency(r.Value, 'USD', 0)}
                              </td>
                              <td className='Company'>{r['Company Name']}</td>
                              <td>{r['Insider Name']}</td>
                              <td>{r.Title}</td>
                              <td>{formatDate(r['Filing Date'])}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2 ref={canslimRef}>CANSLIM</h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5>
                    <a
                      href='https://www.investopedia.com/terms/c/canslim.asp'
                      target='_blank'
                    >
                      Get into these high-growth stocks before institutional
                      funds are fully invested. Premium only.{' '}
                      <i className='ion-android-open'></i>
                    </a>
                  </h5>
                  <br />
                  {userType === 'Member' && (
                    <div>
                      <div className='text-center'>
                        <br />
                        <p style={{ margin: '0 auto 2px' }}>Premium Content</p>
                        <p className='small faded'>
                          Support Lucid and get access to <br />
                          premium content.
                        </p>
                        <small>
                          <a
                            onClick={() => handleShowUpgrade(true)}
                            className='btn btn-primary'
                          >
                            Upgrade to Premium
                          </a>
                        </small>
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <>
                      {canslim?.length === 0 ? (
                        'No CANSLIM Found.'
                      ) : (
                        <div className='h-scroll'>
                          <table>
                            <thead>
                              <tr>
                                {canslimHeaders.map((d) => (
                                  <th
                                    key={d.id}
                                    className={d.id}
                                    title={d.title}
                                  >
                                    {d.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {canslim.map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Ticker}${index}`}
                                  onClick={() => handleShowModal(r.Ticker)}
                                >
                                  <td>{index + 1}</td>
                                  <td className='Company'>{r.Company}</td>
                                  <Preview
                                    ticker={r.Ticker}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />

                                  <td>{r.Sector}</td>
                                  <td>{r['Market Cap']}</td>
                                  <td>{r['P/E']}</td>
                                  <td>{formatNumber(r['Volume'], 2)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className='panel-container text-center'>
                  <h2>Unusual Options Activity </h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5 className='faded'>
                    Options that are trading at a higher volume relative to the
                    contract's open interest. This signals a potential big move
                    in the stock.
                  </h5>
                  <div className='pumpdump'>
                    <a
                      onClick={() => goTo('options')}
                      className='btn btn-secondary'
                    >
                      View All
                    </a>
                  </div>
                  <br />
                  <div className='h-scroll'>
                    <UnusualOptions
                      optionsData={optionsData}
                      handleShowModal={handleShowModal}
                    />
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Most Active Options</h2>
                  <h3 className='faded'>US Markets</h3>
                  <h5 className='faded'>
                    Put/Call Volume above 1 may indicate a selloff and below 1
                    is a buying opportunity. Higher IV Rank means higher
                    risk/reward.
                  </h5>
                  <div className='pumpdump'>
                    <a
                      onClick={() => goTo('options?tab=Most-Active')}
                      className='btn btn-secondary'
                    >
                      View All
                    </a>
                  </div>
                  <br />
                  <div className='h-scroll'>
                    <table>
                      <thead>
                        <tr>
                          {optionsActiveHeaders.map((d) => (
                            <th key={d.id} className={d.id} title={d.title}>
                              {d.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {optionsActive?.slice(0, 10).map((r, index) => (
                          <tr
                            className='link'
                            key={`${r.Symbol}${index}`}
                            onClick={() => handleShowModal(r.Symbol)}
                          >
                            <Preview
                              ticker={r.Symbol}
                              itemIndex={index}
                              breakPoint='4'
                            />
                            <td>{formatNumber(r['IV Rank'] * 100, 2)}%</td>
                            <td>{formatNumber(r['Options Vol'], 0)}</td>
                            <td>{formatNumber(r['% Put'] * 100, 2)}%</td>
                            <td>{formatNumber(r['% Call'] * 100, 2)}%</td>
                            <td
                              title={
                                r['Put/Call Vol'] < 1
                                  ? 'Indicates a buying opportunity'
                                  : 'Indicates a potential selloff'
                              }
                              className={
                                r['Put/Call Vol'] < 1 ? 'success' : 'danger'
                              }
                            >
                              {formatNumber(r['Put/Call Vol'], 2)}
                            </td>
                            <td className='Company'>{r.Name}</td>
                            <td>{r.Time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            <Footer></Footer>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
