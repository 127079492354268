export const formatNumber = (number, decimals) => {
  if (decimals) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
    }).format(number);
  } else {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(number);
  }
};

export const formatCurrency = (number, currency, decimals) => {
  if (decimals) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      //currencyDisplay: 'name',
      maximumSignificantDigits: decimals,
    }).format(number);
  } else {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      //currencyDisplay: 'name',
      maximumSignificantDigits: Math.trunc(Math.abs(number)).toFixed().length,
    }).format(number);
  }
};

export const formatPercent = (number, decimals) => {
  if (decimals) {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
    }).format(number);
  } else {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumSignificantDigits: Math.trunc(Math.abs(number)).toFixed().length,
    }).format(number);
  }
};

export const formatDate = (date) => {
  const dateToUse = new Date(date);
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(dateToUse);
};

export const formatDateTime = (date) => {
  const dateToUse = new Date(date);
  return new Intl.DateTimeFormat('en-US', {
    //dateStyle: 'medium',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(dateToUse);
};
