import React, { useState, useEffect } from 'react';
import Comments from '../layouts/comments';
import Loader from '../layouts/loader';
import {
  getUsername,
  setUsername,
  getCode,
  createUUID,
} from '../helpers/helpers';
import {
  getChats,
  getUsernameFromSheet,
  getCommentCounts,
} from '../helpers/api';
import '../styles/chat.scss';

const code = getCode();
let username = getUsername();

function Chat({
  userType,
  allTickers,
  handleShowModal,
  handleShowUpgrade,
  currentFundGroup,
}) {
  const [desiredUsername, setDesiredUsername] = useState('');
  const [usernameChat, setUsernameChat] = useState(username);
  const [usernameError, setUsernameError] = useState('');
  const [chatComments, setChatComments] = useState([]);
  const [chatComment, setChatComment] = useState('');
  const [chatCommentUUID, setChatCommentUUID] = useState(createUUID());
  const [deleteCommentUUID, setDeleteCommentUUID] = useState('');
  const [commentCounts, setCommentCounts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Main Room');
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getSheetUN = async () => {
    if (!username) {
      const usernameFromS = await getUsernameFromSheet();
      setUsernameChat(usernameFromS ? usernameFromS : '');
    } else {
      setUsernameChat(username);
    }
  };

  const saveDesiredUsername = () => {
    if (desiredUsername.length > 0) {
      //is user in list?
      let usernameExists = chatComments.find(
        (u) => u.username === desiredUsername
      );

      if (usernameExists) {
        setUsernameError('Username already exists. Please try another name.');
        setDesiredUsername('');
        setTimeout(() => {
          setUsernameError('');
        }, 3000);
      } else {
        //submit username to form
        window.document.querySelector('#submitusernameChatform').click();
        setTimeout(() => {
          setUsernameChat(desiredUsername);
          setUsername(desiredUsername);
          setTimeout(() => {
            getChats();
          }, 1000);
        }, 0);
      }
    }

    /* setTimeout(() => {
      window.document.querySelector('#submitusernameChatform').click();
    }, 500); */
  };

  const postChatComment = () => {
    window.document.querySelector('#submitchatform').click();
    setTimeout(() => {
      setChatComment('');
      startTimer(2000);
      setChatCommentUUID(createUUID());
    }, 1000);
  };

  const getChatsFromSheet = async () => {
    const chatsFromSheet = await getChats();
    const commentCountsFromSheet = await getCommentCounts();
    const filteredCC = commentCountsFromSheet.filter((c) => c.count > 0);
    setChatComments(chatsFromSheet.reverse());
    setCommentCounts(
      allTickers.map((a) => {
        const counted = filteredCC.find((f) => f.ticker === a.ticker);
        return {
          ...a,
          count: counted ? counted.count : 0,
        };
      })
    );
    setIsLoading(false);
  };

  const startTimer = (seconds) => {
    setTimeout(() => {
      getChatsFromSheet();
    }, seconds);
  };

  const deleteComment = (comment) => {
    setDeleteCommentUUID(comment.uuid);
    const newCommentsFromSheet = chatComments.filter(
      (c) => c.uuid !== comment.uuid
    );
    setChatComments(newCommentsFromSheet);
  };

  useEffect(() => {
    if (username) {
      setUsernameChat(username);
    } else {
      getSheetUN();
    }
  }, []);

  useEffect(() => {
    if (allTickers?.length > 0) {
      getChatsFromSheet();
      startTimer(5000);
    }
  }, [allTickers]);

  return (
    <div className={`chat ${currentFundGroup}`}>
      <iframe
        name='secret-frame-three'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>
      <iframe
        name='secret-frame-eight'
        width='0'
        height='0'
        border='0'
        style={{ display: 'none' }}
      ></iframe>

      {userType === 'Premium' && (
        <div className='chat-container'>
          {isLoading && <Loader title={`${userType} Chat`} />}
          {!isLoading && (
            <>
              {!usernameChat && (
                <div className='panel'>
                  <div className='form-group'>
                    <label htmlFor='usernamelabel'>
                      Create a Username to chat.
                    </label>
                    <input
                      type='text'
                      placeholder='Enter username...'
                      value={desiredUsername}
                      onChange={(e) => setDesiredUsername(e.target.value)}
                    />
                    {usernameError && <p className='danger'>{usernameError}</p>}

                    <button
                      style={{ width: '100%' }}
                      className='btn-primary'
                      onClick={() => saveDesiredUsername()}
                    >
                      Save Username
                    </button>
                  </div>

                  <form
                    className='form'
                    action='https://docs.google.com/forms/d/e/1FAIpQLSc54G_4XfEb_cTdorCYlD-7iM7nV73YZxo5ND3HRRayu4lo3g/formResponse'
                    id='ss-form9'
                    method='POST'
                    target='secret-frame-eight'
                    style={{ display: 'none' }}
                  >
                    <div className='form-group'>
                      <label htmlFor='code'>Code</label>
                      <input
                        name='entry.1431414506'
                        readOnly
                        type='text'
                        value={code}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='registrationEmail'>Username</label>
                      <input
                        name='entry.1475482522'
                        type='text'
                        value={desiredUsername}
                        onChange={(e) => setDesiredUsername(e.target.value)}
                      />
                    </div>
                    <input
                      id='submitusernameChatform'
                      type='submit'
                      value='Submit Username'
                    />
                  </form>
                </div>
              )}

              {usernameChat && (
                <div className='chat-module'>
                  <div className='left'>
                    <div className='search-container'>
                      <input
                        type='text'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={`Search Tickers...`}
                      />
                      <i
                        className={
                          searchText.length > 1 ? 'ion-ios-close' : 'hidden'
                        }
                        onClick={() => setSearchText('')}
                      ></i>
                    </div>
                    <a
                      onClick={() => setSelectedTab('Main Room')}
                      className={selectedTab === 'Main Room' ? 'active' : ''}
                    >
                      MAIN ROOM <small>({chatComments.length + 1})</small>
                      <div className='small faded'>Premium Members</div>
                    </a>

                    {commentCounts
                      .sort((a, b) => {
                        return b.count - a.count;
                      })
                      .map((c) => (
                        <div
                          key={c.ticker}
                          className={
                            searchText.length
                              ? Object.values(c)
                                  .join(' ')
                                  .toLowerCase()
                                  .match(searchText.toLowerCase())
                                ? ''
                                : 'hidden'
                              : ''
                          }
                        >
                          <a
                            key={c.ticker}
                            onClick={() => setSelectedTab(c.ticker)}
                            className={selectedTab === c.ticker ? 'active' : ''}
                          >
                            {c.ticker} <small>({c.count})</small>
                            <div className='small faded'>{c.company}</div>
                          </a>
                        </div>
                      ))}
                  </div>
                  <div className='right'>
                    <h1
                      className='link'
                      onClick={() => {
                        if (selectedTab !== 'Main Room')
                          handleShowModal(selectedTab);
                      }}
                    >
                      {selectedTab}
                    </h1>
                    <p className='faded'>
                      {selectedTab === 'Main Room'
                        ? 'Chat with other Premium members and the Lucid team.'
                        : `Chat about ${selectedTab}. Also available on the detail page.`}
                    </p>
                    {selectedTab === 'Main Room' && (
                      <div className='form-group'>
                        <input
                          name='comment'
                          placeholder='Write a comment...'
                          type='text'
                          value={chatComment}
                          onChange={(e) => setChatComment(e.target.value)}
                        />
                        <button
                          className='btn-primary btn-block'
                          onClick={() => postChatComment()}
                        >
                          <i className='ion-chatbubble'></i> Comment
                        </button>
                      </div>
                    )}
                    <div className='form-group'>
                      {selectedTab !== 'Main Room' && (
                        <Comments ticker={selectedTab}></Comments>
                      )}
                      {selectedTab === 'Main Room' &&
                        chatComments.map((comment, index) => (
                          <div key={`${comment.timestamp}${index}`}>
                            {comment?.comment?.length && (
                              <div className='item'>
                                <h6 className='faded'>{comment?.timestamp}</h6>
                                <p>{comment?.comment}</p>

                                <small className='faded'>
                                  {comment?.email}
                                </small>
                                {comment?.code === code && (
                                  <button
                                    onClick={() => deleteComment(comment)}
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className='form-group'>
                      <form
                        className='form'
                        action='https://docs.google.com/forms/d/e/1FAIpQLSeRQc3K6ob1faqW7oWpAmwUJKvhrJlFEKfhXgHNW9QgDIPtHQ/formResponse'
                        id='ss-form-3'
                        method='POST'
                        target='secret-frame-three'
                        style={{ display: 'none' }}
                      >
                        <div className='form-group'>
                          <label htmlFor='registrationEmail'>Username</label>
                          <input
                            name='entry.598047707'
                            type='text'
                            value={usernameChat}
                            readOnly
                          />
                        </div>
                        <div className='form-group'>
                          <label htmlFor='code'>Code</label>
                          <input
                            name='entry.1541762063'
                            type='text'
                            value={code}
                            readOnly
                          />
                        </div>
                        <div className='form-group'>
                          <label htmlFor='commentForm'>Comment</label>
                          <input
                            name='entry.477130092'
                            type='text'
                            readOnly
                            value={chatComment}
                          />
                        </div>

                        <input
                          id='submitchatform'
                          type='submit'
                          value='Submit Comment'
                        />
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {userType === 'Member' && (
        <div>
          <div
            className='panel'
            style={{ padding: '40px 10px', textAlign: 'center' }}
          >
            <div className='jumbo'>🚀</div>
            <h2>Upgrade to Premium</h2>
            <p style={{ marginBottom: '20px' }}>
              Get access to chat and premium member features.
            </p>
            <a
              onClick={() => handleShowUpgrade(true)}
              className='btn btn-primary'
            >
              Upgrade to Premium
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
export default Chat;
