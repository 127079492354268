import React, { useState } from 'react';
import { squeezeHeaders } from '../helpers/fundvars';
import Preview from '../layouts/preview';
import { formatNumber } from '../helpers/format';

let institutional = [];
let headers = [];

function Institutional({
  institutionalData,
  handleShowModal,
  ticker,
  handleShowSimpleModal,
  type,
}) {
  if (ticker) {
    institutional = institutionalData?.filter((f) => f.Ticker === ticker);
    headers = squeezeHeaders.filter((v) => v.id !== 'Ticker' && v.id !== 'No.');
  } else {
    institutional = institutionalData;
    headers = squeezeHeaders;
  }

  const handleShowModalhandle = (ticker) => {
    if (type === 'meme') {
      handleShowSimpleModal(ticker);
    } else {
      handleShowModal(ticker);
    }
  };

  return (
    <>
      {institutional?.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                {headers.map((d) => (
                  <th key={d.id} className={d.id} title={d.title}>
                    {d.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {institutional
                .sort(function (a, b) {
                  return b['Inst Own'] - a['Inst Own'];
                })
                .map((r, index) => (
                  <tr
                    className='link'
                    key={`${r.Ticker}${index}`}
                    onClick={() => handleShowModalhandle(r.Ticker)}
                  >
                    {!ticker && (
                      <>
                        <td>{index + 1}</td>

                        <Preview
                          ticker={r.Ticker}
                          itemIndex={index}
                          breakPoint='4'
                        />
                      </>
                    )}

                    <td>{r['Market Cap']}</td>
                    <td>{r['Outstanding']}</td>
                    <td>{r['Float']}</td>
                    <td>{formatNumber(r['Insider Own'] * 100, 0)}%</td>
                    <td>{formatNumber(r['Insider Trans'] * 100, 0)}%</td>
                    <td>{formatNumber(r['Inst Own'] * 100, 0)}%</td>
                    <td>{formatNumber(r['Inst Trans'] * 100, 0)}%</td>
                    <td>{formatNumber(r['Float Short'] * 100, 0)}%</td>
                    <td>{r['Short Ratio']}</td>
                    <td>{r['Avg Volume']}</td>
                    <td>{r['Volume'] ? formatNumber(r['Volume'], 2) : ''}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </>
      ) : (
        <div>
          <br />
          No data found.
        </div>
      )}
    </>
  );
}
export default Institutional;
