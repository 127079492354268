import React, { useState, useEffect } from 'react';
import { getAggregate } from '../helpers/api';
import { useHistory } from 'react-router-dom';
import { getOptions } from '../helpers/api';
import Announcement from '../layouts/announcement';
import {
  getDuplicates,
  getShared,
  removeDuplicateValue,
  flattenObject,
} from '../helpers/helpers';
import { fundGroups, investorHeaders } from '../helpers/fundvars';
import Table from '../layouts/table';
import Aggregate from './Aggregate';
import Preview from '../layouts/preview';
import UpcomingEarnings from '../layouts/upcomingearnings';
import { formatNumber, formatCurrency, formatDate } from '../helpers/format';
import '../styles/all.scss';

let tiered;
let uniqueTickers;
let netPortfolioArray;
let firstArray;
let tickersPerFund;
let chartD;
let keys;
let date;
let ArkTickers = [];
let BRTickers = [];
let GlobalTickers = [];

function All({
  userType,
  handleShowModal,
  handleShowUpgrade,
  type,
  industryData,
}) {
  const history = useHistory();
  const [fundData, setFundData] = useState([]);
  const [fundOptionsData, setFundOptionsData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [etf, setEtf] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [alsoHeldByHeight, setAlsoHeldByHeight] = useState('200px');

  const getOptionsData = async (stocklist) => {
    let fundOptions = [];
    const allTickers = stocklist.map((s) => s.ticker);
    ////API Get Industry Data
    //const industryData = await getOptions();

    //EFT Returns Data
    const returns = Object.values(industryData['ETFStats']);
    const etfReturns = fundGroups.map((f) => ({
      fund: f.fundGroup,
      color: f.color,
      data: returns.filter(
        (r) =>
          f.fundGroup.toLowerCase().includes(r.Issuer.toLowerCase()) ||
          r['Fund Name'].toLowerCase().includes(f.fundGroup.toLowerCase())
      ),
    }));
    setEtf(etfReturns);

    //Earnings Data
    const schedule = Object.values(industryData['Earnings Calendar']);
    //Filter Calendar to Fund Tickers
    const fundEarnings = schedule.filter((s) => allTickers.includes(s.symbol));
    const earnings = fundEarnings
      .sort((a, b) => {
        return new Date(a.reportDate) - new Date(b.reportDate);
      })
      .map((f) => {
        const eData = stocklist.find((s) => s.ticker === f.symbol);
        return { ...f, ...eData };
      });
    fundOptions.earningsData = earnings;

    //Options
    /* const options = Object.values(industryData['Unusual Options Activity']);
    const oData = options.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );
    fundOptions.optionsData = oData; */

    //Options Most Active
    // const optionsActive = Object.values(industryData['Options Most Active']);
    // const oActiveData = optionsActive.filter((s) =>
    //   allTickers.map((t) => t.ticker).includes(s.Symbol)
    // );
    // fundOptions.optionsActiveData = oActiveData;

    //Insider Trading
    /* const insider = Object.values(industryData['Insider Trading']);
    const iData = insider
      .filter((s) => allTickers.map((t) => t.ticker).includes(s.Ticky))
      .map((m) => ({
        ...m,
        Qty: parseInt(m.Qty),
        Owned: parseInt(m.Owned),
        ΔOwn: parseInt(m['ΔOwn']) === 0 ? 0 : parseInt(m['ΔOwn']),
        Value: parseInt(m.Value),
      }));
    fundOptions.insiderData = iData; */

    //Most Actively Traded Stocks
    /* const mostActive = Object.values(industryData['Stock Volume']);
    const sVolumeData = mostActive.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );
    fundOptions.stockVolumeData = sVolumeData; */

    //Stock Volume Change
    /* const stockVolumeAdvances = Object.values(
      industryData['Stock Volume Advances']
    );
    const sVolumeAdvancesData = stockVolumeAdvances.filter((s) =>
      allTickers.map((t) => t.ticker).includes(s.Symbol)
    );
    fundOptions.stockVolumeAdvancesData = sVolumeAdvancesData; */
    return fundOptions;
  };

  const handleGetAggregate = async () => {
    const res = await getAggregate();
    firstArray = Object.values(res).slice(0, 3);
    keys = Object.keys(res).slice(0, 3);
    netPortfolioArray = Object.values(res).slice(3, 6);
    ArkTickers = Object.values(Object.values(res)[0]).map((t) => t.ticker);
    BRTickers = Object.values(Object.values(res)[1]).map((t) => t.ticker);
    GlobalTickers = Object.values(Object.values(res)[2]).map((t) => t.ticker);
    const resToArrayFlat = firstArray.map((m) => Object.values(m)).flat();
    const allTickers = resToArrayFlat.map((t) => t.ticker);
    const firstArrayValues = firstArray.map((f) => Object.values(f));
    tickersPerFund = firstArrayValues.map((f) =>
      f
        .map((ff) => ({
          ticker: ff.ticker,
          company: ff.company,
        }))
        .sort((a, b) => `${a.ticker}`.localeCompare(`${b.ticker}`))
    );

    //List of tickers where it exists in atleast 2 funds:
    //const commonTickers = getDuplicates(allTickers);
    uniqueTickers = removeDuplicateValue(allTickers);
    //Also Held By
    const held = Object.values(industryData.Investors);
    const heldBy = held.filter((s) =>
      uniqueTickers.map((t) => t).includes(s.Symbol)
    );
    setInvestors(heldBy);

    //List of tickers where it's shared in all funds:
    //const sharedTickers = getShared(allTickers, firstArray.length);

    //Stand Alone Array
    tiered = fundGroups.map((f, index) => ({
      ...f,
      data: Object.values(firstArray[index]),
    }));

    //Set State
    setFundData(tiered);
    setIsLoading(false);

    const generateChartData = (netPortfolioObject, index) => {
      const netPortfolio = Object.values(netPortfolioObject);
      /* console.log(
        'tickersperfund',
        netPortfolio.filter((n) => !n.ticker)
      ); */
      return tickersPerFund[index].map((a) => ({
        ticker: a,
        company: a.company.toString(),
        shares: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.shares ? d.shares : 0 }))
          ),
        },
        marketvalue: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({
                [d.date]: d.sharesmarketvalue ? d.sharesmarketvalue : 0,
              }))
          ),
        },
        weight: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.weight ? d.weight : 0 }))
          ),
        },
        dailycapitalinjection: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({
                [d.date]: d.dailycapitalinjection ? d.dailycapitalinjection : 0,
              }))
          ),
        },
        weeklycapitalinjection: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({
                [d.date]: d.weeklycapitalinjection
                  ? d.weeklycapitalinjection
                  : 0,
              }))
          ),
        },
        float: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.float ? d.float : 0 }))
          ),
        },
        portfolioweightdelta: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({
                [d.date]: d.portfolioweightdelta ? d.portfolioweightdelta : 0,
              }))
          ),
        },
        close: {
          ...flattenObject(
            netPortfolio
              .filter((n) => n.ticker.toString() === a.ticker.toString())
              .map((d) => ({ [d.date]: d.priceclosed ? d.priceclosed : 0 }))
          ),
        },
        dates: netPortfolio
          .filter((n) => n.ticker.toString() === a.ticker.toString())
          .map((d) => d.date),
      }));
    };

    chartD = netPortfolioArray.map((n, index) => ({
      fund: keys[index],
      name: keys[index],
      type: 'Active',
      data: generateChartData(n, index),
    }));

    setFundOptionsData(
      tiered.map((t) => {
        const data = getOptionsData(t.data);
        return {
          fund: t.fundGroup,
          color: t.color,
          data,
        };
      })
    );
  };

  const handleShowModalWithAggChart = (ticker, type) => {
    handleShowModal(ticker, type, chartD);
  };

  useEffect(() => {
    if (industryData && industryData.Investors) {
      handleGetAggregate();
    }
  }, [industryData]);

  return (
    <div className='dashboard all-dashboard'>
      <Announcement />
      {type === 'Dashboard' && (
        <>
          {userType === 'Member' && (
            <div>
              <div style={{ padding: '10px', textAlign: 'center' }}>
                <br />
                <div className='jumbo' style={{ marginBottom: '-30px' }}>
                  🚀
                </div>
                <h2>Upgrade to Premium</h2>
                <p style={{ marginBottom: '20px' }}>
                  Get access to All Funds and premium member features.
                </p>

                <a
                  onClick={() => handleShowUpgrade(true)}
                  className='btn btn-primary'
                >
                  Upgrade
                </a>
              </div>
            </div>
          )}

          {userType === 'Premium' && (
            <>
              {isLoading && (
                <div className='loading-indicator'>
                  <h1 className='text-center'>All Dashboard</h1>
                  <div className='loader'></div>
                  Loading
                </div>
              )}
              {!isLoading && (
                <>
                  <div>
                    <h2>ETF Return Summary</h2>
                    <h5 className='faded'>Premium only.</h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container etf'>
                          {etf?.map((s) => (
                            <div className='panel' key={s.fund}>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fund}{' '}
                                <small className='white faded'>
                                  {tiered &&
                                    formatDate(tiered[0]?.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Ticker</th>
                                      <th total='Assets Under Management'>
                                        AUM
                                      </th>
                                      <th title='Management Expense as a Percentage of Assets'>
                                        Expense Ratio
                                      </th>
                                      <th title='3 month Total Returns'>
                                        3Mo TR
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {s.data
                                      .sort((a, b) => {
                                        return b['3-Mo TR'] - a['3-Mo TR'];
                                      })
                                      .map((d, index) => (
                                        <tr
                                          key={`${d.Ticker}${s.fund}`}
                                          title={`${d.Ticker}: ${d['Fund Name']}`}
                                        >
                                          <td>
                                            <a
                                              href={`https://finance.yahoo.com/quote/${d.Ticker}`}
                                              target='_blank'
                                            >
                                              <b>
                                                {d.Ticker}{' '}
                                                <i className='ion-android-open small faded'></i>
                                              </b>
                                            </a>
                                          </td>
                                          <td>{d.AUM}</td>
                                          <td>{d['Expense Ratio']}</td>
                                          <td>
                                            {formatNumber(
                                              d['3-Mo TR'] * 100,
                                              2
                                            )}
                                            %
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2>Top Daily Adds/Buys in $</h2>
                    <h5 className='faded'>Premium only.</h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container'>
                          {fundData?.map((s) => (
                            <div className='panel' key={s.fundGroup}>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fundGroup}{' '}
                                <small className='white faded'>
                                  {formatDate(s.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                {s.data.filter((d) => d.bought > 0).length >
                                0 ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Ticker</th>
                                        <th>Bought</th>
                                        <th>Close</th>
                                        <th>Prev</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {s.data
                                        .sort((a, b) => {
                                          return b.bought - a.bought;
                                        })
                                        .filter((f) => f.bought > 0)
                                        .slice(0, 20)
                                        .map((d, index) => (
                                          <tr
                                            key={`${d.ticker}${s.fundGroup}`}
                                            onClick={() =>
                                              handleShowModalWithAggChart(
                                                d.ticker,
                                                s.fundGroup
                                              )
                                            }
                                            title={`${d.ticker}: ${d.company}`}
                                          >
                                            <td>
                                              <b>{d.ticker}</b>
                                            </td>
                                            <td className='success'>
                                              {formatCurrency(
                                                d.bought,
                                                'USD',
                                                0
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.close,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.previous,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className='text-center faded'>
                                    No daily buys.
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2>Top Daily Weight Increase</h2>
                    <h5 className='faded'>
                      {`Highlighted rows: Close < Previous Close. Premium only.`}
                    </h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container'>
                          {fundData?.map((s) => (
                            <div className='panel'>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fundGroup}
                                <small className='white faded'>
                                  {formatDate(s.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Ticker</th>
                                      <th>Weight Chg</th>
                                      <th>Close</th>
                                      <th>Prev</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {s.data
                                      .sort((a, b) => {
                                        return (
                                          b.changeinweight - a.changeinweight
                                        );
                                      })
                                      .filter((f) => f.changeinweight > 0)
                                      .slice(0, 20)
                                      .map((d, index) => (
                                        <tr
                                          key={`${d.ticker}${s.fundGroup}`}
                                          className={
                                            d.close < d.previous
                                              ? 'highlight'
                                              : ''
                                          }
                                          onClick={() =>
                                            handleShowModalWithAggChart(
                                              d.ticker,
                                              s.fundGroup
                                            )
                                          }
                                          title={`${d.ticker}: ${d.company}`}
                                        >
                                          <td>
                                            <b>{d.ticker}</b>
                                          </td>
                                          <td
                                            className={
                                              d.close < d.previous
                                                ? ''
                                                : 'success'
                                            }
                                          >
                                            {formatNumber(d.changeinweight, 2)}%
                                          </td>
                                          <td>
                                            {formatCurrency(d.close, 'USD', 2)}
                                          </td>
                                          <td>
                                            {formatCurrency(
                                              d.previous,
                                              'USD',
                                              2
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2>Top Daily Shares Added</h2>
                    <h5 className='faded'>Premium only.</h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container'>
                          {fundData?.map((s) => (
                            <div className='panel'>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fundGroup}
                                <small className='white faded'>
                                  {formatDate(s.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                {s.data.filter((d) => d.d1shares > 0).length >
                                0 ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Ticker</th>
                                        <th>Shares</th>
                                        <th>Close</th>
                                        <th>Prev</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {s.data
                                        .sort((a, b) => {
                                          return b.d1shares - a.d1shares;
                                        })
                                        .filter((f) => f.d1shares > 0)
                                        .slice(0, 20)
                                        .map((d, index) => (
                                          <tr
                                            key={`${d.ticker}${s.fundGroup}`}
                                            title={`${d.ticker}: ${d.company}`}
                                            onClick={() =>
                                              handleShowModalWithAggChart(
                                                d.ticker,
                                                s.fundGroup
                                              )
                                            }
                                          >
                                            <td>
                                              <b>{d.ticker}</b>
                                            </td>
                                            <td className='success'>
                                              {formatNumber(d.d1shares)}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.close,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.previous,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className='text-center faded'>
                                    No daily buys.
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <h2>Top Daily Sells in $</h2>
                    <h5 className='faded'>Premium only.</h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container'>
                          {fundData?.map((s) => (
                            <div className='panel'>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fundGroup}
                                <small className='white faded'>
                                  {formatDate(s.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                {s.data.filter((d) => d.bought < 0).length >
                                0 ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Ticker</th>
                                        <th>Sold</th>
                                        <th>Close</th>
                                        <th>Prev</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {s.data
                                        .sort((a, b) => {
                                          return a.bought - b.bought;
                                        })
                                        .filter((f) => f.bought < 0)
                                        .slice(0, 20)
                                        .map((d, index) => (
                                          <tr
                                            key={`${d.ticker}${s.fundGroup}`}
                                            title={`${d.ticker}: ${d.company}`}
                                            onClick={() =>
                                              handleShowModalWithAggChart(
                                                d.ticker,
                                                s.fundGroup
                                              )
                                            }
                                          >
                                            <Preview
                                              title='Ticker'
                                              ticker={d.ticker}
                                              itemIndex={index}
                                              breakPoint='4'
                                            />
                                            <td className='danger'>
                                              {formatCurrency(
                                                d.bought,
                                                'USD',
                                                0
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.close,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.previous,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className='text-center faded'>
                                    No daily sells.
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2>Top Daily Decrease in Weight</h2>
                    <h5 className='faded'>
                      {`Highlighted rows: Close > Previous Close. Premium only.`}
                    </h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container'>
                          {fundData?.map((s) => (
                            <div className='panel'>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fundGroup}{' '}
                                <small className='white faded'>
                                  {formatDate(s.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                {s.data.filter((d) => d.changeinweight < 0)
                                  .length > 0 ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Ticker</th>
                                        <th>Weight Chg</th>
                                        <th>Close</th>
                                        <th>Prev</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {s.data
                                        .sort((a, b) => {
                                          return (
                                            a.changeinweight - b.changeinweight
                                          );
                                        })
                                        .filter((f) => f.changeinweight < 0)
                                        .slice(0, 20)
                                        .map((d, index) => (
                                          <tr
                                            key={`${d.ticker}${s.fundGroup}`}
                                            className={
                                              d.close > d.previous
                                                ? 'highlight'
                                                : ''
                                            }
                                            title={`${d.ticker}: ${d.company}`}
                                            onClick={() =>
                                              handleShowModalWithAggChart(
                                                d.ticker,
                                                s.fundGroup
                                              )
                                            }
                                          >
                                            <Preview
                                              title='Ticker'
                                              ticker={d.ticker}
                                              itemIndex={index}
                                              breakPoint='4'
                                            />
                                            <td className='danger'>
                                              {formatNumber(
                                                d.changeinweight,
                                                2
                                              )}
                                              %
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.close,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.previous,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className='text-center faded'>
                                    No daily decrease in weight.
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2>Top Daily Shares Sold</h2>
                    <h5 className='faded'>Premium only.</h5>
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div className='panel-container'>
                          {fundData?.map((s) => (
                            <div className='panel'>
                              <h4
                                className='text-center'
                                style={{ color: s.color }}
                              >
                                {s.fundGroup}
                                <small className='white faded'>
                                  {formatDate(s.data[0].date)}
                                </small>
                              </h4>
                              <div className='panel-content'>
                                {s.data.filter((d) => d.d1shares < 0).length >
                                0 ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Ticker</th>
                                        <th>Shares</th>
                                        <th>Close</th>
                                        <th>Prev</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {s.data
                                        .sort((a, b) => {
                                          return a.d1shares - b.d1shares;
                                        })
                                        .filter((f) => f.d1shares < 0)
                                        .slice(0, 20)
                                        .map((d, index) => (
                                          <tr
                                            key={`${d.ticker}${s.fundGroup}`}
                                            title={`${d.ticker}: ${d.company}`}
                                            onClick={() =>
                                              handleShowModalWithAggChart(
                                                d.ticker,
                                                s.fundGroup
                                              )
                                            }
                                          >
                                            <Preview
                                              title='Ticker'
                                              ticker={d.ticker}
                                              itemIndex={index}
                                              breakPoint='4'
                                            />
                                            <td className='danger'>
                                              {formatNumber(d.d1shares)}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.close,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {formatCurrency(
                                                d.previous,
                                                'USD',
                                                2
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className='text-center faded'>
                                    No daily sells.
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='text-center'>
                    <h2>ARK Stocks Also Held By</h2>
                    <h5 className='faded'>
                      Taken from SEC (i.e. 13F/C) filings of the investor's
                      portfolio. Use this to further your conviction on a stock.
                    </h5>

                    <div className='pumpdump'>
                      <a
                        className='btn btn-secondary'
                        style={{
                          margin: '10px auto 0',
                        }}
                        onClick={() =>
                          alsoHeldByHeight !== '100%'
                            ? setAlsoHeldByHeight('100%')
                            : setAlsoHeldByHeight('200px')
                        }
                      >
                        {alsoHeldByHeight !== '100%' ? 'Expand' : 'Collapse'}
                      </a>
                    </div>
                    <br />

                    <div
                      className='h-scroll'
                      style={{
                        height: alsoHeldByHeight,
                        overflow: 'auto',
                        maxWidth: '1600px',
                        margin: 'auto',
                      }}
                    >
                      {userType === 'Premium' && (
                        <table>
                          <thead>
                            <tr>
                              {investorHeaders.map((d) => (
                                <th key={d.id} className={d.id} title={d.title}>
                                  {d.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {investors
                              .filter((v) => ArkTickers.includes(v.Symbol))
                              .sort((a, b) => {
                                return `${a.Symbol}`.localeCompare(
                                  `${b.Symbol}`
                                );
                              })
                              .map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Symbol}${index}`}
                                  onClick={() =>
                                    handleShowModalWithAggChart(
                                      r.Symbol,
                                      'Ark Invest'
                                    )
                                  }
                                >
                                  <td>{r.Investor}</td>
                                  <td className='Company'>{r.Company}</td>
                                  <Preview
                                    ticker={r.Symbol}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                  <td
                                    className={
                                      r.Type === 'Stock'
                                        ? ''
                                        : r.Type === 'Call'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {r.Type}
                                  </td>
                                  <td>{formatNumber(r.Holdings, 0)}</td>
                                  <td>${formatNumber(r.Value, 0)}</td>
                                  <td>
                                    {formatCurrency(r['Avg Paid'], 'USD', 2)}
                                  </td>
                                  <td
                                    className={
                                      r.Weight == r.Prev
                                        ? 'faded'
                                        : r.Weight > r.Prev
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatNumber(r.Weight, 2)}
                                  </td>
                                  <td>{formatNumber(r.Prev, 2)}</td>
                                  <td>{formatDate(r['Source Date'])}</td>
                                  <td>{formatDate(r['Date Reported'])}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {userType === 'Member' && (
                        <div>
                          <div className='text-center'>
                            <br />
                            <p style={{ margin: '0 auto 2px' }}>
                              Premium Content
                            </p>
                            <p className='small faded'>
                              See which stocks Warren Buffett and other icons
                              hold from the ETFs.
                            </p>
                            <small>
                              <a
                                onClick={() => handleShowUpgrade(true)}
                                className='btn btn-primary'
                              >
                                Upgrade to Premium
                              </a>
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='text-center'>
                    <h2>BlackRock Stocks Also Held By</h2>
                    <h5 className='faded'>
                      Taken from SEC (i.e. 13F/C) filings of the investor's
                      portfolio. Use this to further your conviction on a stock.
                    </h5>

                    <div className='pumpdump'>
                      <a
                        className='btn btn-secondary'
                        style={{
                          margin: '10px auto 0',
                        }}
                        onClick={() =>
                          alsoHeldByHeight !== '100%'
                            ? setAlsoHeldByHeight('100%')
                            : setAlsoHeldByHeight('200px')
                        }
                      >
                        {alsoHeldByHeight !== '100%' ? 'Expand' : 'Collapse'}
                      </a>
                    </div>
                    <br />

                    <div
                      className='h-scroll'
                      style={{
                        height: alsoHeldByHeight,
                        overflow: 'auto',
                        maxWidth: '1600px',
                        margin: 'auto',
                      }}
                    >
                      {userType === 'Premium' && (
                        <table>
                          <thead>
                            <tr>
                              {investorHeaders.map((d) => (
                                <th key={d.id} className={d.id} title={d.title}>
                                  {d.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {investors
                              .filter((v) => BRTickers.includes(v.Symbol))
                              .sort((a, b) => {
                                return `${a.Symbol}`.localeCompare(
                                  `${b.Symbol}`
                                );
                              })
                              .map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Symbol}${index}`}
                                  onClick={() =>
                                    handleShowModalWithAggChart(
                                      r.Symbol,
                                      'Ark Invest'
                                    )
                                  }
                                >
                                  <td>{r.Investor}</td>
                                  <td className='Company'>{r.Company}</td>
                                  <Preview
                                    ticker={r.Symbol}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                  <td
                                    className={
                                      r.Type === 'Stock'
                                        ? ''
                                        : r.Type === 'Call'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {r.Type}
                                  </td>
                                  <td>{formatNumber(r.Holdings, 0)}</td>
                                  <td>${formatNumber(r.Value, 0)}</td>
                                  <td>
                                    {formatCurrency(r['Avg Paid'], 'USD', 2)}
                                  </td>
                                  <td
                                    className={
                                      r.Weight == r.Prev
                                        ? 'faded'
                                        : r.Weight > r.Prev
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatNumber(r.Weight, 2)}
                                  </td>
                                  <td>{formatNumber(r.Prev, 2)}</td>
                                  <td>{formatDate(r['Source Date'])}</td>
                                  <td>{formatDate(r['Date Reported'])}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {userType === 'Member' && (
                        <div>
                          <div className='text-center'>
                            <br />
                            <p style={{ margin: '0 auto 2px' }}>
                              Premium Content
                            </p>
                            <p className='small faded'>
                              See which stocks Warren Buffett and other icons
                              hold from the ETFs.
                            </p>
                            <small>
                              <a
                                onClick={() => handleShowUpgrade(true)}
                                className='btn btn-primary'
                              >
                                Upgrade to Premium
                              </a>
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='text-center'>
                    <h2>Global Stocks Also Held By</h2>
                    <h5 className='faded'>
                      Taken from SEC (i.e. 13F/C) filings of the investor's
                      portfolio. Use this to further your conviction on a stock.
                    </h5>

                    <div className='pumpdump'>
                      <a
                        className='btn btn-secondary'
                        style={{
                          margin: '10px auto 0',
                        }}
                        onClick={() =>
                          alsoHeldByHeight !== '100%'
                            ? setAlsoHeldByHeight('100%')
                            : setAlsoHeldByHeight('200px')
                        }
                      >
                        {alsoHeldByHeight !== '100%' ? 'Expand' : 'Collapse'}
                      </a>
                    </div>
                    <br />

                    <div
                      className='h-scroll'
                      style={{
                        height: alsoHeldByHeight,
                        overflow: 'auto',
                        maxWidth: '1600px',
                        margin: 'auto',
                      }}
                    >
                      {userType === 'Premium' && (
                        <table>
                          <thead>
                            <tr>
                              {investorHeaders.map((d) => (
                                <th key={d.id} className={d.id} title={d.title}>
                                  {d.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {investors
                              .filter((v) => GlobalTickers.includes(v.Symbol))
                              .sort((a, b) => {
                                return `${a.Symbol}`.localeCompare(
                                  `${b.Symbol}`
                                );
                              })
                              .map((r, index) => (
                                <tr
                                  className='link'
                                  key={`${r.Symbol}${index}`}
                                  onClick={() =>
                                    handleShowModalWithAggChart(
                                      r.Symbol,
                                      'Ark Invest'
                                    )
                                  }
                                >
                                  <td>{r.Investor}</td>
                                  <td className='Company'>{r.Company}</td>
                                  <Preview
                                    ticker={r.Symbol}
                                    itemIndex={index}
                                    breakPoint='4'
                                  />
                                  <td
                                    className={
                                      r.Type === 'Stock'
                                        ? ''
                                        : r.Type === 'Call'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {r.Type}
                                  </td>
                                  <td>{formatNumber(r.Holdings, 0)}</td>
                                  <td>${formatNumber(r.Value, 0)}</td>
                                  <td>
                                    {formatCurrency(r['Avg Paid'], 'USD', 2)}
                                  </td>
                                  <td
                                    className={
                                      r.Weight == r.Prev
                                        ? 'faded'
                                        : r.Weight > r.Prev
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {formatNumber(r.Weight, 2)}
                                  </td>
                                  <td>{formatNumber(r.Prev, 2)}</td>
                                  <td>{formatDate(r['Source Date'])}</td>
                                  <td>{formatDate(r['Date Reported'])}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {userType === 'Member' && (
                        <div>
                          <div className='text-center'>
                            <br />
                            <p style={{ margin: '0 auto 2px' }}>
                              Premium Content
                            </p>
                            <p className='small faded'>
                              See which stocks Warren Buffett and other icons
                              hold from the ETFs.
                            </p>
                            <small>
                              <a
                                onClick={() => handleShowUpgrade(true)}
                                className='btn btn-primary'
                              >
                                Upgrade to Premium
                              </a>
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='text-center'>
                    <h2>Upcoming Earnings</h2>
                    <h5 className='faded'>
                      Track what the Funds are doing prior to Earnings. Premium
                      Only.
                    </h5>
                    <div className='pumpdump'>
                      <a
                        onClick={() =>
                          limit === 10 ? setLimit(9999) : setLimit(10)
                        }
                        className='btn btn-secondary'
                      >
                        {' '}
                        Expand All{' '}
                      </a>
                    </div>
                    <br />
                    <div className='h-scroll'>
                      {userType === 'Premium' && (
                        <div style={{ padding: '10px' }}>
                          {fundOptionsData?.map((s) => (
                            <div className='' key={s.fundGroup}>
                              <h4
                                className='text-center'
                                style={{
                                  backgroundColor: s.color,
                                  color: '#fff',
                                }}
                              >
                                {s.fund}{' '}
                              </h4>
                              <div
                                className='panel-content'
                                style={{ marginBottom: '20px' }}
                              >
                                <UpcomingEarnings
                                  userType={userType}
                                  earningsData={s}
                                  handleShowUpgrade={handleShowUpgrade}
                                  type={type}
                                  sort='ticker'
                                  handleShowModalWithAggChart={
                                    handleShowModalWithAggChart
                                  }
                                  fundGroup={s.fund}
                                  limit={limit}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default All;
