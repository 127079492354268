import React, { useState, useEffect } from 'react';
import {
  stockVolumeHeaders,
  stockVolumeAdvancesHeaders,
  stockTabs,
} from '../helpers/fundvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import Preview from '../layouts/preview';
import '../styles/calendars.scss';
import { formatNumber } from '../helpers/format';

function Stock({
  myWatchlist,
  userType,
  currentFundGroup,
  handleShowModal,
  handleShowUpgrade,
  stockVolumeData,
  stockVolumeAdvancesData,
  date,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [stockVolume, setStockVolume] = useState([]);
  const [stockVolumeAdvances, setStockVolumeAdvances] = useState([]);
  const [orderReversed, setOrderReversed] = useState(false);
  const [orderMethod, setOrderMethod] = useState('Volume');
  const [selectedTab, setSelectedTab] = useState(stockTabs[0]);

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
    if (tab === stockTabs[0]) {
      setOrderMethod(['Volume']);
      setStockVolume(stockVolumeData);
    } else if (tab === stockTabs[1]) {
      setOrderMethod(['Volume']);
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = stockVolumeData.filter((f) =>
        WLtickers.includes(f.Symbol)
      );
      setStockVolume(filteredByWL);
    } else if (tab === stockTabs[2]) {
      setOrderMethod(['% Volume']);
      setStockVolumeAdvances(stockVolumeAdvancesData);
    } else if (tab === stockTabs[3]) {
      setOrderMethod(['% Volume']);
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = stockVolumeAdvancesData.filter((f) =>
        WLtickers.includes(f.Symbol)
      );
      setStockVolumeAdvances(filteredByWL);
    }
  };

  useEffect(() => {
    setStockVolume(stockVolumeData);
    setStockVolumeAdvances(stockVolumeAdvancesData);
    setIsLoading(false);
  }, [stockVolumeData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`calendars ${currentFundGroup}`}>
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} stockVolume`} />
      )}

      {!isLoading && (
        <div>
          <div>
            <div className='header-content d-flex'>
              <h1>
                <span className='hidden-mobile'>Stock Activity </span>
              </h1>
              <div className='tabs'>
                {stockTabs.map((tab) => (
                  <div
                    key={tab}
                    className={selectedTab === tab ? 'tab active' : 'tab'}
                    onClick={() => handleSetSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>

            <div>
              {userType === 'Member' && (
                <div>
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    <br />
                    <div className='jumbo'>🚀</div>
                    <h2>Upgrade to Premium</h2>
                    <p style={{ marginBottom: '20px' }}>
                      Get access to Market Activity: Stock for{' '}
                      {currentFundGroup} ETFs and premium member features.
                    </p>

                    <a
                      onClick={() => handleShowUpgrade(true)}
                      className='btn btn-primary'
                    >
                      Upgrade
                    </a>
                  </div>
                </div>
              )}
              {userType === 'Premium' && (
                <div className='h-scroll'>
                  {(selectedTab == stockTabs[0] ||
                    selectedTab == stockTabs[1]) && (
                    <div>
                      {stockVolume.length > 0 && (
                        <table className='striped h-scroll fixed'>
                          <thead>
                            <tr>
                              {stockVolumeHeaders.map((d) => (
                                <th
                                  onClick={() => handleSetOrderMethod(d.id)}
                                  key={d.id}
                                  className={d.id}
                                  title={d.title}
                                >
                                  {d.name}
                                  {orderMethod.toString() === d.id && (
                                    <i
                                      className={
                                        orderReversed
                                          ? 'ion-arrow-down-b small'
                                          : 'ion-arrow-up-b small'
                                      }
                                    />
                                  )}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {stockVolume &&
                              stockVolume
                                .sort((a, b) => {
                                  if (isNaN(a[orderMethod])) {
                                    if (orderReversed) {
                                      return `${a[orderMethod]}`.localeCompare(
                                        `${b[orderMethod]}`
                                      );
                                    } else {
                                      return `${b[orderMethod]}`.localeCompare(
                                        `${a[orderMethod]}`
                                      );
                                    }
                                  } else {
                                    if (orderReversed) {
                                      return a[orderMethod] - b[orderMethod];
                                    } else {
                                      return b[orderMethod] - a[orderMethod];
                                    }
                                  }
                                })
                                .map((r, index) => (
                                  <tr
                                    className='link'
                                    key={r.Symbol}
                                    onClick={() => handleShowModal(r.Symbol)}
                                  >
                                    <Preview
                                      title='Ticker'
                                      CSSclass='strong'
                                      ticker={r.Symbol}
                                      itemIndex={index}
                                      breakPoint='4'
                                    />
                                    <td>{r.Name}</td>
                                    <td>{formatNumber(r.Volume, 0)}</td>
                                    <td>{r.Time}</td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      )}
                      {stockVolume.length === 0 &&
                        selectedTab === stockTabs[1] && (
                          <div
                            className='panel'
                            style={{
                              padding: '20px 10px',
                              maxWidth: '800px',
                              textAlign: 'center',
                              border: 'none',
                            }}
                          >
                            <div className='jumbo'>🧐</div>
                            <h2>
                              No Most Traded Stocks in your {currentFundGroup}{' '}
                              Watchlist.
                            </h2>
                            <p style={{ marginBottom: '20px' }}>
                              Check out the{' '}
                              <a
                                onClick={() =>
                                  handleSetSelectedTab(stockTabs[0])
                                }
                              >
                                {stockTabs[0]} Tab
                              </a>
                              .
                            </p>
                          </div>
                        )}
                    </div>
                  )}
                  {(selectedTab == stockTabs[2] ||
                    selectedTab == stockTabs[3]) && (
                    <div>
                      {stockVolumeAdvances.length > 0 && (
                        <table className='striped h-scroll fixed'>
                          <thead>
                            <tr>
                              {stockVolumeAdvancesHeaders.map((d) => (
                                <th
                                  onClick={() => handleSetOrderMethod(d.id)}
                                  key={d.id}
                                  className={d.id}
                                  title={d.title}
                                >
                                  {d.name}
                                  {orderMethod.toString() === d.id && (
                                    <i
                                      className={
                                        orderReversed
                                          ? 'ion-arrow-down-b small'
                                          : 'ion-arrow-up-b small'
                                      }
                                    />
                                  )}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {stockVolumeAdvances &&
                              stockVolumeAdvances
                                .sort((a, b) => {
                                  if (isNaN(a[orderMethod])) {
                                    if (orderReversed) {
                                      return `${a[orderMethod]}`.localeCompare(
                                        `${b[orderMethod]}`
                                      );
                                    } else {
                                      return `${b[orderMethod]}`.localeCompare(
                                        `${a[orderMethod]}`
                                      );
                                    }
                                  } else {
                                    if (orderReversed) {
                                      return a[orderMethod] - b[orderMethod];
                                    } else {
                                      return b[orderMethod] - a[orderMethod];
                                    }
                                  }
                                })
                                .map((r, index) => (
                                  <tr
                                    className='link'
                                    key={r.Symbol}
                                    onClick={() => handleShowModal(r.Symbol)}
                                  >
                                    <Preview
                                      title='Ticker'
                                      CSSclass='strong'
                                      ticker={r.Symbol}
                                      itemIndex={index}
                                      breakPoint='4'
                                    />
                                    <td>{r.Name}</td>
                                    <td>{formatNumber(r.Volume, 0)}</td>
                                    <td
                                      className={
                                        r['% Volume'] > 0 ? 'success' : 'danger'
                                      }
                                    >
                                      {formatNumber(r['% Volume'] * 100, 2)}%
                                    </td>
                                    <td>{r.Time}</td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      )}
                      {stockVolumeAdvances.length === 0 &&
                        selectedTab === stockTabs[3] && (
                          <div
                            className='panel'
                            style={{
                              padding: '20px 10px',
                              maxWidth: '800px',
                              textAlign: 'center',
                              border: 'none',
                            }}
                          >
                            <div className='jumbo'>🧐</div>
                            <h2>
                              No Volume Advances in your {currentFundGroup}{' '}
                              Watchlist.
                            </h2>
                            <p style={{ marginBottom: '20px' }}>
                              Check out the{' '}
                              <a
                                onClick={() =>
                                  handleSetSelectedTab(stockTabs[2])
                                }
                              >
                                {stockTabs[2]} Tab
                              </a>
                              .
                            </p>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Stock;
