import React, { useState } from 'react';
import Widget from './widget';

function Preview({
  ticker,
  itemIndex,
  breakPoint,
  handleShowModal,
  CSSclass,
  title,
  type,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSetIsHovered = (boolean) => {
    setIsHovered(boolean);
    if (itemIndex < breakPoint) {
      setIsExpanded(true);
    }
  };

  if (!type) {
    return (
      <td
        title={title}
        className={
          isExpanded
            ? `preview-td ${CSSclass}`
            : `expanded preview-td ${CSSclass}`
        }
        onMouseOver={() => handleSetIsHovered(true)}
        onMouseLeave={() => handleSetIsHovered(false)}
        onClick={() => (handleShowModal ? handleShowModal(ticker) : null)}
      >
        <b>{ticker}</b>
        {isHovered && (
          <div className='widget'>
            <Widget ticker={ticker} />
          </div>
        )}
      </td>
    );
  } else {
    return (
      <div
        title={title}
        className={
          isExpanded
            ? `preview-td ${CSSclass}`
            : `expanded preview-td ${CSSclass}`
        }
        onMouseOver={() => handleSetIsHovered(true)}
        onMouseLeave={() => handleSetIsHovered(false)}
        onClick={() => (handleShowModal ? handleShowModal(ticker) : null)}
      >
        <b>{ticker}</b>
        {isHovered && (
          <div className='widget'>
            <Widget ticker={ticker} />
          </div>
        )}
      </div>
    );
  }
}
export default Preview;
