import React, { useState, useEffect } from 'react';
import '../styles/landing.scss';
import { getLogin } from '../helpers/api';

const referrerFromLocalStorage = localStorage.getItem('referrer');
let search = window.location.search;
let params = new URLSearchParams(search);
let referralParam = params.get('join');

function Landing() {
  const [selectedView, setSelectedView] = useState('Sign In');
  const [alert, setAlert] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [how, setHow] = useState('');
  const [referrer, setReferrer] = useState(referrerFromLocalStorage);

  useEffect(() => {
    if (referralParam) setSelectedView('Sign Up');
  }, []);

  const logUserIn = async () => {
    const result = await getLogin(email, password);
    if (result.error) {
      setAlert(result.error);
      setTimeout(() => {
        setAlert('');
      }, 8000);
    }
  };

  const signInNow = () => {
    window.history.replaceState(null, null, window.location.pathname);
    setSelectedView('Sign In');
  };

  const signUpUser = () => {
    document
      .querySelector('#submitregistrationform')
      .click((e) => e.preventDefault());
    setTimeout(() => {
      setSelectedView('Success');
      setPassword('');
      setFirstName('');
      setLastName('');
      setHow('');
      setReferrer('');
      localStorage.removeItem('referrer');
    }, 500);
  };

  return (
    <div className='landing'>
      <p>
        <img
          src='https://lucidtracking.s3.amazonaws.com/assets/logos/LUCID.svg'
          alt='Logo'
          className='logo'
        />
        <sup>beta</sup>
        <iframe
          name='secret-frame'
          width='0'
          height='0'
          border='0'
          className='hidden'
        ></iframe>
      </p>

      <div
        className='faded'
        style={{ margin: '15px auto 0', maxWidth: '600px' }}
      >
        Market Data Aggregation Tool for tracking ARK Invest, Black Rock, Global
        X, Crypto, and trending Meme Stocks with Short Interest.
      </div>

      {selectedView === 'Sign Up' && (
        <div>
          <div className='panel'>
            <h3 className='text-center'>Request Membership.</h3>
            <p className='faded small text-center'>
              Free during beta. Premium features available.
            </p>

            <form
              className='form'
              action='https://docs.google.com/forms/d/e/1FAIpQLSdcdPSpMo9Cdai_leUc9PW_QuLaW9QFpUnkfdF-b7e6VcrlMA/formResponse'
              id='ss-form1'
              method='POST'
              target='secret-frame'
            >
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input
                  name='entry.1599458351'
                  type='email'
                  value={email}
                  autoComplete='email'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='firstName'>First Name</label>
                <input
                  name='entry.2069467006'
                  type='text'
                  value={firstName}
                  autoComplete='given-name'
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='registrationLastName'>Last Name</label>
                <input
                  name='entry.1731993222'
                  type='text'
                  value={lastName}
                  autoComplete='family-name'
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='registrationHow'>
                  How'd you hear about us?
                </label>
                <input
                  name='entry.272060154'
                  type='text'
                  value={how}
                  onChange={(e) => setHow(e.target.value)}
                />
              </div>

              <div className='form-group hidden'>
                <label htmlFor='referrer'>Referrer</label>
                <input
                  name='entry.1351829643'
                  type='text'
                  value={referrer}
                  onChange={(e) => setReferrer(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <button
                  className='btn btn-primary'
                  disabled={
                    email.length === 0 ||
                    firstName.length === 0 ||
                    lastName.length === 0
                  }
                  onClick={() => signUpUser()}
                >
                  Sign Up <span>{email}</span>
                </button>
              </div>

              <input
                className='hidden'
                id='submitregistrationform'
                type='submit'
                value='Submit Registration'
              />
            </form>

            <div className='small text-center'>
              By using Lucidtracking.com you agree to our{' '}
              <a href='/terms' target='_blank'>
                Terms of Service.
              </a>
            </div>
          </div>
          <p className='text-center'>
            Already have an account?{' '}
            <a href='' onClick={() => signInNow()}>
              Sign In
            </a>
          </p>
        </div>
      )}

      {selectedView === 'Sign In' && (
        <div>
          <div className='panel'>
            <h3 className='text-center'>Please Sign In</h3>
            <p className='faded small text-center'>Best viewed on desktop</p>
            <div className={alert.length ? 'small danger' : 'hidden'}>
              {alert}
            </div>

            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input
                name='email'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete='email'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <input
                name='password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete='password'
              />
            </div>
            <button className='btn-primary' onClick={() => logUserIn()}>
              Sign In
            </button>
            <div className='small text-center'>
              By using Lucidtracking.com you agree to our{' '}
              <a href='/terms' target='_blank'>
                Terms of Service.
              </a>
            </div>
          </div>

          <p>
            Don't have an account?{' '}
            <a onClick={() => setSelectedView('Sign Up')}>Request Membership</a>
          </p>
        </div>
      )}

      {selectedView === 'Success' && (
        <div className='panel'>
          <h3>Thanks for requesting membership.</h3>

          <p>
            Check <b>{email}</b> in a few mins to get your login credentials. We
            are in beta and request that you send us feedback as you use Lucid.
          </p>

          <p className='danger'>
            Due to high user demand for Lucid, it may take up to 2 hours to
            process your registration.
          </p>
        </div>
      )}

      <small className='disclaimer faded'>
        Recommendations, data, charting, analysis, and comments presented on
        Lucidtracking.com do not represent the opinions of Lucidtracking.com on
        whether to buy, sell or hold shares of stock and crypto. Investors
        should be cautious about any and all stock recommendations and should
        consider the source of any advice on asset selection. Various factors,
        including personal or corporate ownership, may influence or factor into
        an expert's asset analysis or opinion. All investors are advised to
        conduct their own independent research into individual assets before
        making a purchase decision and are advised that past asset performance
        is no guarantee of future price appreciation.
      </small>

      <h2>Lucid in the Wild</h2>
      <div className='video-container'>
        <div className='hidden-mobile'>
          <iframe
            title='Palantir'
            width={560 / 2}
            height={315 / 2}
            src='https://www.youtube.com/embed/QT4NRS1A7og'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
          <iframe
            title='Lucid'
            width={560 / 2}
            height={315 / 2}
            src='https://www.youtube.com/embed/pmpwt9p7JcA?start=187'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>
        <div>
          <iframe
            title='Lucid Video'
            width={560 / 2}
            height={315 / 2}
            src='https://www.youtube.com/embed/4xsA8MReNgo'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
          <iframe
            title='Video of Lucid'
            width={560 / 2}
            height={315 / 2}
            src='https://www.youtube.com/embed/3bERKe4MMh4'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>
        <div>
          <iframe
            title='Lucid in the wild'
            width={560 / 2}
            height={315 / 2}
            src='https://www.youtube.com/embed/df9CBTH19ro?start=400'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
          <iframe
            video='Stock video using Lucid'
            width={560 / 2}
            height={315 / 2}
            src='https://www.youtube.com/embed/hrbh00Byw6E?start=400'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Landing;
