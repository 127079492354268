import React, { useState, useEffect } from 'react';
import TradingViewWidget, { Themes, BarStyles } from 'react-tradingview-widget';

function SimpleModal({ ticker, name, theme, handleShowSimpleModal }) {
  const tabs = ['Profile', 'Chart'];
  const tradingViewTheme = theme ? Themes.LIGHT : Themes.DARK;
  const themeModule = theme ? 'light' : 'dark';
  const themeForTradingViewEmbed = theme ? 'light' : 'dark';
  const [ticky, setTicky] = useState(ticker);
  const [selectedTab, setSelectedTab] = useState('Profile');

  const getCharts = () => {
    setTimeout(() => {
      if (document.getElementById('companychart')) {
        const script = document.createElement('script');
        script.src =
          'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbol: ticky,
          //isTransparent: true,
          largeChartUrl: '',
          displayMode: 'adaptive',
          width: '100%',
          height: '100%',
          locale: 'en',
          colorTheme: themeModule,
        });

        document.getElementById('companychart').appendChild(script);
      }

      if (document.getElementById('fundamentalchart')) {
        const script = document.createElement('script');
        script.src =
          'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbol: ticky,
          colorTheme: themeForTradingViewEmbed,
          //isTransparent: true,
          largeChartUrl: '',
          displayMode: 'adaptive',
          width: '100%',
          height: '100%',
          locale: 'en',
          theme: themeModule,
        });

        document.getElementById('fundamentalchart').appendChild(script);
      }
    }, 500);
  };

  useEffect(() => {
    getCharts();
  }, []);

  return (
    <div className='modal'>
      <div className='detail'>
        <div className='header-content d-flex modal-header'>
          <h1>
            <a
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              onClick={() => handleShowSimpleModal('', '')}
            >
              <i className='ion-chevron-left'></i> {ticky}
              <span className='faded'>{name}</span>
            </a>
          </h1>
          <div className='tabs'>
            {tabs.map((tab) => (
              <div
                key={tab}
                className={selectedTab === tab ? 'tab active' : 'tab'}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className='right'>
            <a
              href={`https://finance.yahoo.com/quote/${ticky}`}
              target='_blank'
            >
              <button className='yahoo'>y!</button>
            </a>
          </div>
        </div>

        <div className='fade-in' style={{ height: 'calc(100vh - 50px)' }}>
          {selectedTab === 'Profile' && (
            <>
              <div className='info-container'>
                <div className='fade-in inside-div' id='companychart'></div>
                <div className='fade-in inside-div inside-div-chart'>
                  <TradingViewWidget
                    barstyles={BarStyles.LINE}
                    symbol={ticky}
                    theme={tradingViewTheme}
                    locale='us'
                    autosize
                    interval='D'
                    timezone='Etc/UTC'
                    style='3'
                    toolbar_bg='#f1f3f6'
                    enable_publishing='false'
                    hide_side_toolbar='true'
                    details={false}
                  />
                </div>
              </div>
              <div id='fundamentalchart'></div>
            </>
          )}

          {selectedTab === 'Chart' && (
            <TradingViewWidget
              symbol={ticky}
              theme={tradingViewTheme}
              locale='us'
              autosize
              interval='D'
              timezone='Etc/UTC'
              style='1'
              toolbar_bg='#f1f3f6'
              enable_publishing='false'
              hide_side_toolbar='false'
              details={true}
              studies={[
                'MACD@tv-basicstudies',
                'MASimple@tv-basicstudies',
                'OBV@tv-basicstudies',
                'RSI@tv-basicstudies',
                'PriceOsc@tv-basicstudies',
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default SimpleModal;
