import fire from './fire.js';

const email = 'member@lucidtracking.com';
const password = 'Lucid123456!';

export const signUserInFB = async () => {
  const user = await fire
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch((error) => console.log('error', error));
  return user;
};

export const getMemeFB = async () => {
  let res;
  const dbRef = await fire.database().ref();
  dbRef
    .child('1bpmk9KlVESRTm3gboUz9NfC6pMqHPP-6XY6XqMM9rvk')
    .child('mqv4')
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        res = snapshot.val();
        //console.log('finished', res);
        return res;
      } else {
        //console.log('No data available');
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const isFBUser = () => {
  fire.auth().onAuthStateChanged((user) => {
    return user;
  });
};
