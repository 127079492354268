import React, { useState, useEffect } from 'react';
import { calendarTabs, economicCalendarHeaders } from '../helpers/calendarvars';
import '../styles/aggregate.scss';
import Loader from '../layouts/loader';
import Preview from '../layouts/preview';
import '../styles/calendars.scss';
import { getEconomicCalendar } from '../helpers/api';
import { formatCurrency, formatDate, formatNumber } from '../helpers/format';

function Calendars({
  myWatchlist,
  userType,
  currentFundGroup,
  allTickers,
  handleShowModal,
  handleShowUpgrade,
  earningsData,
  date,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(calendarTabs[0]);
  const [earningsCalendar, setEarningsCalendar] = useState([]);
  const [economicCalendar, setEconomicCalendar] = useState([]);

  const getEarningsData = async () => {
    //setEarningsCalendar(earningsData);
    if (selectedTab === calendarTabs[0]) {
      setEarningsCalendar(earningsData);
    }
    if (selectedTab === calendarTabs[1]) {
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = earningsData.filter((f) =>
        WLtickers.includes(f.symbol)
      );
      setEarningsCalendar(filteredByWL);
    }
    setIsLoading(false);
  };

  const getEconomicData = async () => {
    const economicData = await getEconomicCalendar();
    setEconomicCalendar(economicData);
  };

  useEffect(() => {
    getEarningsData();
  }, [allTickers]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setSelectedCalendarTab = (tab) => {
    setSelectedTab(tab);
    if (tab === calendarTabs[0]) {
      setEarningsCalendar(earningsData);
    }
    if (tab === calendarTabs[1]) {
      const WLtickers = myWatchlist.map((m) => m.ticker);
      const filteredByWL = earningsData.filter((f) =>
        WLtickers.includes(f.symbol)
      );
      setEarningsCalendar(filteredByWL);
    }
    if (tab === calendarTabs[2]) {
      getEconomicData();
    }
  };

  return (
    <div className={`calendars ${currentFundGroup}`}>
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} Calendars`} />
      )}

      {!isLoading && (
        <div>
          <div>
            <div className='header-content d-flex'>
              <h1>Calendars</h1>
              <div className='tabs'>
                {calendarTabs.map((tab) => (
                  <div
                    key={tab}
                    className={selectedTab === tab ? 'tab active' : 'tab'}
                    onClick={() => setSelectedCalendarTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            </div>
            {(selectedTab === calendarTabs[0] ||
              selectedTab === calendarTabs[1]) && (
              <div>
                {userType === 'Member' && (
                  <div>
                    <div style={{ padding: '10px', textAlign: 'center' }}>
                      <br />
                      <div className='jumbo'>🚀</div>
                      <h2>Upgrade to Premium</h2>
                      <p style={{ marginBottom: '20px' }}>
                        Get access to Earnings Calendar for {currentFundGroup}{' '}
                        ETFs and premium member features.
                      </p>

                      <a
                        onClick={() => handleShowUpgrade(true)}
                        className='btn btn-primary'
                      >
                        Upgrade
                      </a>
                      <br />
                      <div className='aggregates'>
                        <img
                          className='sample'
                          src='https://lucidtracking.s3.amazonaws.com/assets/appassets/Earnings.png'
                          alt='Calendars Sample'
                        />
                        <br />
                      </div>
                    </div>
                  </div>
                )}
                {userType === 'Premium' && (
                  <div className='h-scroll'>
                    {selectedTab === calendarTabs[1] &&
                      earningsCalendar.length === 0 && (
                        <div
                          className='panel'
                          style={{
                            padding: '20px 10px',
                            maxWidth: '800px',
                            textAlign: 'center',
                            border: 'none',
                          }}
                        >
                          <div className='jumbo'>🧐</div>
                          <h2>
                            No upcoming earnings in your {currentFundGroup}{' '}
                            Watchlist.
                          </h2>
                          <p style={{ marginBottom: '20px' }}>
                            Check out the{' '}
                            <a
                              onClick={() =>
                                setSelectedCalendarTab(calendarTabs[0])
                              }
                            >
                              All Earnings Tab
                            </a>
                            .
                          </p>
                        </div>
                      )}
                    {earningsCalendar.length > 0 && (
                      <table className='striped h-scroll fixed'>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th className='Company hidden-mobile'>Company</th>
                            <th>Ticker</th>
                            <th>
                              Shares Held
                              <div>{date}</div>
                            </th>
                            <th>-1 Day</th>
                            <th>-7 Days</th>
                            <th>-14 Days</th>
                            <th>-30 Days</th>
                            <th>Estimate</th>
                            <th>Close</th>
                            <th>Previous</th>
                            <th>Weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          {earningsCalendar?.map((r, index) => (
                            <tr
                              key={`${r.symbol}${index}`}
                              className='link'
                              onClick={() => handleShowModal(r.symbol)}
                            >
                              <td>{formatDate(r.reportDate)}</td>
                              <td className='Company hidden-mobile'>
                                <b>{r.name}</b>
                              </td>
                              <Preview
                                title='Ticker'
                                CSSclass='strong'
                                ticker={r.symbol}
                                itemIndex={index}
                                breakPoint='4'
                              />
                              <td
                                className={
                                  r.shares === r.shares1d
                                    ? 'faded'
                                    : r.shares > r.shares1d
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares, 2)}
                              </td>
                              <td
                                className={
                                  r.shares1d === r.shares7d
                                    ? 'faded'
                                    : r.shares1d > r.shares7d
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares1d, 2)}
                              </td>
                              <td
                                className={
                                  r.shares7d === r.shares14d
                                    ? 'faded'
                                    : r.shares7d > r.shares14d
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares7d, 2)}
                              </td>
                              <td
                                className={
                                  r.shares14d === r.shares30
                                    ? 'faded'
                                    : r.shares14d > r.shares30
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {formatNumber(r.shares14d, 2)}
                              </td>
                              <td>{formatNumber(r.shares30d, 2)}</td>
                              <td
                                className={
                                  r.estimate < 0
                                    ? 'danger'
                                    : r.estimate > 0
                                    ? 'success'
                                    : 'faded'
                                }
                              >
                                {formatCurrency(r.estimate, 'USD', 2)}
                              </td>
                              <td
                                className={
                                  r.close < r.previous
                                    ? 'danger'
                                    : r.close > 0
                                    ? 'success'
                                    : 'faded'
                                }
                              >
                                {formatCurrency(r.close, 'USD', 2)}
                              </td>
                              <td>{formatCurrency(r.previous, 'USD', 2)}</td>
                              <td>{r.weight}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            )}
            {selectedTab === calendarTabs[2] && (
              <div className='h-scroll'>
                <table className='striped'>
                  <thead>
                    <tr>
                      {economicCalendarHeaders.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {economicCalendar.length > 0 &&
                      economicCalendar.map((item) => (
                        <tr key={item.time} className='link'>
                          <td>{formatDate(item.time)}</td>
                          <td>{item.country}</td>
                          <td>{item.event}</td>
                          <td>{item.impact}</td>
                          <td>{formatCurrency(item.actual, 'USD', true)}</td>
                          <td>{formatCurrency(item.estimate, 'USD', true)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Calendars;
