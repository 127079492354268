import React, { useState, useEffect } from 'react';
import Loader from '../layouts/loader';
import Preview from '../layouts/preview';
import '../styles/aggregate.scss';
import { formatNumber, formatCurrency, formatDate } from '../helpers/format';
import {
  getMyTickersFromLS,
  saveMyTickersToLS,
  getMyHeaders,
  stocklistHeaders,
} from '../helpers/fundvars';

function Aggregate({
  userType,
  stocklist,
  currentFundGroup,
  fundGroups,
  handleShowModal,
  handleShowUpgrade,
  type,
  sort,
}) {
  const [toggledTutorial, setToggledTutorial] = useState(false);
  const [toggledFilters, setToggledFilters] = useState(false);
  const [aggregateData, setAggregateData] = useState(stocklist);
  const [isLoading, setIsLoading] = useState(true);
  const [orderReversed, setOrderReversed] = useState(sort ? true : false);
  const [orderMethod, setOrderMethod] = useState(sort || 'bought');
  const [searchQuery, setSearchQuery] = useState('');
  const [myTickers, setMyTickers] = useState({});
  const [myEtfs, setMyEtfs] = useState([]);
  const [filterCount, setFilterCount] = useState();
  const [myHeaders, setMyHeaders] = useState(getMyHeaders());
  const etfs = fundGroups
    .find((f) => f.fundGroup === currentFundGroup)
    .data.map((d) => ({ fund: d.fund, name: d.name, type: d.type }));
  const allEtfs = etfs.map((f) => f.fund);
  useEffect(() => {
    if (stocklist?.length > 0) {
      setAggregateData(stocklist);
      setMyTickers(getMyTickersFromLS(currentFundGroup, stocklist));
      setMyEtfs(allEtfs);
      setIsLoading(false);
      toggleAllTickers();
      setTimeout(() => {
        setFilterCount(getCount());
        //console.log('myTickers', myTickers);
      }, 500);
    }
  }, [stocklist]);

  const addToMyTickers = (tickername) => {
    let tickers = [...myTickers.tickers];
    let myTicks = { fund: currentFundGroup, tickers: [] };
    if (tickers.includes(tickername)) {
      tickers.forEach((t, index) => {
        if (t === tickername) {
          tickers.splice(index, 1);
        }
        return t;
      });
      myTicks.tickers = tickers;
      setMyTickers(myTicks);
      saveMyTickersToLS(myTicks, currentFundGroup);
      setFilterCount(myTickers.tickers.length - 1);
    } else {
      tickers.push(tickername);
      myTicks.tickers = tickers;
      setMyTickers(myTicks);
      saveMyTickersToLS(myTicks, currentFundGroup);
      setFilterCount(myTickers.tickers.length + 1);
    }
  };

  const toggleAllTickers = () => {
    let myTicks = myTickers;
    let tempTickers = [];
    const stocklistTickers = stocklist.map((s) => s.ticker);
    if (myTicks) {
      if (
        myTicks.tickers &&
        myTicks.tickers.length === stocklist.length &&
        myTicks.tickers.length !== 0
      ) {
        setMyTickers({ fund: myTickers.fund, tickers: [] });
        setFilterCount(0);
      } else {
        //Checkmark All Tickers
        tempTickers = stocklistTickers;
        setMyTickers({ fund: myTickers.fund, tickers: tempTickers });
        saveMyTickersToLS(tempTickers, currentFundGroup);
        setFilterCount(tempTickers.length);
      }
    } else {
      //const tickers = getMyTickersFromLS(currentFundGroup, stocklist);
      tempTickers = stocklistTickers;
      setMyTickers({ fund: currentFundGroup, tickers: tempTickers });
      saveMyTickersToLS(tempTickers, currentFundGroup);
      setFilterCount(tempTickers.length);
    }
  };

  const handleToggleFilters = () => {
    if (myEtfs.length === 0) {
      setMyEtfs(allEtfs);
    }
    setToggledFilters(!toggledFilters);
  };

  const addToMyHeaders = (id) => {
    let myHeads = [...myHeaders];
    if (myHeads.includes(id)) {
      myHeads.forEach((m, index) => {
        if (m === id) {
          myHeads.splice(index, 1);
        }
      });
      setMyHeaders(myHeads);
      localStorage.setItem('myHeaders', JSON.stringify(myHeads));
    } else {
      myHeads.push(id);
      setMyHeaders(myHeads);
      localStorage.setItem('myHeaders', JSON.stringify(myHeads));
    }
  };

  const toggleAllHeaders = () => {
    let headers = stocklistHeaders.map((s) => s.id);
    if (myHeaders.length === headers.length) {
      setMyHeaders([]);
      localStorage.removeItem('myHeaders');
    } else {
      setMyHeaders(headers);
      localStorage.setItem('myHeaders', JSON.stringify(headers));
    }
  };

  const addToMyEtfs = (fund) => {
    if (myEtfs.includes(fund)) {
      setMyEtfs(myEtfs.filter((f) => f !== fund));
    } else {
      const appended = [...myEtfs, fund];
      setMyEtfs(appended);
    }
  };

  const toggleAllEtfs = () => {
    if (myEtfs.length === etfs.length) {
      setMyEtfs([]);
    } else {
      setMyEtfs(allEtfs);
    }
  };

  const filterData = (search) => {
    let tempData = aggregateData;

    const doSearch = () => {
      let filteredData = tempData.filter((s) =>
        Object.values(s).join(' ').toLowerCase().match(search.toLowerCase())
      );
      setSearchQuery(search);
      setAggregateData(filteredData);
    };

    if (searchQuery.length > search.length) {
      tempData = stocklist;
    }

    doSearch();
  };

  const getCount = () => {
    let tickerLength;
    if (myTickers && myTickers.tickers) {
      tickerLength = myTickers.tickers.length;
    } else {
      tickerLength = 0;
    }
    return tickerLength;
  };

  const handleSetOrderMethod = (header) => {
    if (header === orderMethod) {
      setOrderReversed(!orderReversed);
    } else {
      setOrderMethod(header);
    }
  };

  return (
    <div className={`aggregates-page ${currentFundGroup}`}>
      {isLoading && (
        <Loader title={`${userType} ${currentFundGroup} Aggregate`} />
      )}

      {!isLoading && (
        <div>
          <h1 className='header-content d-flex'>
            <div>
              <span>
                {type} <span className='hidden-mobile'>Aggregate</span>
              </span>{' '}
              <span className='faded'>{formatDate(stocklist[0].date)}</span>{' '}
              <a>
                <i
                  className='ion-help-circled'
                  onClick={() => setToggledTutorial(!toggledTutorial)}
                ></i>
              </a>
            </div>
            <div className='actions-container'>
              <div className='search-container'>
                <input
                  type='text'
                  placeholder='Search Aggregates...'
                  value={searchQuery}
                  onChange={(e) => filterData(e.target.value)}
                />
                <i
                  className={
                    searchQuery.length > 1 ? 'ion-ios-close' : 'hidden'
                  }
                  onClick={() => filterData('')}
                ></i>
              </div>

              <i
                className='ion-android-funnel'
                onClick={() => handleToggleFilters()}
              ></i>
              <span className='badge'>{filterCount}</span>
            </div>
          </h1>
          {toggledFilters && (
            <div className='aggregate-filters'>
              <div className='column' style={{ borderRight: '2px solid #222' }}>
                <strong onClick={() => toggleAllEtfs()}>
                  <i
                    className={
                      myEtfs &&
                      myEtfs.length === etfs.length &&
                      myEtfs.length > 0
                        ? 'ion-android-checkbox success'
                        : 'ion-android-checkbox-blank'
                    }
                  ></i>{' '}
                  All ETFs
                </strong>
                {etfs.map((etf) => (
                  <div key={etf.fund} onClick={() => addToMyEtfs(etf.fund)}>
                    <i
                      className={
                        myEtfs && myEtfs.includes(etf.fund)
                          ? 'ion-android-checkbox success'
                          : 'ion-android-checkbox-blank'
                      }
                    ></i>
                    {etf.fund} <small className='faded'>{etf.name}</small>
                  </div>
                ))}
              </div>

              <div className='column' style={{ borderRight: '2px solid #222' }}>
                <strong onClick={() => toggleAllTickers()}>
                  <i
                    className={
                      myTickers?.tickers &&
                      myTickers?.tickers?.length === aggregateData.length &&
                      myTickers?.tickers?.length > 0
                        ? 'ion-android-checkbox success'
                        : 'ion-android-checkbox-blank'
                    }
                  ></i>{' '}
                  All Tickers
                </strong>
                {aggregateData.map((ticker) => (
                  <div
                    key={ticker.ticker}
                    onClick={() => addToMyTickers(ticker.ticker)}
                  >
                    <i
                      className={
                        myTickers?.tickers &&
                        myTickers?.tickers?.includes(ticker.ticker)
                          ? 'ion-android-checkbox success'
                          : 'ion-android-checkbox-blank'
                      }
                    ></i>
                    {ticker.ticker}{' '}
                    <small className='faded'>{ticker.company}</small>
                  </div>
                ))}
              </div>

              <div className='column'>
                <strong onClick={() => toggleAllHeaders()}>
                  <i
                    className={
                      myHeaders.length !== stocklistHeaders.length
                        ? 'ion-android-checkbox-blank'
                        : 'ion-android-checkbox success'
                    }
                  ></i>
                  Table Headers
                </strong>
                {stocklistHeaders.map((head) => (
                  <div key={head.id} onClick={() => addToMyHeaders(head.id)}>
                    <i
                      className={
                        myHeaders?.includes(head.id)
                          ? 'ion-android-checkbox success'
                          : 'ion-android-checkbox-blank'
                      }
                    ></i>
                    {head.name}
                  </div>
                ))}
              </div>
            </div>
          )}

          {userType === 'Premium' && toggledTutorial && (
            <>
              <p className='aggregates-tutorial'>
                Data summed across all ETFs giving you the net total across
                their entire portfolio for each individual stock. For example,{' '}
                {currentFundGroup} may have bought more shares of TSLA in one
                ETF but sold some in another ETF, and this page displays the net
                total of that data.
              </p>
              <p className='aggregates-tutorial'>
                Tip: sort by 7D, 14D, 30D Shares Change % to see what{' '}
                {currentFundGroup} is buying over time.
              </p>
            </>
          )}

          {userType === 'Member' && (
            <div>
              {toggledTutorial && (
                <div className='aggregates-tutorial'>
                  <p style={{ paddingBottom: 0 }}>
                    Data summed across all ETFs giving you the net total across
                    their entire portfolio for each individual stock. For
                    example, {currentFundGroup} may have bought more shares of
                    TSLA in one ETF but sold some in another ETF, and this page
                    displays the net total of that data.
                  </p>
                </div>
              )}
              <div className='text-center'>
                <div className='panel'>
                  <div className='jumbo'>🚀</div>
                  <h2>Upgrade to Premium</h2>
                  <p style={{ marginBottom: '20px' }}>
                    {' '}
                    Get access to Aggregate and premium member features.
                  </p>
                  <a
                    style={{ marginBottom: '0px' }}
                    onClick={() => handleShowUpgrade(true)}
                    className='btn btn-primary'
                  >
                    Upgrade to Premium
                  </a>
                </div>

                <img
                  className='sample'
                  src='https://lucidtracking.s3.amazonaws.com/assets/appassets/sample-aggregate.png'
                  alt='Aggregate Sample'
                />
              </div>
            </div>
          )}

          {userType === 'Premium' && (
            <div className='h-scroll'>
              <table className='striped fixed'>
                <thead>
                  <tr className='link'>
                    {type && (
                      <th onClick={() => handleSetOrderMethod('fund')}>Fund</th>
                    )}
                    {stocklistHeaders.map((header) => (
                      <th
                        key={header.id}
                        className={
                          myHeaders && myHeaders.includes(header.id)
                            ? `${header.name}`
                            : `${header.name} hidden`
                        }
                        title={header.name}
                        onClick={() => handleSetOrderMethod(header.id)}
                      >
                        {header.name}
                        {`${orderMethod}` === header.id && (
                          <i
                            className={
                              orderReversed
                                ? 'ion-arrow-down-b small'
                                : 'ion-arrow-up-b small'
                            }
                          />
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {aggregateData
                    .sort((a, b) => {
                      if (isNaN(a[orderMethod])) {
                        if (orderReversed) {
                          return `${a[orderMethod]}`.localeCompare(
                            `${b[orderMethod]}`
                          );
                        } else {
                          return `${b[orderMethod]}`.localeCompare(
                            `${a[orderMethod]}`
                          );
                        }
                      } else {
                        if (orderReversed) {
                          return a[orderMethod] - b[orderMethod];
                        } else {
                          return b[orderMethod] - a[orderMethod];
                        }
                      }
                    })
                    .map((item, index) => {
                      return (
                        <tr
                          //onClick={() => goTo(item.ticker)}
                          onClick={() => handleShowModal(item.ticker)}
                          key={`${item.ticker}${index}`}
                          title={`${item.ticker}: ${item.company}`}
                          className={
                            myTickers?.tickers &&
                            myTickers?.tickers?.includes(item.ticker) &&
                            myEtfs?.filter(
                              (e) => item?.etf?.split('|').indexOf(e) !== -1
                            ).length > 0
                              ? 'link'
                              : 'hidden'
                          }
                        >
                          {type && <td>{item.fund}</td>}
                          <td
                            className={
                              myHeaders && myHeaders.includes('company')
                                ? 'Company'
                                : 'hidden'
                            }
                          >
                            <b>{item.company}</b>
                          </td>
                          <Preview
                            CSSclass={
                              myHeaders && myHeaders.includes('ticker')
                                ? 'Ticker'
                                : 'hidden'
                            }
                            title='Ticker'
                            ticker={item.ticker}
                            itemIndex={index}
                            breakPoint='4'
                          />
                          <td
                            className={
                              myHeaders && myHeaders.includes('etf')
                                ? 'ETF'
                                : 'hidden'
                            }
                          >
                            {item.etf?.replaceAll('|', ', ')}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('bought')
                                ? 'Bought'
                                : 'hidden'
                            }
                          >
                            <span
                              className={item.bought === 0 ? 'faded' : 'hidden'}
                            >
                              No Change
                            </span>
                            {item.bought !== 0 && (
                              <span
                                className={
                                  item.bought < 0 ? 'danger' : 'success'
                                }
                              >
                                {formatCurrency(item.bought, 'USD', false)}
                              </span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d1shares')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d1shares === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d1shares > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d1shares)}
                              </span>
                            )}
                            {item.d1shares < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d1shares)}
                              </span>
                            )}
                            {item.d1shares === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d1delta')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d1delta === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d1delta > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d1delta)}%
                              </span>
                            )}
                            {item.d1delta < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d1delta)}%
                              </span>
                            )}
                            {item.d1delta === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d7shares')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d7shares === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d7shares > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d7shares)}
                              </span>
                            )}
                            {item.d7shares < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d7shares)}
                              </span>
                            )}
                            {item.d7shares === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d7delta')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d7delta === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d7delta > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d7delta)}%
                              </span>
                            )}
                            {item.d7delta < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d7delta)}%
                              </span>
                            )}
                            {item.d7delta === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d14shares')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d14shares === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d14shares > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d14shares)}
                              </span>
                            )}
                            {item.d14shares < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d14shares)}
                              </span>
                            )}
                            {item.d14shares === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d14delta')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d14delta === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d14delta > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d14delta)}%
                              </span>
                            )}
                            {item.d14delta < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d14delta)}%
                              </span>
                            )}
                            {item.d14delta === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d30shares')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d30shares === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d30shares > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d30shares)}
                              </span>
                            )}
                            {item.d30shares < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d30shares)}
                              </span>
                            )}
                            {item.d30shares === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('d30delta')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.d30delta === 0 && (
                              <span className='faded'>No Change</span>
                            )}
                            {item.d30delta > 0 && (
                              <span className='success'>
                                {' '}
                                +{formatNumber(item.d30delta)}%
                              </span>
                            )}
                            {item.d30delta < 0 && (
                              <span className='danger'>
                                {formatNumber(item.d30delta)}%
                              </span>
                            )}
                            {item.d30delta === null && (
                              <span className='faded'>No Data</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('close')
                                ? ''
                                : 'hidden'
                            }
                          >
                            <span
                              className={
                                item.close > item.previous
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {formatCurrency(item.close, 'USD', true)}
                            </span>
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('previous')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {formatCurrency(item.previous, 'USD', true)}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('weight')
                                ? ''
                                : 'hidden'
                            }
                          >
                            <span
                              className={
                                item.weight > item.previousweight
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {item.weight}%
                            </span>
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('previousweight')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.previousweight}%
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('changeinweight')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {formatNumber(item.changeinweight)}%
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('marketshare')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {formatCurrency(item.marketshare, 'USD', false)}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('shares')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {formatNumber(item.shares)}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('float')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {!item.float?.toString().length && (
                              <span className='faded'>No Data</span>
                            )}

                            <span
                              className={
                                item.float?.toString().length ? '' : 'hidden'
                              }
                            >
                              {formatNumber(item.float, 0)}
                            </span>
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('floatperc')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {!item.floatperc?.toString().length && (
                              <span className='faded'>No Data</span>
                            )}
                            <span
                              className={
                                item.floatperc?.toString().length
                                  ? ''
                                  : 'hidden'
                              }
                            >
                              {item.floatperc}%
                            </span>
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('wavgbuyprice')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {!item.wavgbuyprice && (
                              <span className='faded'>No Data</span>
                            )}

                            {item.wavgbuyprice &&
                              item.wavgbuyprice?.length === 0 && (
                                <span className='faded'>No Data</span>
                              )}

                            <span>
                              {item.wavgbuyprice &&
                                formatCurrency(item.wavgbuyprice, 'USD', 2)}
                            </span>
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('trdir')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.trdir ? (
                              <span
                                className={
                                  item.trdir === 'Buy' ? 'success' : 'danger'
                                }
                              >
                                {item.trdir}
                              </span>
                            ) : (
                              <span className='faded'>N/A</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('trshares')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.trshares ? (
                              <span
                                className={
                                  item.trdir === 'Buy' ? 'success' : 'danger'
                                }
                              >
                                {formatNumber(item.trshares, 0)}
                              </span>
                            ) : (
                              <span className='faded'>N/A</span>
                            )}
                          </td>
                          <td
                            className={
                              myHeaders && myHeaders.includes('trmarkval')
                                ? ''
                                : 'hidden'
                            }
                          >
                            {item.trmarkval ? (
                              <span
                                className={
                                  item.trdir === 'Buy' ? 'success' : 'danger'
                                }
                              >
                                {formatCurrency(item.trmarkval, 'USD', 0)}
                              </span>
                            ) : (
                              <span className='faded'>N/A</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Aggregate;
