import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { detailHeaders, detailTabs } from '../helpers/detailvars';
import Loader from '../layouts/loader';
import Comments from '../layouts/comments';
import Insider from '../pages/Insider';
import Squeeze from '../layouts/squeeze';
import Patterns from '../layouts/patterns';
import AlsoHeldBy from '../layouts/alsoheldby';
import UnusualOptions from '../layouts/unusualoptions';
import { formatNumber, formatCurrency, formatDate } from '../helpers/format';
import { checkIsWatching } from '../helpers/helpers';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import MyResponsiveLine from '../charts/line-nivo';
import TradingViewWidget, { Themes, BarStyles } from 'react-tradingview-widget';
import { getDetailInfo } from '../helpers/api';
import '../styles/detail.scss';
import Institutional from '../layouts/institutional';
import Senate from '../layouts/senate';

let ranking = [];

function Detail({
  userType,
  fundArray,
  addToWatchlist,
  ticker,
  handleShowModal,
  handleShowUpgrade,
  currentFundGroup,
  tickerTranslations,
  myWatchlist,
  allTickers,
  insiderData,
  date,
  type,
  chartDataFromAgg,
  isLoaded,
  topRankings,
  investorsData,
  optionsData,
  shortSqueezeData,
  institutionalData,
  senateData,
  theme,
}) {
  const history = useHistory();
  const paramsTicker = window.location.pathname.split('/');
  let detailTicker;

  const isDetail = paramsTicker.includes('detail');

  if (ticker) detailTicker = ticker;
  if (isDetail) detailTicker = decodeURI(paramsTicker[2]);

  if (topRankings) {
    ranking = topRankings
      .map((t) => t.data.find((tt) => tt.ticker === detailTicker))
      .filter((r) => r);
  }

  const tradingViewTheme = theme ? Themes.LIGHT : Themes.DARK;
  const themeModule = theme ? 'light' : 'dark';
  const themeForTradingViewEmbed = theme ? 'light' : 'dark';
  const [isLoading, setIsLoading] = useState(!isLoaded);
  const [isWatching, setIsWatching] = useState(false);
  const [selectedTab, setSelectedTab] = useState(detailTabs[1]);
  const [overview, setOverview] = useState([]);
  const [detail, setDetail] = useState([]);
  const [detailTab, setDetailTab] = useState([]);
  const [company, setCompany] = useState('');
  const [orderMethod, setOrderMethod] = useState('date');
  const [orderReversed, setOrderReversed] = useState(false);
  const [ticky, setTicky] = useState('');
  const [tabs, setTabs] = useState(detailTabs);
  const [patterns, setPatterns] = useState([]);
  const [
    filteredByTickerchartDataFromAgg,
    setFilteredByTickerChartDataFromAgg,
  ] = useState([]);
  const [tickerDetail, setTickerDetail] = useState({});

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
    if (tab === tabs[2]) {
      getCharts();
    }
  };

  const goTo = (route) => {
    history.push(`/${route}`);
  };

  const handleAddToWatchlist = (ticker, action) => {
    setIsWatching(!isWatching);
    addToWatchlist(ticker, action);
  };

  useEffect(() => {
    if (chartDataFromAgg?.length > 0) {
      const filtered = chartDataFromAgg
        .map((c) => {
          const tickerFormatted = c.data.filter(
            (d) => d.ticker.ticker === detailTicker
          );
          const data = c.data.filter((d) => d.ticker.ticker === detailTicker);
          return {
            ...c,
            ticker: detailTicker,
            data,
            company: data[0]?.ticker?.company,
          };
        })
        .filter((f) => f.data.length > 0);
      setFilteredByTickerChartDataFromAgg(filtered);
      setCompany(filtered[0]?.company);
    }
  }, [chartDataFromAgg]);

  useEffect(() => {
    if (fundArray?.length) {
      setIsWatching(checkIsWatching(detailTicker));
      setOverview(
        fundArray
          .map((f) => ({
            fund: f.fund,
            name: f.name,
            type: f.type,
            data: f.chartData.filter((s) => s.ticker == detailTicker),
          }))
          .filter((d) => d.data.length > 0) || chartDataFromAgg
      );

      const filteredFundArray = fundArray.filter((f) => {
        return f.list.find((ff) => ff.ticker == detailTicker);
      });
      const filteredFundDataArray = filteredFundArray.map((f) => {
        return {
          ...f,
          data: f.data.filter((dd) => dd.ticker == detailTicker).reverse(),
        };
      });

      setDetail(filteredFundDataArray);
      //setDetailTab(filteredFundArray[0].fund);
      setDetailTab(
        filteredFundArray?.length > 1 ? 'Snapshot' : filteredFundArray[0]?.fund
      );

      let tickerTrans = tickerTranslations.find(
        (t) => t.ticker === detailTicker
      );
      if (tickerTrans) {
        setTicky(
          tickerTranslations.find((t) => t.ticker == detailTicker).ticky
        );
      } else {
        setTicky(ticker);
      }
      setIsLoading(false);
    }
  }, [fundArray, detailTicker, ticker]);

  const orderBy = (orderMethod) => {
    setOrderMethod(orderMethod);
    setOrderReversed(!orderReversed);
  };

  const getTickerDetailApi = async () => {
    const dT = await getDetailInfo(detailTicker)[0];
    setTickerDetail(dT);
  };

  const getCharts = () => {
    setTimeout(() => {
      if (document.getElementById('companychart')) {
        const script = document.createElement('script');
        script.src =
          'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbol: ticky ? ticky : detailTicker,
          //isTransparent: true,
          largeChartUrl: '',
          displayMode: 'adaptive',
          width: '100%',
          height: '100%',
          locale: 'en',
          colorTheme: themeModule,
        });

        document.getElementById('companychart').appendChild(script);
      }

      if (document.getElementById('fundamentalchart')) {
        const script = document.createElement('script');
        script.src =
          'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbol: ticky ? ticky : detailTicker,
          colorTheme: themeForTradingViewEmbed,
          //isTransparent: true,
          largeChartUrl: '',
          displayMode: 'adaptive',
          width: '100%',
          height: '100%',
          locale: 'en',
          theme: themeModule,
        });

        document.getElementById('fundamentalchart').appendChild(script);
      }
    }, 500);
  };

  useEffect(() => {
    if (overview.length) setCompany(overview[0].data[0].company);
  }, [overview]);

  useEffect(() => {
    if (type) {
      setSelectedTab(detailTabs[0]);
      setIsLoading(false);
    }
    getCharts();

    //getTickerDetailApi();
  }, []);

  return (
    <div
      className={
        selectedTab === detailTabs[0] || selectedTab === detailTabs[4]
          ? `detail affix ${currentFundGroup}`
          : `detail ${currentFundGroup}`
      }
    >
      {isLoading && <Loader title={`${userType} ${detailTicker} Detail`} />}

      {!isLoading && (
        <div>
          <div>
            <div
              className={
                ticker
                  ? 'header-content d-flex modal-header'
                  : 'header-content d-flex'
              }
            >
              <h1>
                {detailTicker && (
                  <a
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                    onClick={() => (isDetail ? goTo('') : handleShowModal(''))}
                  >
                    <span>
                      <i className='ion-chevron-left' />
                      {detailTicker}{' '}
                    </span>

                    <span
                      className='hidden-mobile small'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '5px',
                      }}
                    >
                      <div
                        className={
                          overview?.length > 0
                            ? 'faded has-rank'
                            : 'faded detail-company'
                        }
                      >
                        {company}
                      </div>
                      {ranking?.length > 0 && (
                        <div title='Rank'>
                          #{ranking[0]?.rank}
                          <span
                            className={
                              ranking[0]?.rankchg === 0
                                ? ''
                                : ranking[0]?.rankchg > 0
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <i
                              style={{ margin: 0 }}
                              className={
                                ranking[0]?.rankchg < 0
                                  ? 'ion-arrow-down-a'
                                  : 'ion-arrow-up-a'
                              }
                            ></i>
                            {ranking[0]?.rankchg}%
                            {ranking?.length > 1 ? '' : ' in ETF'}
                          </span>
                        </div>
                      )}
                    </span>
                  </a>
                )}
              </h1>

              <div className='tabs'>
                {tabs.map((tab, index) => {
                  if (type) {
                    return index === 0 ||
                      index === 2 ||
                      index === 4 ||
                      index === 6 ? (
                      <div
                        key={tab}
                        className={selectedTab === tab ? 'tab active' : 'tab'}
                        onClick={() => handleSetSelectedTab(tab)}
                      >
                        {tab === 'Stock Info' ? (
                          <span>
                            <span className='hidden-mobile'>Stock </span>Info
                          </span>
                        ) : (
                          tab
                        )}
                      </div>
                    ) : (
                      ''
                    );
                  } else {
                    return (
                      index !== 0 && (
                        <div
                          key={tab}
                          className={selectedTab === tab ? 'tab active' : 'tab'}
                          onClick={() => handleSetSelectedTab(tab)}
                        >
                          {tab === 'Stock Info' ? (
                            <span>
                              <span className='hidden-mobile'>Stock </span>Info
                            </span>
                          ) : (
                            tab
                          )}
                        </div>
                      )
                    );
                  }
                })}
              </div>
              <div className='right'>
                {type && (
                  <a
                    href={`${window.location.origin}/detail/${detailTicker}/${type}`}
                    target='_blank'
                    style={{ margin: 'auto 2.5px 0 0' }}
                  >
                    <button>
                      {`${type}: ${detailTicker}`}
                      <i
                        className='ion-android-open faded'
                        style={{ marginLeft: '5px' }}
                      ></i>
                    </button>
                  </a>
                )}
                <a
                  href={`https://finance.yahoo.com/quote/${detailTicker}`}
                  target='_blank'
                >
                  <button className='yahoo'>y!</button>
                </a>
                {isWatching ? (
                  <button
                    className='btn btn-success'
                    onClick={() =>
                      handleAddToWatchlist(detailTicker, 'Removed')
                    }
                  >
                    Watching
                  </button>
                ) : (
                  <button
                    onClick={() => handleAddToWatchlist(detailTicker, 'Added')}
                  >
                    Watch
                  </button>
                )}
              </div>
            </div>
            {selectedTab === tabs[0] && (
              <div className='overview'>
                <div>
                  {userType === 'Member' && (
                    <div>
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        <br />
                        <div className='jumbo'>🚀</div>
                        <h2>Upgrade to Premium</h2>
                        <p style={{ marginBottom: '20px' }}>
                          Get access to Aggregate Charts and premium member
                          features.
                        </p>

                        <a
                          onClick={() => handleShowUpgrade(true)}
                          className='btn btn-primary'
                        >
                          Upgrade to Premium
                        </a>

                        <br />
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <div className='charts'>
                      <div>
                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Weight and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='weight'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>
                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Shares and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='shares'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Daily Capital Injection and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='dailycapitalinjection'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Weekly Capital Injection and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='weeklycapitalinjection'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Change in Weight and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={filteredByTickerchartDataFromAgg}
                                    type='portfolioweightdelta'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {selectedTab === tabs[3] && (
              <div className='overview'>
                <div>
                  {userType === 'Member' && (
                    <div>
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        <br />
                        <div className='jumbo'>🚀</div>
                        <h2>Upgrade to Premium</h2>
                        <p style={{ marginBottom: '20px' }}>
                          Get access to Charts and premium member features.
                        </p>

                        <a
                          onClick={() => handleShowUpgrade(true)}
                          className='btn btn-primary'
                        >
                          Upgrade to Premium
                        </a>

                        <br />
                      </div>
                    </div>
                  )}
                  {userType === 'Premium' && (
                    <div className='charts'>
                      <div>
                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Shares and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='shares'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>
                        {/* <div className='chart-container'>
                          <h3 className='text-center'>Shares</h3>
                          <div className='chart-container'>
                            <div
                              className='fade-in charts'
                              style={{
                                height: 400,
                                //minWidth: `${aggregateList.length * 20}px`,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='shares'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div> */}

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Weight and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='weight'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Daily Capital Injection and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='dailycapitalinjection'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Weekly Capital Injection and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='weeklycapitalinjection'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>

                        <div className='charts-combined chart-container'>
                          <h3 className='text-center'>
                            Change in Portfolio Weight and{' '}
                            <i
                              className='ion-record'
                              style={{
                                color: '#666',
                                fontSize: '.65em',
                                lineHeight: 1,
                                verticalAlign: '1px',
                              }}
                            />{' '}
                            Price
                          </h3>
                          <div className='chart-container'>
                            <div
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='close'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                          <div className='chart-container'>
                            <div
                              className='fade-in'
                              style={{
                                height: 400,
                              }}
                            >
                              <ParentSize>
                                {({ width, height }) => (
                                  <MyResponsiveLine
                                    width={width}
                                    height={height}
                                    chartData={overview}
                                    type='portfolioweightdelta'
                                  />
                                )}
                              </ParentSize>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {selectedTab === tabs[4] && (
              <div className='fade-in' style={{ height: 'calc(100vh - 50px)' }}>
                <TradingViewWidget
                  symbol={ticky}
                  theme={tradingViewTheme}
                  locale='us'
                  autosize
                  interval='D'
                  timezone='Etc/UTC'
                  style='1'
                  toolbar_bg='#f1f3f6'
                  enable_publishing='false'
                  hide_side_toolbar='false'
                  details={true}
                  studies={[
                    'MACD@tv-basicstudies',
                    'MASimple@tv-basicstudies',
                    'OBV@tv-basicstudies',
                    'RSI@tv-basicstudies',
                    'PriceOsc@tv-basicstudies',
                  ]}
                />
              </div>
            )}
            {/* {selectedTab === tabs[1] && (
              <div>
                {tickerDetail?.price} {tickerDetail?.ytd}{' '}
                {tickerDetail?.['3-Mo TR']} {tickerDetail?.longBusinessSummary}{' '}
                {tickerDetail?.industry} {tickerDetail?.sector}{' '}
                {tickerDetail?.website} {tickerDetail?.state}{' '}
                {tickerDetail?.country} hi
              </div>
            )} */}
            {selectedTab === tabs[1] && (
              <div className='detail-tab'>
                <div className='h-scroll sub-header double'>
                  <div className='tabs'>
                    {detail.length > 1 && (
                      <div
                        className={
                          detailTab === 'Snapshot' ? 'tab active' : 'tab'
                        }
                        onClick={() => setDetailTab('Snapshot')}
                      >
                        <div>
                          <div>Snapshot</div>
                          <small className='faded white'>8 Days</small>
                        </div>
                      </div>
                    )}
                    {detail.map((d) => (
                      <div
                        key={d.fund}
                        className={detailTab === d.fund ? 'tab active' : 'tab'}
                        onClick={() => setDetailTab(d.fund)}
                      >
                        <div>
                          <div>{d.fund}</div>
                          <small className='faded white'>{d.name}</small>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {detailTab === 'Snapshot' && (
                  <div className='detail-group'>
                    <div className='h-scroll'>
                      <table className='striped h-scroll fixed etfs'>
                        <thead>
                          <tr>
                            {detailHeaders.map((d) => (
                              <th
                                key={d.id}
                                className={d.id}
                                title={d.title}
                                onClick={() => orderBy(d.id)}
                              >
                                {d.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className='table-with-labels'>
                          {detail?.map((d) => {
                            const filteredData = d.data.slice(0, 9);

                            return filteredData.map((ff, index) => (
                              <>
                                {index === 0 && (
                                  <tr className='tr-label'>
                                    <td>{d.fund}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                )}
                                {index !== 8 && (
                                  <tr
                                    key={`${ff.fund}${index}`}
                                    className='link'
                                  >
                                    <td>{formatDate(ff.date)}</td>
                                    <td
                                      title={
                                        ff.shares ===
                                        filteredData[index + 1]?.shares
                                          ? 'No Change in Shares Held'
                                          : ff.shares >
                                            filteredData[index + 1]?.shares
                                          ? 'Add to position'
                                          : 'Reduced position'
                                      }
                                      className={
                                        ff.shares ===
                                        filteredData[index + 1]?.shares
                                          ? 'faded'
                                          : ff.shares >
                                            filteredData[index + 1]?.shares
                                          ? 'success'
                                          : 'danger'
                                      }
                                    >
                                      {ff.shares
                                        ? formatNumber(ff.shares, 0)
                                        : 'No Data'}{' '}
                                    </td>
                                    <td>{ff.weight}</td>
                                    <td>
                                      {formatCurrency(
                                        ff.sharesmarketvalue,
                                        'USD',
                                        0
                                      )}
                                    </td>
                                    <td
                                      className={
                                        ff.dailychange && ff.dailychange > 0
                                          ? 'success'
                                          : ff.dailychange == 0
                                          ? 'faded'
                                          : 'danger'
                                      }
                                    >
                                      {ff.dailychange && `${ff.dailychange}%`}
                                    </td>
                                    <td
                                      className={
                                        ff.dailycapitalinjection > 0
                                          ? 'success'
                                          : ff.dailycapitalinjection == 0
                                          ? 'faded'
                                          : 'danger'
                                      }
                                    >
                                      {formatCurrency(
                                        ff.dailycapitalinjection,
                                        'USD',
                                        0
                                      )}
                                    </td>
                                    <td
                                      className={
                                        ff.weeklycapitalinjection > 0
                                          ? 'success'
                                          : ff.weeklycapitalinjection == 0
                                          ? 'faded'
                                          : 'danger'
                                      }
                                    >
                                      {formatCurrency(
                                        ff.weeklycapitalinjection,
                                        'USD',
                                        0
                                      )}
                                    </td>
                                    <td
                                      className={
                                        ff.priceclosed > ff.previousclose
                                          ? 'success'
                                          : ff.priceclosed == ff.previousclose
                                          ? 'faded'
                                          : 'danger'
                                      }
                                    >
                                      ${formatNumber(ff.priceclosed, 2)}
                                    </td>
                                    <td>
                                      ${formatNumber(ff.previousclose, 2)}
                                    </td>
                                    <td className='tradenotedir'>
                                      {ff.tradenotedir ? (
                                        <span
                                          className={
                                            ff.tradenotedir === 'Buy'
                                              ? 'success'
                                              : 'danger'
                                          }
                                        >
                                          {ff.tradenotedir}
                                        </span>
                                      ) : (
                                        <span className='faded'>N/A</span>
                                      )}
                                    </td>
                                    <td className='tradenoteshares'>
                                      {ff.tradenoteshares ? (
                                        <span
                                          className={
                                            ff.tradenotedir === 'Buy'
                                              ? 'success'
                                              : 'danger'
                                          }
                                        >
                                          {formatNumber(ff.tradenoteshares, 0)}
                                        </span>
                                      ) : (
                                        <span className='faded'>N/A</span>
                                      )}
                                    </td>
                                    <td className='tradenotevalue'>
                                      {ff.tradenotevalue ? (
                                        <span
                                          className={
                                            ff.tradenotedir === 'Buy'
                                              ? 'success'
                                              : 'danger'
                                          }
                                        >
                                          {formatCurrency(
                                            ff.tradenotevalue,
                                            'USD',
                                            0
                                          )}
                                        </span>
                                      ) : (
                                        <span className='faded'>N/A</span>
                                      )}
                                    </td>
                                    <td>{ff.float}</td>
                                  </tr>
                                )}
                              </>
                            ));
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {detail.map((d, idx) => (
                  <div key={d.fund} className='detail-group'>
                    <div className='h-scroll'>
                      {detailTab === d.fund && (
                        <table className='striped h-scroll fixed'>
                          <thead>
                            <tr>
                              {detailHeaders.map((d) => (
                                <th
                                  key={d.id}
                                  className={d.id}
                                  title={d.title}
                                  onClick={() => orderBy(d.id)}
                                >
                                  {d.name}
                                  <i
                                    className={
                                      orderMethod === d.id && orderReversed
                                        ? 'ion-arrow-up-b small'
                                        : ''
                                    }
                                  ></i>
                                  <i
                                    className={
                                      orderMethod === d.id && !orderReversed
                                        ? 'ion-arrow-down-b small'
                                        : ''
                                    }
                                  ></i>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {d.data
                              .sort((a, b) => {
                                if (isNaN(a[orderMethod])) {
                                  let aa;
                                  let bb;
                                  if (a[orderMethod].includes('/')) {
                                    aa = new Date(a[orderMethod]);
                                    bb = new Date(b[orderMethod]);
                                    if (orderReversed) {
                                      return aa - bb;
                                    } else {
                                      return bb - aa;
                                    }
                                  } else {
                                    if (orderReversed) {
                                      return `${a[orderMethod]}`.localeCompare(
                                        `${b[orderMethod]}`
                                      );
                                    } else {
                                      return `${b[orderMethod]}`.localeCompare(
                                        `${a[orderMethod]}`
                                      );
                                    }
                                  }
                                } else {
                                  if (orderReversed) {
                                    return a[orderMethod] - b[orderMethod];
                                  } else {
                                    return b[orderMethod] - a[orderMethod];
                                  }
                                }
                              })
                              .map((ff, index) => (
                                <tr
                                  key={`${ff.fund}${index}`}
                                  className='link'
                                  /* onClick={() =>
                                    goTo(`detail/${ff.data.ticker}`)
                                  } */
                                >
                                  <td>{formatDate(ff.date)}</td>
                                  <td
                                    title={
                                      ff.shares === d.data[index]?.shares
                                        ? 'No Change in Shares Held'
                                        : ff.shares > d.data[index]?.shares
                                        ? 'Add to position'
                                        : 'Reduced position'
                                    }
                                    className={
                                      ff.shares === d.data[index + 1]?.shares ||
                                      index === d.data.length - 1
                                        ? 'faded'
                                        : ff.shares > d.data[index + 1]?.shares
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >
                                    {ff.shares
                                      ? formatNumber(ff.shares, 0)
                                      : 'No Data'}{' '}
                                  </td>
                                  <td>{ff.weight}</td>
                                  <td>
                                    {formatCurrency(
                                      ff.sharesmarketvalue,
                                      'USD',
                                      0
                                    )}
                                  </td>
                                  <td
                                    className={
                                      ff.dailychange > 0
                                        ? 'success'
                                        : ff.dailychange == 0
                                        ? 'faded'
                                        : 'danger'
                                    }
                                  >
                                    {`${ff.dailychange}%`}
                                  </td>
                                  <td
                                    className={
                                      ff.dailycapitalinjection > 0
                                        ? 'success'
                                        : ff.dailycapitalinjection == 0
                                        ? 'faded'
                                        : 'danger'
                                    }
                                  >
                                    {formatCurrency(
                                      ff.dailycapitalinjection,
                                      'USD',
                                      0
                                    )}
                                  </td>
                                  <td
                                    className={
                                      ff.weeklycapitalinjection > 0
                                        ? 'success'
                                        : ff.weeklycapitalinjection == 0
                                        ? 'faded'
                                        : 'danger'
                                    }
                                  >
                                    {formatCurrency(
                                      ff.weeklycapitalinjection,
                                      'USD',
                                      0
                                    )}
                                  </td>
                                  <td
                                    className={
                                      ff.priceclosed > ff.previousclose
                                        ? 'success'
                                        : ff.priceclosed == ff.previousclose
                                        ? 'faded'
                                        : 'danger'
                                    }
                                  >
                                    {formatCurrency(ff.priceclosed, 'USD', 2)}
                                  </td>
                                  <td>
                                    {formatCurrency(ff.previousclose, 'USD', 2)}
                                  </td>
                                  <td className='tradenotedir'>
                                    {ff.tradenotedir ? (
                                      <span
                                        className={
                                          ff.tradenotedir === 'Buy'
                                            ? 'success'
                                            : 'danger'
                                        }
                                      >
                                        {ff.tradenotedir}
                                      </span>
                                    ) : (
                                      <span className='faded'>N/A</span>
                                    )}
                                  </td>
                                  <td className='tradenoteshares'>
                                    {ff.tradenoteshares ? (
                                      <span
                                        className={
                                          ff.tradenotedir === 'Buy'
                                            ? 'success'
                                            : 'danger'
                                        }
                                      >
                                        {formatNumber(ff.tradenoteshares, 0)}
                                      </span>
                                    ) : (
                                      <span className='faded'>N/A</span>
                                    )}
                                  </td>
                                  <td className='tradenotevalue'>
                                    {ff.tradenotevalue ? (
                                      <span
                                        className={
                                          ff.tradenotedir === 'Buy'
                                            ? 'success'
                                            : 'danger'
                                        }
                                      >
                                        {formatCurrency(
                                          ff.tradenotevalue,
                                          'USD',
                                          0
                                        )}
                                      </span>
                                    ) : (
                                      <span className='faded'>N/A</span>
                                    )}
                                  </td>
                                  <td>{ff.float}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {selectedTab === tabs[5] && (
              <>
                <div className='panel-container text-center'>
                  <h2>Also Held By</h2>
                  <h5 className='faded'>
                    Taken from SEC (i.e. 13F/C) filings of the investor's
                    portfolio. Use this to further your conviction on a stock.
                  </h5>
                  <br />

                  <div
                    className='h-scroll'
                    style={{
                      overflow: 'auto',
                      maxWidth: '1200px',
                    }}
                  >
                    {userType === 'Premium' && (
                      <AlsoHeldBy
                        investorsData={investorsData}
                        handleShowModal={handleShowModal}
                        ticker={detailTicker}
                      />
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            See which stocks Warren Buffett and other icons hold
                            from the {currentFundGroup} ETFs.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Insider Buying/Selling</h2>
                  <h5 className='faded'>
                    See what the insiders (executives, owners, institutions) are
                    doing with their options and stocks for tickers held in{' '}
                    {currentFundGroup}'s ETFs.
                  </h5>

                  <br />
                  <div className='h-scroll full-height'>
                    {userType === 'Premium' && (
                      <Insider
                        myWatchlist={myWatchlist}
                        userType={userType}
                        currentFundGroup={currentFundGroup}
                        allTickers={allTickers}
                        handleShowModal={handleShowModal}
                        handleShowUpgrade={handleShowUpgrade}
                        insiderData={insiderData}
                        date={date}
                        ticker={detailTicker}
                      />
                    )}
                    {userType === 'Member' && (
                      <div>
                        <div className='text-center'>
                          <br />
                          <p style={{ margin: '0 auto 2px' }}>
                            Premium Content
                          </p>
                          <p className='small faded'>
                            Support Lucid and get access to <br />
                            premium content.
                          </p>
                          <small>
                            <a
                              onClick={() => handleShowUpgrade(true)}
                              className='btn btn-primary'
                            >
                              Upgrade to Premium
                            </a>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Institutional Ownership + Buying</h2>
                  <h5 className='faded'>
                    Stocks that institutional buyers such as mutual funds are
                    buying. Institutional ownership is the percentage of a
                    firm's shares held by these wired-in players, and ranges
                    from 50% to 95% for in favor stocks. We monitor
                    Institutional Transactions to confirm that they are still
                    buying shares. Premium only. Premium only.
                  </h5>
                  <br />
                  <div className='h-scroll full-height'>
                    <Institutional
                      institutionalData={institutionalData}
                      handleShowModal={handleShowModal}
                      ticker={detailTicker}
                    />
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>US Senate Buying</h2>
                  <h5 className='faded'>
                    Disclosed US Government Senate Buys and Sells. Premium only.
                  </h5>
                  <br />
                  <div className='h-scroll full-height'>
                    <Senate
                      senateData={senateData}
                      handleShowModal={handleShowModal}
                      ticker={detailTicker}
                    />
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Unusual Options Activity</h2>
                  <h5 className='faded'>
                    Options that are trading at a higher volume relative to the
                    contract's open interest. This signals a potential big move
                    in the stock.
                  </h5>
                  <br />
                  <div className='h-scroll full-height'>
                    <UnusualOptions
                      optionsData={optionsData}
                      handleShowModal={handleShowModal}
                      ticker={detailTicker}
                    />
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Short Squeeze</h2>
                  <a
                    href='https://www.investopedia.com/terms/s/shortsqueeze.asp'
                    target='_blank'
                  >
                    Large short positions pressured to close as price rises
                    causing prices to rise further. Premium only.{' '}
                    <i className='ion-android-open'></i>
                  </a>
                  <br />
                  <div className='h-scroll full-height'>
                    <Squeeze
                      shortSqueezeData={shortSqueezeData}
                      handleShowModal={handleShowModal}
                      ticker={detailTicker}
                    />
                  </div>
                </div>

                <div className='panel-container text-center'>
                  <h2>Patterns</h2>
                  <h5 className='faded'>
                    Distinctive formations created by the movements of security
                    prices on a chart and are the foundation of technical
                    analysis. Premium only.{' '}
                  </h5>
                  <br />
                  <div className='h-scroll full-height'>
                    <Patterns
                      patternData={patterns}
                      handleShowModal={handleShowModal}
                      ticker={detailTicker}
                    />
                  </div>
                  <br />
                  <br />
                </div>
              </>
            )}
            {selectedTab === tabs[6] && (
              <Comments
                ticker={detailTicker}
                handleShowUpgrade={handleShowUpgrade}
              />
            )}

            {selectedTab === tabs[2] && (
              <>
                <div className='info-container'>
                  <div className='fade-in inside-div' id='companychart'></div>
                  <div className='fade-in inside-div inside-div-chart'>
                    <TradingViewWidget
                      barstyles={BarStyles.LINE}
                      symbol={ticky}
                      theme={tradingViewTheme}
                      locale='us'
                      autosize
                      interval='D'
                      timezone='Etc/UTC'
                      style='3'
                      toolbar_bg='#f1f3f6'
                      enable_publishing='false'
                      hide_side_toolbar='true'
                      details={false}
                    />
                  </div>
                </div>
                <div id='fundamentalchart'></div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Detail;
